import React, { useEffect, useState } from "react"
import {
  makeStyles,
  IconButton,
  TableRow,
  TableCell,
  Collapse,
  Button
} from "@material-ui/core"
import { formatDate } from "../../Common/momentFunctions"
import { FormattedMessage, injectIntl } from "react-intl"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import axios from "axios"
import DishLoader from "../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import TransactionsList from "../TransactionsList"
import { getBudgetTransactions } from "../_axios/orderHistoryCrud"

const useStyles = makeStyles(theme => ({
  collapsibleRow: {
    "& > *": {
      borderBottom: "unset"
    }
  }
}))

function UserBudgetsItem({
  row,
  openBudgetDialog,
  intl
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [transactionsData, setTransactionsData] = useState({
    data: [],
    isLoading: false
  })

  function fetchBudgetTransactions(cancelToken) {
    setTransactionsData({ ...transactionsData, isLoading: true })
    getBudgetTransactions(row.budgetID, cancelToken.token)
      .then(({ data }) => {
        setTransactionsData({
          data,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_TRANSACTION_DATA_FOR_BUDGET"
          })
        )
      )
  }

  useEffect(() => {
    if (open) {
      const cancelToken = axios.CancelToken.source()
      fetchBudgetTransactions(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [open])

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.budgetID} className={classes.collapsibleRow}>
        <TableCell 
          component="th"
          scope="row"
          align="left"
          width={24}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{formatDate(row.from)}</TableCell>
        <TableCell align="left">{formatDate(row.to)}</TableCell>
        <TableCell align="left"><span className={classes.priceDefinition}>{row.balance}₪</span></TableCell>
        <TableCell align="left"><span className={classes.priceDefinition}>{row.intialBalance}₪</span></TableCell>
        <TableCell align="left">
          <Button
            variant="outlined"
            type="button"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={() => openBudgetDialog(row.budgetID, row.balance)}
          >
            <FormattedMessage id="BUTTON.FIX_BUDGET" />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="position-relative">
              {transactionsData.isLoading ? (
                <DishLoader height={30} minHeight={30} />
              ) : (
                <TransactionsList itemsData={transactionsData} type={'budget'}/>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default injectIntl(UserBudgetsItem)
