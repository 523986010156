/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../../Common/EnhancedTableHead"
import { stableSort } from "../../../Common/tableSortingFunctions"
import MealItem from "./MealItem"
import { useStyles } from "../../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Delete",
    disablePadding: true,
    notSortable: true,
    notForReadOnly: true,
    label: ""
  },
  {
    id: "Image",
    disablePadding: false,
    notSortable: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: false,
    label: <FormattedMessage id="MEAL_ITEM.LABELS.NAME" />
  },
  {
    id: "ImageUrl",
    disablePadding: false,
    label: <FormattedMessage id="MEAL_ITEM.LABELS.IMAGE" />
  },
  {
    id: "SupplierName",
    disablePadding: false,
    label: <FormattedMessage id="ADMIN_USERS.FILTER.SUPPLIER_FIELD.LABEL" />
  },
  {
    id: "Priority",
    disablePadding: false,
    label: <FormattedMessage id="MEAL_ITEM.PRIORITY_FIELD.LABEL" />
  },
  {
    id: "Days",
    disablePadding: false,
    notSortable: true,
    label: <FormattedMessage id="MEAL_ITEM.LABELS.DAYS" />
  },
  {
    id: "Prices",
    disablePadding: false,
    notSortable: true
  }
]

function desc(a, b, orderBy) {
  if (orderBy === "Name") {
    if (b.Item.Name < a.Item.Name) {
      return -1
    }
    if (b.Item.Name > a.Item.Name) {
      return 1
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function MealItemList({
  locationID,
  mealID,
  mealItems,
  selectedIDs,
  onSelect,
  onDaysChange,
  onItemFieldChange,
  setDateRangeDialogState
}) {
  const classes = useStyles()
  const rows = mealItems
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <MealItem
                      key={row.id}
                      locationID={locationID}
                      mealID={mealID}
                      row={row}
                      isSelected={selectedIDs.includes(row.Item.ItemID)}
                      onSelect={onSelect}
                      onItemFieldChange={onItemFieldChange}
                      onDaysChange={onDaysChange}
                      setDateRangeDialogState={setDateRangeDialogState}
                    />
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default MealItemList
