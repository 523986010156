/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import { Button, Paper, TablePagination } from "@material-ui/core"
import { getMealTypeText } from "../Common/mealTypesTranslation"
import renderRefundType from "../Common/customer/customer-function"
import clsx from "clsx"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "ItemName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ITEM_NAME" />
  },
  {
    id: "UserName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.EMPLOYEE_NAME" />
  },
  {
    id: "MealType",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.MEAL_TYPE" />
  },
  {
    id: "SupplierName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.RESTAURANT" />
  },
  {
    id: "CustomerName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER" />
  },
  {
    id: "OrderNumber",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDER_NUMBER" />
  },
  {
    id: "EmploeeAmount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.EMPLOYEE_AMOUNT" />
  },
  {
    id: "SubsidyAmount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUBSIDY_AMOUNT" />
  },
  {
    id: "TotalCost",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.TOTAL_COST" />
  },
  {
    id: "RefundAmount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.REFUND_AMOUNT" />
  },
  {
    id: "SuppplierCostRefund",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.REFUND_SUPPLIER_AMOUNT" />
  },
  {
    id: "Actions",
    disablePadding: true,
    notSortable: true,
    label: ""
  }
]

function ItemList({
  itemsData,
  paginationState,
  onPageChange,
  openRefundDialog,
  openCreditCardRefundDialog,
  intl
}) {
  const classes = useStyles()
  const rows = itemsData.displayedData
  const [emptyRows, setEmptyRows] = useState(0)

  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function handleChangePage(event, newPage) {
    onPageChange({ page: newPage })
  }

  function handleChangeRowsPerPage(event) {
    const rowsPerPageValue = +event.target.value
    onPageChange({ rowsPerPage: rowsPerPageValue })
  }

  useEffect(() => {
    if (paginationState) {
      let value =
        paginationState.rowsPerPage -
        Math.min(
          paginationState.rowsPerPage,
          itemsData.itemsCount -
            paginationState.page * paginationState.rowsPerPage
        )

      setEmptyRows(value)
    }
  }, [itemsData.displayedData])

  return (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
        <div className={clsx(classes.tableWrapper, classes.maxHeight)}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.OrderItemID}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.ItemName}
                      </TableCell>
                      <TableCell align="left">{row.UserName}</TableCell>
                      <TableCell align="left">
                        {getMealTypeText(intl, row.MealType)}
                      </TableCell>
                      <TableCell align="left">{row.SupplierName}</TableCell>
                      <TableCell align="left">{row.CustomerName}</TableCell>
                      <TableCell align="left">{row.OrderNumber}</TableCell>
                      <TableCell align="left">{row.EmploeeAmount}</TableCell>
                      <TableCell align="left">{row.SubsidyAmount}</TableCell>
                      <TableCell align="left">{row.TotalCost}</TableCell>
                      <TableCell align="left">{row.SubsidyRefundAmount + row.WalletRefundAmount + row.CostumerCreditRefundAmount + row.CreditCardRefundAmount + row.BudgetRefundAmount}</TableCell>
                      <TableCell align="left">{row.SuppplierCostRefund ?? 0}</TableCell>
                      <TableCell align="center">
                       <div>
                          {(!row.RefundDate && !row.RefundType)
                            ? (
                                <Button
                                  variant="outlined"
                                  type="button"
                                  size="small"
                                  color="secondary"
                                  className={classes.button}
                                  onClick={() =>
                                    openRefundDialog(
                                      row.OrderItemID,
                                      row.SubsidyAmount,
                                      row
                                    )
                                  }
                                >
                                  <FormattedMessage id="TABLE.SUBSIDY_REFUND_BUTTON" />
                                </Button>
                              )
                            : renderRefundType(row.RefundType, intl)}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={itemsData.itemsCount}
          rowsPerPage={paginationState.rowsPerPage}
          page={paginationState.page}
          labelRowsPerPage={intl.formatMessage({
            id: "TABLE.PAGINATION.ROWS_PER_PAGE"
          })}
          backIconButtonProps={{
            "aria-label": intl.formatMessage({
              id: "TABLE.PAGINATION.PREVIOUS_PAGE"
            })
          }}
          nextIconButtonProps={{
            "aria-label": intl.formatMessage({
              id: "TABLE.PAGINATION.NEXT_PAGE"
            })
          }}
          labelDisplayedRows={({ from, to, count }) =>
            intl.formatMessage(
              {
                id: "TABLE.PAGINATION.DISPLAYED_ROWS_LABEL"
              },
              {
                from,
                to,
                count
              }
            )
          }
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default injectIntl(ItemList)
