/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"
import UserBudgetsItem from "./UserBudgetsItem"

const headRows = [
  {
    id: "expand",
    notSortable: true,
    label: ""
  },
  {
    id: "from",
    label: <FormattedMessage id="DATE_FIELD.FROM" />
  },
  {
    id: "to",
    label: <FormattedMessage id="DATE_FIELD.TO" />
  },
  {
    id: "balance",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.BALANCE" />
  },
  {
    id: "intialBalance",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.INITIAL_BALANCE" />
  },
  {
    id: "changeBudget",
    notSortable: true,
    label: ""
  }
]

function UserBudgetsList({ itemsData, openBudgetDialog, intl }) {
  const classes = useStyles()
  const rows = itemsData.data
  const [sortBy, setSortBy] = useState("from")
  const [orderBy, setOrderBy] = useState("desc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
      >
        <EnhancedTableHead
          headRows={headRows}
          orderBy={orderBy}
          sortBy={sortBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => 
            <UserBudgetsItem row={row} openBudgetDialog={openBudgetDialog}/>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default injectIntl(UserBudgetsList)
