/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"
import UserTransfersItem from "./UserTransfersItem"

const headRows = [
  {
    id: "actions",
    disablePadding: false,
    label: ''
  },
  {
    id: "fullName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.FULL_NAME" />
  },
  {
    id: "departmentName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DEPARTMENT_NAME" />
  },
  {
    id: "amount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.AMOUNT" />
  },
  {
    id: "transferStatus",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TRANSFER_STATUS" />
  },
  {
    id: "requestDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REQUEST_DATE" />
  },
  {
    id: "expectedTransferDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EXPECTED_TRANSFER_DATE" />
  },
  {
    id: "transferDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TRANSFER_DATE" />
  },
  {
    id: "id",
    disablePadding: false,
    label: ""
  },
];

function UserTransfersList({ itemsData, openTransferDialog, intl }) {
  const classes = useStyles()
  const rows = itemsData.data
  const [sortBy, setSortBy] = useState("from")
  const [orderBy, setOrderBy] = useState("desc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
      >
        <EnhancedTableHead
          headRows={headRows}
          orderBy={orderBy}
          sortBy={sortBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => 
            <UserTransfersItem intl={intl} setRowSettings={openTransferDialog} row={row}/>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default injectIntl(UserTransfersList)
