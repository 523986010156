import axios from "axios"
import { actions as snackbarActions } from "./snackbarRedux"

export function handleApiError(dispatch, error, message) {
    if (!axios.isCancel(error)) {
        dispatch(snackbarActions.setSnackbarData(message, error));
        console.error("Error: ", message);
    } else {
        console.warn("Request was canceled: ", message);
    }
}

export function handleApiSuccess(dispatch, message) {
  dispatch(snackbarActions.setSnackbarData(
    message))
}
