import React from "react"
import { FormattedMessage } from "react-intl"

export const dayCollection = [
  { value: 0, label: <FormattedMessage id="DAYS.SUN" /> },
  { value: 1, label: <FormattedMessage id="DAYS.MON" /> },
  { value: 2, label: <FormattedMessage id="DAYS.TUE" /> },
  { value: 3, label: <FormattedMessage id="DAYS.WED" /> },
  { value: 4, label: <FormattedMessage id="DAYS.THU" /> },
  { value: 5, label: <FormattedMessage id="DAYS.FRI" /> },
  { value: 6, label: <FormattedMessage id="DAYS.SAT" /> }
]
