/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import {FormattedMessage, injectIntl} from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getCustomerReports } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import OrderItemsList from "../modules/Reports/CustomerReports/OrderItemsList"
import OrderItemsSummary from "../modules/Reports/CustomerReports/OrderItemsSummary"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import CustomerLocationDateFilterPanel from "../modules/Reports/CustomerReports/CustomerLocationDateFilterPanel"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

function CustomerReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchReports(cancelToken) {
    const { customer, location, from, to } = searchParams
    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      customer: customer.CustomerID
    }

    props.history.push({
      pathname: "/reports-customer",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getCustomerReports(
      customer.CustomerID,
      location,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true, "active")
      .then(({ data }) => {
        setCustomerData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)
      const from = urlParams.get("from")
          ? new Date(urlParams.get("from"))
          : new Date()
      const to = urlParams.get("to")
          ? new Date(urlParams.get("to"))
          : new Date()

      const location = urlParams.get("location") || ""
      const customer = urlParams.get("customer")
        ? customerData.data.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}

      setSearchParams({
        from,
        to,
        customer,
        location
      })
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
      searchParams.customer &&
      searchParams.customer.CustomerID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      setExportFileName(
        intl.formatMessage({
          id: "REPORT.EXPORT_FILE_NAME.CUSTOMER_DETAIL"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          searchParams.customer.Name +
          "_" +
          moment(searchParams.date).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
   // const quantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.Quantity, 0), 0)
    //const itemPrice = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.ItemPrice, 0), 0)
   // const itemPriceTotal = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + ( t.ItemPrice * t.Quantity), 0), 0)
   // const customerPrice = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.CustomerPrice, 0), 0)
   // const customerPriceTotal = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + (t.CustomerPrice * t.Quantity), 0), 0)
   // const profit = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + (Math.round((t.CustomerPrice - t.ItemPrice ) * t.Quantity*100)/100), 0), 0)

    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.SUPPLIER_NAME"
          })
        ] = report.SupplierName
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = meal.ItemName
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = meal.Quantity
        formattedMeal[
            intl.formatMessage({
              id: "REPORT.ITEM_COST"
            })
            ] = meal.SupplierPriceTotal
        formattedMeal[
            intl.formatMessage({
              id: "REPORT.INCOME"
            })
            ] = meal.TotalAmount
        formattedMeal[
            intl.formatMessage({
              id: "REPORT.PROFIT"
            })
            ] =(Math.round((meal.TotalAmount - meal.SupplierPriceTotal ) *100)/100) 
        formattedReport.push(formattedMeal);
      })
    })




    // const formattedMeal = {}
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.SUPPLIER_NAME"
    //     })
    //     ] = intl.formatMessage({
    //       id: "REPORT.TOTAL"
    //     })
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.QUANTITY"
    //     })
    //     ] = quantity
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.ITEM_COST"
    //     })
    //     ] = itemPrice
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.ITEM_PRICE_TOTAL"
    //     })
    //     ] = itemPriceTotal
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.ITEM_COSTUMER_COST"
    //     })
    //     ] = customerPrice
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.ITEM_COSTUMER_TOTAL_COST"
    //     })
    //     ] = customerPriceTotal
    // formattedMeal[
    //     intl.formatMessage({
    //       id: "REPORT.PROFIT"
    //     })
    //     ] = profit
    // formattedReport.push(formattedMeal);


    return formattedReport
  }
  const supplierTotal = reportsData.reports.reduce((sum, item) => {
    sum.OrderQuantity += item.OrderQuantity;
    sum.SupplierPrice += item.SupplierPrice;
    sum.TotalAmount += item.TotalAmount;
    return sum;
  }, {
    OrderQuantity: 0,
    SupplierPrice: 0,
    TotalAmount: 0
  });
  return (
    <>
      {
        (<div className={classes.reportName}>
          <FormattedMessage id="REPORT.CUSTOMER_REPORT_TITLE" />
        </div>)
      }
      {searchParams.customer && (
        <CustomerLocationDateFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {
        reportsData && reportsData.reports && reportsData.reports.length > 0
          && (<Paper className={classes.paper}>
          <Typography className={classes.heading}>
            <FormattedMessage id="REPORT.TOTAL" />
          </Typography>
          <div className={classes.supplierDetailsOrder}>
            <div>
                <span>
                  <FormattedMessage id="ORDERS_TABLE.LABELS.QUANTITY" />
                </span>
              -
              <span className={classes.supplierDetailsOrderItem}>
                   {supplierTotal.OrderQuantity}
                </span>
            </div>
            <div>

                <span>
                  <FormattedMessage id="REPORT.ITEM_COST" />
                </span>
                  -
                  <span className={classes.supplierDetailsOrderItem}>
                  {Math.round(supplierTotal.SupplierPrice * 100) / 100}
                </span>
              </div>
            <div>
                <span>
                  <FormattedMessage id="REPORT.INCOME" />
                </span>
              -
              <span className={classes.supplierDetailsOrderItem}>
                   {Math.round(supplierTotal.TotalAmount * 100) / 100}
                </span>
            </div>

          </div>
        </Paper>)
      }

      <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(supplier => (
          <Paper className={classes.paper} key={supplier.SupplierId}>
            <Typography className={classes.heading}>
                {supplier.SupplierName}
            </Typography>
            <OrderItemsList ordersData={supplier} />
            <OrderItemsSummary ordersData={supplier} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(CustomerReportsPage))
)
