import {injectIntl} from "react-intl";
import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "../Common/_styles/formDialogStyles";

function ComboForm({
    intl,
    items,
    checkForError,
    formik,
    renderErrors
}) {
    const classes = useStyles();
    return <>
        {
            items?.map(itemData => {
                return <>
                    <div>
                        {itemData.ItemName}
                    </div>
                    <div>
                        {
                            itemData.OptionGroups.map(optionGroup => {
                                return <>
                                    <div className={classes.conditionContainer}>
                                        {optionGroup.IsMandatory && (
                                            <>
                                                <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MANDATORY"
                              })}
                            </span>
                                                    <span>{optionGroup.IsMandatory}</span>
                                                </div>
                                            </>
                                        )}
                                        {optionGroup.OptionGroup.Min > 0 && (
                                            <>
                                                <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MIN"
                              })}
                            </span>
                                                    <span>{optionGroup.OptionGroup.Min}</span>
                                                </div>
                                            </>
                                        )}
                                        {optionGroup.OptionGroup.Max > 0 && (
                                            <>
                                                <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MAX"
                              })}
                            </span>
                                                    <span>{optionGroup.OptionGroup.Max}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <FormControl
                                        variant="filled"
                                        className={classes.textField}
                                        fullWidth
                                        error={checkForError(`${itemData.ItemName}_${optionGroup.Lable}`)}
                                    >
                                        <InputLabel>{optionGroup.Lable}</InputLabel>
                                        <Select
                                            multiple
                                            {...formik.getFieldProps(`${itemData.ItemName}_${optionGroup.Lable}`)}
                                            defaultValue={[]}
                                            input={<FilledInput name={optionGroup.Lable} />}
                                        >
                                            {optionGroup.OptionGroup.Options.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div>{renderErrors(`${itemData.ItemName}_${optionGroup.Lable}`)}</div>
                                </>
                            })
                        }
                    </div>

                </>
            })
        }
    </>

}
export default injectIntl(ComboForm);
