/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import ConfirmationDialog from "../Common/ConfirmationDialog"
import PriceDetails from "./PriceDetails"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function RefundFormDialog({
  show,
  onClose,
  onExtraAction,
  intl,
  submitData,
  updateItems,
  subsidyAmount,
  data,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirmationDialogData, setConfirmationDialogData] = useState({
    show: false,
    data: null,
    setSubmitting: null,
    resetForm: null,
    setFieldError: null
  })

  const initialValues = {
    useRefundSubsidy: false,
    refundType: 3,
    refundReason: "",
    isPartial: false,
    partialAmountBudget: 0,
    partialAmountCreditCard: 0,
    partialAmountWallet: 0,
    partialAmountCustomerCredit: 0,
    partialAmountSubsidy: 0,
    partialSupplierCost: data?.suppplierCost ?? 0
  }

  const reasonValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const validationSchema = Yup.object().shape(
      {
          refundReason: reasonValidationSchema,
          isPartial: Yup.boolean(),
          partialAmountBudget: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.BudgetAmount, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.BudgetAmount ?? "")
              .notRequired(),
          partialAmountCreditCard: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.CreditCardAmount, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.CreditCardAmount ?? "")
              .notRequired(),
          partialAmountWallet: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.WalletAmount, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.WalletAmount ?? "")
              .notRequired(),
          partialAmountCustomerCredit: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.CostumerCreditAmount, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.CostumerCreditAmount ?? "")
              .notRequired(),
          partialAmountSubsidy: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.SubsidyAmount, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.SubsidyAmount ?? "")
              .notRequired(),
          partialSupplierCost: Yup.number()
              .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
              .max(data?.suppplierCost, intl.formatMessage({ id: "VALIDATION.MAX_VALUE" }) + " " + data?.suppplierCost ?? "")
              .notRequired()
      },
      ["refundReason"]);


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
      const { useRefundSubsidy, refundType, refundReason, isPartial, partialAmountBudget, partialAmountCreditCard, partialAmountWallet, partialAmountCustomerCredit, partialAmountSubsidy, partialSupplierCost } = values;

      const data = {
        RefundType: refundType,
        RefundReason: refundReason,
        IsPartial: isPartial 
      }
      if (useRefundSubsidy) {
        data.SubsidyRefund = subsidyAmount
      }

      if (isPartial) {
        data.IsPartial = isPartial;
        data.Wallet = partialAmountWallet;
        data.CustomerCredit = partialAmountCustomerCredit;
        data.Subsidy = partialAmountSubsidy;
        data.CreditCard = partialAmountCreditCard;
        data.Budget = partialAmountBudget;
        data.SupplierCost = partialSupplierCost;
      }

      setSubmitting(false)
      setConfirmationDialogData({
        show: true,
        data: data,
        setSubmitting,
        resetForm,
        setFieldError
      })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  const handleConfirmRefund = () => {
    confirmationDialogData.setSubmitting(true)
    submitData(confirmationDialogData.data)
      .then(() => {
        setConfirmationDialogData({ show: false })
        confirmationDialogData.setSubmitting(false)
        updateItems(confirmationDialogData.data)
        onClose()
        confirmationDialogData.resetForm()
        if (onExtraAction) {
          onExtraAction()
        }
      })
      .catch(error => {
        setConfirmationDialogData({ show: false })
        confirmationDialogData.setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_REFUND"
          })
        )
      })
  }

  return (
    <div>
      <ConfirmationDialog
        show={confirmationDialogData.show}
        onSubmit={handleConfirmRefund}
        onClose={() => setConfirmationDialogData({ show: false })}
        isSubmitting={formik.isSubmitting}
        dialogTitle={<FormattedMessage id="CONFIRM_REFUND_DIALOG.TITLE" />}
        contentText={intl.formatMessage({
          id: "CONFIRM_REFUND_DIALOG.TEXT"
        })}
      />
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="REFUND_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent>
            {/*{subsidyAmount !== 0 && (
              <div className="d-inline-flex w-100">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.useRefundSubsidy}
                      name="useRefundSubsidy"
                      color="secondary"
                      {...formik.getFieldProps("useRefundSubsidy")}
                    />
                  }
                  label={intl.formatMessage({
                    id: "SUBSIDY_REFUND_FIELD.LABEL"
                  })}
                />
              </div>
            )}*/}
            {
              data && (
                  <div>
                    <PriceDetails 
                    hidden={data?.BudgetAmount == null || data?.BudgetAmount === 0}
                    price={data.BudgetAmount} 
                    description={intl.formatMessage({
                      id: "REFUND_FORM.BUDGET"
                    })}/>
                    <PriceDetails 
                      hidden={data?.WalletAmount == null || data?.WalletAmount === 0}
                      price={data.WalletAmount} 
                      description={intl.formatMessage({
                      id: "REFUND_FORM.WALLET"
                    })}/>
                    <PriceDetails 
                      hidden={data?.CreditCardAmount == null || data?.CreditCardAmount === 0}
                      price={data.CreditCardAmount} 
                      description={intl.formatMessage({
                      id: "REFUND_FORM.CREDIT_CARD"
                    })}/>
                    <PriceDetails 
                      hidden={data?.CostumerCreditAmount == null || data?.CostumerCreditAmount === 0}
                      price={data.CostumerCreditAmount} 
                      description={intl.formatMessage({
                      id: "REFUND_FORM.CUSTOMER"
                    })}/>
                    <PriceDetails 
                      hidden={data?.SubsidyAmount == null || data?.SubsidyAmount === 0}
                      price={data.SubsidyAmount} 
                      description={intl.formatMessage({
                      id: "REFUND_FORM.SUBSIDY"
                    })}/>
                    <br/>
                  </div>
              )
            }
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formik.values.isPartial}
                        name="isPartial"
                        color="secondary"
                        onChange={() => {
                            formik.setFieldValue("isPartial", !formik.values.isPartial);
                        }}
                    />
                }
                label={intl.formatMessage({
                    id: "PARTIAL_REFUND_CHECKBOX",
                })}
            />
            {formik.values.isPartial && (

                <div>
                    {(formik.values.refundType === 1 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialAmountBudget"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.BUDGET",
                            })}
                            className={classes.textField}
                            fullWidth
                            hidden={data?.BudgetAmount == null || data?.BudgetAmount === 0}
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.BudgetAmount, step: 0.01 } }}
                            {...formik.getFieldProps("partialAmountBudget")}
                            error={checkForError("partialAmountBudget")}
                        />
                    )}
                    {renderErrors("partialAmountBudget")}

                    
                    {(formik.values.refundType === 1 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialAmountWallet"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.WALLET",
                            })}
                            className={classes.textField}
                            fullWidth
                            hidden={data?.WalletAmount == null || data?.WalletAmount === 0}
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.WalletAmount, step: 0.01 } }}
                            {...formik.getFieldProps("partialAmountWallet")}
                            error={checkForError("partialAmountWallet")}
                        />
                    )}
                    {renderErrors("partialAmountWallet")}

                    
                    {(formik.values.refundType === 1 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialAmountCreditCard"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.CREDIT_CARD",
                            })}
                            className={classes.textField}
                            fullWidth
                            hidden={data?.CreditCardAmount == null || data?.CreditCardAmount === 0}
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.CreditCardAmount, step: 0.01 } }}
                            {...formik.getFieldProps("partialAmountCreditCard")}
                            error={checkForError("partialAmountCreditCard")}
                        />
                    )}
                    {renderErrors("partialAmountCreditCard")}

                    
                    {(formik.values.refundType === 1 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialAmountCustomerCredit"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.CUSTOMER",
                            })}
                            className={classes.textField}
                            fullWidth
                            hidden={data?.CostumerCreditAmount == null || data?.CostumerCreditAmount === 0}
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.CostumerCreditAmount, step: 0.01 } }}
                            {...formik.getFieldProps("partialAmountCustomerCredit")}
                            error={checkForError("partialAmountCustomerCredit")}
                        />
                    )}
                    {renderErrors("partialAmountCustomerCredit")}
                    
                    {(formik.values.refundType === 1 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialAmountSubsidy"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.SUBSIDY",
                            })}
                            className={classes.textField}
                            fullWidth
                            hidden={data?.SubsidyAmount == null || data?.SubsidyAmount === 0}
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.SubsidyAmount, step: 0.01 } }}
                            {...formik.getFieldProps("partialAmountSubsidy")}
                            error={checkForError("partialAmountSubsidy")}
                        />
                    )}
                    {renderErrors("partialAmountSubsidy")}

                    {(formik.values.refundType === 2 || formik.values.refundType === 3) && (
                        <TextField
                            name="partialSupplierCost"
                            label={intl.formatMessage({
                                id: "REFUND_FORM.SUPPLIER_COST",
                            })}
                            className={classes.textField}
                            fullWidth
                            variant="filled"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: data?.suppplierCost, step: 0.01 } }}
                            {...formik.getFieldProps("partialSupplierCost")}
                            error={checkForError("partialSupplierCost")}
                        />
                    )}
                    {renderErrors("partialSupplierCost")}
                </div>
            )}

            <TextField
              name="refundReason"
              label={intl.formatMessage({
                id: "REFUND_REASON_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("refundReason")}
              error={checkForError("refundReason")}
            />
            {renderErrors("refundReason")}
            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("refundType")}
            >
              <InputLabel htmlFor="refund-type-select">
                <FormattedMessage id="REFUND_TYPE_FIELD.LABEL" />
              </InputLabel>
              <Select
                {...formik.getFieldProps("refundType")}
                input={
                  <FilledInput name="refundType" id="refund-type-select" />
                }
              >
                <MenuItem value={1}>
                  <FormattedMessage id="REFUND_TYPE_FIELD.VALUE.CUSTOMER" />
                </MenuItem>
                <MenuItem value={2}>
                  <FormattedMessage id="REFUND_TYPE_FIELD.VALUE.SUPPLIER" />
                </MenuItem>
                <MenuItem value={3}>
                  <FormattedMessage id="REFUND_TYPE_FIELD.VALUE.BOTH" />
                </MenuItem>
              </Select>
            </FormControl>
            {renderErrors("refundType")}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={onClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(RefundFormDialog)
