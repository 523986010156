/* eslint-disable no-restricted-imports */
import React, {useState} from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import Cropper from "react-cropper";

export function CropperDialog({
  show,
  onClose,
  onSubmit,
  isSubmitting,
  dialogTitle,
  image,
  contentText
}) {
  const [cropper, setCropper] = useState();
  if (cropper) {
    cropper.setAspectRatio(1);
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            {dialogTitle}
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Cropper
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={onClose}
            className="mr-3"
          >
            <FormattedMessage id="DIALOG.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            disabled={isSubmitting}
            onClick={ev => onSubmit(cropper.getCroppedCanvas().toDataURL())}
            autoFocus
          >
            {isSubmitting && <span className="spinner spinner-white"></span>}
            <FormattedMessage id="DIALOG.SUBMIT_BUTTON" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CropperDialog
