/* eslint-disable no-restricted-imports */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import { FormattedMessage } from "react-intl"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    opacity: 0.5,
    zIndex: 10,
    "&:hover": {
      opacity: 1
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default function AddUserButton({ onClick }) {
  const classes = useStyles()

  return (
    <>
      <Fab
        color="secondary"
        variant="extended"
        aria-label="Create user"
        className={classes.fab}
        onClick={onClick}
      >
        <AddIcon className={classes.extendedIcon} />
        <FormattedMessage id="ADMIN_USERS.ADD_USER_BUTTON" />
      </Fab>
    </>
  )
}
