/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import moment from 'moment';

const headRows = [

    {
        id: "OrderToDate",
        disablePadding: false,
        notSortable: true,
        width: 17,
        label: ""
    },
    {
        id: "MealType",
        disablePadding: false,
        notSortable: true,
        width: 12,
        label: ""
    },
    {
        id: "TotalCount",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="REPORT.ITEMS" />
    },
    {
        id: "SubsidyAmount",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="TABLE.HEAD_LABELS.SUBSIDY" />
    },
    {
        id: "BudgetAmount",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="TABLE.HEAD_LABELS.BUDGET" />
    },
    {
        id: "CustomerCreditAmount",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER_CREDIT" />
    },
    {
        id: "Total",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="REPORT.TOTAL_COST" />
    }
]


function OrderItemsSummary({ reportsData }) {
    const classes = useStyles()
    const rows = reportsData
    const [sortBy, setSortBy] = useState("Total")
    const [orderBy, setOrderBy] = useState("asc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    const totalItemsCount = reportsData.reduce((sum, report) => sum + report.ItemsCount, 0);
    const totalRefundCount = reportsData.reduce((sum, report) => sum + report.RefundCount, 0);
    const totalTotalCount = totalItemsCount - totalRefundCount;
    const totalSubsidyAmount = reportsData.reduce((sum, report) => sum + (report.SubsidyAmount - report.SubsidyRefundAmount), 0);
    const totalRefundSubsidyAmount = reportsData.reduce((sum, report) => sum + report.SubsidyRefundAmount, 0);
    const totalBudgetAmount = reportsData.reduce((sum, report) => sum + (report.BudgetAmount - report.BudgetRefundAmount), 0);
    const totalRefundBudgetAmount = reportsData.reduce((sum, report) => sum + report.BudgetRefundAmount, 0);
    const totalCustomerCreditAmount = reportsData.reduce((sum, report) => sum + (report.CustomerCreditAmount - report.CustomerCreditRefundAmount), 0);
    const totalRefundCustomerCreditAmount = reportsData.reduce((sum, report) => sum + report.CustomerCreditRefundAmount, 0);
    const totalTotal = reportsData.reduce((sum, report) => sum + (
        report.BudgetAmount +
        report.CustomerCreditAmount +
        report.SubsidyAmount -
        report.BudgetRefundAmount -
        report.CustomerCreditRefundAmount -
        report.SubsidyRefundAmount
    ), 0);
    const totalRefundTotal = reportsData.reduce((sum, report) => sum + (
        report.BudgetRefundAmount +
        report.CustomerCreditRefundAmount +
        report.SubsidyRefundAmount
    ), 0);

    const date = reportsData.length > 0 ? moment.utc(reportsData[0].OrderToDate).format('YYYY-MM') : ""

    return (
        <Paper className={classes.paper}>
            <div className={classes.tableWrapperSummary}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className={classes.collapsibleRow}
                        >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{totalRefundCount !== 0 ? ` (${totalRefundCount}) ` : ``}{totalTotalCount}</TableCell>
                            <TableCell>{totalRefundSubsidyAmount !== 0 ? ` (${totalRefundSubsidyAmount.toFixed(2) }) ` : ``}{totalSubsidyAmount.toFixed(2)}</TableCell>
                            <TableCell>{totalRefundBudgetAmount !== 0 ? ` (${totalRefundBudgetAmount.toFixed(2) }) ` : ``}{totalBudgetAmount.toFixed(2)}</TableCell>
                            <TableCell>{totalRefundCustomerCreditAmount !== 0 ? ` (${totalRefundCustomerCreditAmount.toFixed(2) }) ` : ``}{totalCustomerCreditAmount.toFixed(2)}</TableCell>
                            <TableCell>{totalRefundTotal !== 0 ? ` (${totalRefundTotal.toFixed(2)}) ` : ``}{totalTotal.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default OrderItemsSummary
