/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react"
import TextField from "@material-ui/core/TextField"
import CreateIcon from "@material-ui/icons/Create"
import CheckIcon from "@material-ui/icons/Check"
import Tooltip from "@material-ui/core/Tooltip"
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Button,
    IconButton,
    Paper,
    Select,
    Typography,
    InputLabel, InputAdornment
} from "@material-ui/core"
import {FormattedMessage, injectIntl} from "react-intl"
import MenuItem from "@material-ui/core/MenuItem"
import Avatar from "@material-ui/core/Avatar"
import InputMask from "react-input-mask"
import {useFormik} from "formik"
import * as Yup from "yup"
import PlacesAutocomplete from "react-places-autocomplete"
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete"
import DeleteIcon from "@material-ui/icons/Delete"
import {useStyles} from "../Common/_styles/detailsFormStyles"
import {actions as snackbarActions} from "../../../redux/snackbar/snackbarRedux"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../redux/snackbar/snackbarHandlers"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";

function Thumb({imageFile, initialURL, setStoredImageURL}) {
    const [state, setState] = useState({
        loading: false
    })

    useEffect(() => {
        setState({loading: true})
        if (imageFile) {
            let reader = new FileReader()

            reader.onloadend = () => {
                setState({loading: false, thumb: reader.result})
                setStoredImageURL(reader.result)
            }

            reader.readAsDataURL(imageFile)
        } else {
            setState({loading: false, thumb: initialURL})
        }
    }, [imageFile, initialURL])

    if (!imageFile && !initialURL) {
        return null
    }

    if (state.loading) {
        return <span className="spinner spinner-white"></span>
    }
    return (
        <Avatar
            alt="Logo"
            variant="rounded"
            src={state.thumb}
            style={{height: 100, width: 100}}
        />
    )
}

function CustomerDetails({
                             saveImage,
                             intl,
                             selectedCustomer,
                             customersData,
                             sumbitCustomer,
                             updateCustomers,
                             deleteCustomer,
                             isUserOwner
                         }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isReadOnly, setIsReadOnly] = useState(true)
    const [storedImageURL, setStoredImageURL] = useState("")

    const initialValues = {
        name: selectedCustomer.Name || "",
        companyID: selectedCustomer.CompanyID || "",
        address: selectedCustomer.Address || "",
        contactName: selectedCustomer.ContactName || "",
        contactPhone: selectedCustomer.ContactPhone || "",
        contactEmail: selectedCustomer.ContactEmail || "",
        advanceOrderType: selectedCustomer.AdvanceOrderType || 0,
        bankTransferTimeType: selectedCustomer.BankTransferTimeType || 0,
        allowWallet: selectedCustomer.AllowWallet || false,
        allowBankTransfer: selectedCustomer.AllowBankTransfer || false,
        bankTransferFee: selectedCustomer.BankTransferFee || 0,
        bankTransferMin: selectedCustomer.BankTransferMin || 0,
        bankTransferMax: selectedCustomer.BankTransferMax || 0,
        isPriceDisplayed: selectedCustomer.IsPriceDisplayed || false,
        settingsSmsInvitationContent: selectedCustomer.SettingsSmsInvitationContent || ''
    }
    initialValues.logoFile = null

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            value => {
                const customer = customersData.customers.find(
                    customer => customer.Name === value
                )
                return (
                    customer === undefined ||
                    customer.CustomerID === selectedCustomer.CustomerID
                )
            }
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const emailValidationSchema = Yup.string()
        .email(
            intl.formatMessage({
                id: "VALIDATION.WRONG_EMAIL_FORMAT"
            })
        )
        .min(2, intl.formatMessage({id: "VALIDATION.MIN_LENGTH_FIELD"}))
        .max(50, intl.formatMessage({id: "VALIDATION.MAX_LENGTH_FIELD"}))
        .when("phone", {
            is: phone => {
                return !phone
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const phoneValidationSchema = Yup.string()
        .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            intl.formatMessage({
                id: "VALIDATION.WRONG_PHONE_FORMAT"
            })
        )
        .when("email", {
            is: email => {
                return !email
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const companyValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const addressValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const orderTypeValidationSchema = Yup.number().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const logoFileValidationSchema = Yup.mixed()
        .when({
            is: () => {
                return !selectedCustomer.Name;
            },
            then: Yup.mixed().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })
        .test(
            "fileType",
            intl.formatMessage({
                id: "VALIDATION.UNSUPPORTED_FILE_FORMAT"
            }),
            value => {
                if (value) {
                    return ["image/png", "image/jpeg", "image/jpg"].includes(value.type);
                }
                return true;
            }
        );

    const feeValidationSchema = Yup.number().max(
        100,
        intl.formatMessage({
            id: "VALIDATION.MAX_VALUE"
        })
    )

    const minValidationSchema = Yup.number().min(
        0,
        intl.formatMessage({
            id: "VALIDATION.MIN_VALUE"
        })
    )

    const maxValidationSchema = Yup.number().min(
        0,
        intl.formatMessage({
            id: "VALIDATION.MIN_VALUE"
        })
    )

    const validationSchema = Yup.object().shape(
        {
            name: nameValidationSchema,
            contactPhone: phoneValidationSchema,
            contactEmail: emailValidationSchema,
            companyID: companyValidationSchema,
            address: addressValidationSchema,
            logoFile: logoFileValidationSchema,
            advanceOrderType: orderTypeValidationSchema,
            bankTransferFee: feeValidationSchema,
            bankTransferMin: minValidationSchema,
            bankTransferMax: maxValidationSchema
        },
        [
            "contactPhone",
            "contactEmail",
            "name",
            "companyID",
            "address",
            "logoFile",
            "advanceOrderType",
            "bankTransferFee",
            "bankTransferMin",
            "bankTransferMax"
        ]
    )

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)
            const {
                logoFile,
                name,
                companyID,
                address,
                geoPoint,
                contactName,
                contactPhone,
                contactEmail,
                settingsSmsInvitationContent,
                advanceOrderType,
                bankTransferTimeType,
                allowWallet,
                allowBankTransfer,
                bankTransferFee,
                bankTransferMin,
                bankTransferMax,
                isPriceDisplayed
            } = values

            const changedCustomer = {
                CustomerID: selectedCustomer.CustomerID,
                Name: name,
                CompanyID: companyID,
                Address: address,
                GeoPoint: geoPoint,
                ContactName: contactName,
                ContactPhone: contactPhone,
                ContactEmail: contactEmail,
                SettingsSmsInvitationContent: settingsSmsInvitationContent,
                AdvanceOrderType: advanceOrderType,
                BankTransferTimeType: bankTransferTimeType,
                AllowWallet: allowWallet,
                AllowBankTransfer: allowBankTransfer,
                BankTransferFee: bankTransferFee,
                BankTransferMin: bankTransferMin,
                BankTransferMax: bankTransferMax,
                IsPriceDisplayed: isPriceDisplayed
            }
            sumbitCustomer(changedCustomer)
                .then(() => {
                    if (logoFile) {
                        saveImage(changedCustomer.CustomerID, logoFile)
                            .then(() => {
                                dispatch(
                                    snackbarActions.setSnackbarData(
                                        intl.formatMessage({
                                            id: "API.CREATE_SUCCESS"
                                        })
                                    )
                                )
                                updateCustomers({
                                    ...changedCustomer,
                                    Logo: [{URL: storedImageURL}]
                                })
                                setIsReadOnly(true)
                                setSubmitting(false)
                                setIsReadOnly(true)
                            })
                            .catch(error => {
                                setSubmitting(false)
                                handleApiError(
                                    dispatch,
                                    error,
                                    intl.formatMessage({
                                        id: "API.ERROR.FAILED_TO_SAVE_IMAGE"
                                    })
                                )
                            })
                    } else {
                        updateCustomers(changedCustomer)
                        setSubmitting(false)
                        setIsReadOnly(true)
                    }
                })
                .catch(error => {
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_UPDATE_CUSTOMER_DETAILS"
                        })
                    )
                    setSubmitting(false)
                })
        }
    })

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span
                className={fieldName === "logoFile" ? classes.fileError : classes.error}
            >
        {formik.errors[fieldName]}
      </span>
        ) : null
    }

    function handleSelectPlace(value) {
        formik.setFieldValue("address", value)
        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                formik.setFieldValue("geoPoint", {lat, lng})
            })
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                {selectedCustomer.Name ? (
                    <>
                        {isUserOwner && (
                            <div className={classes.iconRow}>
                                <Tooltip
                                    title={intl.formatMessage({
                                        id: "CREATE_FORM.DELETE_BUTTON"
                                    })}
                                >
                                    <IconButton type="button" onClick={deleteCustomer}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                                {isReadOnly ? (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.EDIT.TOOLTIP"
                                        })}
                                    >
                                        <IconButton
                                            type="button"
                                            onClick={() => setIsReadOnly(false)}
                                        >
                                            <CreateIcon/>
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.SUBMIT.TOOLTIP"
                                        })}
                                    >
                                        <IconButton
                                            type="button"
                                            onClick={() => formik.submitForm()}
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                        <div className="row">
                            <div className="col-6">
                                <div className="position-relative">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.LABELS.NAME"
                                        })}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        hiddenLabel
                                        {...formik.getFieldProps("name")}
                                        error={checkForError("name")}
                                    />
                                    {renderErrors("name")}
                                </div>
                                <div className="position-relative">
                                    <TextField
                                        id="company"
                                        name="companyID"
                                        label={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.LABELS.COMPANY_ID"
                                        })}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        {...formik.getFieldProps("companyID")}
                                        error={checkForError("companyID")}
                                    />
                                    {renderErrors("companyID")}
                                </div>
                                <div className="position-relative">
                                    <PlacesAutocomplete
                                        value={formik.values.address}
                                        onChange={value => formik.setFieldValue("address", value)}
                                        onSelect={handleSelectPlace}
                                        searchOptions={{country: "il", language: "iw"}}
                                    >
                                        {({
                                              getInputProps,
                                              suggestions,
                                              getSuggestionItemProps,
                                              loading
                                          }) => (
                                            <div className="position-relative">
                                                <TextField
                                                    className={classes.textField}
                                                    label={intl.formatMessage({
                                                        id: "CUSTOMER_DETAIL.LABELS.ADDRESS"
                                                    })}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: isReadOnly,
                                                        ...getInputProps()
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    error={checkForError("address")}
                                                />
                                                <Paper className={classes.paper} square>
                                                    {loading && (
                                                        <div className="spinner spinner-blue mt-3"></div>
                                                    )}
                                                    {suggestions.map(suggestion => {
                                                        return (
                                                            <MenuItem
                                                                component="div"
                                                                {...getSuggestionItemProps(suggestion)}
                                                                key={suggestion.placeId}
                                                            >
                                                                {suggestion.description}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Paper>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {renderErrors("address")}
                                </div>
                                <div className="position-relative">
                                    <FormControl
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                    >
                                        <InputLabel>
                                            <FormattedMessage id="CUSTOMER_DETAIL.ADVANCE_ORDER_TYPE_FIELD.LABEL"/>
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("advanceOrderType")}
                                            label={intl.formatMessage({
                                                id: "CUSTOMER_DETAIL.ADVANCE_ORDER_TYPE_FIELD.LABEL"
                                            })}
                                            readOnly={isReadOnly}
                                        >
                                            <MenuItem value={0}>
                                                <FormattedMessage
                                                    id="CUSTOMER_DETAIL.ADVANCE_ORDER_TYPE_FIELD.VALUE.FOURTH"/>
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage
                                                    id="CUSTOMER_DETAIL.ADVANCE_ORDER_TYPE_FIELD.VALUE.FIFTH"/>
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <FormattedMessage
                                                    id="CUSTOMER_DETAIL.ADVANCE_ORDER_TYPE_FIELD.VALUE.WEEKLY"/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {renderErrors("advanceOrderType")}
                                </div>
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center align-items-stretch">
                                <Typography variant="h6">
                                    <FormattedMessage id="CUSTOMER_DETAIL.LABELS.CONTACT"/>
                                </Typography>
                                <div className="position-relative">
                                    <TextField
                                        id="contact-name"
                                        name="contactName"
                                        label={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.LABELS.CONTACT_NAME"
                                        })}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        hiddenLabel
                                        {...formik.getFieldProps("contactName")}
                                        error={checkForError("contactName")}
                                    />
                                    {renderErrors("contactName")}
                                </div>
                                <div className="position-relative">
                                    <InputMask
                                        name="contactPhone"
                                        mask="999-9999999"
                                        {...formik.getFieldProps("contactPhone")}
                                    >
                                        {() => (
                                            <TextField
                                                id="contactPhone"
                                                label={intl.formatMessage({
                                                    id: "CUSTOMER_DETAIL.LABELS.CONTACT_PHONE"
                                                })}
                                                className={classes.textField}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                margin="normal"
                                                fullWidth
                                                error={checkForError("contactPhone")}
                                                inputProps={{
                                                    className: classes.textRight
                                                }}
                                                InputProps={{
                                                    readOnly: isReadOnly
                                                }}
                                                dir="ltr"
                                            />
                                        )}
                                    </InputMask>
                                    {renderErrors("contactPhone")}
                                </div>
                                <div className="position-relative">
                                    <TextField
                                        id="contact-email"
                                        name="contactEmail"
                                        label={intl.formatMessage({
                                            id: "CUSTOMER_DETAIL.LABELS.CONTACT_EMAIL"
                                        })}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        {...formik.getFieldProps("contactEmail")}
                                        error={checkForError("contactEmail")}
                                        inputProps={{
                                            className: classes.textRight
                                        }}
                                        dir="ltr"
                                    />
                                    {renderErrors("contactEmail")}
                                </div>
                            </div>
                        </div>
                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row margin-right-5">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.allowWallet}
                                        name="allowWallet"
                                        color="secondary"
                                        disableRipple={isReadOnly}
                                        {...formik.getFieldProps("allowWallet")}
                                        onChange={!isReadOnly ? formik.getFieldProps("allowWallet").onChange : undefined}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: "CUSTOMER_DETAIL.ALLOW_WALLET_FIELD.LABEL"
                                })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isPriceDisplayed}
                                        name="isPriceDisplayed"
                                        color="secondary"
                                        disableRipple={isReadOnly}
                                        {...formik.getFieldProps("isPriceDisplayed")}
                                        onChange={!isReadOnly ? formik.getFieldProps("isPriceDisplayed").onChange : undefined}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: "CUSTOMER_DETAIL.IS_CUSTOMER_PRICE_DISPLAYED_FIELD.LABEL"
                                })}
                            />
                        </div>
                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row margin-right-5">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.allowBankTransfer}
                                        name="allowBankTransfer"
                                        color="secondary"
                                        disableRipple={isReadOnly}
                                        {...formik.getFieldProps("allowBankTransfer")}
                                        onChange={(ev) =>{
                                            if (!isReadOnly) {
                                                formik.getFieldProps("allowBankTransfer").onChange(ev);
                                                if (ev.currentTarget.value === 'false') {
                                                    formik.setFieldValue('bankTransferFee', 0);
                                                    formik.setFieldValue('bankTransferMin', 0);
                                                    formik.setFieldValue('bankTransferMax', 0);
                                                }
                                            }
                                        }}
                                    />
                                }
                                label={intl.formatMessage({
                                    id: "CUSTOMER_DETAIL.ALLOW_BANK_TRANSFER_FIELD.LABEL"
                                })}
                            />
                        </div>


                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row">
                            <div className="col-6">
                                <TextField
                                    id="bankTransferFee"
                                    name="bankTransferFee"
                                    className={classes.textField}
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_DETAIL.BANK_TRANSFER_FEE_FIELD.LABEL"
                                    })}
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                        className: classes.input,
                                        readOnly: isReadOnly
                                    }}
                                    {...formik.getFieldProps("bankTransferFee")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                        )
                                    }}
                                    margin="normal"
                                    type="number"
                                    fullWidth
                                    onChange={event => {
                                        formik.setFieldValue(
                                            "bankTransferFee",
                                            +event.currentTarget.value
                                        )
                                    }}
                                    error={checkForError("bankTransferFee")}
                                />
                                {renderErrors("bankTransferFee")}
                            </div>
                        </div>

                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row">
                            <div className="col-6">
                                <TextField
                                    id="bankTransferMin"
                                    name="bankTransferMin"
                                    className={classes.textField}
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_DETAIL.BANK_TRANSFER_MIN_FIELD.LABEL"
                                    })}
                                    inputProps={{
                                        min: 0,
                                        className: classes.input,
                                        readOnly: isReadOnly
                                    }}
                                    {...formik.getFieldProps("bankTransferMin")}
                                    margin="normal"
                                    type="number"
                                    fullWidth
                                    onChange={event => {
                                        formik.setFieldValue(
                                            "bankTransferMin",
                                            +event.currentTarget.value
                                        )
                                    }}
                                    error={checkForError("bankTransferMin")}
                                />
                                {renderErrors("bankTransferMin")}
                            </div>
                        </div>
                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row">
                            <div className="col-6">
                                <TextField
                                    id="bankTransferMax"
                                    name="bankTransferMax"
                                    className={classes.textField}
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_DETAIL.BANK_TRANSFER_MAX_FIELD.LABEL"
                                    })}
                                    inputProps={{
                                        min: 0,
                                        className: classes.input,
                                        readOnly: isReadOnly
                                    }}
                                    {...formik.getFieldProps("bankTransferMax")}
                                    margin="normal"
                                    type="number"
                                    fullWidth
                                    onChange={event => {
                                        formik.setFieldValue(
                                            "bankTransferMax",
                                            +event.currentTarget.value
                                        )
                                    }}
                                    error={checkForError("bankTransferMax")}
                                />
                                {renderErrors("bankTransferMax")}
                            </div>
                        </div>
                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="position-relative">
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                fullWidth
                            >
                                <InputLabel>
                                    <FormattedMessage id="CUSTOMER_DETAIL.BANK_TRANSFER_TYPE_FIELD.LABEL"/>
                                </InputLabel>
                                <Select
                                    {...formik.getFieldProps("bankTransferTimeType")}
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_DETAIL.BANK_TRANSFER_TYPE_FIELD.LABEL"
                                    })}
                                    readOnly={isReadOnly}
                                >
                                    <MenuItem>
                                        <FormattedMessage
                                            id="CUSTOMER_DETAIL.BANK_TRANSFER_TYPE_FIELD.VALUE.NONE"/>
                                    </MenuItem>
                                    <MenuItem value={'NOW'}>
                                        <FormattedMessage
                                            id="CUSTOMER_DETAIL.BANK_TRANSFER_TYPE_FIELD.VALUE.NOW"/>
                                    </MenuItem>
                                    <MenuItem value={'END_MONTH'}>
                                        <FormattedMessage
                                            id="CUSTOMER_DETAIL.BANK_TRANSFER_TYPE_FIELD.VALUE.LAST_TIME_MONTH"/>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {renderErrors("bankTransferTimeType")}
                        </div>

                      {/*  <div className="row">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                <DatePicker
                                    autoOk
                                    clearable
                                    format="dd/MM/yyyy"
                                    disabled={formik.values.bankTransferTimeType !== 'END_MONTH'}
                                    value={formik.values.transferDate}
                                    onChange={value => {
                                        formik.setFieldValue("bankTransferDate", value)
                                    }}
                                    minDate={new Date()}
                                    TextFieldComponent={({helperText, InputProps, ...props}) => (<TextField
                                        id="bank-transfer-date"
                                        label={intl.formatMessage({
                                            id: "ADMIN_BANKS.CREATE_FORM.BANK_TRANSFER_DATE_FIELD.LABEL"
                                        })}
                                        className={classes.textField}
                                        {...props}
                                        margin="normal"
                                        variant="filled"
                                        fullWidth
                                    />)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
*/}

                        <div hidden={process.env.REACT_APP_FLAVOR=='NY'} className="row">
                            <div className="col-6">
                                <TextField
                                    id="settingsSmsInvitationContent"
                                    name="settingsSmsInvitationContent"
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_DETAIL.SETTINGS_SMS_INVITATION_CONTENT_FIELD.LABEL"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("settingsSmsInvitationContent")}
                                    error={checkForError("settingsSmsInvitationContent")}
                                />
                                {renderErrors("settingsSmsInvitationContent")}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="row d-flex flex-row mt-2 mb-2">
                                    <div className="col-4 p-0"/>
                                    <div className="col-4 d-flex justify-content-center p-0">
                                        <Thumb
                                            imageFile={formik.values.logoFile}
                                            initialURL={
                                                selectedCustomer.Logo.length !== 0
                                                    ? selectedCustomer.Logo[0].URL
                                                    : undefined
                                            }
                                            setStoredImageURL={setStoredImageURL}
                                        />
                                    </div>
                                    <div className="col-4 p-0"/>
                                </div>
                                {!isReadOnly && (
                                    <div className="row mt-2 mb-2">
                                        <div className="col-8 p-0"/>
                                        <div className="col-4 p-0">
                                            <Button variant="contained" component="label">
                                                ...
                                                <input
                                                    id="logo-file"
                                                    name="logoFile"
                                                    type="file"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    disabled={isReadOnly}
                                                    onChange={event => {
                                                        formik.setFieldValue(
                                                            "logoFile",
                                                            event.currentTarget.files[0]
                                                        )
                                                    }}
                                                    hidden
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>





                    </>
                ) : (
                    <div className={classes.form}/>
                )}
            </form>
        </>
    )
}

export default injectIntl(CustomerDetails)
