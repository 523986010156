import axios from "axios"

export function getItems(supplierID, cancelToken) {
  return axios.get(`/Supplier/${supplierID}/Items`, {
    cancelToken
  })
}
export function getFilteredItems(supplierID, priceCategoryID, cancelToken) {
  return axios.get(`/Supplier/${supplierID}/Items?priceCategoryID=${priceCategoryID || ''}`, {
    cancelToken
  })
}
export function getItemVersion(supplierID, itemID, version, cancelToken) {
  return axios.get(`/Supplier/${supplierID}/Item/${itemID}/${version}`, {
    cancelToken
  })
}
export function getMealItemPrices(itemID, cancelToken) {
  return axios.get(`/Item/${itemID}/getMealItemsPrices`, {
    cancelToken
  })
}
export function updateMealItemPrices(data, itemID) {
  return axios.patch(`/Item/${itemID}/MealItemPrice`, data)
}
export function updateItemVersion(data, supplierID, itemID, version) {
  return axios.patch(`/Supplier/${supplierID}/Item/${itemID}/${version}`, data)
}
export function createNewItemVersion(supplierID, itemID) {
  return axios.post(`/Supplier/${supplierID}/Item/${itemID}/NewVersion`)
}
export function deleteItemVersion(supplierID, itemID, version) {
  return axios.delete(`/Supplier/${supplierID}/Item/${itemID}/${version}`)
}
export function createItem(data, supplierID) {
  return axios.post(`/Supplier/${supplierID}/Item`, data)
}
export function saveItemImage(data, supplierID, itemID, version) {
  return axios.post(
    `/Supplier/${supplierID}/Item/${itemID}/${version}/Image`,
    data
  )
}

export function getMeals(supplierID, mealType, date, cancelToken) {
  return axios.get(`/Supplier/GetOrders/${mealType}/${date}`, {
    params: {
      supplierID
    },
    cancelToken
  })
}

export function getExportItemData(supplierID) {
  return axios.get(`/Supplier/${supplierID}/Items/Details`)
}

export function activateItems(supplierID, data) {
  return axios.post(`/Supplier/${supplierID}/Items/Activate`, data)
}

export function activateItem(supplierID, id, version, data) {
  return axios.post(`/Supplier/${supplierID}/Items/Activate/${id}/${version}`, data)
}
