export function renderStatus(intl, value, isNew) {
  switch (value) {
    case 1:
      return isNew
        ? intl.formatMessage({
            id: "STATUS.NEW"
          })
        : intl.formatMessage({
            id: "STATUS.ACTIVE"
          })
    case 2:
      return intl.formatMessage({
        id: "STATUS.CANCELLED"
      })
    case 3:
      return intl.formatMessage({
        id: "STATUS.DELETED"
      })
    case 4:
      return intl.formatMessage({
        id: "STATUS.READY"
      })
    case 5:
      return intl.formatMessage({
        id: "STATUS.EDIT"
      })
    default:
      return null
  }
}
