/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { InputAdornment } from "@material-ui/core"
import { useStyles } from "../../Common/_styles/formDialogStyles"

function CreateOptionFormDialog({
  show,
  saveOption,
  closeDialog,
  options,
  isUserAdmin,
  optionData,
  intl
}) {
  const classes = useStyles()

  let initialValues
  if (optionData) {
    initialValues = {
      name: optionData.Name,
      catalogNumber: optionData.CatalogNumber,
      striker: optionData.Stiker,
      price: optionData.Price,
      customerPrice: optionData.CustomerPrice,
      clientPrice: optionData.ClientPrice
    }
  } else {
    initialValues = {
      name: "",
      catalogNumber: "",
      striker: false,
      price: 0,
      customerPrice: 0,
      clientPrice: 0
    }
  }

  function handleSumbit(option) {
    saveOption(option)
  }

  function handleClose() {
    closeDialog()
  }

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      value => {
        const option = options.find(option => option.Name === value)
        return (
          option === undefined ||
          (optionData && option.Name === optionData.Name)
        )
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const strikerValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const priceValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  let validationSchema
  if (isUserAdmin) {
    validationSchema = Yup.object().shape(
      {
        name: nameValidationSchema,
        striker: strikerValidationSchema,
        price: priceValidationSchema,
        customerPrice: priceValidationSchema,
        clientPrice: priceValidationSchema
      },
      ["name", "striker", "price", "customerPrice", "clientPrice"]
    )
  } else {
    validationSchema = Yup.object().shape(
      {
        name: nameValidationSchema,
        striker: strikerValidationSchema,
        price: priceValidationSchema
      },
      ["name", "striker", "price"]
    )
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const option = {
        Name: values.name,
        CatalogNumber: values.catalogNumber,
        Stiker: values.striker,
        Price: values.price,
        Days: [0, 1, 2, 3, 4, 5, 6]
      }
      if (isUserAdmin) {
        option.CustomerPrice = values.customerPrice
        option.ClientPrice = values.clientPrice
      }
      handleSumbit(option)
      resetForm()
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="OPTION.CREATE_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent className={classes.smallForm}>
            <TextField
              id="name"
              name="name"
              label={intl.formatMessage({
                id: "OPTION.LABELS.NAME"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("name")}
              error={checkForError("name")}
            />
            {renderErrors("name")}



            <TextField
                id="catalogNumber"
                name="catalogNumber"
                label={intl.formatMessage({
                  id: "OPTION.LABELS.CATALOG_NUMBER"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                fullWidth
                {...formik.getFieldProps("catalogNumber")}
                error={checkForError("catalogNumber")}
            />
            {renderErrors("catalogNumber")}



            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("striker")}
            >
              <InputLabel htmlFor="striker-select">
                <FormattedMessage id="OPTION.LABELS.STRIKER" />
              </InputLabel>
              <Select
                {...formik.getFieldProps("striker")}
                input={<FilledInput name="striker" id="striker-select" />}
              >
                <MenuItem value={true}>
                  <FormattedMessage id="OPTION.YES" />
                </MenuItem>
                <MenuItem value={false}>
                  <FormattedMessage id="OPTION.NO" />
                </MenuItem>
              </Select>
            </FormControl>
            {renderErrors("striker")}
            <TextField
              id="price"
              name="price"
              label={intl.formatMessage({
                id: "OPTION.LABELS.PRICE"
              })}
              className={classes.textField}
              InputProps={{
                endAdornment: <InputAdornment position="end">₪</InputAdornment>
              }}
              margin="normal"
              variant="filled"
              type="number"
              fullWidth
              {...formik.getFieldProps("price")}
              error={checkForError("price")}
            />
            {renderErrors("price")}
            {isUserAdmin && (
              <>
                <TextField
                  id="customer-price"
                  name="customerPrice"
                  label={intl.formatMessage({
                    id: "OPTION.LABELS.CUSTOMER_PRICE"
                  })}
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">₪</InputAdornment>
                    )
                  }}
                  margin="normal"
                  variant="filled"
                  type="number"
                  fullWidth
                  {...formik.getFieldProps("customerPrice")}
                  error={checkForError("customerPrice")}
                />
                {renderErrors("customerPrice")}
                <TextField
                  id="client-price"
                  name="clientPrice"
                  label={intl.formatMessage({
                    id: "OPTION.LABELS.CLIENT_PRICE"
                  })}
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">₪</InputAdornment>
                    )
                  }}
                  margin="normal"
                  variant="filled"
                  type="number"
                  fullWidth
                  {...formik.getFieldProps("clientPrice")}
                  error={checkForError("clientPrice")}
                />
                {renderErrors("clientPrice")}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreateOptionFormDialog)
