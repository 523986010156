/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import {
  Button,
  FilledInput,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import OptionList from "./OptionList"
import AddIcon from "@material-ui/icons/Add"
import CreateOptionFormDialog from "./CreateOptionFormDialog"
import DeleteIcon from "@material-ui/icons/Delete"
import DishLoader from "../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import {
    renderInput,
    getSuggestions,
    renderSuggestion
} from "../../Common/downshiftFunctions"
import Downshift from "downshift"
import { useStyles } from "../_styles/complexFormStyles"
import { useStyles as detailsFormStyles } from "../../Common/_styles/detailsFormStyles"
import clsx from "clsx"

const icons = []
for (let i = 0; i < 11; i++) {
  icons.push(i)
}

function OptionGroupForm({
  intl,
  isUserAdmin,
  setSelectedOptionGroup,
  selectedOptionGroup,
  optionGroups,
  onSubmit,
  onDelete,
  updateOptionGroups,
  createNewOptionGroupVersion,
  suppliers
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const detailsClasses = detailsFormStyles()
  const [selectedSupplier, setSelectedSupplier] = useState({})
  const [createOptionData, setCreateOptionData] = useState({
    show: false
  })

  const initialValues = {
    name: selectedOptionGroup.Name,
    note: selectedOptionGroup.Description || "",
    icon: selectedOptionGroup.Icon || 0,
    min: selectedOptionGroup.Min || 0,
    max: selectedOptionGroup.Max || 0,
    version: selectedOptionGroup.Version || 1,
    status: selectedOptionGroup.Status,
    options: selectedOptionGroup.Options ? [...selectedOptionGroup.Options] : [],
    deliverySupplierID: selectedOptionGroup.DeliverySupplierID || null
  }


  useEffect(() => {
      if (initialValues.deliverySupplierID) {
          const initialSupplier = suppliers.find(
              supplier => supplier.SupplierID === initialValues.deliverySupplierID
          );
          setSelectedSupplier(initialSupplier || {});
      }
  }, [initialValues.deliverySupplierID, suppliers]);

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      value => {
        const group = optionGroups.find(group => group.Name === value)
        return group === undefined || group.id === selectedOptionGroup.id
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const iconValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const minValidationSchema = Yup.number()
    .integer(intl.formatMessage({ id: "VALIDATION.NOT_INTEGER" }))
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .max(
      Yup.ref("max"),
      intl.formatMessage({ id: "VALIDATION.MIN_GREATER_THEN_MAX" })
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const maxValidationSchema = Yup.number()
    .integer(intl.formatMessage({ id: "VALIDATION.NOT_INTEGER" }))
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const statusValidationSchema = Yup.number().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const optionsValidationSchema = Yup.array().test(
    "NotEmpty",
    intl.formatMessage({
      id: "VALIDATION.OPTIONS_EMPTY"
    }),
    value => {
      return value.length > 0
    }
  )

  const validationSchema = Yup.object().shape(
    {
      name: nameValidationSchema,
      icon: iconValidationSchema,
      min: minValidationSchema,
      max: maxValidationSchema,
      status: statusValidationSchema,
      options: optionsValidationSchema,
      deliverySupplierID: Yup.string().nullable()
    },
      ["name", "icon", "min", "max", "status", "options", "deliverySupplierID"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const { name, note, icon, min, max, version, status, options, deliverySupplierID } = values
      const newGroup = {
        Name: name,
        Description: note,
        Icon: icon,
        Min: min,
        Max: max,
        Version: version,
        Status: status,
        Options: options
      }
      if (deliverySupplierID) {
          newGroup.DeliverySupplierID = deliverySupplierID
      }

      if (
        selectedOptionGroup.availableVersions &&
        selectedOptionGroup.availableVersions.includes(version)
      ) {
        newGroup.Version = selectedOptionGroup.Version
      }
      if (Object.keys(selectedOptionGroup).includes("Name")) {
        newGroup.id = selectedOptionGroup.id
      }
      onSubmit(newGroup)
        .then(() => {
          updateOptionGroups({
            ...newGroup,
            id: newGroup.id
          })
        })
        .catch(error => {
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_UPDATE_OPTION_GROUP"
            })
          )
          setSubmitting(false)
        })
    }
  })

  function handleOptionDelete(deletedOption) {
    formik.setFieldValue(
      "options",
      formik.values.options.filter(option => option.Name !== deletedOption.Name)
    )
  }

  function openCreateOptionForm() {
    setCreateOptionData({ show: true })
  }

  function handleDaysChange(event, optionName, dayValue) {
    const changedOptionGroups = [...formik.values.options]
    const changedOptionGroup = changedOptionGroups.find(
      option => option.Name === optionName
    )

    if (event.target.checked) {
      changedOptionGroup.Days = [...changedOptionGroup.Days, dayValue]
    } else {
      changedOptionGroup.Days = changedOptionGroup.Days.filter(
        value => value !== dayValue
      )
    }

    formik.setFieldValue("options", [...changedOptionGroups])
  }

  function openEditOptionForm(optionForEditing) {
    const index = formik.values.options.findIndex(
      option => option.Name === optionForEditing.Name
    )
    setCreateOptionData({
      show: true,
      index
    })
  }

  function handleSaveOption(option) {
    option.OptionGroupID = selectedOptionGroup.id
    if (createOptionData.index !== undefined) {
      const options = [...formik.values.options]
      options[createOptionData.index] = option
      formik.setFieldValue("options", [...options])
    } else {
      formik.setFieldValue("options", [...formik.values.options, option])
    }
    setCreateOptionData({ show: false })
  }

  function sendForReview() {
    formik.validateForm().then(errors => {
      if (Object.values(errors).length === 0) {
        formik.setFieldValue("status", 2)
        formik.submitForm()
      } else {
        formik.submitForm()
      }
    })
  }

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span
        className={
          fieldName === "options" ? classes.errorForOptions : classes.error
        }
      >
        {formik.errors[fieldName]}
      </span>
    ) : null
  }

  function createNewVersion() {
    createNewOptionGroupVersion()
      .then(() => {
        const newVersion =
          1 +
          selectedOptionGroup.availableVersions.reduce(
            (max, version) => (version > max ? version : max),
            1
          )
        setSelectedOptionGroup({
          ...selectedOptionGroup,
          Status: 1,
          Version: newVersion
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_NEW_OPTION_GROUP_VERSION"
          })
        )
      )
  }

  useEffect(() => {
    if (
      selectedOptionGroup.availableVersions &&
      selectedOptionGroup.availableVersions.includes(formik.values.version)
    ) {
      setSelectedOptionGroup({
        ...selectedOptionGroup,
        Version: formik.values.version
      })
    }
  }, [formik.values.version])

  const isReadOnly = !isUserAdmin && formik.values.status !== 1

  return (
    <div>
      {selectedOptionGroup.isLoading && <DishLoader centered />}
      <CreateOptionFormDialog
        show={createOptionData.show}
        isUserAdmin={isUserAdmin}
        saveOption={handleSaveOption}
        optionData={formik.values.options[createOptionData.index]}
        closeDialog={() => setCreateOptionData({ show: false })}
        options={formik.values.options}
      />
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className="row pt-5">
              <div className="col-8 d-flex justify-content-center align-items-center">
                <FormControl
                  variant="filled"
                  className={classes.smallTextField}
                  fullWidth
                >
                  <Select
                    {...formik.getFieldProps("version")}
                    input={
                      <FilledInput
                        name="version"
                        inputProps={{
                          className: classes.input
                        }}
                        id="option-group-version-select"
                      />
                    }
                  >
                    {selectedOptionGroup.availableVersions ? (
                      selectedOptionGroup.availableVersions.map(version => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={1}>1</MenuItem>
                    )}
                    {selectedOptionGroup.availableVersions &&
                      !selectedOptionGroup.availableVersions.includes(
                        formik.values.version
                      ) && (
                        <MenuItem value={formik.values.version}>
                          {formik.values.version}
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  color="secondary"
                  onClick={createNewVersion}
                  className={classes.button}
                  disabled={
                    formik.isSubmitting ||
                    !selectedOptionGroup.availableVersions ||
                    optionGroups
                      .find(
                        optionGroup => optionGroup.id === selectedOptionGroup.id
                      )
                      .Versions.find(
                        version => version.Status === 3 || version.Status === 4
                      ) === undefined ||
                    optionGroups
                      .find(
                        optionGroup => optionGroup.id === selectedOptionGroup.id
                      )
                      .Versions.find(version => version.Status === 1) !==
                      undefined ||
                    !selectedOptionGroup.availableVersions.includes(
                      formik.values.version
                    )
                  }
                >
                  <FormattedMessage id="CREATE_FORM.NEW_VERSION_BUTTON" />
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  className={classes.button}
                  disabled={formik.isSubmitting}
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    onDelete(selectedOptionGroup.id, formik.values.version)
                  }
                >
                  <FormattedMessage id="CREATE_FORM.DELETE_BUTTON" />
                </Button>
              </div>
              <div className="col-4" />
            </div>
            <div className="row pt-5">
              <div className="col-2 d-flex justify-content-end align-items-center pr-0">
                <FormattedMessage id="CREATE_FORM.NAME_FIELD.LABEL" />
              </div>
              <div className="col-6 pl-0">
                <TextField
                  id="option-group-name"
                  className={classes.textField}
                  margin="normal"
                  variant="filled"
                  hiddenLabel
                  inputProps={{
                    className: classes.input,
                    readOnly: isReadOnly
                  }}
                  fullWidth
                  {...formik.getFieldProps("name")}
                  error={checkForError("name")}
                />
                {renderErrors("name")}
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row">
              <div className="col-2 d-flex justify-content-end align-items-center pr-0">
                <FormattedMessage id="CREATE_FORM.NOTE_FIELD.LABEL" />
              </div>
              <div className="col-6 pl-0">
                <TextField
                  id="option-group-note"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                    readOnly: isReadOnly
                  }}
                  multiline
                  rows="4"
                  margin="normal"
                  variant="filled"
                  fullWidth
                  {...formik.getFieldProps("note")}
                  error={checkForError("note")}
                />
              </div>
              <div className="col-4 text-center d-flex justify-content-center align-items-center">
                <FormControlLabel
                  className={classes.textFieldWrapper}
                  control={
                    <FormControl
                      variant="filled"
                      className={classes.smallTextField}
                      fullWidth
                      error={checkForError("icon")}
                    >
                      <Select
                        {...formik.getFieldProps("icon")}
                        input={
                          <FilledInput
                            name="icon"
                            inputProps={{
                              className: classes.input,
                              readOnly: isReadOnly
                            }}
                            id="icon-select"
                          />
                        }
                      >
                        {icons.map(index => (
                          <MenuItem key={index} value={index}>
                            <img
                              src={toAbsoluteUrl(
                                `/media/svg/option-icons/${index}.svg`
                              )}
                              alt="Icon"
                              className={classes.iconDisplay}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  labelPlacement="top"
                  label={intl.formatMessage({
                    id: "CREATE_FORM.ICON_FIELD.LABEL"
                  })}
                />
              </div>
                      </div>

            <div className="row pt-5">
                <div className="col-2 d-flex justify-content-end align-items-center pr-0">
                          </div>
                          <div className="col-6 p-0">
                              <div>
                                  <Downshift
                                      id="downshift-for-supplier-2"
                                      selectedItem={
                                          selectedSupplier.Name ? selectedSupplier : null
                                      }
                                      onChange={selected => {
                                          setSelectedSupplier(selected || {});
                                          formik.setFieldValue("deliverySupplierID", selected ? selected.SupplierID : null);
                                      }
                                      }
                                      itemToString={item => (item ? item.Name : "")}
                                  >
                                      {({
                                          clearSelection,
                                          getInputProps,
                                          getItemProps,
                                          getLabelProps,
                                          getMenuProps,
                                          highlightedIndex,
                                          inputValue,
                                          isOpen,
                                          openMenu,
                                          selectedItem
                                      }) => {
                                          const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
                                              onChange: event => {
                                                  if (event.target.value === "") {
                                                      clearSelection()
                                                  }
                                              },
                                              onFocus: openMenu,
                                          })

                                          return (
                                              <div className={detailsClasses.container}>
                                                  {renderInput({
                                                      fullWidth: true,
                                                      classes,
                                                      label: intl.formatMessage({
                                                          id: "FILTER.DELIVERY_SUPPLIER_FIELD.LABEL"
                                                      }),
                                                      InputLabelProps: getLabelProps({
                                                          shrink: true
                                                      }),
                                                      InputProps: { onBlur, onChange, onFocus },
                                                      inputProps
                                                  })}

                                                  <div {...getMenuProps()}>
                                                      {isOpen ? (
                                                          <Paper className={detailsClasses.paper} square>
                                                              {getSuggestions(inputValue, suppliers, {
                                                                  showEmpty: true
                                                              }).map((suggestion, index) =>
                                                                  renderSuggestion({
                                                                      suggestion,
                                                                      index,
                                                                      itemProps: getItemProps({
                                                                          item: suggestion
                                                                      }),
                                                                      highlightedIndex,
                                                                      selectedItem
                                                                  })
                                                              )}
                                                          </Paper>
                                                      ) : null}
                                                  </div>
                                              </div>
                                          )
                                      }}
                                  </Downshift>
                              </div>
                </div>
                <div className="col-4"></div>
            </div>

            <div className="row">
              <div className="col-2" />
              <div className="col-6 d-flex justify-content-center align-items-center">
                <div className="position-relative">
                  <FormControlLabel
                    className={classes.smallTextFieldWrapper}
                    control={
                      <TextField
                        id="min"
                        className={classes.smallTextField}
                        margin="normal"
                        variant="filled"
                        type="number"
                        hiddenLabel
                        inputProps={{
                          min: 0,
                          className: classes.input,
                          readOnly: isReadOnly
                        }}
                        fullWidth
                        {...formik.getFieldProps("min")}
                        error={checkForError("min")}
                      />
                    }
                    labelPlacement="start"
                    label={intl.formatMessage({
                      id: "CREATE_FORM.MIN_FIELD.LABEL"
                    })}
                  />
                  {renderErrors("min")}
                </div>
                <div className="position-relative">
                  <FormControlLabel
                    className={classes.smallTextFieldWrapper}
                    control={
                      <TextField
                        id="max"
                        className={classes.smallTextField}
                        margin="normal"
                        variant="filled"
                        type="number"
                        hiddenLabel
                        inputProps={{
                          min: 0,
                          className: classes.input,
                          readOnly: isReadOnly
                        }}
                        fullWidth
                        {...formik.getFieldProps("max")}
                        error={checkForError("max")}
                      />
                    }
                    labelPlacement="start"
                    label={intl.formatMessage({
                      id: "CREATE_FORM.MAX_FIELD.LABEL"
                    })}
                  />
                  {renderErrors("max")}
                </div>
              </div>
              <div className="col-4" />
            </div>
            <div className="row pb-5">
              <div className="col-12 text-right">
                <OptionList
                  options={formik.values.options}
                  onDelete={handleOptionDelete}
                  onEdit={openEditOptionForm}
                  onDaysChange={handleDaysChange}
                  isReadOnly={isReadOnly}
                  isUserAdmin={isUserAdmin}
                />
                <div className="position-relative d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="secondary"
                      className={clsx(classes.button, "flex-shrink-0")}
                      disabled={formik.isSubmitting || isReadOnly}
                    >
                      <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                      {formik.isSubmitting && (
                        <span className="ml-1 spinner spinner-white"></span>
                      )}
                    </Button>
                    {!isUserAdmin ? (
                      <Button
                        variant="contained"
                        type="button"
                        size="large"
                        color="secondary"
                        onClick={sendForReview}
                        className={classes.button}
                        disabled={formik.isSubmitting || isReadOnly}
                      >
                        <FormattedMessage id="CREATE_FORM.SEND_TO_REVIEW_BUTTON" />
                        {formik.isSubmitting && (
                          <span className="ml-1 spinner spinner-white"></span>
                        )}
                      </Button>
                    ) : (
                      <FormControl
                        variant="filled"
                        className={clsx(
                          classes.statusTextField,
                          "flex-shrink-0"
                        )}
                        fullWidth
                      >
                        <Select
                          {...formik.getFieldProps("status")}
                          input={
                            <FilledInput
                              name="status"
                              inputProps={{
                                className: classes.input
                              }}
                              id="option-group-status-select"
                            />
                          }
                        >
                          <MenuItem value={1}>
                            <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.DRAFT" />
                          </MenuItem>
                          <MenuItem value={2}>
                            <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.WAITING" />
                          </MenuItem>
                          <MenuItem value={3}>
                            <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.APPROVED" />
                          </MenuItem>
                          <MenuItem value={4}>
                            <FormattedMessage id="CREATE_FORM.STATUS_FIELD.VALUE.REJECTED" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    disabled={formik.isSubmitting || isReadOnly}
                    onClick={openCreateOptionForm}
                  >
                    <FormattedMessage id="CREATE_FORM.NEW_OPTION_BUTTON" />
                    <AddIcon className={classes.extendedIcon} />
                  </Button>
                  {renderErrors("options")}
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(OptionGroupForm)
