import axios from "axios"

export function getOrderHistory(UserID, DateFrom, DateTo, cancelToken) {
  return axios.post(
    `/OrderHistory/${UserID}`,
    { DateFrom, DateTo },
    {
      cancelToken
    }
  )
}

export function getItemHistory(UserID, DateFrom, DateTo, cancelToken) {
  return axios.post(
    `/ItemHistory/${UserID}`,
    { DateFrom, DateTo },
    {
      cancelToken
    }
  )
}

export function getUserActivity(UserID, DateFrom, DateTo, cancelToken) {
  return axios.post(
    `/User/Activity/${UserID}`,
    { DateFrom, DateTo },
    {
      cancelToken
    }
  )
}

export function getUsersBudgets(UserID, cancelToken) {
  return axios.get(
    `/Budget/UsersBudgets/${UserID}`,
    {
      cancelToken
    }
  )
}

export function addToBudgetBalance(budgetID, amount, comments) {
  return axios.post(`/Budget/FixBudget/${budgetID}`, {
    amount,
    comments
  })
}

export function getBudgetTransactions(budgetID, cancelToken) {
  return axios.get(`/Budget/Transaction/${budgetID}`, {
    cancelToken
  })
}

export function getUserTransfer(userID, from, to, cancelToken) {
  return axios.get(
      `/Bank/Transfers?userID=${userID}&from=${from}&to=${to}`,
      {
        cancelToken
      }
  )
}

export function getWalletTransactions(userID, cancelToken) {
  return axios.get(`/Wallet/Transactions/${userID}`, {
    cancelToken
  })
}
