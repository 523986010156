/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import BlurOn from "@material-ui/icons/BlurOn";
import BlurOff from "@material-ui/icons/BlurOff";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import DishLoader from "../../../app/modules/Common/DishLoader"
import ConfirmationDialog from "../Common/ConfirmationDialog"
import {
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Select
} from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import MenuItem from "@material-ui/core/MenuItem";
import InputMask from "react-input-mask";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete from "react-places-autocomplete";
import { useStyles } from "../Common/_styles/detailsFormStyles";
import { CropperDialog } from "../../modules/Common/CropperDialog";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { he } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux";
import SupplierItemSelectorDialog from "./Duplication/SupplierItemSelectorDialog";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Cropper from "react-cropper";
import StateFreezeFormDialog from "./StateFreezeFormDialog";
import "cropperjs/dist/cropper.css";
import {getSuppliers} from "../../modules/Suppliers/_axios/supplierCrud";
import axios from "axios";

function SupplierImportDetails({
  intl,
  selectedSupplier,
  allSuppliers,
  submitSupplier,
  submitSupplierCopyItem,
  submitCertificateDoc,
  updateSuppliers,
  isUserAdmin,
  saveImage,
  settingsFreezeSupplier,
   statusLoading
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showAddMealItemFormDialog, setShowAddMealItemFormDialog] = useState(
      false
  );
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(
        false
    );
    const [importData, setImportData] = useState(null);
  useEffect(() => {
    function fetchSuppliers(cancelToken) {
      setSupplierData({...supplierData, isLoading: true});
      getSuppliers(cancelToken.token, "active")
          .then(({data}) => {
            setSupplierData({
              ...supplierData, data, isLoading: false
            });
          })
          .catch(error => handleApiError(dispatch, error, intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })));
    }

    const cancelToken = axios.CancelToken.source();
    fetchSuppliers(cancelToken);
    return () => cancelToken.cancel();
  }, []);
  const [supplierData, setSupplierData] = useState({
    data: [], isLoading: true, backup_data: [],
  });
  function addItems() {
    setLoading(true);
      statusLoading(true);
    submitSupplierCopyItem({
      Items: importData.map(i => i.Item.ItemID)
    }, {
      SupplierID: selectedSupplier.SupplierID
    },)
        .then(() => {
          setLoading(false);
            statusLoading(false)
          setShowConfirmationDialog(false);
          setImportData(null);
        })
        .catch(error => {
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_COPY_SUPPLIER_ITEM"
              })
          );
          setLoading(false);
            statusLoading(false)
        });
  }

  return (
    <>
        <ConfirmationDialog
            show={showConfirmationDialog}
            onSubmit={addItems}
            onClose={() => {
                setShowConfirmationDialog(false)
                setImportData(null);
            }}
            isSubmitting={loading}
            dialogTitle={<FormattedMessage id="COPY_ITEM_DIALOG.TITLE" />}
            contentText={intl.formatMessage({
                id: "COPY_ITEM_DIALOG.TEXT"
            })}
        />
      <SupplierItemSelectorDialog
          show={showAddMealItemFormDialog}
          closeDialog={() => setShowAddMealItemFormDialog(false)}
          submitData={(data) => {
              setImportData(data);
              setShowConfirmationDialog(true);
          }}
          suppliers={supplierData.data || []}
      />
      <br/>
      <div>
        <Button variant="contained" component="label"
                disabled={loading}
                onClick={() => [
          setShowAddMealItemFormDialog(true)
        ]}>
          <FormattedMessage id="SUPPLIER_DETAILS.DUPLICATION" />
        </Button>
      </div>
      <br/>
      {
        loading && <DishLoader/>
      }
    </>
  );
}

export default injectIntl(SupplierImportDetails);
