import axios from "axios"

export function createSpecialDate(data) {
    return axios.post(`/SpecialDates`, data)
}
export function editSpecialDate(id, data) {
    return axios.patch(`/SpecialDates/${id}`, data)
}
export function deleteSpecialDate(id) {
    return axios.delete(`/SpecialDates/${id}`)
}
export function getSpecialDates(id?, from, to) {
    if (id!=null){
        return axios.get(`/SpecialDates?customerId=${id}&fromDate=${from}&toDate=${to}`)
    }
    return axios.get(`/SpecialDates?fromDate=${from}&toDate=${to}`)
}
export function getSpecialDatesBySuppler(id?, from, to) {
    if (id!=null){
        return axios.get(`/SpecialDates?supplierId=${id}&fromDate=${from}&toDate=${to}`)
    }
    return axios.get(`/SpecialDates?fromDate=${from}&toDate=${to}`)
}