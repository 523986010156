/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { Paper } from "@material-ui/core"
import Downshift from "downshift"
import SetDateRangeDialog from "../../Customers/Locations/Meals/SetDateRangeDialog"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../../Common/downshiftFunctions"
import { useStyles } from "../../Common/_styles/filterPanelStyles"
import { formatDate } from "../../Common/momentFunctions"

function ItemCancellationRangeFilterPanel({
  onSearchParamsSave,
  initialSearchParams,
  supplierData,
  customerData,
  intl
}) {
  const classes = useStyles()

  const [searchParams, setSearchParams] = useState(initialSearchParams)
  const [dateRangeDialogState, setDateRangeDialogState] = useState({
    show: false
  })

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
  }

  function handleSetDateRange(from, to) {
    handleSearchParamChange("from")(from)
    handleSearchParamChange("to")(to)
    setDateRangeDialogState({ show: false })
  }

  function handleOpenSetDateRangeDialog() {
    setDateRangeDialogState({
      show: true,
      from: searchParams.from,
      to: searchParams.to
    })
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }


  return (
    <>
      <SetDateRangeDialog
        intl={intl}
        show={dateRangeDialogState.show}
        saveRange={(from, to) => handleSetDateRange(from, to)}
        closeDialog={() => setDateRangeDialogState({ show: false })}
        maxDate={dateRangeDialogState.maxDate}
        minDate={dateRangeDialogState.minDate}
        maxRange={35}
        initialStartDate={dateRangeDialogState.from}
        initialEndDate={dateRangeDialogState.to}
      />
      <form
        className={classes.root}
        onSubmit={handleSearchParamsSubmit}
        autoComplete="off"
      >
        {(
          <Downshift
            id="downshift-for-supplier"
            selectedItem={
              searchParams.supplier?.Name ? searchParams.supplier : null
            }
            onChange={selected => {
              handleSearchParamChange("location")("")
              handleSearchParamChange("supplier")(selected || {})
            }}
            itemToString={item => (item ? item.Name : "")}
          >
            {({
              clearSelection,
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              openMenu,
              selectedItem
            }) => {
              const {
                onChange,
                onBlur,
                onFocus,
                ...inputProps
              } = getInputProps({
                onChange: event => {
                  if (event.target.value === "") {
                    clearSelection()
                  }
                },
                onFocus: openMenu
              })

              return (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    label: intl.formatMessage({
                      id: "FILTER.SUPPLIER_FIELD.LABEL"
                    }),
                    nested: true,
                    InputLabelProps: getLabelProps({
                      shrink: true
                    }),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue, supplierData.data, {
                          showEmpty: true
                        }).map((suggestion, index) =>
                            {
                              return (
                                  renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion
                                    }),
                                    highlightedIndex,
                                    selectedItem
                                  })
                              )
                            }
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              )
            }}
          </Downshift>
        )}


        {(
            <Downshift
                id="downshift-for-customer"
                selectedItem={
                  searchParams.customer?.Name ? searchParams.customer?.Name : null
                }
                onChange={selected => {
                  handleSearchParamChange("location")("")
                  handleSearchParamChange("customer")(selected || {})
                }}
                itemToString={item => (item ? item.Name : "")}
            >
              {({
                  clearSelection,
                  getInputProps,
                  getItemProps,
                  getLabelProps,
                  getMenuProps,
                  highlightedIndex,
                  inputValue,
                  isOpen,
                  openMenu,
                  selectedItem
                }) => {
                const {
                  onChange,
                  onBlur,
                  onFocus,
                  ...inputProps
                } = getInputProps({
                  onChange: event => {
                    if (event.target.value === "") {
                      clearSelection()
                    }
                  },
                  onFocus: openMenu
                })

                return (
                    <div className={classes.container}>
                      {renderInput({
                        fullWidth: true,
                        classes,
                        label: intl.formatMessage({
                          id: "FILTER.CUSTOMER_FIELD.LABEL"
                        }),
                        nested: true,
                        InputLabelProps: getLabelProps({
                          shrink: true
                        }),
                        InputProps: { onBlur, onChange, onFocus },
                        inputProps
                      })}

                      <div {...getMenuProps()}>
                        {isOpen ? (
                            <Paper className={classes.paper} square>
                              {getSuggestions(inputValue, customerData.data, {
                                showEmpty: true
                              }).map((suggestion, index) =>
                                  renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion
                                    }),
                                    highlightedIndex,
                                    selectedItem
                                  })
                              )}
                            </Paper>
                        ) : null}
                      </div>
                    </div>
                )
              }}
            </Downshift>
        )}



        <div className="d-flex flex-row justify-content-center align-items-baseline">
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.FROM"
            })}
            fullWidth
            value={searchParams.from ? formatDate(searchParams.from) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
          <div className={classes.divider}>-</div>
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.TO"
            })}
            fullWidth
            value={searchParams.to ? formatDate(searchParams.to) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
        </div>
        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={
            !searchParams.from ||
            !searchParams.to
          }
          className={`font-weight-bold my-3 ml-10`}
        >
          <span>
            <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
          </span>
        </Button>
      </form>
    </>
  )
}

export default injectIntl(ItemCancellationRangeFilterPanel)
