/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import {TextField, Tooltip} from "@material-ui/core"
import DishLoader from "../Common/DishLoader"
import {
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow
} from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
      position: "relative",
      boxShadow: "none !important"
  },
  tableWrapper: {
    maxHeight: 424,
    overflowY: "auto"
  },
  locationSearch: {
    marginTop: 8,
    marginBottom: 8
    },
  mark_all: {
    border: "none",
    background: "none"
  }
}))

function LocationListForBanner({
  selected,
  setSelected,
  locationsData,
  onChange,
  handleSelection,
  intl
}) {
  const classes = useStyles()

  const rowsLocations = locationsData.locations || []
  //const rows = locationsData.locations || []
  const [emptyRows, setEmptyRows] = useState(0)
  const [rows, setRows] = useState(rowsLocations)

  if (locationsData.isLoading) {
    return <DishLoader />
  }

  function handleClick(event, id) {
    setSelected(id)
  }


  function locationSearch(event) {
    setRows(rowsLocations.filter(item => item.Name.indexOf(event.currentTarget.value) !== -1));
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>


        <div className={classes.locationSearch}>
          <TextField
              id="message"
              name="message"
              label={intl.formatMessage({
                id: "ADMIN_DETAIL.LABELS.LOCATION"
              })}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                className: classes.textRight
              }}
              dir="ltr"
              onChange={locationSearch}
          />
        </div>

        {locationsData.isLoading && <DishLoader centered />}
        <div className={classes.tableWrapper}>
          <Table stickyHeader aria-labelledby="tableTitle" size="small">
            {
                          <TableHead>
                              <TableRow>
                                  <TableCell
                                      key="checkbox"
                                      align="left"
                                  >
                                      <button
                                          type="button"
                                          className={classes.mark_all}
                                          onClick={(event) => handleSelection(event)}
                                      >
                                          <Checkbox
                                              checked={true}
                                              disabled={true}
                                              name="breakfast"
                                              color="secondary"
                                          />
                                          {
                                              intl.formatMessage({
                                                  id: "ADMIN_DETAIL.LABELS.SELECT_ALL"
                                              })
                                          }
                                          
                                         
                                      </button>

                                  </TableCell>
                                  <TableCell
                                      key="Name"
                                      align="left"
                                  >
                                      Name
                                  </TableCell>

                              </TableRow>


                          </TableHead>
            }
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.LocationID}
                    onClick={(event) => setSelected(row.LocationID)}
                  >
                    <TableCell
                      component="th"
                      align="center"
                      padding="none"
                      scope="row"
                    >
                      {
                        row.IsSelected && <Checkbox
                            checked={true}
                            disabled={true}
                            name="breakfast"
                            color="secondary"
                        />
                      }
                      {
                        !row.IsSelected && <Checkbox
                            checked={false}
                            disabled={true}
                            name="breakfast"
                            color="secondary"
                        />
                      }
                    </TableCell>
                    <TableCell align="left">
                      {row.Name}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(LocationListForBanner)
