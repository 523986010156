/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function CreateQuestionFormDialog({
  show,
  initialQuestionState,
  closeDialog,
  submitData,
  questionsData,
  intl
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let initialValues
  if (initialQuestionState.ID) {
    initialValues = {
      question: initialQuestionState.Question,
      answer: initialQuestionState.Answer,
      order: initialQuestionState.Order
    }
  } else {
    initialValues = {
      question: "",
      answer: "",
      order: 0
    }
  }

  function handleSumbit(data) {
    closeDialog(data)
  }

  function handleClose() {
    closeDialog(null)
  }

  const questionValidationSchema = Yup.string()
    .test(
      "questionIsUnique",
      intl.formatMessage({
        id: "VALIDATION.QUESTION_EXISTS"
      }),
      value => {
        const question = questionsData.questions.find(
          question => question.question === value
        )
        return question === undefined || question.ID === initialQuestionState.ID
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const answerValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const orderValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const validationSchema = Yup.object().shape(
    {
      question: questionValidationSchema,
      answer: answerValidationSchema,
      order: orderValidationSchema
    },
    ["question", "answer", "order"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const { question, answer, order } = values
      const questionData = {
        ID: initialQuestionState.ID,
        Question: question,
        Answer: answer,
        Order: order
      }
      submitData(questionData)
        .then(({ data }) => {
          dispatch(
            snackbarActions.setSnackbarData(
              intl.formatMessage({
                id: initialQuestionState.ID
                  ? "API.EDIT_SUCCESS"
                  : "API.CREATE_SUCCESS"
              })
            )
          )
          handleSumbit({
            ...questionData,
            ID: data ? data.id : initialQuestionState.ID
          })
        })
        .catch(error => {
          setSubmitting(false)
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: initialQuestionState.ID
                ? "API.ERROR.FAILED_TO_EDIT_FAQ"
                : "API.ERROR.FAILED_TO_CREATE_FAQ"
            })
          )
        })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="CREATE_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent className={classes.bigForm}>
            <div className="position-relative">
              <TextField
                name="question"
                label={intl.formatMessage({
                  id: "LABELS.QUESTION"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                fullWidth
                {...formik.getFieldProps("question")}
                error={checkForError("question")}
              />
              {renderErrors("question")}
            </div>
            <div className="position-relative">
              <TextField
                name="answer"
                label={intl.formatMessage({
                  id: "LABELS.ANSWER"
                })}
                className={classes.textField}
                multiline
                rows="4"
                margin="normal"
                variant="filled"
                fullWidth
                {...formik.getFieldProps("answer")}
                error={checkForError("answer")}
              />
            </div>
            <div className="position-relative">
              <TextField
                name="order"
                label={intl.formatMessage({
                  id: "LABELS.ORDER"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                type="number"
                inputProps={{
                  min: 0
                }}
                fullWidth
                {...formik.getFieldProps("order")}
                error={checkForError("order")}
              />
              {renderErrors("order")}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreateQuestionFormDialog)
