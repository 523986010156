/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage, injectIntl } from "react-intl"
import { getCombos } from "../../../Combos/_axios/comboCrud.js"
import UploadedCombosList from "./UploadedCombosList.js"
import { useStyles } from "../../../Common/_styles/detailsFormStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../../redux/snackbar/snackbarHandlers.js"
import Downshift from "downshift"
import { Paper, Typography } from "@material-ui/core"
import { getSuggestions, renderInput, renderSuggestion } from "../../../Common/downshiftFunctions.js"
import axios from "axios"
import DishLoader from "../../../Common/DishLoader.js"

function AddMealComboFormDialog({ intl, show, closeDialog, submitData, suppliers, takenCombosIDs, mealTypeIndex }) {
  const dispatch = useDispatch()
  function fetchCombos(cancelToken) {
    setCombosData({ ...combosData, isLoading: true, notRequested: false })
    getCombos(cancelToken.token)
      .then(({ data }) => {
        const combos = data.filter(item => takenCombosIDs.indexOf(item.Id) === -1);
        setCombosData({
          combos,
          selected: combos.map(combo => combo.Id),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_MEAL_COMBOS"
          })
        )
      )
  }

  const [combosData, setCombosData] = useState({
    combos: [],
    selected: [],
    isLoading: false,
    notRequested: true
  })

  useEffect(() => {
    if (show) {
      setCombosData({
        combos: [],
        selected: [],
        isLoading: false,
        notRequested: true
      })
      setSubmitting(false)
      const cancelToken = axios.CancelToken.source()
      fetchCombos(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [show])

  const [isSubmitting, setSubmitting] = useState(false)

  const classes = useStyles()

  function handleSubmit() {
    setSubmitting(true)
    const chosenItems = combosData.combos.filter(item =>
        combosData.selected.includes(item.Id)
    )
    submitData(chosenItems)
  }

  function handleToggle(checked, ComboID) {
    if (checked) {
      setCombosData({
        ...combosData,
        selected: [...combosData.selected, ComboID]
      })
    } else {
      setCombosData({
        ...combosData,
        selected: combosData.selected.filter(value => value !== ComboID)
      })
    }
  }

  function handleToggleAll(checked) {
    if (checked) {
      setCombosData({
        ...combosData,
        selected: combosData.combos.map(combo => combo.ComboID)
      })
    } else {
      setCombosData({
        ...combosData,
        selected: []
      })
    }
  }

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: "100%" } }}
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="ADD_MEAL_COMBO_FORM.TITLE" />
        </h3>
      </DialogTitle>
      <DialogContent style={{ height: 500 }}>
        {combosData.isLoading ? (
          <div>
              <DishLoader/>
          </div>
        ) : combosData.combos.length > 0 ? (
          <UploadedCombosList
            combos={combosData.combos}
            selected={combosData.selected}
            onToggle={handleToggle}
            onToggleAll={handleToggleAll}
          />
        ) : combosData.notRequested ? null : (
          <Typography variant="h4">
            <FormattedMessage id="COMMON.NO_RESULTS" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          size="large"
          onClick={closeDialog}
          className={classes.button}
          disabled={isSubmitting}
        >
          <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
        </Button>
        <Button
          variant="contained"
          type="submit"
          size="large"
          color="secondary"
          className={classes.button}
          disabled={isSubmitting || combosData.selected.length === 0}
          onClick={handleSubmit}
        >
          <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
          {isSubmitting && <span className="ml-1 spinner spinner-white"></span>}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default injectIntl(AddMealComboFormDialog)
