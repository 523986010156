/* eslint-disable no-restricted-imports */
import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { InputAdornment } from "@material-ui/core"
import ConfirmationDialog from "../Common/ConfirmationDialog"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function CreditCardRefundFormDialog({
  show,
  onClose,
  intl,
  submitData,
  updateItems,
  employeeAmount
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirmationDialogData, setConfirmationDialogData] = useState({
    show: false,
    data: null,
    setSubmitting: null,
    resetForm: null,
    setFieldError: null
  })

  const initialValues = {
    employeeRefund: "",
    refundReason: ""
  }

  const priceValidationSchema = Yup.number()
    .when({
      is: () => {
        return employeeAmount !== 0
      },
      then: Yup.number().required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
    })
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .max(
      employeeAmount,
      intl.formatMessage({ id: "VALIDATION.EMPLOYEE_REFUND.MAX_VALUE" }) +
        " " +
        employeeAmount
    )

  const reasonValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const validationSchema = Yup.object().shape(
    {
      employeeRefund: priceValidationSchema,
      refundReason: reasonValidationSchema
    },
    ["employeeRefund", "refundReason"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
      const { employeeRefund, refundReason } = values

      const data = {
        RefundReason: refundReason
      }
      if (employeeRefund) {
        data.EmployeeRefundSum = employeeRefund
      }

      setSubmitting(false)
      setConfirmationDialogData({
        show: true,
        data: data,
        setSubmitting,
        resetForm,
        setFieldError
      })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  const handleConfirmRefund = () => {
    confirmationDialogData.setSubmitting(true)
    submitData(confirmationDialogData.data)
      .then(() => {
        setConfirmationDialogData({ show: false })
        confirmationDialogData.setSubmitting(false)
        updateItems(confirmationDialogData.data)
        onClose()
        confirmationDialogData.resetForm()
      })
      .catch(error => {
        setConfirmationDialogData({ show: false })
        confirmationDialogData.setSubmitting(false)
        if (error.response && error.response.data) {
          if (
            error.response.data.Message ===
            "Invalid employee amount refund is more than employee cost"
          ) {
            confirmationDialogData.setFieldError(
              "employeeRefund",
              intl.formatMessage({
                id: "VALIDATION.EMPLOYEE.REFUND_IS_GREATER_THEN_COST"
              })
            )
          } else if (
            error.response.data.Message === "Index From Tranzila not found"
          ) {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.TRANZILA_INDEX_NOT_FOUND"
              })
            )
          } else {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_REFUND"
              })
            )
          }
        } else {
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_REFUND"
            })
          )
        }
      })
  }

  return (
    <div>
      <ConfirmationDialog
        show={confirmationDialogData.show}
        onSubmit={handleConfirmRefund}
        onClose={() => setConfirmationDialogData({ show: false })}
        isSubmitting={formik.isSubmitting}
        dialogTitle={<FormattedMessage id="CONFIRM_REFUND_DIALOG.TITLE" />}
        contentText={intl.formatMessage({
          id: "CONFIRM_REFUND_DIALOG.TEXT"
        })}
      />
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="REFUND_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent>
            {`${intl.formatMessage({
              id: "MAX_REFUND_AMOUNT"
            })}: ${employeeAmount}`}
            <TextField
              name="employeeRefund"
              label={intl.formatMessage({
                id: "EMPLOYEE_REFUND_FIELD.LABEL"
              })}
              placeholder={`${intl.formatMessage({
                id: "EMPLOYEE_REFUND_FIELD.PLACEHOLDER"
              })} ${employeeAmount}`}
              className={classes.textField}
              InputProps={{
                endAdornment: <InputAdornment position="end">₪</InputAdornment>
              }}
              margin="normal"
              variant="filled"
              type="number"
              fullWidth
              {...formik.getFieldProps("employeeRefund")}
              error={checkForError("employeeRefund")}
            />
            {renderErrors("employeeRefund")}
            <TextField
              name="refundReason"
              label={intl.formatMessage({
                id: "REFUND_REASON_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("refundReason")}
              error={checkForError("refundReason")}
            />
            {renderErrors("refundReason")}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={onClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreditCardRefundFormDialog)
