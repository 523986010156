/* eslint-disable no-restricted-imports */

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { List, ListItem, ListItemText } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    maxHeight: 300,
    overflowY: "auto"
  },
  item: {
    marginBottom: theme.spacing(1),
    border: "1px solid #ebedf3"
  }
}))

export default function ElementList({ rows, selected, setSelected }) {
  const classes = useStyles()

  return (
    <List className={classes.root} component="div">
      {rows.map(row => (
        <ListItem
          button
          key={row.Name}
          selected={row.Name === selected}
          className={classes.item}
          onClick={() => setSelected(row.Name)}
        >
          <ListItemText primary={row.Name} />
        </ListItem>
      ))}
    </List>
  )
}
