/* eslint-disable no-restricted-imports */

import { Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import CreateTagButton from "../modules/Common/CreateNewButton.js"
import CreateTagFormDialog from "../modules/Tags/CreateTagFormDialog.js"
import DeleteTagDialog from "../modules/Tags/DeleteTagDialog.js"
import TagList from "../modules/Tags/TagList.js"
import {
  createTag,
  editTag,
  deleteTag,
  getTags,
  setTagIcon
} from "../modules/Tags/_axios/tagCrud.js"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"

function TagsAdminPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const [editTagState, setEditTagState] = useState({})
  const [deleteTagState, setDeleteTagState] = useState({ show: false })
  const [showCreateTagPanel, setShowCreateTagPanel] = useState(false)
  const [tagsData, setTagsData] = useState({
    tags: [],
    isLoading: true
  })

  function handleCloseDeleteTagPanel(tagID) {
    if (tagID) {
      setTagsData({
        ...tagsData,
        tags: [...tagsData.tags.filter(tag => tag.TagID !== tagID)]
      })
    }
    setDeleteTagState({ show: false })
  }

  function handleOpenCreateTagPanel() {
    setShowCreateTagPanel(true)
  }

  function handleCloseCreateTagPanel(tagData) {
    if (tagData) {
      if (!tagsData.tags.find(tag => tag.TagID === tagData.TagID)) {
        setTagsData({
          ...tagsData,
          tags: [...tagsData.tags, tagData]
        })
      } else {
        const tags = [...tagsData.tags]
        let changedTagIndex = tags.findIndex(tag => tag.TagID === tagData.TagID)
        tags[changedTagIndex] = { ...tags[changedTagIndex], ...tagData }
        setTagsData({
          ...tagsData,
          tags: [...tags]
        })
      }
      const cancelToken = axios.CancelToken.source()
      fetchTags(cancelToken)
    }
    setEditTagState({})
    setShowCreateTagPanel(false)
  }

  function handleEditTag(tag) {
    setEditTagState({ ...tag })
  }

  function handleDeleteTag(tag) {
    setDeleteTagState({ show: true, tag })
  }

  function createOrEditTag(submittedTag) {
    if (submittedTag.tagId) {
      return editTag(submittedTag.tagId, submittedTag.tokenData)
    }
    return createTag(submittedTag.tokenData)
  }

  function saveTagIcon(tagID, iconFile) {
    const data = new FormData()
    data.append("file", iconFile)
    return setTagIcon(tagID, data)
  }

  function fetchTags(cancelToken) {
    setTagsData({ ...tagsData, isLoading: true })
    getTags(cancelToken.token)
      .then(({ data }) => {
        setTagsData({
          tags: data,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_TAGS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchTags(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (editTagState.TagID) {
      setShowCreateTagPanel(true)
    }
  }, [editTagState])

  return (
    <>
      <CreateTagButton onClick={handleOpenCreateTagPanel} />
      <CreateTagFormDialog
        show={showCreateTagPanel}
        initialTagState={editTagState}
        closeDialog={handleCloseCreateTagPanel}
        submitData={createOrEditTag}
        submitIcon={saveTagIcon}
        tagsData={tagsData}
      />
      <DeleteTagDialog
        show={deleteTagState.show}
        onClose={handleCloseDeleteTagPanel}
        sendRequest={deleteTag}
        tagData={deleteTagState.tag}
      />
      <Typography
        variant="h3"
        id="userAdminTitle"
        style={{ textAlign: "right" }}
      >
        <FormattedMessage id="TITLE" />
      </Typography>

      <TagList
        tagsData={tagsData}
        onEdit={handleEditTag}
        onDelete={handleDeleteTag}
      />
    </>
  )
}

export default injectIntl(TagsAdminPage)
