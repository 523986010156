import React, { Suspense, lazy, useEffect, useState } from "react"
import { Redirect, Switch, Route, useHistory } from "react-router-dom"
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout"
import { BuilderPage } from "./pages/BuilderPage"
import { MyPage } from "./pages/MyPage"
import { DashboardPage } from "./pages/DashboardPage"
import UsersAdminPage from "./pages/UsersAdminPage"
import PushAdminPage from "./pages/PushAdminPage"
import BannerAdminPage from "./pages/BannerAdminPage"
import CustomersListPage from "./pages/CustomersListPage"
import CustomersEditPage from "./pages/CustomersEditPage"
import TagsAdminPage from "./pages/TagsAdminPage"
import SpecialDatesAdminPage from "./pages/SpecialDatesAdminPage"
import SuppliersEditPage from "./pages/SuppliersEditPage"
import SupplierReportsPage from "./pages/SupplierReportsPage"
import OrderHistoryPage from "./pages/OrderHistoryPage"
import RefundAdminPage from "./pages/RefundAdminPage"
import SupplierDetailsReportsPage from "./pages/SupplierDetailsReportsPage"
import POSRequestLogReportsPage from "./pages/POSRequestLogReportsPage"
import OrderReportsPage from "./pages/OrderReportsPage"
import PushReportsPage from "./pages/PushReportsPage"
import ItemCancellationOrdersReportsPage from "./pages/ItemCancellationOrdersReportsPage"

import CombosAdminPage from "./pages/CombosAdminPage"
import PriceCategoryAdminPage from "./pages/PriceCategoryAdminPage"

import FaqAdminPage from "./pages/FaqAdminPage"
import EmployeeReportsPage from "./pages/EmployeeReportsPage"
import SuppliersListPage from "./pages/SuppliersListPage"
import RatingReportsPage from "./pages/RatingReportsPage"

import { useLang } from "../_metronic/i18n"
import heCommonMessages from "../_metronic/i18n/messages/common/he"
import enCommonMessages from "../_metronic/i18n/messages/common/en"
import heUserMessages from "../_metronic/i18n/messages/user/he"
import enUserMessages from "../_metronic/i18n/messages/user/en"
import heCustomerMessages from "../_metronic/i18n/messages/customer/he"
import enCustomerMessages from "../_metronic/i18n/messages/customer/en"
import heSupplierMessages from "../_metronic/i18n/messages/supplier/he"
import enSupplierMessages from "../_metronic/i18n/messages/supplier/en"
import heTagMessages from "../_metronic/i18n/messages/tag/he"
import enTagMessages from "../_metronic/i18n/messages/tag/en"
import heSpecialDateMessages from "../_metronic/i18n/messages/specialDates/he"
import enSpecialDateMessages from "../_metronic/i18n/messages/specialDates/en"
import heFaqMessages from "../_metronic/i18n/messages/faq/he"
import enFaqMessages from "../_metronic/i18n/messages/faq/en"
import heEmployeeReportMessages from "../_metronic/i18n/messages/employee-reports/he"
import enEmployeeReportMessages from "../_metronic/i18n/messages/employee-reports/en"
import heHistoryMessages from "../_metronic/i18n/messages/history/he"
import enHistoryMessages from "../_metronic/i18n/messages/history/en"
import heRefundMessages from "../_metronic/i18n/messages/refund/he"
import enRefundMessages from "../_metronic/i18n/messages/refund/en"
import heRatingMessages from "../_metronic/i18n/messages/rating-reports/he"
import enRatingMessages from "../_metronic/i18n/messages/rating-reports/en"

import hePushMessages from "../_metronic/i18n/messages/push-reports/he"
import enPushMessages from "../_metronic/i18n/messages/push-reports/en"

import heItemCancellationMessages from "../_metronic/i18n/messages/item-cancellation-orders-reports/he"
import enItemCancellationMessages from "../_metronic/i18n/messages/item-cancellation-orders-reports/en"


import heCreditCardReportMessages from "../_metronic/i18n/messages/credit-card-reports/he"
import enCreditCardReportMessages from "../_metronic/i18n/messages/credit-card-reports/en"
import heItemOrdersReportMessages from "../_metronic/i18n/messages/item-orders-reports/he"
import enItemOrdersReportMessages from "../_metronic/i18n/messages/item-orders-reports/en"
import heStoreReportMessages from "../_metronic/i18n/messages/store-reports/he"
import enStoreReportMessages from "../_metronic/i18n/messages/store-reports/en"
import heOrdersTabMessages from "../_metronic/i18n/messages/orders/he"
import enOrdersTabMessages from "../_metronic/i18n/messages/orders/en"
import heOrderAdminMessages from "../_metronic/i18n/messages/order-admin/he"
import enOrderAdminMessages from "../_metronic/i18n/messages/order-admin/en"

import heMealManagementMessages from "../_metronic/i18n/messages/management-meals/he"
import enMealManagementMessages from "../_metronic/i18n/messages/management-meals/en"


import heRefundReportMessages from "../_metronic/i18n/messages/refund-reports/he"
import enRefundReportMessages from "../_metronic/i18n/messages/refund-reports/en"

import hePosMessages from "../_metronic/i18n/messages/pos-reports/he"
import enPosMessages from "../_metronic/i18n/messages/pos-reports/en"

import heCombosMessages from "../_metronic/i18n/messages/combos/he"
import enCombosMessages from "../_metronic/i18n/messages/combos/en"

import hePriceCategoryMessages from "../_metronic/i18n/messages/price-category/he"
import enPriceCategoryMessages from "../_metronic/i18n/messages/price-category/en"

import { injectIntl, IntlProvider } from "react-intl"
import { connect } from "react-redux"
import * as error from "../redux/snackbar/snackbarRedux"
import CustomSnackbar from "./modules/Common/CustomSnackbar"
import { supplierUserRoles } from "./modules/Users/rolesTranslation"
import CreditCardReportsPage from "./pages/CreditCardReportsPage"
import MealsManagementAdminPage from "./pages/MealsManagementAdminPage"
import ItemOrdersReportsPage from "./pages/ItemOrdersReportsPage"
import "./../sass/common.scss"
import CustomerStoreReportsPage from "./pages/CustomerStoreReportsPage"
import SupplierStoreReportsPage from "./pages/SupplierStoreReportsPage"
import RefundReportsPage from "./pages/RefundReportsPage"

import CustomerDetailsReportsPage from "./pages/CustomerDetailsReportsPage"
import CustomerReportsPage from "./pages/CustomerReportsPage"
import CustomerBillingReportsPage from "./pages/CustomerBillingReportsPage"

import OrdersAdminPage from "./pages/OrdersAdminPage"
import WalletBalanceReportsPage from "./pages/WalletBalanceReportsPage"
import BankTransferReportsPage from "./pages/BankTransferReportsPage"
import BudgetUsageReportsPage from "./pages/BudgetUsageReportsPage"



const commonMessages = {
  he: heCommonMessages,
  en: enCommonMessages
}

const posMessages = {
    he: hePosMessages,
    en: enPosMessages
}

const combosMessages = {
    he: heCombosMessages,
    en: enCombosMessages
}

const priceCategoryMessages = {
    he: hePriceCategoryMessages,
    en: enPriceCategoryMessages
}

const userMessages = {
  he: heUserMessages,
  en: enUserMessages
}

const customerMessages = {
  he: heCustomerMessages,
  en: enCustomerMessages
}

const supplierMessages = {
  he: heSupplierMessages,
  en: enSupplierMessages
}

const faqMessages = {
  he: heFaqMessages,
  en: enFaqMessages
}

const tagMessages = {
  he: heTagMessages,
  en: enTagMessages
}
const specialDateMessages = {
    he: heSpecialDateMessages,
    en: enSpecialDateMessages
}

const employeeReportMessages = {
  he: heEmployeeReportMessages,
  en: enEmployeeReportMessages
}


const ratingReportMessages = {
  he: heRatingMessages,
  en: enRatingMessages
}

const pushReportMessages = {
  he: hePushMessages,
  en: enPushMessages
}

const itemCancellationReportMessages = {
    he: heItemCancellationMessages,
    en: enItemCancellationMessages
}

const creditCardReportMessages = {
  he: heCreditCardReportMessages,
  en: enCreditCardReportMessages
}

const itemOrdersReportMessages = {
  he: heItemOrdersReportMessages,
  en: enItemOrdersReportMessages
}

const historyMessages = {
  he: heHistoryMessages,
  en: enHistoryMessages
}

const refundMessages = {
  he: heRefundMessages,
  en: enRefundMessages
}

const storeReportMessages = {
  he: heStoreReportMessages,
  en: enStoreReportMessages
}

const refundReportMessages = {
  he: heRefundReportMessages,
  en: enRefundReportMessages
}

const ordersTabMessages = {
  he: heOrdersTabMessages,
  en: enOrdersTabMessages
}

const orderAdminMessages = {
  he: heOrderAdminMessages,
  en: enOrderAdminMessages
}

const mealManagementMessages = {
    he: heMealManagementMessages,
    en: enMealManagementMessages
}

const GuardedRoute = ({ component: Component, auth, child, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth === true ? (
        <Component {...props} {...rest} children={child} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
)

function BasePage({
  userRole,
  snackbarMessage,
  snackbarStatus,
  userCustomerInfo,
  ownSupplierID,
  intl,
  ...props
}) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const language = localStorage.getItem("lang")
  let locale = useLang()
  if (language) {
    locale = language
  }

  const history = useHistory()

  const [snackbarData, setSnackbarData] = useState({
    show: false,
    variant: "success"
  })

  function handleCloseSnackbar() {
    setSnackbarData({ ...snackbarData, show: false })
    props.clearSnackbarData()
    }

  useEffect(() => {
    if (snackbarStatus === 200) {
      setSnackbarData({
        show: true,
        message: snackbarMessage,
        variant: "success"
      })

      // Close snackbar after 6 seconds
      const delay = setTimeout(() => handleCloseSnackbar(), 6000)
      return () => clearTimeout(delay)
    } else if (snackbarStatus) {
      let message = snackbarMessage
      switch (snackbarStatus) {
        case 401:
          props.clearSnackbarData()
          history.push("/logout")
          break
        case 503:
          message = intl.formatMessage({
            id: "API.TEMP_ERROR"
          })
          break
        default:
          break
      }
      setSnackbarData({
        show: true,
        message: message,
        variant: "error"
      })

      // Close snackbar after 6 seconds
      const delay = setTimeout(() => handleCloseSnackbar(), 6000)
      return () => clearTimeout(delay)
    }
  }, [snackbarStatus, snackbarMessage])

  function getDefaultRoute() {
    switch (userRole) {
      case "Supplier Admin":
      case "Supplier User":
        return `/supplier/${ownSupplierID}`
      case "Customer Admin":
        return `/customer/${userCustomerInfo.CustomerID}`
      default:
        return "/dashboard"
    }
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <CustomSnackbar data={snackbarData} onClose={handleCloseSnackbar} />
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={getDefaultRoute()} />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <GuardedRoute
          auth={["Admin", "Owner", "Customer Admin", "Supplier Admin"].includes(
            userRole
          )}
          path="/users"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...userMessages[locale] }}
          child={<UsersAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
            auth={["Admin", "Owner"].includes(userRole)}
            path="/pushes"
            component={IntlProvider}
            locale={locale}
            messages={{ ...commonMessages[locale], ...userMessages[locale] }}
            child={<PushAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
            auth={["Admin", "Owner"].includes(userRole)}
            path="/banner"
            component={IntlProvider}
            locale={locale}
            messages={{ ...commonMessages[locale], ...userMessages[locale] }}
            child={<BannerAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner", "Customer Admin", "Supplier Admin"].includes(
            userRole
          )}
          path="/user/:id/order-history"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...historyMessages[locale] }}
          child={<OrderHistoryPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/customers"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...customerMessages[locale] }}
          child={<CustomersListPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/suppliers"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...supplierMessages[locale] }}
          child={<SuppliersListPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner", "Customer Admin"].includes(userRole)}
          path="/customer/:id"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...userMessages[locale],
            ...customerMessages[locale],
            ...ordersTabMessages[locale]
          }}
          child={<CustomersEditPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner", ...supplierUserRoles].includes(userRole)}
          path="/supplier/:id"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...supplierMessages[locale],
            ...ordersTabMessages[locale]
          }}
          child={<SuppliersEditPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-order"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...customerMessages[locale]
          }}
          child={<OrderReportsPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner", "Customer Admin"].includes(userRole)}
          path="/reports-employee"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...employeeReportMessages[locale]
          }}
          child={<EmployeeReportsPage />}
        ></GuardedRoute>
                {/* <GuardedRoute
          auth={["Admin", "Owner", "Customer Admin"].includes(userRole)}
          path="/reports-detailed-employee"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...employeeReportMessages[locale]
          }}
          child={<DetaildEmployeeReportsPage />}
        ></GuardedRoute> */}
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-wallet-balance"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...employeeReportMessages[locale]
          }}
          child={<WalletBalanceReportsPage />}
        ></GuardedRoute>

          <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/reports-bank-transfer"
              component={IntlProvider}
              locale={locale}
              messages={{
                  ...commonMessages[locale],
                  ...employeeReportMessages[locale]
              }}
              child={<BankTransferReportsPage />}
          ></GuardedRoute>


        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-budget-usage"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...employeeReportMessages[locale]
          }}
          child={<BudgetUsageReportsPage userRole={userRole} />}
        ></GuardedRoute>


        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-credit"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...creditCardReportMessages[locale]
          }}
          child={<CreditCardReportsPage />}
        ></GuardedRoute>
          <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/management-meals"
              component={IntlProvider}
              locale={locale}
              messages={{
                  ...commonMessages[locale],
                  ...mealManagementMessages[locale]
              }}
              child={<MealsManagementAdminPage />}
          ></GuardedRoute>

        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-store-customer"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...storeReportMessages[locale]
          }}
          child={<CustomerStoreReportsPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-store-supplier"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...storeReportMessages[locale]
          }}
          child={<SupplierStoreReportsPage />}
        ></GuardedRoute>

        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-refund"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...refundReportMessages[locale]
          }}
          child={<RefundReportsPage />}
        ></GuardedRoute>


        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-supplier"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...supplierMessages[locale]
          }}
          child={<SupplierReportsPage />}
        ></GuardedRoute>
        <GuardedRoute
            auth={["Admin", "Owner", "Supplier Admin"].includes(userRole)}
          path="/reports-details-supplier"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...supplierMessages[locale]
          }}
          child={<SupplierDetailsReportsPage />}
        ></GuardedRoute>
          <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/reports-pos-request-log"
              component={IntlProvider}
              locale={locale}
              messages={{
                  ...commonMessages[locale],
                  ...posMessages[locale]
              }}
              child={<POSRequestLogReportsPage />}
          ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-details-customer"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...customerMessages[locale]
          }}
          child={<CustomerDetailsReportsPage />}
        ></GuardedRoute>

        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-customer"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...customerMessages[locale]
          }}
          child={<CustomerReportsPage />}
              ></GuardedRoute>
        
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-billing-customer"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...customerMessages[locale]
          }}
          child={<CustomerBillingReportsPage />}
        ></GuardedRoute>

        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-item-orders"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...itemOrdersReportMessages[locale]
          }}
          child={<ItemOrdersReportsPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-rating"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...ratingReportMessages[locale]
          }}
          child={<RatingReportsPage />}
        ></GuardedRoute>

      <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/reports-push"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...pushReportMessages[locale]
          }}
          child={<PushReportsPage />}
        ></GuardedRoute>

          <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/reports-cancelled-item-orders"
              component={IntlProvider}
              locale={locale}
              messages={{
                  ...commonMessages[locale],
                  ...itemCancellationReportMessages[locale]
              }}
              child={<ItemCancellationOrdersReportsPage />}
          ></GuardedRoute>

        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/tags"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...tagMessages[locale]
          }}
          child={<TagsAdminPage />}
        ></GuardedRoute>
          <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/specialDates"
              component={IntlProvider}
              locale={locale}
              messages={{
                  ...commonMessages[locale],
                  ...specialDateMessages[locale]
              }}
              child={<SpecialDatesAdminPage />}
          ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/faq"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...faqMessages[locale] }}
          child={<FaqAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/combos"
              component={IntlProvider}
              locale={locale}
              messages={{ ...commonMessages[locale], ...combosMessages[locale] }}
              child={<CombosAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
              auth={["Admin", "Owner"].includes(userRole)}
              path="/price-categories"
              component={IntlProvider}
              locale={locale}
              messages={{ ...commonMessages[locale], ...priceCategoryMessages[locale] }}
              child={<PriceCategoryAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/refund"
          component={IntlProvider}
          locale={locale}
          messages={{ ...commonMessages[locale], ...refundMessages[locale] }}
          child={<RefundAdminPage />}
        ></GuardedRoute>
        <GuardedRoute
          auth={["Admin", "Owner"].includes(userRole)}
          path="/order-admin"
          component={IntlProvider}
          locale={locale}
          messages={{
            ...commonMessages[locale],
            ...orderAdminMessages[locale]
          }}
          child={<OrdersAdminPage />}
        ></GuardedRoute>
        
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}

function mapStateToProps(state) {
  return {
    userRole: state.auth.user.Role,
    ownSupplierID: state.auth.user.SupplierID,
    userCustomerInfo: state.auth.user.Customer,
    snackbarStatus: state.snackbar.status,
    snackbarMessage: state.snackbar.message
  }
}
export default injectIntl(connect(mapStateToProps, error.actions)(BasePage))
