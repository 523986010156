/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import Button from "@material-ui/core/Button"
import { getRoleID } from "../../Users/rolesTranslation"
import { FormattedMessage, injectIntl } from "react-intl"
import { customerUserRoles } from "../../Users/rolesTranslation"
import { useStyles } from "../../Common/_styles/filterPanelStyles"

function FilterPanel({
  onSearchParamsSave,
  initialSearchParams,
  allLocations,
  intl
}) {
  const classes = useStyles()

  const defaultSearchParams = {
    role: "all",
    text: "",
    location: "",
    department: ""
  }
  const [searchParams, setSearchParams] = useState(initialSearchParams)

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
  }

  function handleSearchParamsClear() {
    setSearchParams({ ...searchParams, ...defaultSearchParams })
    onSearchParamsSave(defaultSearchParams)
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }

  return (
    <form
      className={classes.root}
      onSubmit={handleSearchParamsSubmit}
      autoComplete="off"
    >
      <TextField
        id="standard-search"
        label={intl.formatMessage({
          id: "ADMIN_USERS.FILTER.SEARCH_FIELD.LABEL"
        })}
        className={classes.textField}
        value={searchParams.text}
        onChange={handleSearchParamChange("text")}
      />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="role">
          <FormattedMessage id="ADMIN_USERS.FILTER.ROLE_FIELD.LABEL" />
        </InputLabel>
        <Select
          value={searchParams.role}
          onChange={handleSearchParamChange("role")}
          inputProps={{
            name: "role",
            id: "role"
          }}
        >
          <MenuItem value="all">
            <FormattedMessage id="ADMIN_USERS.FILTER.ROLE_FIELD.PLACEHOLDER" />
          </MenuItem>
          {customerUserRoles.map(role => (
            <MenuItem key={role} value={role}>
              <FormattedMessage id={getRoleID(role)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.textFieldsWrapper}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="location">
            <FormattedMessage id="ADMIN_USERS.FILTER.LOCATION_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.location}
            onChange={handleSearchParamChange("location")}
            inputProps={{
              name: "location",
              id: "location"
            }}
          >
            <MenuItem value="">
              <FormattedMessage id="ADMIN_USERS.FILTER.COMMON.ALL" />
            </MenuItem>
            {allLocations.map(location => (
              <MenuItem key={location.LocationID} value={location.LocationID}>
                {location.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          className={classes.formControl}
          disabled={!searchParams.customer.Name}
        >
          <InputLabel htmlFor="department">
            <FormattedMessage id="ADMIN_USERS.FILTER.DEPARTMENT_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.department}
            onChange={handleSearchParamChange("department")}
            inputProps={{
              name: "department",
              id: "department"
            }}
          >
            <MenuItem value="">
              <FormattedMessage id="ADMIN_USERS.FILTER.COMMON.ALL" />
            </MenuItem>
            {searchParams.customer.Departments &&
              searchParams.customer.Departments.map(department => (
                <MenuItem
                  key={department.DepartmentID}
                  value={department.DepartmentID}
                >
                  {department.Name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="active">
            <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.active}
            onChange={handleSearchParamChange("active")}
            inputProps={{
              name: "active"
            }}
          >
            <MenuItem value="active">
              <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.ACTIVE" />
            </MenuItem>
            <MenuItem value="not active">
              <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.NOT_ACTIVE" />
            </MenuItem>
            <MenuItem value="all">
              <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.ALL" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Button
        id="search_params_submit"
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-10`}
      >
        <span>
          <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
        </span>
      </Button>
      <Button
        id="search_params_clear"
        type="button"
        variant="outlined"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-20`}
        onClick={handleSearchParamsClear}
      >
        <ClearIcon />
      </Button>
    </form>
  )
}

export default injectIntl(FilterPanel)
