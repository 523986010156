/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import { Link } from "react-router-dom"
import { Button, TextField } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import Undo from "@material-ui/icons/Undo"
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  link: { color: "black" },
  textField: {
    margin: theme.spacing(1),
    width: 250
  },
  lottieWrapper: { position: "relative", minHeight: 400 }
}))

export default function AlignItemsList({
  displayedData,
  openCreatePanel,
  variant,
  hideNewActionButton,
  showActionButton,
  action
}) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [searchText, setSearchText] = useState("")

  function handleSearchTextChange(event) {
    const value = event.target.value
    setSearchText(value.toLowerCase())
  }

  useEffect(() => {
    let result
    if (searchText.length !== 0) {
      result = displayedData.data.filter(row =>
        row.Name.toLowerCase().includes(searchText)
      )
    } else {
      result = displayedData.data
    }
    setRows(result)
  }, [searchText, displayedData])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <TextField
          id="standard-search"
          label={<FormattedMessage id="SEARCH_FIELD.LABEL" />}
          className={classes.textField}
          InputLabelProps={{
            classes: {
              root: classes.formLabelRoot,
              shrink: classes.formLabelShrink
            }
          }}
          value={searchText}
          onChange={handleSearchTextChange}
        />
        {
           !hideNewActionButton && (
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    onClick={openCreatePanel}
                >
                  <FormattedMessage id="CREATE_NEW_BUTTON" />
                </Button>
          )
        }
      </div>
      {displayedData.isLoading ? (
        <div className={classes.lottieWrapper}>
          <lottie-player
            src="/media/lotties/dish_loader.json"
            speed="1"
            loop
            autoplay
            style={{
              position: "absolute"
            }}
          ></lottie-player>
        </div>
      ) : (
        <List className={classes.root}>
          {variant === "customers"
            ? rows.map((row, index) => (
                <div key={row.Name}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Link
                        className={classes.link}
                        to={`customer/${row.CustomerID}`}
                      >
                        <Avatar
                          alt="Logo"
                          variant="rounded"
                          src={
                            row.Logo.length !== 0 ? row.Logo[0].URL : undefined
                          }
                        />
                      </Link>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          className={classes.link}
                          to={`customer/${row.CustomerID}`}
                        >
                          {row.Name}
                        </Link>
                      }
                    />
                    {
                        showActionButton && (
                            <IconButton edge="end" aria-label="delete" onClick={(e) => action(row)}>
                              <Undo />
                            </IconButton>
                        )
                    }
                  </ListItem>
                  {index !== rows.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </div>
              ))
            : rows.map((row, index) => (
                <div key={row.Name}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Link
                          className={classes.link}
                          to={`supplier/${row.SupplierID}`}
                      >
                        <Avatar
                            alt="Logo"
                            variant="rounded"
                            src={
                              row.Logo.length !== 0 ? row.Logo[0].URL : undefined
                            }
                        />
                      </Link>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          className={classes.link}
                          to={`supplier/${row.SupplierID}`}
                        >
                          {row.Name}
                        </Link>
                      }
                    />
                    {
                      showActionButton && (
                            <IconButton edge="end" aria-label="delete" onClick={(e) => action(row)}>
                              <Undo />
                            </IconButton>
                        )
                    }
                  </ListItem>
                  {index !== rows.length - 1 && <Divider component="li" />}
                </div>
              ))}
        </List>
      )}
    </>
  )
}
