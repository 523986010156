import axios from "axios"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

export const actionTypes = {
  SetSnackbarData: "[Set Snackbar Data] Action",
  ClearSnackbarData: "[Clear Snackbar Data] Action"
}

const initialErrorState = {
  status: undefined,
  message: undefined
}

export const reducer = persistReducer(
  {
    storage,
    key: "v713-demo1-snackbar",
    whitelist: ["status", "message"]
  },
  (state = initialErrorState, action) => {
    switch (action.type) {
      case actionTypes.SetSnackbarData: {
        const { status, message } = action.payload
        return { ...state, status, message }
      }

      case actionTypes.ClearSnackbarData: {
        return { status: undefined, message: undefined }
      }

      default:
        return state
    }
  }
)

export const actions = {
  setSnackbarData: (message, errorData) => ({
    type: actionTypes.SetSnackbarData,
    payload: {
      status: errorData
        ? errorData.response
          ? errorData.response.status
          : 503
        : 200,
      message
    }
  }),
  clearSnackbarData: () => ({ type: actionTypes.ClearSnackbarData })
}
