import usersMock from "../../Auth/__mocks__/userTableMock.js"

let idMock = 9

function createSupplierData(name) {
  return {
    SupplierID: `c5acd6a0-eeec-40fd-be36-80453e14df${++idMock}`,
    Name: name,
    CompanyID: "123456789",
    Address: "",
    GeoPoint: {
      GeoPointID: "6b0243ca-a92b-45dc-8fcf-cd6a80605d36",
      lat: 41.12,
      lon: -71.34
    },
    Phone: "111-1111111",
    Email: "test@test.com"
  }
}

export const suppliers = [
  createSupplierData("Supplier1"),
  createSupplierData("Supplier2"),
  createSupplierData("Supplier3"),
  createSupplierData("Supplier4"),
  createSupplierData("Supplier5"),
  createSupplierData("Supplier6"),
  createSupplierData("Supplier7"),
  createSupplierData("Supplier8"),
  createSupplierData("Supplier9"),
  createSupplierData("Supplier10"),
  createSupplierData("Supplier11"),
  createSupplierData("Supplier12"),
  createSupplierData("Supplier13")
]

export default function mockSuppliers(mock) {
  mock.onGet("/Suppliers").reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        let result = suppliers
        return [200, result]
      }
    }
    return [400]
  })

  mock
    .onPatch(RegExp(`/Suppliers/(.*)$`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const SupplierID = url.slice(
        url.lastIndexOf("/Suppliers/") + 11,
        url.length
      )

      const { Name, Phone, Email, CompanyID, Address, GeoPoint } = JSON.parse(
        data
      )

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          let changedSupplier = suppliers.find(
            supplier => supplier.SupplierID === SupplierID
          )

          if (Name) {
            changedSupplier.Name = Name
          }
          if (Phone) {
            changedSupplier.Phone = Phone
          }
          if (Email) {
            changedSupplier.Email = Email
          }
          if (CompanyID) {
            changedSupplier.CompanyID = CompanyID
          }
          if (Address) {
            changedSupplier.Address = Address
            changedSupplier.GeoPoint = GeoPoint
          }
          return [200]
        }
      }
      return [400]
    })
}
