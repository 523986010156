/* eslint-disable no-restricted-imports */

import axios from "axios"
import React, { useEffect, useState } from "react"
import UsersList from "../modules/Users/UsersList"
import AddUserButton from "../modules/Users/AddUserButton"
import FilterPanel from "../modules/Users/FilterPanel"
import CreateUserFormDialog from "../modules/Users/CreateUserFormDialog"
import UserDetails from "../modules/Users/UserDetails"
import {
  getUsers,
  getUserByID,
  createUser,
  updateUser,
  getUserInfo
} from "../modules/Users/_axios/userCrud"
import {
  getWalletBalance,
  addToWalletBalance
} from "../modules/Users/_axios/walletCrud"
import {
  getCustomer,
  getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import {
  getSupplier,
  getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud"
import { FormattedMessage, injectIntl } from "react-intl"
import { Typography } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import {
  allUserRoles,
  allUserRolesExceptAdmin,
  customerUserRoles,
  supplierUserRoles
} from "../modules/Users/rolesTranslation"
import { useSelector, useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import {limitTypes} from "../modules/Users/limitTypesTranslation.js"
import WalletDialog from "../modules/Users/WalletDialog"

function UsersAdminPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const ownSupplierID = useSelector(state => state.auth.user.SupplierID)
  const userRole = useSelector(state => state.auth.user.Role)
  const defAdminRightSupport = useSelector(state => state.auth.user.AdminRightSupport)
  const [adminRightSupport, setAdminRightSupport] = useState(defAdminRightSupport)
  const userCustomerInfo = useSelector(state => state.auth.user.Customer)
  const [selectedUser, setSelectedUser] = useState({ UserID: "" })
  const [showCreateUserPanel, setShowCreateUserPanel] = useState(false)
  const [usersData, setUsersData] = useState({
    usersCount: 0,
    displayedUsers: [],
    isLoading: true
  })
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })
  const [supplierData, setSupplierData] = useState({
    suppliers: [],
    isLoading: true
  })
  const [searchParams, setSearchParams] = useState({})
  const [paginationState, setPaginationState] = useState()
  const [walletData, setWalletData] = useState({
    userID: null,
    balance: 0,
    isLoading: false,
    fetchCount: 0
  })

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"
  const isUserOwner = userRole === "Owner"
  const isUserSupplierAdmin = userRole === "Supplier Admin"
  const isUserCustomerAdmin = userRole === "Customer Admin"

  function addUser(newUserData) {
    return createUser(newUserData)
  }

  function saveUser(newUserData) {
    return updateUser(newUserData, newUserData.UserID)
  }

  function selectUser(UserID) {
    if (selectedUser.UserID !== UserID) {
      setSelectedUser({ UserID })
    }
  }
  function getAvailableLimitTypes() {
    return limitTypes
  }
  function handleOpenCreateUserPanel() {
    setShowCreateUserPanel(true)
  }

  function handleCloseCreateUserPanel(userID) {
    if (userID) {
      selectUser(userID)
    }
    setShowCreateUserPanel(false)
  }

  function handleSearchParamsSave(searchParams) {
    setSearchParams(searchParams)
  }

  function handlePaginationStateChange(changes) {
    const newState = { ...paginationState, ...changes }
    setPaginationState(newState)
  }

  function fetchUserInfo() {
    const cancelToken = axios.CancelToken.source()
    getUserInfo(cancelToken)
      .then(({ data }) => {
        setAdminRightSupport(data.UserInfo.AdminRightSupport)
      })
      .catch(() => {})
  }

  function fetchCustomers(cancelToken) {
    if (!isUserAdmin) {
      // Request for Customer Admin
      getCustomer(cancelToken.token, userCustomerInfo.CustomerID, true)
          .then(({ data }) => {
            setCustomerData({ customers: [data], isLoading: false })
          })
          .catch(error =>
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_CUSTOMER"
                  })
              )
          )
    } else {
      // Request for Admin
      getCustomersList(cancelToken.token, true, "active")
          .then(({ data }) => {
            setCustomerData({ customers: data, isLoading: false })
          })
          .catch(error =>
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                  })
              )
          )
    }
  }

  function fetchSuppliers(cancelToken) {
    if (!isUserAdmin) {
      // Request for Supplier Admin
      getSupplier(cancelToken.token, ownSupplierID)
          .then(({ data }) => {
            setSupplierData({ suppliers: [data], isLoading: false })
          })
          .catch(error =>
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_SUPPLIER"
                  })
              )
          )
    } else {
      // Request for Admin
      getSuppliers(cancelToken.token, "active")
          .then(({ data }) => {
            setSupplierData({ suppliers: data, isLoading: false })
          })
          .catch(error =>
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                  })
              )
          )
    }
  }

  function fetchWalletByUserID(cancelToken) {
    setWalletData({ ...walletData, isLoading: true })
    getWalletBalance(walletData.userID, cancelToken.token)
        .then(({ data }) => {
          setWalletData({ ...walletData, balance: data, isLoading: false })
        })
        .catch(error => {
          setWalletData({ userID: null, fetchCount: 0, isLoading: false })
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_WALLET"
              })
          )
        })
  }

  function fetchUserByID(cancelToken) {
    setSelectedUser({ ...selectedUser, isLoading: true })
    getUserByID(selectedUser.UserID, cancelToken.token)
        .then(({ data }) => {
          setSelectedUser(data)
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_USER_BY_ID"
                })
            )
        )
  }

  function fetchUsers(cancelToken) {
    const { page, rowsPerPage, orderBy, sortBy } = paginationState
    const {
      text,
      role,
      customer,
      location,
      department,
      supplier,
      active
    } = searchParams

    const params = {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      sortBy,
      orderBy
    }
    if (text !== "") {
      params.text = text
    }
    if (customer.CustomerID) {
      params.customerid = customer.CustomerID
      if (location) {
        params.locationid = location
      }
      if (department) {
        params.departmentid = department
      }
    } else if (supplier.SupplierID) {
      params.supplierid = supplier.SupplierID
    }
    if (role !== "all") {
      params.role = role
    }
    if (active !== "all") {
      params.active = active === "active" ? true : false
    }

    props.history.push({
      pathname: "/users",
      search: "?" + new URLSearchParams(params).toString()
    })

    setUsersData({ ...usersData, isLoading: true })
    getUsers(params, cancelToken.token)
        .then(({ data }) => {
          setUsersData({
            usersCount: data.total,
            displayedUsers: data.items,
            isLoading: false
          })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_USERS"
                })
            )
        )
  }

  function updateUsers(newUserData) {
    const users = [...usersData.displayedUsers]

    if (
        (searchParams.active === "active" && !newUserData.Active) ||
        (searchParams.active === "not active" && newUserData.Active)
    ) {
      handleRemoveUser(newUserData.UserID)
      return
    }

    const changedUserIndex = users.findIndex(
        users => users.UserID === newUserData.UserID
    )
    if (changedUserIndex !== -1) {
      users[changedUserIndex] = { ...newUserData }
      setUsersData({
        ...usersData,
        displayedUsers: [...users]
      })
      setSelectedUser({ ...newUserData })
    }
  }

  function handleRemoveUser(id) {
    setUsersData({
      ...usersData,
      usersCount: usersData.usersCount - 1,
      displayedUsers: usersData.displayedUsers.filter(
          user => user.UserID !== id
      )
    })
    setSelectedUser({ UserID: "" })
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    if (isUserSupplierAdmin) {
      setCustomerData({ customers: [], isLoading: false })
    } else {
      fetchCustomers(cancelToken)
    }
    if (isUserCustomerAdmin) {
      setSupplierData({ suppliers: [], isLoading: false })
    } else {
      fetchSuppliers(cancelToken)
    }
    fetchUserInfo();
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading && !supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const rowsPerPageValue = urlParams.get("limit")
      const rowsPerPage = rowsPerPageValue ? +rowsPerPageValue : 50
      const pageValue = urlParams.get("offset")
      const page = pageValue ? +pageValue : 0
      const sortBy = urlParams.get("sortBy") || "FirstName"
      const orderBy = urlParams.get("orderBy") || "asc"
      const text = urlParams.get("text") || ""
      const role = urlParams.get("role") || "all"
      const customer = isUserAdmin
          ? customerData.customers.find(
          customer => customer.CustomerID === urlParams.get("customerid")
      ) || {}
          : customerData.customers[0] || {}
      const location = urlParams.get("locationid") || ""
      const department = urlParams.get("departmentid") || ""
      const supplier = isUserAdmin
          ? supplierData.suppliers.find(
          supplier => supplier.SupplierID === urlParams.get("supplierid")
      ) || {}
          : supplierData.suppliers[0] || {}
      const activeValue = urlParams.get("active")
      const active = activeValue
          ? "active"
          : activeValue === false
              ? "not active"
              : "all"

      setSearchParams({
        text,
        role,
        customer,
        location,
        department,
        supplier,
        active
      })
      setPaginationState({
        page,
        rowsPerPage,
        orderBy,
        sortBy
      })
    }
  }, [customerData, supplierData])

  useEffect(() => {
    if (
        searchParams.customer &&
        searchParams.supplier &&
        paginationState.rowsPerPage
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchUsers(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [paginationState, searchParams])

  useEffect(() => {
    if (selectedUser.UserID) {
      const userDetails = usersData.displayedUsers.find(
          user => user.UserID === selectedUser.UserID
      )
      if (!userDetails) {
        const cancelToken = axios.CancelToken.source()
        fetchUserByID(cancelToken)
        return () => cancelToken.cancel()
      } else {
        setSelectedUser(userDetails)
      }
    }
  }, [selectedUser.UserID])

  useEffect(() => {
    if (walletData.userID && walletData.fetchCount) {
      const cancelToken = axios.CancelToken.source()
      fetchWalletByUserID(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [walletData.fetchCount])

  function getAvailableUserRoles() {
    if (userRole === "Admin" || userRole === "Owner") {
      return adminRightSupport ? allUserRoles : allUserRolesExceptAdmin
    }
    if (userRole === "Customer Admin") {
      return customerUserRoles.slice(1)
    }
    if (userRole === "Supplier Admin") {
      return supplierUserRoles.slice(1)
    }
  }

  return (
      <>
        <AddUserButton onClick={handleOpenCreateUserPanel} />
        {searchParams.customer && (
            <CreateUserFormDialog
                show={showCreateUserPanel}
                closeDialog={handleCloseCreateUserPanel}
                submitData={addUser}
                variant={
                    isUserAdmin ? (userRole === "Owner" ? "owner" : "admin") : isUserCustomerAdmin ? "customer" : "user"
                }
                allCustomers={customerData.customers}
                allSuppliers={supplierData.suppliers}
                availableUserRoles={getAvailableUserRoles()}
            />
        )}
        <WalletDialog
            show={!!walletData.userID}
            isEditAllowed={isUserAdmin}
            currentBalance={walletData.balance}
            isLoading={walletData.isLoading}
            closeDialog={() => setWalletData({ userID: null, fetchCount: 0 })}
            refreshBalance={() => setWalletData({ ...walletData, fetchCount: walletData.fetchCount + 1 })}
            submitData={(amount) => addToWalletBalance(walletData.userID, amount)}
        />
        <Typography
            variant="h3"
            id="userAdminTitle"
            style={{ textAlign: "right" }}
        >
          <FormattedMessage id="ADMIN_USERS.TITLE" />
        </Typography>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-custom flex-row justify-content-center gutter-b">
              <div className="card-body flex-grow-0">
                {searchParams.customer && searchParams.supplier && (
                    <FilterPanel
                        userRole={userRole}
                        adminRightSupport={adminRightSupport}
                        customerData={customerData}
                        supplierData={supplierData}
                        onSearchParamsSave={handleSearchParamsSave}
                        initialSearchParams={searchParams}
                    />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <UsersList
                paginationState={paginationState}
                onChange={handlePaginationStateChange}
                usersData={usersData}
                selected={selectedUser.UserID}
                setSelected={selectUser}
                openWalletDialog={(userID) => setWalletData({ userID, fetchCount: 1 })}
            />
          </div>
          <div className="col-md-6">
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <UserDetails
                    submitData={saveUser}
                    updateUsers={updateUsers}
                    userData={selectedUser}
                    allCustomers={customerData.customers}
                    allSuppliers={supplierData.suppliers}
                    availableUserRoles={getAvailableUserRoles()}
                    disableCompanyCreditCheckbox={!isUserOwner}
                    formVariant={
                      isUserAdmin
                          ? userRole === "Owner"
                              ? "owner"
                              : "admin"
                          : isUserCustomerAdmin
                              ? "customer"
                              : "user"
                    }
                    limitTypes={getAvailableLimitTypes()}
                />
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default withRouter(injectIntl(UsersAdminPage))
