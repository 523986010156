/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/RatingReports/FilterPanel"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getRatingReports } from "../modules/Reports/_axios/reportsCrud"
import RatingList from "../modules/Reports/RatingReports/RatingList"
import StarRateIcon from "@material-ui/icons/StarRate"
import { toAbsoluteUrl } from "../../_metronic/_helpers"
import Paper from "@material-ui/core/Paper"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function RatingReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })
  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function processReports(data) {
    return data
      .sort((a, b) => new Date(a.Date) - new Date(b.Date))
      .filter(item => item.Items.length)
      .map(item => ({
        ...item,
        AverageRating:
          Math.round((item.Items.reduce((sum, el) => sum + el.Rating, 0) / item.Items.length) * 100) / 100
      }))
  }

  function fetchReports(cancelToken) {
    const { supplier, customer, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      supplier: supplier?.SupplierID || '',
    }

    if (customer?.CustomerID && customer?.CustomerID != 'undefined') {
        params['customer'] = customer?.CustomerID;
    }

    props.history.push({
      pathname: "/reports-rating",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getRatingReports(
      supplier?.SupplierID,
      customer?.CustomerID,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: processReports(data),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token, "active")
      .then(({ data }) => {
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  function fetchCustomers(cancelToken) {
      getCustomersList(cancelToken.token, false, "active")
        .then(({ data }) => {
          setCustomerData({ data: data, isLoading: false })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                })
            )
        )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
      const cancelToken = axios.CancelToken.source()
      fetchCustomers(cancelToken)
      return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading && !customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const supplier = urlParams.get("supplier")
        ? supplierData.data.find(
            supplier => supplier.SupplierID === urlParams.get("supplier")
          )
        : {}
        const customer = urlParams.get("customer")
            ? customerData.data.find(
                customer => customer.CustomerID === urlParams.get("customer")
            )
            : {}

      setSearchParams({
        from,
        to,
        supplier,
        customer
      })
    }
  }, [supplierData, customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
        (
            (searchParams.supplier &&
                searchParams.supplier.SupplierID) ||
            (searchParams.customer &&
                searchParams.customer.CustomerID)
        )
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      setExportFileName(
        intl.formatMessage({
          id: "EXPORT_FILE_NAME"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_SUPPLIER"
          }) +
          "_" +
          searchParams.supplier?.Name +
          "_" +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const quantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.Rating, 0), 0)
    const count = reportsData.reports.reduce((sum, i) => sum += i.Items.length, 0)
    const rating = count > 0 ? Math.round((quantity / count) * 100) / 100 : 0
    const formattedItems = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(rating => {
        const formattedRating = {}
        formattedRating[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = report.Name
        formattedRating[
          intl.formatMessage({
              id: "REPORT.SUPPLIER_NAME"
          })
        ] = report.SupplierName
        formattedRating[
          intl.formatMessage({
            id: "REPORT.CUSTOMER"
          })
        ] = rating.CustomerName + rating.LocationName
        formattedRating[
          intl.formatMessage({
            id: "REPORT.DATE"
          })
        ] = moment(rating.OrderToDate)
          .locale("he")
          .format("DD/MM/YYYY dddd")
        formattedRating[
          intl.formatMessage({
            id: "REPORT.USER_NAME"
          })
        ] = rating.UserName
        formattedRating[
          intl.formatMessage({
            id: "REPORT.RATING"
          })
        ] = rating.Rating
        formattedRating[
          intl.formatMessage({
            id: "REPORT.COMMENT"
          })
        ] = rating.Comment
        formattedItems.push(formattedRating)
      })
    })




    const formattedRating = {}
    formattedRating[
        intl.formatMessage({
          id: "REPORT.ITEM_NAME"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedRating[
        intl.formatMessage({
          id: "REPORT.RATING"
        })
        ] = rating
    formattedItems.push(formattedRating)

    return formattedItems
  }

  return (
    <>
      {searchParams.supplier && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(item => (
          <Paper className={classes.paper} key={item.Name}>
            <div className={classes.heading}>
              <img
                src={
                  item.ImageUrl && item.ImageUrl.length !== 0
                    ? item.ImageUrl[0].URL
                    : ""
                }
                onError={e => {
                  e.target.onerror = null
                  e.target.src = toAbsoluteUrl(
                    `/media/svg/image_filler_icon.svg`
                  )
                }}
                alt="Item"
                className={classes.iconDisplay}
              />
              <div className={classes.headingInfo}>{item.Name}</div>
              <div className={classes.headingInfo}>
                <StarRateIcon />
                {item.AverageRating}
              </div>
            </div>
            <div className={classes.subtitle}>
                {item.SupplierName}
            </div>
            <RatingList ratingData={item} searchParams={searchParams} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(RatingReportsPage))
)
