import React from "react"
import { useLang } from "./Metronici18n"
import { IntlProvider } from "react-intl"
import "@formatjs/intl-relativetimeformat/polyfill"
import "@formatjs/intl-relativetimeformat/dist/locale-data/en"
import "@formatjs/intl-relativetimeformat/dist/locale-data/he"

import heMessages from "./messages/common/he"
import enMessages from "./messages/common/en"

const allMessages = {
  he: heMessages,
  en: enMessages
}

export function I18nProvider({ children }) {
  const language = localStorage.getItem("lang")
  let locale = useLang()
  if (language) {
    locale = language
  }
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}
