import axios from "axios"

export function getCombos(cancelToken) {
  return axios.get("/Combos", {
    cancelToken
  })
}
export function createCombo(formData) {
    return axios.post("/Combos", formData);
}
export function editCombo(formData, id) {
    return axios.put(`/Combos/${id}`, formData);
}
export function deleteCombo(id) {
  return axios.delete(`/Combos/${id}`)
}
export function getCombosOptions(id, itemId) {
  return axios.get(`/Combos/${id}/Items/${itemId}/Options`)
}