import axios from "axios"

export function getWalletBalance(UserID, cancelToken) {
  return axios.get(`/Wallet/Balance/${UserID}`, {
    cancelToken: cancelToken
  })
}

export function addToWalletBalance(userID, amount) {
  return axios.post("/Wallet/Add", null, {
    params: {
      userID,
      amount
    }
  })
}