/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Link } from "react-router-dom"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import moment from "moment"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "MealType",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MEAL_TYPE" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  },
  {
    id: "RefoundQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "NetQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NET_QUANTITY" />
  },
  {
    id: "TotalOptionCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_OPTION_COST" />
  },
  {
    id: "TotalItemCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_ITEM_COST" />
  },
  {
    id: "TotalRefound",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_REFUND" />
  },
  {
    id: "TotalCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />
  }
]

function SupplierMealsList({ mealsData, searchParams, intl }) {
  const classes = useStyles()
  const rows = mealsData.Meals || [];
  const [sortBy, setSortBy] = useState("MealType")
  const [orderBy, setOrderBy] = useState("asc")

  const quantity = rows.reduce((sum, it) => sum + it.Quantity, 0)
  const refoundQuantity = rows.reduce((sum, it) => sum + it.RefoundQuantity, 0)
  const netQuantity = rows.reduce((sum, it) => sum + it.NetQuantity, 0)
  const totalCost = rows.reduce((sum, it) => sum + it.TotalCost, 0)
  const totalItemCost = rows.reduce((sum, it) => sum + it.TotalItemCost, 0)
  const totalRefound = rows.reduce((sum, it) => sum + it.TotalRefound, 0)
  const totalOptionCost = rows.reduce((sum, it) => sum + it.TotalOptionCost, 0)

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function renderMealType(type) {
    return (
      <Link
        to={`/supplier/${
          searchParams.supplier.SupplierID
        }?tab=orders&mealType=${type}&date=${moment(mealsData.Date).format(
          "YYYY-MM-DD"
        )}&location=${searchParams.location}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {getMealTypeText(intl, type)}
      </Link>
    )
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                className={classes.summary}
                tabIndex={-1}
            >
              <TableCell component="th" scope="row" align="left">
                <FormattedMessage id="REPORT.TOTAL" />
              </TableCell>
              <TableCell>{quantity}</TableCell>
              <TableCell>{refoundQuantity}</TableCell>
              <TableCell>{netQuantity}</TableCell>
              <TableCell>{totalOptionCost}</TableCell>
              <TableCell>{totalItemCost}</TableCell>
              <TableCell>{totalRefound}</TableCell>
              <TableCell>{totalCost}</TableCell>
            </TableRow>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.Date + "_" + index}
                >
                  <TableCell component="th" scope="row" align="left">
                    {renderMealType(row.MealType)}
                  </TableCell>
                  <TableCell>{row.Quantity}</TableCell>
                  <TableCell>{row.RefoundQuantity}</TableCell>
                  <TableCell>{row.NetQuantity}</TableCell>
                  <TableCell>{row.TotalOptionCost}</TableCell>
                  <TableCell>{row.TotalItemCost}</TableCell>
                  <TableCell>{row.TotalRefound}</TableCell>
                  <TableCell>{row.TotalCost}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(SupplierMealsList)
