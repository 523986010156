/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputMask from "react-input-mask"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";

function ConfirmationResendDialog({
  show,
  closeDialog,
  submitData,
  intl,
  variant,
  handleResend,
  data,
}) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const [loading, setLoading] = useState(false);

  function handleConfirmation() {
    setLoading(true);
    handleResend(data.ID).then((data) => {
      closeDialog();
      submitData(data)
    }).finally(() => {
      setLoading(false);
    });
  }

  function handleClose() {
    closeDialog(null)
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="POS.RESEND_CONFIRMATION" />
          </h3>
        </DialogTitle>
          <DialogContent>

          </DialogContent>
          <DialogActions>
            <Button
                variant="contained"
                type="submit"
                size="large"
                color="secondary"
                className={classes.button}
                disabled={loading}
                onClick={handleConfirmation}
            >
              <FormattedMessage id="POS.CONFIRM_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              onClick={handleClose}
            >
              <FormattedMessage id="POS.CANCEL_BUTTON" />
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}
export default injectIntl(ConfirmationResendDialog)
