/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import {useFormik} from "formik"
import * as Yup from "yup"
import {FormattedMessage, injectIntl} from "react-intl"
import {FormControlLabel} from "@material-ui/core"
import {useStyles} from "../Common/_styles/formDialogStyles"
import {actions as snackbarActions} from "../../../redux/snackbar/snackbarRedux"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../redux/snackbar/snackbarHandlers"
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


function CreatePriceCategoryFormDialog( {
                                        show,
                                        initialPriceCategoryState,
                                        closeDialog,
                                        submitData,
                                        submitIcon,
                                        priceCategoryTypes,
                                        priceCategorysData,
                                        locationID,
                                            handleSubmit,
                                        intl
                                    }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    let initialValues = {
        name: initialPriceCategoryState?.Name,
        id: initialPriceCategoryState?.Id,
    }

    function handleSumbit(data) {
        closeDialog(data)
    }

    function handleClose() {
        formik.resetForm()
        closeDialog(null)
    }

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            value => {
                const priceCategory = priceCategorysData.priceCategorys.find(priceCategory => priceCategory.Name === value)
                return priceCategory === undefined || priceCategory.Id === initialPriceCategoryState.Id
            }
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const validationSchema = Yup.object().shape(
        {
            name: nameValidationSchema,
        },
        ["name"]
    )

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const todayLabel = new Date();

    const today = (todayLabel.getMonth() + 1) + '/' +
        todayLabel.getDate() + '/' +
        todayLabel.getFullYear();
    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            const {name} = values
            const tokenData = {}
            tokenData.Name = name;
            tokenData.Id = initialPriceCategoryState?.Id;
            submitData({
                tokenData
            })
                .then(({data}) => {
                    dispatch(
                        snackbarActions.setSnackbarData(
                            intl.formatMessage({
                                id: "API.PRICE_CATEGORY_CREATE_SUCCESS"
                            })
                        )
                    )
                    setSubmitting(false)
                    tokenData.Id = data.Id
                    tokenData.Date = convert(tokenData.Date)
                    handleSubmit({Id: data.Id, ...tokenData})
                    handleClose();
                })
                .catch(error => {
                    setSubmitting(false)
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: initialPriceCategoryState?.Id
                                ? "API.ERROR.FAILED_TO_UPDATE_TAG"
                                : "API.ERROR.FAILED_TO_CREATE_TAG"
                        })
                    )
                })
        }
    })

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span className={classes.error}>{formik.errors[fieldName]}</span>
        ) : null
    }

    return (
        <div>
            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" disableTypography={true}>
                    <h3 style={{fontWeight: "bold", textAlign: "center"}}>
                        <FormattedMessage id="CREATE_FORM.PRICE_CATEGORY"/>
                    </h3>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <DialogContent className={classes.smallForm}>
                        <div className="position-relative">
                            <TextField
                                id="name"
                                name="name"
                                label={intl.formatMessage({
                                    id: "CREATE_FORM.PRICE_CATEGORY.LABEL"
                                })}
                                className={classes.textField}
                                margin="normal"
                                variant="filled"
                                fullWidth
                                {...formik.getFieldProps("name")}
                                // error={checkForError("name")}
                            />
                            {/*{renderErrors("name")}*/}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            onClick={handleClose}
                            className={classes.button}
                        >
                            <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON"/>
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="secondary"
                            className={classes.button}
                            disabled={formik.isSubmitting}
                        >
                            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON"/>
                            {formik.isSubmitting && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default injectIntl(CreatePriceCategoryFormDialog)
