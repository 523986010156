/* eslint-disable no-restricted-imports */
import React, {useState} from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import {useFormik} from "formik"
import * as Yup from "yup"
import {FormattedMessage, injectIntl} from "react-intl"
import {InputAdornment} from "@material-ui/core"
import ConfirmationDialog from "../Common/ConfirmationDialog"
import {useStyles} from "../Common/_styles/formDialogStyles"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../redux/snackbar/snackbarHandlers"

function PriceDetails({
                          description,
                          price,
                          hidden = false
                      }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    return (
        <div hidden={hidden} className={classes.descriptionLine}>
            <span>
              {description}
            </span>
            <span>
            {price}
          </span>
        </div>
    )
}

export default injectIntl(PriceDetails)
