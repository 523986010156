/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getSorting, stableSort } from "../../Common/tableSortingFunctions"
import moment from 'moment';

const headRows = [
  {
    id: "UserName",
    disablePadding: false,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "CostumerCreditAmount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.CREDIT" />
  },
  {
    id: "SupplierName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.SUPPLIER_NAME" />
  },
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  },
  {
    id: "Refound",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "Total",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL" />
  },
  {
    id: "OrderToDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DATE" />
  }
]

function CollapsibleRow({ row, indexId, classes }) {
    const [open, setOpen] = useState(false)

    const dateObj = row.OrderToDate != null ? moment.utc(row.OrderToDate) : null;
    const date = dateObj ? dateObj.format('DD-MM-YYYY') : "";

    const dayOfWeek = dateObj ? dateObj.day() : null;
    const dayOfWeekId = dayOfWeek !== null ? `DAYS.${['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][dayOfWeek]}` : "";

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.collapsibleRow}
      >
        <TableCell>{row.UserName}</TableCell>
        <TableCell>{row.CostumerCreditAmount}</TableCell>
        <TableCell>{row.SupplierName}</TableCell>
        <TableCell>{row.ItemName}</TableCell>
        <TableCell>{row.Quantity}</TableCell>
        <TableCell>{row.Refound === true ? row.Quantity : 0}</TableCell>
        <TableCell>{row.Total}</TableCell>
        <TableCell>{date} {dayOfWeekId && <FormattedMessage id={dayOfWeekId} />} </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function OrderItemsCreditList({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
  const [sortBy, setSortBy] = useState("OrderToDate")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, i) => {
              return (
                  <CollapsibleRow key={row.OrderToDate + "_" + row.UserName + "_" + i} row={row} classes={classes} />
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsCreditList
