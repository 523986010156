/* eslint-disable no-restricted-imports */

import {Typography} from "@material-ui/core"
import axios from "axios"
import StateFormDialog from "../modules/Customers/StateFormDialog";
import React, {useEffect, useState} from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import CreateCustomerFormDialog from "../modules/Common/CreateWithNameFormDialog.js"
import CustomerList from "../modules/Common/DisplayList.js"
import {withRouter} from "react-router-dom"
import {
    createCustomer,
    getCustomersList,
    customerSettings
} from "../modules/Customers/_axios/customerCrud.js"
import {connect} from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../redux/snackbar/snackbarHandlers.js"
import {Tab, Tabs} from "react-bootstrap";

function CustomersListPage({intl, ...props}) {
    const dispatch = useDispatch()
    const [customersData, setCustomersData] = useState({
        data: [],
        requestCount: 0,
        isLoading: true
    })
    const [showCreateCustomerPanel, setShowCreateCustomerPanel] = useState(false)

    const [showStatePopup, setShowStatePopup] = useState(false)
    const [Customer, setCustomer] = useState()
    const [tab, setTab] = useState(1);

    function saveCustomer(newCustomer) {
        return createCustomer(newCustomer)
    }

    function fetchCustomers(state, cancelToken) {
        setCustomersData({...customersData, isLoading: true})
        getCustomersList(cancelToken.token, undefined, state)
            .then(({data}) => {
                setCustomersData({
                    ...customersData,
                    data: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    const handleNewCustomerSubmit = (values, {setSubmitting, resetForm}) => {
        setSubmitting(true)
        const newCustomer = {Name: values.name}
        saveCustomer(newCustomer)
            .then(({data}) => {
                setSubmitting(false)
                resetForm()
                props.history.push(`customer/${data.CustomerID}`)
            })
            .catch(error => {
                setSubmitting(false)
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_CREATE_CUSTOMER"
                    })
                )
            })
    }

    function getCustomersHandler(state) {
        const cancelToken = axios.CancelToken.source()
        fetchCustomers(state, cancelToken)
        return () => cancelToken.cancel()
    }

    useEffect(() => {
        if (tab) {
            getCustomersHandler(tab)
        }
    }, [tab])

    function changeStatus(ev) {
        setShowStatePopup(true);
        setCustomer(ev);
    }

    function handleState(ev, customerID) {
        return customerSettings(ev, customerID);
    }


    return (
        <>
            <CreateCustomerFormDialog
                show={showCreateCustomerPanel}
                title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                closeDialog={() => setShowCreateCustomerPanel(false)}
                handleSubmit={handleNewCustomerSubmit}
                checkIfNameUnique={value => {
                    const customer = customersData.data.find(
                        customer => customer.Name === value
                    )
                    return customer === undefined
                }}
            />
            <Typography variant="h3" style={{textAlign: "right"}}>
                <FormattedMessage id="TITLE"/>
            </Typography>
            <Tabs id="main-tab"
                  activeKey={tab}
                  onSelect={(k) => setTab(k)}
                  unmountOnExit>
                <Tab eventKey="1"
                     title={<FormattedMessage id="TABS.CUSTOMERS"/>}>
                </Tab>
                <Tab
                    eventKey="0"
                    title={<FormattedMessage id="TABS.CUSTOMERS_ARCHIVE"/>}
                >
                </Tab>
            </Tabs>
            <CustomerList
                variant="customers"
                hideNewActionButton={tab == '0'}
                showActionButton={true}
                action={ev => changeStatus(ev)}
                displayedData={customersData}
                openCreatePanel={() => setShowCreateCustomerPanel(true)}
            />
            <StateFormDialog
                show={showStatePopup}
                title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                closeDialog={() => {
                    setShowStatePopup(false);
                    setCustomer(undefined);
                }}
                submitData={ev => handleState({
                    state: Customer.State === 'active' ? 0 : 1,
                }, Customer.CustomerID)}
                afterSubmitCloseDialog={() => {
                    getCustomersHandler(tab)
                    setShowStatePopup(false);
                    setCustomer(undefined);
                }}
                isSubmitting={false}
                intl={intl}
                message={
                    Customer?.State === 'active' ? intl.formatMessage({
                        id: "ADMIN.CONFIRMATION_FORM.REQUEST_ARCHIVE"
                    }) : intl.formatMessage({
                        id: "ADMIN.CONFIRMATION_FORM.REQUEST_ACTIVE"
                    })
                }
            />
        </>
    )
}

export default withRouter(
    injectIntl(connect(null, snackbarRedux.actions)(CustomersListPage))
)
