/* eslint-disable no-restricted-imports */

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Checkbox,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import { useFormik } from "formik"
import * as Yup from "yup"
import DeleteIcon from "@material-ui/icons/Delete"
import DishLoader from "../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import { dayCollection } from "../../Common/dayCollection"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  textField: {
    margin: theme.spacing(1)
  },
  input: {
    padding: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  error: { position: "absolute", bottom: -8, left: 10, color: "#F018A6" }
}))

function DepartmentForm({
  intl,
  onSubmit,
  selectedDepartment,
  selectedCustomerID,
  departments,
  updateDepartments,
  removeDepartment
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  let initialValues = {
    name: selectedDepartment.Name || "",
    comment: selectedDepartment.Details || "",
    mealPerDay: selectedDepartment.MealPerDay,
    budgetAmount: selectedDepartment.BudgetAmount,
    budgetDailyLimit: selectedDepartment.BudgetDailyLimit,
    budgetType: selectedDepartment.BudgetType,
    budgetDaysAvailability: selectedDepartment.BudgetDaysAvailability ? JSON.parse(selectedDepartment.BudgetDaysAvailability) : [],
    breakfast: selectedDepartment.Breakfast,
    breakfastSubsidy: selectedDepartment.BreakfastSubsidy,
    lunch: selectedDepartment.Lunch,
    isSubsidyOnly: selectedDepartment.IsSubsidyOnly,
    lunchSubsidy: selectedDepartment.LunchSubsidy,
    dinner: selectedDepartment.Dinner,
    dinnerSubsidy: selectedDepartment.DinnerSubsidy
  }

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      value => {
        const department = departments.find(
          department => department.Name === value
        )
        return (
          department === undefined ||
          department.DepartmentID === selectedDepartment.DepartmentID
        )
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const subsidyValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const validationSchema = Yup.object().shape(
    {
      name: nameValidationSchema,
      budgetAmount: subsidyValidationSchema,
      budgetDailyLimit: subsidyValidationSchema,
      breakfastSubsidy: subsidyValidationSchema,
      lunchSubsidy: subsidyValidationSchema,
      dinnerSubsidy: subsidyValidationSchema
    },
    ["name", "breakfastSubsidy", "lunchSubsidy", "dinnerSubsidy", "budgetAmount", "budgetDailyLimit"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const {
        name,
        comment,
        mealPerDay,
        budgetAmount,
        budgetDailyLimit,
        budgetType,
        budgetDaysAvailability,
        breakfast,
        breakfastSubsidy,
        lunch,
        lunchSubsidy,
        isSubsidyOnly,
        dinner,
        dinnerSubsidy
      } = values

      let newDepartment = {}
      newDepartment.DepartmentID = selectedDepartment.DepartmentID
      newDepartment.CustomerID = selectedCustomerID
      newDepartment.Name = name
      newDepartment.Details = comment
      newDepartment.MealPerDay = mealPerDay
      newDepartment.BudgetAmount = budgetAmount
      newDepartment.BudgetDailyLimit = budgetDailyLimit
      newDepartment.BudgetType = budgetType
      newDepartment.BudgetDaysAvailability = JSON.stringify(budgetDaysAvailability)
      newDepartment.Breakfast = breakfast
      newDepartment.BreakfastSubsidy = breakfastSubsidy
      newDepartment.Lunch = lunch
      newDepartment.IsSubsidyOnly = isSubsidyOnly
      newDepartment.LunchSubsidy = lunchSubsidy
      newDepartment.Dinner = dinner
      newDepartment.DinnerSubsidy = dinnerSubsidy
      onSubmit(newDepartment)
        .then(() => {
          updateDepartments({
            ...newDepartment
          })
          setSubmitting(false)
          resetForm()
        })
        .catch(error => {
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_UPDATE_DEPARTMENT"
            })
          )
          setSubmitting(false)
        })
    }
  })

  function onDaysChange(event, dayValue) {
    event.preventDefault();
    let changedBudgetItems;
    if (event.target.checked) {
      changedBudgetItems = [...formik.values.budgetDaysAvailability, dayValue]
    } else {
      changedBudgetItems = formik.values.budgetDaysAvailability.filter(
          value => value !== dayValue
      )
    }
    formik.setFieldValue('budgetDaysAvailability', [...changedBudgetItems])
  }

  function handleDelete() {
    formik.setSubmitting(true)
    removeDepartment()
  }

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      {selectedDepartment.isLoading && <DishLoader centered />}
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <div className={classes.root}>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.NAME_FIELD.LABEL" />
                </div>
                <div className="col-8 pl-0">
                  <TextField
                    id="name"
                    className={classes.textField}
                    margin="normal"
                    variant="filled"
                    hiddenLabel
                    inputProps={{
                      className: classes.input
                    }}
                    fullWidth
                    {...formik.getFieldProps("name")}
                    error={checkForError("name")}
                  />
                  {renderErrors("name")}
                </div>
              </div>
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.COMMENT_FIELD.LABEL" />
                </div>
                <div className="col-8 pl-0">
                  <TextField
                    id="comment"
                    className={classes.textField}
                    margin="normal"
                    variant="filled"
                    hiddenLabel
                    inputProps={{
                      className: classes.input
                    }}
                    fullWidth
                    {...formik.getFieldProps("comment")}
                    error={checkForError("comment")}
                  />
                  {renderErrors("comment")}
                </div>
              </div>
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.MEAL_PER_DAY_FIELD.LABEL" />
                </div>
                <div className="col-8 pl-0">
                  <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                  >
                    <Select
                      {...formik.getFieldProps("mealPerDay")}
                      input={
                        <FilledInput
                          name="mealPerDay"
                          inputProps={{
                            className: classes.input
                          }}
                          id="meal-per-day-select"
                        />
                      }
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                  {renderErrors("mealPerDay")}
                </div>
              </div>
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.BUDGET_TYPE_FIELD.LABEL" />
                </div>
                <div className="col-8 pl-0">
                  <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                  >
                    <Select
                      {...formik.getFieldProps("budgetType")}
                      input={
                        <FilledInput
                          name="budgetType"
                          inputProps={{
                            className: classes.input
                          }}
                          id="budget-type-select"
                        />
                      }
                      onChange={event => {
                        const value = event.target.value;
                        if (value === "None") {
                          formik.setFieldValue("budgetAmount", 0)
                        }
                        if (!["Weekly", "Monthly"].includes(value)) {
                          formik.setFieldValue("budgetDailyLimit", 0)
                        }
                        formik.setFieldValue("budgetType", value)
                      }}
                    >
                      <MenuItem value={"None"}><FormattedMessage id={"BUDGET_TYPE.NONE"}/></MenuItem>
                      <MenuItem value={"Daily"}><FormattedMessage id={"BUDGET_TYPE.DAILY"}/></MenuItem>
                      <MenuItem value={"Weekly"}><FormattedMessage id={"BUDGET_TYPE.WEEKLY"}/></MenuItem>
                      <MenuItem value={"Monthly"}><FormattedMessage id={"BUDGET_TYPE.MONTHLY"}/></MenuItem>
                    </Select>
                  </FormControl>
                  {renderErrors("budgetType")}
                </div>
              </div>
              {
                formik.values.budgetType === "Daily" && (
                    <div className="row">
                      <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="CREATE_DEPARTMENT_FORM.BUDGET_DAYS_AVAILABILITY_FIELD.LABEL" />
                      </div>
                      <div className="col-8 pl-0">
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                            fullWidth
                        >

                          {dayCollection.map(day => (
                              <FormControlLabel
                                  key={day.value}
                                  style={{ margin: 0 }}
                                  control={
                                    <Checkbox
                                        checked={formik.values.budgetDaysAvailability.includes(day.value)}
                                        style={{ padding: 0 }}
                                        onChange={event =>
                                            onDaysChange(event, day.value)
                                        }
                                        color="secondary"
                                    />
                                  }
                                  label={day.label}
                                  labelPlacement="end"
                              />
                          ))}







                        </FormControl>
                        {renderErrors("budgetDaysAvailability")}
                      </div>
                    </div>
                )
              }
              {formik.values.budgetType !== "None" &&
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_DEPARTMENT_FORM.BUDGET_AMOUNT_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="budget-amount"
                      name="budgetAmount"
                      className={classes.textField}
                      inputProps={{
                        min: 0,
                        className: classes.input
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₪</InputAdornment>
                        )
                      }}
                      margin="normal"
                      variant="filled"
                      type="number"
                      fullWidth
                      {...formik.getFieldProps("budgetAmount")}
                      error={checkForError("budgetAmount")}
                    />
                    {renderErrors("budgetAmount")}
                  </div>
                </div>
              }
              {["Weekly", "Monthly"].includes(formik.values.budgetType) &&
                <div className="row">
                  <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_DEPARTMENT_FORM.BUDGET_DAILY_LIMIT_FIELD.LABEL" />
                  </div>
                  <div className="col-8 pl-0">
                    <TextField
                      id="budget-daily-amount"
                      name="budgetDailyLimit"
                      className={classes.textField}
                      inputProps={{
                        min: 0,
                        className: classes.input
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₪</InputAdornment>
                        )
                      }}
                      margin="normal"
                      variant="filled"
                      type="number"
                      fullWidth
                      {...formik.getFieldProps("budgetDailyLimit")}
                      error={checkForError("budgetDailyLimit")}
                    />
                    {renderErrors("budgetDailyLimit")}
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <Checkbox
                    checked={formik.values.breakfast}
                    name="breakfast"
                    color="secondary"
                    {...formik.getFieldProps("breakfast")}
                  />
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center pl-0 pr-0 text-center">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.BREAKFAST_FIELD.LABEL" />
                </div>
                <div className="col-4 pl-0">
                  <TextField
                    id="breakfast-subsidy"
                    name="breakfastSubsidy"
                    className={classes.textField}
                    inputProps={{
                      min: 0,
                      className: classes.input,
                      readOnly: !formik.values.breakfast
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₪</InputAdornment>
                      )
                    }}
                    margin="normal"
                    variant="filled"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps("breakfastSubsidy")}
                    error={checkForError("breakfastSubsidy")}
                  />
                  {renderErrors("breakfastSubsidy")}
                </div>
              </div>
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <Checkbox
                    checked={formik.values.lunch}
                    name="lunch"
                    color="secondary"
                    {...formik.getFieldProps("lunch")}
                  />
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center pl-0 pr-0 text-center">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.LUNCH_FIELD.LABEL" />
                </div>
                <div className="col-4 pl-0">
                  <TextField
                    id="lunch-subsidy"
                    name="lunchSubsidy"
                    className={classes.textField}
                    inputProps={{
                      min: 0,
                      className: classes.input,
                      readOnly: !formik.values.lunch
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₪</InputAdornment>
                      )
                    }}
                    margin="normal"
                    variant="filled"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps("lunchSubsidy")}
                    error={checkForError("lunchSubsidy")}
                  />
                  {renderErrors("lunchSubsidy")}
                </div>
              </div>




              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <Checkbox
                    checked={formik.values.dinner}
                    name="dinner"
                    color="secondary"
                    {...formik.getFieldProps("dinner")}
                  />
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center pl-0 pr-0 text-center">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.DINNER_FIELD.LABEL" />
                </div>
                <div className="col-4 pl-0">
                  <TextField
                    id="dinner-subsidy"
                    name="dinnerSubsidy"
                    className={classes.textField}
                    inputProps={{
                      min: 0,
                      className: classes.input,
                      readOnly: !formik.values.dinner
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₪</InputAdornment>
                      )
                    }}
                    margin="normal"
                    variant="filled"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps("dinnerSubsidy")}
                    error={checkForError("dinnerSubsidy")}
                  />
                  {renderErrors("dinnerSubsidy")}
                </div>
              </div>
              <div className="row">
                <div className="col-4" />
                <div className="col-8 d-flex justify-content-center align-items-center text-left">
                  *
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.PRICE_MESSAGE" />
                </div>
              </div>


              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                  <Checkbox
                      checked={formik.values.isSubsidyOnly}
                      name="isSubsidyOnly"
                      color="secondary"
                      {...formik.getFieldProps("isSubsidyOnly")}
                  />
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center pl-0 pr-0 text-center">
                  <FormattedMessage id="CREATE_DEPARTMENT_FORM.IS_SUBSIDY.LABEL" />
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-end align-items-center pr-0 text-right">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    disabled={formik.isSubmitting}
                  >
                    <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                    {formik.isSubmitting && (
                      <span className="ml-1 spinner spinner-white"></span>
                    )}
                  </Button>
                </div>
              </div>







            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 d-flex justify-content-start align-items-center pr-0 text-right">
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    className={classes.button}
                    disabled={formik.isSubmitting}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete()}
                  >
                    <FormattedMessage id="CREATE_FORM.DELETE_BUTTON" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(DepartmentForm)
