import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers.js";
import Downshift from "downshift";
import UploadedItemsList from "./UploadedItemsList";
import {
  getSuggestions,
  renderInput,
  renderSuggestion
} from "../../Common/downshiftFunctions.js";
import axios from "axios";
import DishLoader from "../../Common/DishLoader.js";

const useStyles = makeStyles(theme => ({
  labelForm: {
    fontWeight: 'bold'
  },
  titleForm: {
    textAlign: 'center'
  }
}));

function MealAllItemUpdateDialog( {
  intl,
  show,
  closeDialog,
  submitData,
  suppliers,
  takenItemsIDs,
  mealTypeIndex
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setSubmitting(false);
    }
  }, [show]);

  const classes = useStyles();

  function handleSubmit() {
    setSubmitting(true);
    submitData({
      priority: 0
    },
      () => {
        setSubmitting(false);
        closeDialog();
    })
  }

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: "100%" } }}
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="UPDATE_ALL_MEAL_ITEM_FORM.TITLE" />
        </h3>
      </DialogTitle>
      <DialogContent style={{ height: 500 }}>
        <div className={classes.titleForm}>
          <FormattedMessage id="UPDATE_ALL_MEAL_ITEM_LABELS.CONFIRMATION"/>
        </div>
        <div className={classes.labelForm}>
          <FormattedMessage id="UPDATE_ALL_MEAL_ITEM_LABELS.PRIORITY" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          size="large"
          onClick={closeDialog}
          className={classes.button}
          disabled={isSubmitting}
        >
          <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
        </Button>
        <Button
          variant="contained"
          type="submit"
          size="large"
          color="secondary"
          className={classes.button}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
          {isSubmitting && <span className="ml-1 spinner spinner-white"></span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(MealAllItemUpdateDialog);
