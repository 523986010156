/* eslint-disable no-restricted-imports */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    marginLeft: -theme.spacing(8),
    bottom: theme.spacing(1),
    backgroundColor: "red",
    opacity: 0.5,
    zIndex: 10,
    "&:hover": {
      opacity: 1
    }
  }
}))

export default function DeleteButton({ onClick }) {
  const classes = useStyles()

  return (
    <>
      <Fab
        color="secondary"
        aria-label="Delete selected"
        className={classes.fab}
        onClick={onClick}
      >
        <DeleteIcon />
      </Fab>
    </>
  )
}
