export const specialDateTypes = [
    "חג",
    "ערב חג"
]

export const specialDateTypesMapping = {
    "חג": 1,
    "ערב חג":2
}

export const specialDateTypesMappingReverted = {
    'Holiday': "חג",
    'Holiday evening': "ערב חג"
}

export function getSpecialDateTypeID(specialDateType) {
    let specialDateTypeValue = specialDateType.toUpperCase().replace(/ /g, "_")
    return `${specialDateTypeValue}`
}
