/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getSorting, stableSort } from "../../Common/tableSortingFunctions"

const headRows = [
  {
    id: "Name",
    disablePadding: false,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "ExternalId",
    disablePadding: false,
    label: <FormattedMessage id="EXPORT_FORM.EXTERNAL_ID_FIELD.LABEL" />
  },
  {
    id: "Mobile",
    disablePadding: false,
    label: <FormattedMessage id="EXPORT_FORM.MOBILE_FIELD.LABEL" />
  },
  {
    id: "Email",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EMAIL" />
  },
  {
    id: "Department",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DEPARTMENT" />
  },
  {
    id: "MealType1",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.BREAKFAST" />
  },
  {
    id: "MealType2",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.LUNCH" />
  },
  {
    id: "MealType3",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DINNER" />
  }
]

function CollapsibleRow({ row, indexId, classes }) {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.collapsibleRow}
      >
        <TableCell>{row.Name}</TableCell>
        <TableCell>{row.ExternalId}</TableCell>
        <TableCell>{row.Mobile}</TableCell>
        <TableCell>{row.Email}</TableCell>
        <TableCell>{row.Department}</TableCell>
        <TableCell>{row.MealType1}</TableCell>
        <TableCell>{row.MealType2}</TableCell>
        <TableCell>{row.MealType3}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function OrderItemsUsersList({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map(row => {
                return (
                 <CollapsibleRow key={row.Name + "_" + row.Mobile + "_" + row.Email} row={row} classes={classes} />
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsUsersList
