/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import CreateDepartmentButton from "../../Common/CreateNewButton"
import DepartmentList from "../ElementList"
import DepartmentForm from "./DepartmentForm"
import {
  updateDepartment,
  createDepartment,
  deleteDepartment
} from "../_axios/customerCrud.js"
import CreateDepartmentFormDialog from "../../Common/CreateWithNameFormDialog"
import { FormattedMessage, injectIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"

function DepartmentPanel({ intl, selectedCustomer, updateCustomer }) {
  const dispatch = useDispatch()
  const [showCreateDepartmentPanel, setShowCreateDepartmentPanel] = useState(
    false
  )
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: ""
  })

  function selectDepartment(Name) {
    if (selectedDepartment.Name !== Name) {
      setSelectedDepartment(
        selectedCustomer.Departments.find(
          department => department.Name === Name
        )
      )
    }
  }

  function saveDepartment(submittedDepartment) {
    return updateDepartment(
      submittedDepartment,
      submittedDepartment.DepartmentID
    )
  }

  function removeDepartment() {
    return deleteDepartment(selectedDepartment.DepartmentID)
      .then(() => {
        const changedCustomer = { ...selectedCustomer }
        changedCustomer.Departments = changedCustomer.Departments.filter(
          department =>
            department.DepartmentID !== selectedDepartment.DepartmentID
        )
        updateCustomer(changedCustomer)
        setSelectedDepartment({ DepartmentID: "" })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_DELETE_DEPARTMENT"
          })
        )
      )
  }

  function updateDepartments(submittedDepartment) {
    const changedCustomer = { ...selectedCustomer }
    const departments = changedCustomer.Departments
    const changedDepartment = departments.find(
      department => department.DepartmentID === submittedDepartment.DepartmentID
    )
    if (changedDepartment) {
      Object.assign(changedDepartment, submittedDepartment)

      updateCustomer(changedCustomer)
    } else {
      const newDepartment = {
        ...submittedDepartment,
        CustomerID: selectedCustomer.CustomerID,
        Details: "",
        BreakfastSubsidy: 0,
        Breakfast: true,
        LunchSubsidy: 0,
        Lunch: true,
        DinnerSubsidy: 0,
        Dinner: true,
        MealPerDay: 0
      }
      departments.push(newDepartment)
      updateCustomer(changedCustomer)
      setShowCreateDepartmentPanel(false)
      setSelectedDepartment(newDepartment)
    }
  }

  const handleNewDepartmentSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const newDepartment = { Name: values.name }
    createDepartment(newDepartment, selectedCustomer.CustomerID)
      .then(({ data }) => {
        setSubmitting(false)
        updateDepartments({ ...newDepartment, DepartmentID: data.Id })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_DEPARTMENT"
          })
        )
      })
  }

  useEffect(() => {
    setSelectedDepartment({ DepartmentID: "" })
  }, [selectedCustomer.CustomerID])

  return (
    <>
      <CreateDepartmentButton
        onClick={() => setShowCreateDepartmentPanel(true)}
      />
      <CreateDepartmentFormDialog
        show={showCreateDepartmentPanel}
        title={<FormattedMessage id="CREATE_DEPARTMENT_FORM.TITLE" />}
        closeDialog={() => setShowCreateDepartmentPanel(false)}
        handleSubmit={handleNewDepartmentSubmit}
        checkIfNameUnique={value => {
          const department = selectedCustomer.Departments.find(
            department => department.Name === value
          )
          return department === undefined
        }}
      />
      <div className="row">
        <div className="col-2">
          <DepartmentList
            rows={selectedCustomer.Departments}
            selected={selectedDepartment.Name}
            setSelected={selectDepartment}
          />
        </div>
        <div className="col-10">
          <div className="row">
            <div className="col-12">
              {selectedDepartment.DepartmentID && (
                <DepartmentForm
                  selectedDepartment={selectedDepartment}
                  selectedCustomerID={selectedCustomer.CustomerID}
                  departments={selectedCustomer.Departments}
                  onSubmit={saveDepartment}
                  updateDepartments={updateDepartments}
                  removeDepartment={removeDepartment}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default injectIntl(DepartmentPanel)
