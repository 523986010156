/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  downshiftField: {
    margin: theme.spacing(1, 0),
    width: 200
  },
  paper: {
    position: "absolute",
    zIndex: 10,
    left: 0,
    right: 0,
    maxHeight: 525,
    overflowY: "scroll"
  },
  spinner: {
    position: "absolute",
    bottom: "50%",
    left: 10
  }
}))
