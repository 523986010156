/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {FormattedMessage, injectIntl} from "react-intl";
import EnhancedTableHead from "../../Common/EnhancedTableHead";
import {stableSort, getSorting} from "../../Common/tableSortingFunctions";
import DishLoader from "../../Common/DishLoader";
import {useStyles} from "../../Common/_styles/elementListStyles";
import {formatDate} from "../../Common/momentFunctions";
import {
    Button,
    Checkbox,
    Collapse,
    FilledInput,
    FormControl,
    IconButton,
    MenuItem,
    Select, TextField,
    Tooltip
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

function BankTransferItem({row, loading, setRowSettings, intl}) {

    const classes = useStyles();
    const [open, setOpen] = useState(false)

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <Tooltip
                    title={intl.formatMessage({
                        id: "OPTION.SEE_DETAILS.TOOLTIP"
                    })}
                >
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Tooltip>
                <TableCell component="th" scope="row" align="left">
                    {row.fullName}
                </TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>
                     <span>
                      ₪
                    </span>
                    <span className='number-direction'>
                      {row.amount}
                    </span>
                </TableCell>
                <TableCell>
                    <FormattedMessage id={`STATUS.${row.transferStatus}`}/>
                </TableCell>
                <TableCell>{row.requestDate ? formatDate(row.requestDate) : ''}</TableCell>
                <TableCell>{row.expectedTransferDate ? formatDate(row.expectedTransferDate) : ''}</TableCell>
                <TableCell>{row.transferDate ? formatDate(row.transferDate) : ''}</TableCell>
                <TableCell component="th" scope="row" align="left">
                    {row.transferStatus === "PENDING" && (
                        <Checkbox
                            checked={row.selected}
                            name="allowBankTransfer"
                            color="secondary"
                            disableRipple={loading}
                            onChange={ev => setRowSettings(row)}
                        />
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="position-relative">
                            <div className='line-item'>
                                <span className='subheader-item'>
                                    <FormattedMessage id={`ADMIN_BANKS.TRANSFER_TIME_TYPE`}/>
                                </span>
                                <span>
                                    {
                                        row.transferTimeType && (
                                            <FormattedMessage id={`EXPORT_FILE_NAME.TRANSFER_TYPE.${row.transferTimeType}`}/>
                                        )
                                    }
                                </span>
                            </div>
                            <div className='line-item'>
                                <span className='subheader-item'>
                                    <FormattedMessage id={`ADMIN_BANKS.USER_IP`}/>
                                </span>
                                <span>
                                    {row.userIP}
                                </span>
                            </div>
                            <div className='line-item'>
                                <span className='subheader-item'>
                                    <FormattedMessage id={`ADMIN_BANKS.USER_DEVICE`}/>
                                </span>
                                <span>
                                    {row.userDeviceType}
                                </span>
                            </div>
                            <div className='line-container'>
                                <div className='line-item'>
                                <span className='subheader-item'>
                                        <FormattedMessage id={`ADMIN_BANKS.ACCOUNT_NAME`}/>
                                    </span>
                                    <span>
                                    {row.transferBankAccountName}
                                </span>
                                </div>
                                <div className='line-item'>
                                <span className='subheader-item'>
                                        <FormattedMessage id={`ADMIN_BANKS.ACCOUNT_NUMBER`}/>
                                    </span>
                                    <span>
                                    {row.transferBankAccountNumber}
                                </span>
                                </div>
                                <div className='line-item'>
                                <span className='subheader-item'>
                                        <FormattedMessage id={`ADMIN_BANKS.BANK_NUMBER`}/>
                                    </span>
                                    <span>
                                    {row.transferBankNumber}
                                </span>
                                </div>
                                <div className='line-item'>
                                <span className='subheader-item'>
                                        <FormattedMessage id={`ADMIN_BANKS.BRANCH_NUMBER`}/>
                                    </span>
                                    <span>
                                    {row.transferBankBranchNumber}
                                </span>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default injectIntl(BankTransferItem);
