/* eslint-disable no-restricted-imports */

import { Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import CreateComboButton from "../modules/Common/CreateNewButton.js"
import DeleteComboDialog from "../modules/Combos/DeleteComboDialog.js"
import ComboDialog from "../modules/Combos/ComboDialog.js"
import ComboList from "../modules/Combos/ComboList.js"
import {
  createCombo,
  editCombo,
  deleteCombo,
  getCombos,
} from "../modules/Combos/_axios/comboCrud.js"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import { getTags } from "../modules/Tags/_axios/tagCrud"

function CombosAdminPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const [editComboState, setEditComboState] = useState({})
  const [supplierData, setSupplierData] = useState({
    suppliers: [],
    isLoading: true
  })
  const [deleteComboState, setDeleteComboState] = useState({ show: false })
  const [showCreateComboPanel, setShowCreateComboPanel] = useState(false)
  const [combosData, setCombosData] = useState({
    combos: [],
    isLoading: true
  })
  const [tagsData, setTagsData] = useState({
    tags: [],
    isLoading: true
  })
  function handleCloseDeleteComboPanel(comboID) {
    if (comboID) {
      setCombosData({
        ...combosData,
        combos: [...combosData.combos.filter(combo => combo.Id !== comboID)]
      })
    }
    setDeleteComboState({ show: false })
  }

  function handleOpenCreateComboPanel() {
    setShowCreateComboPanel(true)
    setEditComboState(null);
  }

  function handleEditCombo(combo) {
    setEditComboState({ ...combo })
    setShowCreateComboPanel(true);
  }

  function handleDeleteCombo(combo) {
    setDeleteComboState({ show: true, combo })
  }

  function createOrEditCombo(submittedCombo) {
    if (submittedCombo.comboId) {
      return editCombo(submittedCombo.comboId, submittedCombo.tokenData)
    }
    return createCombo(submittedCombo.tokenData)
  }
  function fetchCombos(cancelToken) {
    setCombosData({ ...combosData, isLoading: true })
    getCombos(cancelToken.token)
      .then(({ data }) => {
        setCombosData({
          combos: data,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_COMBOS"
          })
        )
      )
  }

  useEffect(() => {
    loadCombos();
  }, [])

  useEffect(() => {
    loadSuppliers()
    loadTags()
  }, [])

  function loadTags() {
    const cancelToken = axios.CancelToken.source()
    setTagsData({ tags: [], isLoading: true })
    getTags(cancelToken.token)
        .then(({ data }) => {
          setTagsData({ tags: data, isLoading: false })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_TAGS"
                })
            )
        )
    return () => cancelToken.cancel()
  }

  function loadSuppliers(){
    const cancelToken = axios.CancelToken.source()
    setSupplierData({ suppliers: [], isLoading: true })
    getSuppliers(cancelToken.token, "active")
        .then(({ data }) => {
          setSupplierData({ suppliers: data, isLoading: false })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                })
            )
        )
    return () => cancelToken.cancel()
  }

  function loadCombos() {
    const cancelToken = axios.CancelToken.source()
    fetchCombos(cancelToken)
    return () => cancelToken.cancel()
    }

  useEffect(() => {
    if (editComboState && editComboState.Id) {
      setShowCreateComboPanel(true)
    }
  }, [editComboState])

  function handleCloseComboPanel() {
    setShowCreateComboPanel(false);
    setEditComboState(null);
  }

  function processCombos() {
    setShowCreateComboPanel(false);
    setEditComboState(null)
    loadCombos();
  }
    function updateComboAndGetNewItems(comboId) {
        const cancelToken = axios.CancelToken.source();
        getCombos(cancelToken.token)
            .then(({ data }) => {
                setCombosData({
                    combos: data,
                    isLoading: false
                });

                const combo = data.find(combo => combo.Id === comboId);

                setEditComboState(null);
                if (combo != null) {
                    setEditComboState({ ...combo });
                    setShowCreateComboPanel(true);
                }
            })
            .catch(error => {
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_COMBOS"
                    })
                );
            });

    }

  return (
    <>
      <CreateComboButton onClick={handleOpenCreateComboPanel} />
      <DeleteComboDialog
        show={deleteComboState.show}
        onClose={handleCloseDeleteComboPanel}
        sendRequest={deleteCombo}
        comboData={deleteComboState.combo}
      />
      {
        showCreateComboPanel && (
            <ComboDialog
                show={showCreateComboPanel}
                onClose={handleCloseComboPanel}
                sendRequest={editComboState?.Id ? editCombo : createCombo}
                handleSubmit={processCombos}
                updateCombos={updateComboAndGetNewItems}
                allTags={tagsData.tags}
                suppliers={supplierData.suppliers}
                initialComboState={editComboState}
            />
          )
      }
      <Typography
        variant="h3"
        id="userAdminTitle"
        style={{ textAlign: "right" }}
      >
        <FormattedMessage id="COMBO.TITLE" />
      </Typography>

      <ComboList
        combosData={combosData}
        suppliers={supplierData.suppliers}
        onEdit={handleEditCombo}
        onDelete={handleDeleteCombo}
      />
    </>
  )
}

export default injectIntl(CombosAdminPage)
