import React, { useEffect } from "react"
import * as FileSaver from "file-saver"
import * as xlsx from "xlsx"
import { Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

export default function ExportButton({
    exportData,
    fileName,
    onLoadData,
    onHandleData,
    isLoading,
    isCSV = false,
    formatMessageId = "EXPORT_BUTTON",
    customExportFunction
}) {
    const fileTypeXLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileTypeCSV = "text/csv;charset=utf-8;"
    const fileExtensionXLSX = ".xlsx"
    const fileExtensionCSV = ".csv"

    const exportToXLSX = (exportData, fileName) => {
        const ws = xlsx.utils.json_to_sheet(exportData)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"], Workbook: { Views: [{ RTL: true }] } }
        const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileTypeXLSX })
        FileSaver.saveAs(data, fileName + fileExtensionXLSX)
    }

    const exportToCSV = (exportData, fileName) => {
        const headers = Object.keys(exportData[0]).join(",")
        const csvData = exportData.map(row => Object.values(row).join(",")).join("\n")
        const csvContent = headers + "\n" + csvData
        const bom = "\uFEFF"
        const data = new Blob([bom + csvContent], { type: fileTypeCSV })
        FileSaver.saveAs(data, fileName + fileExtensionCSV)
    }

    useEffect(() => {
        if (onLoadData && exportData.length && fileName) {
            if (isCSV) {
                exportToCSV(exportData, fileName)
            } else {
                exportToXLSX(exportData, fileName)
            }
        }
    }, [exportData, isCSV])

    return (
        <Button
            id="export_button"
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            dir="ltr"
            className={`font-weight-bold mt-3 mb-3`}
            onClick={e =>
                !onHandleData
                    ? (onLoadData ? onLoadData(e) : (customExportFunction ? customExportFunction(exportData, fileName) : (isCSV ? exportToCSV(exportData, fileName) : exportToXLSX(exportData, fileName))))
                    : onHandleData(customExportFunction ? customExportFunction : (isCSV ? exportToCSV : exportToXLSX))
            }
            disabled={isLoading}
        >
            <FormattedMessage id={formatMessageId} />
            {isLoading && <span className="ml-1 spinner spinner-white"></span>}
        </Button>
    )
}