/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/OrderReports/FilterPanel"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import {FormattedMessage, injectIntl} from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import "moment/locale/he"
import EnhancedTableHead from "../modules/Common/EnhancedTableHead"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { getOrderReports } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import OrderMealsList from "../modules/Reports/OrderReports/OrderMealsList"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

function OrderReportsPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function processReports(data) {
    return data.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  }

  function fetchReports(cancelToken) {
    const { customer, location, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      customer: customer.CustomerID
    }

    props.history.push({
      pathname: "/reports-order",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getOrderReports(
      customer.CustomerID,
      location || null,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: processReports(data),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true, "active")
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const customer = urlParams.get("customer")
        ? customerData.customers.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}
      setSearchParams({
        from,
        to,
        location,
        customer
      })
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
      searchParams.customer &&
      searchParams.customer.CustomerID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          searchParams.customer.Locations.find(
            el => el.LocationID === searchParams.location
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "REPORT.EXPORT_FILE_NAME"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          searchParams.customer.Name +
          "_" +
          locationName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {
    const rows = reportsData.reports;
    const quantity = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.Quantity,  0), 0)
    const toMinQuantity = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.ToMinQuantity,  0), 0)
    const refoundQuantity = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.RefoundQuantity,  0), 0)
    const netQuantity = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.NetQuantity,  0), 0)
    const mealCost = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.MealCost,  0), 0)
    const totalCost = rows.reduce((sum, it) => sum + it.Meals.reduce((sum, it) => sum += it.TotalCost,  0), 0)

    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Meals.forEach(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.DATE"
          })
        ] = moment(report.Date)
          .locale("he")
          .format("DD/MM/YYYY dddd")
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.MEAL_TYPE"
          })
        ] = getMealTypeText(intl, meal.MealType)
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = meal.Quantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TO_MIN_QUANTITY"
          })
        ] = meal.ToMinQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.REFOUND_QUANTITY"
          })
        ] = meal.RefoundQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.NET_QUANTITY"
          })
        ] = meal.NetQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.MEAL_COST"
          })
        ] = meal.MealCost
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TOTAL_COST"
          })
        ] = meal.TotalCost
        formattedReport.push(formattedMeal)
      })
    })

    // Summary
    const formattedMeal = {}
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.DATE"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = quantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.TO_MIN_QUANTITY"
        })
        ] = toMinQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.REFOUND_QUANTITY"
        })
        ] = refoundQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.NET_QUANTITY"
        })
        ] = netQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.MEAL_COST"
        })
        ] = mealCost
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
        ] = totalCost
    formattedReport.push(formattedMeal)


    return formattedReport
  }

  const totalNetQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.NetQuantity;
    }, 0);
  }, 0);

  const totalRefundQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.RefoundQuantity;
    }, 0);
  }, 0);

  const totalQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.Quantity;
    }, 0);
  }, 0);

  const totalCost = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.TotalCost;
    }, 0);
  }, 0);

  const mealCost = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.MealCost;
    }, 0);
  }, 0);

  const toMinCost = reportsData.reports.reduce((sum, item) => {
    return sum + item.Meals.reduce((sum2, itm) => {
      return sum2 + itm.ToMinQuantity;
    }, 0);
  }, 0);

  const headRows = [
    {
      id: "MealType",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.MEAL_TYPE" />
    },
    {
      id: "Quantity",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.QUANTITY" />
    },
    {
      id: "ToMinQuantity",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.TO_MIN_QUANTITY" />
    },
    {
      id: "RefoundQuantity",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
    },
    {
      id: "NetQuantity",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.NET_QUANTITY" />
    },
    {
      id: "MealCost",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.MEAL_COST" />
    },
    {
      id: "TotalCost",
      disablePadding: false,
      label: <FormattedMessage id="REPORT.TOTAL_COST" />
    }
  ]

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {
        <Table aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead headRows={headRows}/>
          <TableBody>
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            >
            <TableCell></TableCell>
            <TableCell>
              {totalQuantity}
            </TableCell>
            <TableCell>
              {toMinCost}
            </TableCell>
            <TableCell>
              {totalRefundQuantity}
            </TableCell>
            <TableCell>
              {totalNetQuantity}
            </TableCell>
            <TableCell>
              {mealCost}
            </TableCell>
            <TableCell>
              {totalCost}
            </TableCell>
         </TableRow>
        </TableBody>
        </Table>

      }
      {
        <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(day => (
          <Paper className={classes.paper} key={day.Date}>
            <Typography className={classes.heading}>
              {moment(day.Date)
                .locale("he")
                .format("DD/MM/YYYY dddd")}
            </Typography>
            <OrderMealsList mealsData={day} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
      }
      
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(OrderReportsPage))
)
