/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"


const headRows = [
    {
        id: "UserName",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "CostumerCreditAmount",
        disablePadding: false,
        notSortable: true,
        width: 8.5,
        label: ""
    },
    {
        id: "SupplierName",
        disablePadding: false,
        notSortable: true,
        width: 12,
        label: ""
    },
    {
        id: "ItemName",
        disablePadding: false,
        notSortable: true,
        width: 20,
        label: ""
    },
    {
        id: "Quantity",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.QUANTITY" />
    },
    {
        id: "Refound",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
    },
    {
        id: "Total",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.TOTAL" />
    },
    {
        id: "OrderToDate",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    }
]

function OrderItemsCreditSummary({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  const totalQuantity = reportsData.reduce((sum, report) => sum + report.Quantity, 0)
  const totalRefound = reportsData.reduce((sum, report) => sum + (report.Refound ? report.Quantity : 0), 0)
  const totalTotal = reportsData.reduce((sum, report) => sum + report.Total, 0)

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapperSummary}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.collapsibleRow}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>

              <TableCell>{totalQuantity}</TableCell>
              <TableCell>{totalRefound}</TableCell>
              <TableCell>{totalTotal}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsCreditSummary
