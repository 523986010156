/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../../Common/_styles/formDialogStyles"

function CreateItemOptionFormDialog({
  show,
  saveItemOption,
  closeDialog,
  itemOptions,
  optionGroups,
  itemOptionData,
  intl
}) {
  const classes = useStyles()

  let initialValues
  if (itemOptionData) {
    initialValues = {
      optionGroup: itemOptionData.OptionGroupID,
      label: itemOptionData.Label,
      isFree: itemOptionData.IsFree,
      isMandatory: itemOptionData.IsMandatory
    }
  } else {
    initialValues = {
      optionGroup: "",
      label: "",
      isFree: true,
      isMandatory: false
    }
  }

  function handleSumbit(option) {
    saveItemOption(option)
  }

  function handleClose() {
    closeDialog()
  }

  const optionGroupValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const labelValidationSchema = Yup.string()
    .test(
      "labelIsUnique",
      intl.formatMessage({
        id: "VALIDATION.LABEL_USED"
      }),
      value => {
        const option = itemOptions.find(option => option.Label === value)
        return (
          option === undefined ||
          (itemOptionData && option.Label === itemOptionData.Label)
        )
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const validationSchema = Yup.object().shape(
    {
      optionGroup: optionGroupValidationSchema,
      label: labelValidationSchema
    },
    ["optionGroup", "label"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const option = {
        OptionGroupID: values.optionGroup,
        Label: values.label,
        IsFree: values.isFree,
        IsMandatory: values.isMandatory
      }
      if (itemOptionData && itemOptionData.id) {
        option.id = itemOptionData.id
      }
      handleSumbit(option)
      resetForm()
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="ITEM_OPTION.ADD_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent className={classes.smallForm}>
            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("optionGroup")}
            >
              <InputLabel htmlFor="parent-select">
                <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.GROUP_NAME" />
              </InputLabel>
              <Select
                input={
                  <FilledInput name="optionGroup" id="option-group-select" />
                }
                value={formik.values.optionGroup}
                onChange={event => {
                  formik.setFieldValue("optionGroup", event.target.value)
                  formik.setFieldValue(
                    "label",
                    optionGroups.find(
                      optionGroup => optionGroup.id === event.target.value
                    ).Name
                  )
                }}
              >
                {optionGroups.map(optionGroup => (
                  <MenuItem key={optionGroup.id} value={optionGroup.id}>
                    {optionGroup.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderErrors("optionGroup")}
            <TextField
              id="label"
              name="label"
              label={intl.formatMessage({
                id: "OPTION.LABELS.DISPLAYNAME"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("label")}
              error={checkForError("label")}
            />
            {renderErrors("label")}
            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("isFree")}
            >
              <InputLabel htmlFor="is-free-select">
                <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.FREE" />
              </InputLabel>
              <Select
                {...formik.getFieldProps("isFree")}
                input={<FilledInput name="isFree" id="is-free-select" />}
              >
                <MenuItem value={true}>
                  <FormattedMessage id="OPTION.YES" />
                </MenuItem>
                <MenuItem value={false}>
                  <FormattedMessage id="OPTION.NO" />
                </MenuItem>
              </Select>
            </FormControl>
            {renderErrors("isFree")}
            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("isMandatory")}
            >
              <InputLabel htmlFor="is-Mandatory-select">
                <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.MANDATORY" />
              </InputLabel>
              <Select
                {...formik.getFieldProps("isMandatory")}
                input={
                  <FilledInput name="isMandatory" id="is-mandatory-select" />
                }
              >
                <MenuItem value={true}>
                  <FormattedMessage id="OPTION.YES" />
                </MenuItem>
                <MenuItem value={false}>
                  <FormattedMessage id="OPTION.NO" />
                </MenuItem>
              </Select>
            </FormControl>
            {renderErrors("isMandatory")}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreateItemOptionFormDialog)
