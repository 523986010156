/* eslint-disable no-restricted-imports */

import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import {FormattedMessage, injectIntl} from "react-intl"
import {he} from "date-fns/esm/locale"
import RefreshIcon from "@material-ui/icons/Refresh"
import {
    renderInput,
    getSuggestions,
    renderSuggestion
} from "../../Common/downshiftFunctions"
import Downshift from "downshift"
import {Paper} from "@material-ui/core"
import {useStyles} from "../../Common/_styles/orderFilterStyles"
import TextField from "@material-ui/core/TextField";

function FilterPanel({
                         onParamsChangeSave,
                         searchParams,
                         customerData,
                         locationsData,
                         priceCategoryData,
                         suppliersData,
                         intl
                     }) {
    const classes = useStyles()

    const handleSearchParamChange = name => data => {
        let value
        if (data.target) {
            value = data.target.value
        } else {
            value = data
        }
        onParamsChangeSave(
            ["location", "customer"].includes(name)
                ? {location: "", [name]: value,}
                : {
                    [name]: value
                }
        )
    }

    function handleRefresh(event) {
        event.preventDefault()
        onParamsChangeSave(searchParams)
    }

    return (
        <>
            <form
                className={classes.root}
                onSubmit={handleRefresh}
                autoComplete="off"
            >

                <Downshift
                    id="downshift-for-customer"
                    selectedItem={
                        searchParams.customer.Name ? searchParams.customer : null
                    }
                    onChange={selected => {
                        handleSearchParamChange("customer")(selected || {})
                    }}
                    itemToString={item => (item ? item.Name : "")}
                >
                    {({
                          clearSelection,
                          getInputProps,
                          getItemProps,
                          getLabelProps,
                          getMenuProps,
                          highlightedIndex,
                          inputValue,
                          isOpen,
                          openMenu,
                          selectedItem
                      }) => {
                        const {onChange, onBlur, onFocus, ...inputProps} = getInputProps({
                            onChange: event => {
                                if (event.target.value === "") {
                                    clearSelection()
                                }
                            },
                            onFocus: openMenu
                        })

                        return (
                            <div className={classes.container}>
                                {renderInput({
                                    fullWidth: true,
                                    classes,
                                    label: intl.formatMessage({
                                        id: "FILTER.CUSTOMER_FIELD.LABEL"
                                    }),
                                    nested: true,
                                    InputLabelProps: getLabelProps({
                                        shrink: true
                                    }),
                                    InputProps: {onBlur, onChange, onFocus},
                                    inputProps
                                })}

                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={classes.paper} square>
                                            {getSuggestions(inputValue, customerData.data, {
                                                showEmpty: true
                                            }).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({
                                                        item: suggestion
                                                    }),
                                                    highlightedIndex,
                                                    selectedItem
                                                })
                                            )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        )
                    }}
                </Downshift>

                <div className="position-relative">
                    {locationsData.isLoading && searchParams.customer.Name && (
                        <span
                            className={`ml-1 spinner spinner-black ${classes.spinner}`}
                        ></span>
                    )}
                    <FormControl
                        className={classes.formControl}
                        disabled={!searchParams.customer.Name || locationsData.isLoading}
                    >
                        <InputLabel htmlFor="location">
                            <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL"/>
                        </InputLabel>
                        <Select
                            value={searchParams.location}
                            onChange={handleSearchParamChange("location")}
                            inputProps={{
                                name: "location",
                                id: "location"
                            }}
                        >
                            {locationsData.data &&
                                locationsData.data.map(location => (
                                    <MenuItem
                                        key={location.LocationID}
                                        value={location.LocationID}
                                    >
                                        {location.Name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>

                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="mealType">
                        <FormattedMessage id="FILTER.MEAL_TYPE.LABEL"/>
                    </InputLabel>
                    <Select
                        value={searchParams.mealType}
                        onChange={handleSearchParamChange("mealType")}
                        inputProps={{
                            name: "mealType",
                            id: "mealType"
                        }}
                    >
                        <MenuItem value={1}>
                            <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST"/>
                        </MenuItem>
                        <MenuItem value={2}>
                            <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH"/>
                        </MenuItem>
                        <MenuItem value={3}>
                            <FormattedMessage id="MEAL_TYPE.VALUE.DINNER"/>
                        </MenuItem>
                        <MenuItem value={4}>
                            <FormattedMessage id="MEAL_TYPE.VALUE.STORE"/>
                        </MenuItem>
                    </Select>
                </FormControl>

                <Downshift
                    id="downshift-for-supplier"
                    selectedItem={
                        searchParams.supplier?.Name ? searchParams.supplier : null
                    }
                    onChange={selected => {
                        handleSearchParamChange("supplier")(selected || {})
                    }}
                    itemToString={item => (item ? item.Name : "")}
                >
                    {({
                          clearSelection,
                          getInputProps,
                          getItemProps,
                          getLabelProps,
                          getMenuProps,
                          highlightedIndex,
                          inputValue,
                          isOpen,
                          openMenu,
                          selectedItem
                      }) => {
                        const {onChange, onBlur, onFocus, ...inputProps} = getInputProps({
                            onChange: event => {
                                if (event.target.value === "") {
                                    clearSelection()
                                }
                            },
                            onFocus: openMenu
                        })

                        return (
                            <div className={classes.container}>
                                {renderInput({
                                    fullWidth: true,
                                    classes,
                                    label: intl.formatMessage({
                                        id: "FILTER.SUPPLIER_FIELD.LABEL"
                                    }),
                                    nested: true,
                                    InputLabelProps: getLabelProps({
                                        shrink: true
                                    }),
                                    InputProps: {onBlur, onChange, onFocus},
                                    inputProps
                                })}
                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={classes.paper} square>
                                            {getSuggestions(inputValue, suppliersData.data, {
                                                showEmpty: true
                                            }).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({
                                                        item: suggestion
                                                    }),
                                                    highlightedIndex,
                                                    selectedItem
                                                })
                                            )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        )
                    }}
                </Downshift>

                {/*<div className="position-relative">
                    {priceCategoryData.isLoading && (
                        <span
                            className={`ml-1 spinner spinner-black ${classes.spinner}`}
                        ></span>
                    )}
                    <FormControl
                        className={classes.formControl}
                        disabled={priceCategoryData.isLoading}
                    >
                        <InputLabel htmlFor="priceCategory">
                            <FormattedMessage id="FILTER.PRICE_CATEGORY_FIELD.LABEL"/>
                        </InputLabel>
                        <Select
                            value={searchParams.priceCategory}
                            onChange={handleSearchParamChange("priceCategory")}
                            inputProps={{
                                name: "priceCategory",
                                id: "priceCategory"
                            }}
                        >
                            <MenuItem>
                                <FormattedMessage id="FILTER.PRICE_CATEGORY_FIELD_ALL.LABEL"/>
                            </MenuItem>
                            {priceCategoryData.data &&
                                priceCategoryData.data.map(priceCategory => (
                                    <MenuItem
                                        key={priceCategory.Id}
                                        value={priceCategory.Id}
                                    >
                                        {priceCategory.Name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>*/}

                <TextField
                    id="standard-search"
                    label={intl.formatMessage({
                        id: "FILTER.SEARCH_FIELD.LABEL"
                    })}
                    onChange={(event) => {
                        handleSearchParamChange("text")(event.currentTarget.value || '')
                    }}
                    className={classes.textField}
                    value={searchParams.text}
                />

            </form>
        </>
    )
}

export default injectIntl(FilterPanel)
