import moment from "moment"

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY")
}

export function formatDateTime(date) {
  return moment(date).format("DD/MM/YYYY HH:mm")
}

export function formatDateForApi(date) {
  return moment(date).format("YYYY-MM-DD")
}
