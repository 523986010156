/* eslint-disable no-restricted-imports */

import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage, injectIntl } from "react-intl";
import EnhancedTableHead from "../../Common/EnhancedTableHead";
import BankTransferItem from "./BankTransferItem";
import { stableSort, getSorting } from "../../Common/tableSortingFunctions";
import DishLoader from "../../Common/DishLoader";
import { useStyles } from "../../Common/_styles/elementListStyles";
import { formatDate } from "../../Common/momentFunctions";
import { Checkbox } from "@material-ui/core";

const headRows = [
  {
    id: "actions",
    disablePadding: false,
    label: ''
  },
  {
    id: "fullName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.FULL_NAME" />
  },
  {
    id: "departmentName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DEPARTMENT_NAME" />
  },
  {
    id: "amount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.AMOUNT" />
  },
  {
    id: "transferStatus",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TRANSFER_STATUS" />
  },
  {
    id: "requestDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REQUEST_DATE" />
  },
  {
    id: "expectedTransferDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EXPECTED_TRANSFER_DATE" />
  },
  {
    id: "transferDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TRANSFER_DATE" />
  },
  {
    id: "id",
    disablePadding: false,
    label: ""
  },
];

function BankTransferList({ reportsData, loading, selectRows, intl }) {

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [sortBy, setSortBy] = useState("fullName");
  const [orderBy, setOrderBy] = useState("asc");

  useEffect(() => {
    if (reportsData.reports) {
      setRows(reportsData.reports.map(t => {
        return {
          ...t,
          selected: false,
        }
      }));
    }
  }, [reportsData.reports])

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc";
    setOrderBy(isDesc ? "asc" : "desc");
    setSortBy(property);
  }

  function setRowSettings(row) {
    row.selected = !row.selected;
    selectRows({
      id: row.id,
      selected: row.selected
    });
  }

  return (
    <Paper className={classes.paper}>
      {reportsData.isLoading && <DishLoader centered />}
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
               <BankTransferItem loading={loading} intl={intl} setRowSettings={setRowSettings} row={row}/>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

export default injectIntl(BankTransferList);
