/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { getRoleID } from "./rolesTranslation"
import { FormattedMessage, injectIntl } from "react-intl"
import LockIcon from "@material-ui/icons/Lock"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { Link } from "react-router-dom"
import AssignmentIcon from "@material-ui/icons/Assignment"
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { IconButton, Tooltip } from "@material-ui/core"
import DishLoader from "../Common/DishLoader"

const headRows = [
  {
    id: "Locked",
    disablePadding: true,
    notSortable: true,
    label: ""
  },
  {
    id: "FirstName",
    label: <FormattedMessage id="ADMIN_USERS.TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "Role",
    label: <FormattedMessage id="ADMIN_USERS.TABLE.HEAD_LABELS.ROLE" />
  },
  {
    id: "CustomerName",
    label: (
      <FormattedMessage id="ADMIN_USERS.TABLE.HEAD_LABELS.CUSTOMER_OR_SUPPLIER" />
    )
  },
  {
    id: "History",
    disablePadding: true,
    notSortable: true,
    label: ""
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    position: "relative"
  },
  tableWrapper: {
    maxHeight: 390,
    overflowY: "auto"
  }
}))

function UserList({
  selected,
  setSelected,
  paginationState,
  usersData,
  onChange,
  openWalletDialog,
  intl
}) {
  const classes = useStyles()
  const rows = usersData.displayedUsers
  const [emptyRows, setEmptyRows] = useState(0)

  useEffect(() => {
    if (paginationState) {
      let value =
        paginationState.rowsPerPage -
        Math.min(
          paginationState.rowsPerPage,
          usersData.usersCount -
            paginationState.page * paginationState.rowsPerPage
        )

      setEmptyRows(value)
    }
  }, [usersData.displayedUsers])

  if (usersData.usersCount === 0 && usersData.isLoading) {
    return <DishLoader />
  }

  function handleRequestSort(event, property) {
    const isDesc =
      paginationState.sortBy === property && paginationState.orderBy === "desc"
    const orderValue = isDesc ? "asc" : "desc"
    onChange({ orderBy: orderValue, sortBy: property })
  }

  function handleClick(event, id) {
    setSelected(id)
  }

  function handleChangePage(event, newPage) {
    onChange({ page: newPage })
  }

  function handleChangeRowsPerPage(event) {
    const rowsPerPageValue = +event.target.value
    onChange({ rowsPerPage: rowsPerPageValue })
  }

  function renderSupplierOrCustomerName(row) {
    if (row.SupplierID) {
      return row.Supplier ? row.Supplier.Name : ""
    }
    if (row.CustomerID) {
      return row.Customer ? row.Customer.Name : ""
    }
  }

  const isSelected = id => selected === id

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {usersData.isLoading && <DishLoader centered />}
        <div className={classes.tableWrapper}>
          <Table stickyHeader aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows}
              orderBy={paginationState.orderBy}
              sortBy={paginationState.sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.UserID)
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.UserID)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.UserID}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      align="center"
                      padding="none"
                      scope="row"
                    >
                      {row.IsLocked && <LockIcon />}
                    </TableCell>
                    <TableCell align="left">
                      {row.FirstName} {row.LastName}
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage id={getRoleID(row.Role)} />
                    </TableCell>
                    <TableCell align="left">
                      {renderSupplierOrCustomerName(row)}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={intl.formatMessage({
                          id: "TOOLTIP.ORDER_HISTORY"
                        })}
                      >
                        <Link
                          to={`/user/${row.UserID}/order-history`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AssignmentIcon />
                        </Link>
                      </Tooltip>
                      {row.Customer?.AllowWallet && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: "TOOLTIP.WALLET"
                          })}
                        >
                          <IconButton
                            type="button"
                            onClick={() => openWalletDialog(row.UserID)}
                            style={{"padding": 0}}
                          >
                            <AccountBalanceWalletIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={usersData.usersCount}
          rowsPerPage={paginationState.rowsPerPage}
          page={paginationState.page}
          labelRowsPerPage={intl.formatMessage({
            id: "TABLE.PAGINATION.ROWS_PER_PAGE"
          })}
          backIconButtonProps={{
            "aria-label": intl.formatMessage({
              id: "TABLE.PAGINATION.PREVIOUS_PAGE"
            })
          }}
          nextIconButtonProps={{
            "aria-label": intl.formatMessage({
              id: "TABLE.PAGINATION.NEXT_PAGE"
            })
          }}
          labelDisplayedRows={({ from, to, count }) =>
            intl.formatMessage(
              {
                id: "TABLE.PAGINATION.DISPLAYED_ROWS_LABEL"
              },
              {
                from,
                to,
                count
              }
            )
          }
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default injectIntl(UserList)
