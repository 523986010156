/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import { Checkbox, Button } from "@material-ui/core";
import EnhancedTableHead from "../Common/EnhancedTableHead";
import { stableSort, getSorting } from "../Common/tableSortingFunctions";
import { useStyles } from "../Common/_styles/elementListStyles";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { useFormik } from "formik"

const headRows = [
    {
        id: "Select",
        disablePadding: true,
        notSortable: true,
        notForReadOnly: true,
        label: ""
    },
    {
        id: "Image",
        disablePadding: false,
        notSortable: true,
        label: ""
    },
    {
        id: "ItemName",
        disablePadding: false,
        label: <FormattedMessage id="COMBO_ITEM_FORM.ITEM_FIELD.LABEL" />
    },
    {
        id: "Price",
        disablePadding: false,
        label: <FormattedMessage id="COMBO_ITEM_FORM.COST_PRICE.LABEL" />
    }
];




function MealItemList({ intl, items, selected, onToggle, onToggleAll }) {
    const classes = useStyles();
    const rows = items;
    const [sortBy, setSortBy] = useState("Name");
    const [orderBy, setOrderBy] = useState("asc");

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc";
        setOrderBy(isDesc ? "asc" : "desc");
        setSortBy(property);
    }


    return (
        <div>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <span className={classes.label}>
                        <FormattedMessage id="COMBO_ITEM_FORM.SELECTED.LABEL" />
                    </span>
                    <span>
                        {
                            selected.length
                        }
                    </span>
                </div>
                <div className={classes.tableWrapper}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <EnhancedTableHead
                            headRows={[
                                {
                                    ...headRows[0],
                                    label: (
                                        <Checkbox
                                            indeterminate={
                                                selected.length > 0 && selected.length < rows.length
                                            }
                                            style={{ padding: 0 }}
                                            checked={selected.length === rows.length}
                                            onChange={event => {
                                                onToggleAll(event.target.checked);
                                            }}
                                        />
                                    )
                                },
                                ...headRows.slice(1)
                            ]}
                            orderBy={orderBy}
                            sortBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(orderBy, sortBy)).map(
                                (row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.ItemID}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                align="left"
                                                width={24}
                                                padding="none"
                                            >
                                                <Checkbox
                                                    checked={selected.includes(row.ItemID)}
                                                    style={{ padding: 0 }}
                                                    onChange={event => {
                                                        onToggle(event.target.checked, row.ItemID);
                                                    }}
                                                    color="secondary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {row.Image && row.Image.length !== 0 && (
                                                    <img
                                                        src={row.Image[0].URL}
                                                        alt="Item"
                                                        className={classes.uploadedItemIconDisplay}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="left">{row.Name}</TableCell>
                                            <TableCell align="left">{Math.round(row.Price * 100) / 100}</TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}

export default MealItemList;
