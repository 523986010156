/* eslint-disable no-restricted-imports */
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ConfirmationDialog from "../../../modules/Common/ConfirmationDialog";
import MealAllItemUpdateDialog from "./MealAllItemUpdateDialog";
import MealSelectorDialog from "./MealSelectorDialog";
import MealUpdateDetailsDialog from "./MealUpdateDetailsDialog";
import AddMealComboFormDialog from "./AddMealComboFormDialog";

function MealSelectorDialogContainer({
  suppliers,
  priceCategoryData,
  addItems,
  updateItems,
  mealTypeIndex,
  applyChanges,
  takenItemsIDs,
  addItemAvailable,
  deleteItemAvailable,
  updateItemAvailable,
  saveItemAvailable,
  deleteItems,
  updateBatchItems,
  addComboAvailable,
  addCombos,
  intl
}) {
  const [showAddMealItemFormDialog, setShowAddMealItemFormDialog] = useState(
    false
  );
  const [showAddMealComboFormDialog, setShowAddMealComboFormDialog] = useState(
    false
  );
  const [showUpdateForAllFormDialog, setShowUpdateForAllFormDialog] = useState(
      false
  );

  const [showUpdateFormDialog, setUpdateFormDialog] = useState(false);

  const [
    showDeleteConfirmationFormDialog,
    setDeleteConfirmationFormDialog
  ] = useState(false);

  const updateBatchItemsHandler = (data, callback) => {
    updateBatchItems(data);
  }

  return (
    <>
      <div className="container">
        {/* <Button
            id="search_params_submit"
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            dir="ltr"
            className={`font-weight-bold my-3 ml-10`}
            onClick={() => setShowUpdateForAllFormDialog(true)}
        >
          <span>
            {intl.formatMessage({
              id: "MEAL_ITEMS.UPDATE_FOR_ALL_FIELD.LABEL"
            })}
          </span>
        </Button> */}
        <Button
          id="search_params_submit_0"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!addComboAvailable}
          onClick={() => setShowAddMealComboFormDialog(true)}
        >
          <span>
            {intl.formatMessage({
              id: "ADD_MEAL_COMBO_FORM.TITLE"
            })}
          </span>
        </Button>
        <Button
          id="search_params_submit_1"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!addItemAvailable}
          onClick={() => setShowAddMealItemFormDialog(true)}
        >
          <span>
            {intl.formatMessage({
              id: "MEAL_ITEMS.ADD_ITEM_FIELD.LABEL"
            })}
          </span>
        </Button>
        <Button
          id="search_params_submit_2"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!updateItemAvailable}
          onClick={() => setUpdateFormDialog(true)}
        >
          <span>
            {intl.formatMessage({
              id: "MEAL_ITEMS.UPDATE_FIELD.LABEL"
            })}
          </span>
        </Button>
        <Button
          id="search_params_submit_3"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!deleteItemAvailable}
          onClick={() => setDeleteConfirmationFormDialog(true)}
        >
          <span>
            {intl.formatMessage({
              id: "MEAL_ITEMS.DELETE_FIELD.LABEL"
            })}
          </span>
        </Button>
        <Button
          id="search_params_submit_4"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!saveItemAvailable}
          onClick={() => applyChanges()}
        >
          <span>
            {intl.formatMessage({
              id: "MEAL_ITEMS.SAVE_FIELD.LABEL"
            })}
          </span>
        </Button>
      </div>
      <div>
        <MealAllItemUpdateDialog
            show={showUpdateForAllFormDialog}
            closeDialog={() => setShowUpdateForAllFormDialog(false)}
            submitData={updateBatchItemsHandler}
            suppliers={suppliers || []}
            priceCategoryData={priceCategoryData.data || []}
            mealTypeIndex={mealTypeIndex}
        />
        <MealSelectorDialog
          show={showAddMealItemFormDialog}
          closeDialog={() => setShowAddMealItemFormDialog(false)}
          submitData={addItems}
          suppliers={suppliers.filter(s => s.State !== "archive") || []}
          priceCategoryData={priceCategoryData.data || []}
          takenItemsIDs={takenItemsIDs}
          mealTypeIndex={mealTypeIndex}
        />
        <AddMealComboFormDialog
          show={showAddMealComboFormDialog}
          closeDialog={() => setShowAddMealComboFormDialog(false)}
          submitData={addCombos}
          suppliers={suppliers.filter(s => s.State !== "archive") || []}
          priceCategoryData={priceCategoryData.data || []}
          takenCombosIDs={takenItemsIDs}
          mealTypeIndex={mealTypeIndex}
        />
        <ConfirmationDialog
          show={showDeleteConfirmationFormDialog}
          dialogTitle={<FormattedMessage id="DIALOG.DELETE.TITLE" />}
          contentText={intl.formatMessage({
            id: "DIALOG.DELETE.TEXT"
          })}
          onClose={() => setDeleteConfirmationFormDialog(false)}
          onSubmit={() => {
              deleteItems();
              setDeleteConfirmationFormDialog(false);
          }}
        />
        <MealUpdateDetailsDialog
          show={showUpdateFormDialog}
          closeDialog={() => setUpdateFormDialog(false)}
          handleSumbit={updateItems}
          intl={intl}
        />
      </div>
    </>
  );
}

export default injectIntl(MealSelectorDialogContainer);
