/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "./FilterPanel"
import { FormattedMessage, injectIntl } from "react-intl"
import moment from "moment"
import OrdersList from "./OrdersList"
import { renderStatus } from "../../Common/itemStatusTranslation"
import * as snackbarRedux from "../../../../redux/snackbar/snackbarRedux"
import { connect } from "react-redux"
import ExportButton from "../../Reports/ExportButton"
import { Button } from "@material-ui/core"
import { renderBoolean } from "./OrdersList"
import { getOrders } from "./_axios/ordersCrud"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import { formatDateForApi, formatDateTime } from "../../Common/momentFunctions"
import { getSuppliers } from "../../Suppliers/_axios/supplierCrud"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import { markOrderReady } from "../../Suppliers/Orders/_axios/ordersCrud"
import { getCustomerLocations } from "../_axios/customerCrud"
import { useSelector } from "react-redux"

function OrdersPanel({ intl, selectedCustomer, ...props }) {
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.auth.user.Role)
  const [ordersData, setOrdersData] = useState({
    data: { Date: null, Orders: [] },
    isLoading: false
  })
  const [deliveryTimesData, setDeliveryTimesData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [locationsData, setLocationsData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [suppliersData, setSuppliersData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = {
      ...searchParams,
      ...changes,
      isDateToday: moment(changes.date).isSame(new Date(), "day")
    }
    setSearchParams(newParams)
  }

  function fetchOrders(cancelToken) {
    const { mealType, date, supplier, location, deliveryTime, deliveryTimeValue } = searchParams

    const params = {
      tab: "orders",
      mealType,
      deliveryTime,
      deliveryTimeValue,
      date: formatDateForApi(date),
      supplier: supplier.SupplierID || "",
      location
    }

    localStorage.setItem("mealType", mealType)

    props.history.push({
      pathname: `/customer/${selectedCustomer.CustomerID}`,
      search: "?" + new URLSearchParams(params).toString()
    })
    setOrdersData({ ...ordersData, isLoading: true })
    getOrders(
      selectedCustomer.CustomerID,
      mealType,
      params.date,
      params.supplier,
      location,
      deliveryTime,
      cancelToken.token
    )
      .then(({ data }) => {
        setOrdersData({
          data: {
            ...data,
            Orders: data.Orders.filter(el =>
              searchParams.isDateToday
                ? (el.Status !== 2 ||
                    moment(el.CloseCancelTime).isBefore(el.StatusUpdated)) &&
                  el.Status !== 3
                : ![2, 3].includes(el.Status)
            ).map(el => {
              el.DeliveryTimeFrom = el.DeliveryTime?.From;
              return el;
            })
          },
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ORDERS_DATA"
          })
        )
      )
  }

  function fetchLocations(cancelToken) {
    setLocationsData({ ...locationsData, isLoading: true })
    getCustomerLocations(selectedCustomer.CustomerID, cancelToken.token)
      .then(({ data }) => {
        setLocationsData({
          data: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
          })
        )
      )
  }

  function handleMarkAllReady() {
    const ids = ordersData.data.Orders.map(el => el.OrderItemID)
    const successIds = []

    const promises = []
    ids.forEach(el => promises.push(markOrderReady(el)))
    async function sendRequests() {
      const result = await Promise.allSettled(promises)
      result.forEach((el, index) => {
        if (el.status === "fulfilled") {
          successIds.push(ids[index])
        }
      })

      setOrdersData({
        data: {
          ...ordersData.data,
          Orders: ordersData.data.Orders.map(el => ({
            ...el,
            IsReady: successIds.includes(el.OrderItemID) ? true : el.IsReady
          }))
        },
        isLoading: false
      })
    }
    sendRequests()
  }

  function handleMarkReady(id) {
    markOrderReady(id)
      .then(() => {
        const orders = [...ordersData.data.Orders]
        const changedItem = orders.find(el => el.OrderItemID === id)
        changedItem.IsReady = true
        setOrdersData({
          data: { ...ordersData.data, Orders: orders },
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_UPDATE_ORDER_DATA"
          })
        )
      )
  }

  function fetchSuppliers(cancelToken) {
    setSuppliersData({ ...suppliersData, isLoading: true })
    getSuppliers(cancelToken.token, "active")
      .then(({ data }) => {
        setSuppliersData({
          data: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    fetchLocations(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!suppliersData.notRequested && !locationsData.notRequested) {
      const urlParams = new URLSearchParams(window.location.search)

      const areParamsPresent = urlParams.get("tab") === "orders"

      let initialParams = {}
      if (areParamsPresent) {
        initialParams = {
          deliveryTime: urlParams.get('deliveryTime') || '',
          deliveryTimeValue:  urlParams.get('deliveryTimeValue') || '',
          mealType:
            +urlParams.get("mealType") || localStorage.getItem("mealType") || 2,
          date: urlParams.get("date")
            ? new Date(urlParams.get("date"))
            : new Date(),
          supplier:
            suppliersData.data.find(
              supplier => supplier.SupplierID === urlParams.get("supplier")
            ) || "",
          location: locationsData.data.length === 1 ? locationsData.data[0] :
              (locationsData.data.find(
              location => location.LocationID === urlParams.get("location")
            ) || "")
        }
        initialParams.isDateToday = moment(initialParams.date).isSame(
          new Date(),
          "day"
        )
      } else {
        initialParams = {
          mealType: localStorage.getItem("mealType") || 2,
          deliveryTime: '',
          deliveryTimeValue: '',
          date: new Date(),
          isDateToday: true,
          supplier: "",
          location: locationsData.data.length === 1 ? locationsData.data[0].LocationID : ''
        }
      }

      setSearchParams(initialParams)
    }
  }, [suppliersData.notRequested, locationsData.notRequested])

  useEffect(() => {
    if (searchParams.mealType && searchParams.date) {
      const customerName = selectedCustomer
        ? intl.formatMessage({
            id: "EXPORT_FILE_CUSTOMER"
          }) +
          "_" +
          selectedCustomer?.Name +
          "_"
        : ""

      const supplierName = searchParams.supplier
        ? intl.formatMessage({
            id: "EXPORT_FILE_SUPPLIER"
          }) +
          "_" +
          searchParams.supplier.Name +
          "_"
        : ""

      const currentLocation = locationsData.data.find(
        location => location.LocationID === searchParams.location
      )
      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "ORDERS_TABLE.EXPORT_FILE_LOCATION"
          }) +
          "_" +
          currentLocation?.Name +
          "_"
        : ""

      setExportFileName(
        `${intl.formatMessage({
          id: "ORDERS_TABLE.EXPORT_FILE_NAME"
        })}_${customerName}${locationName}${supplierName}${intl.formatMessage({
          id: "ORDERS_TABLE.EXPORT_FILE_MEAL"
        })}_${getMealTypeText(intl, searchParams.mealType) || ""}_${moment(
          searchParams.date
        ).format("DD-MM-YYYY")}`
      )

      const cancelToken = axios.CancelToken.source()
      const delay = setTimeout(() => fetchOrders(cancelToken), 300)
      const interval = setInterval(() => fetchOrders(cancelToken), 600000)
      return () => {
        cancelToken.cancel()
        clearTimeout(delay)
        clearInterval(interval)
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (searchParams?.location) {
      const currentLocation = locationsData.data.find(
          location => location.LocationID === searchParams.location
      )
      if (currentLocation) {
        const mealTypeItem =
            currentLocation.MealsInfo.find(i => +i.MealType === +searchParams.mealType);
        if (mealTypeItem) {
          setDeliveryTimesData({
            ...deliveryTimesData,
            data: mealTypeItem.MealDeliveryTimes
          })
          setSearchParams({
            ...searchParams,
            deliveryTime: mealTypeItem.MealDeliveryTimes?.length === 1 ?
                mealTypeItem.MealDeliveryTimes[0].Id : ''
          });
        } else {
          setDeliveryTimesData({
            ...deliveryTimesData,
            data: []
          })
          setSearchParams({
            ...searchParams,
            deliveryTime: '',
            deliveryTimeValue: ''
          });
        }
      } else {
        setDeliveryTimesData({
          ...deliveryTimesData,
          data: []
        })
        setSearchParams({
          ...searchParams,
          deliveryTime: '',
          deliveryTimeValue: ''
        });
      }
    } else {
      setDeliveryTimesData({
        ...deliveryTimesData,
        data: []
      })
      setSearchParams({
        ...searchParams,
        deliveryTime: '',
        deliveryTimeValue: ''
      });
    }
  }, [searchParams?.location])

  function getExportData() {
    const optionGroups = []
    ordersData.data.Orders.forEach(order =>
      order.OptionGroups.forEach(optionGroup => {
        if (!optionGroups.find(el => el.GroupName === optionGroup.GroupName)) {
          optionGroups.push(optionGroup)
        }
      })
    )
    const quantity = ordersData.data.Orders.reduce((s, t) => s += t.Quantity, 0)
    const items = ordersData.data.Orders.sort((a, b) => {
      if (a.SupplierName < b.SupplierName) {
        return -1
      }
      if (a.SupplierName > b.SupplierName) {
        return 1
      }
      return 0
    }).map(order => {
      const formattedOrder = {}
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.SUPPLIER_NAME"
        })
      ] = order.SupplierName
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.LOCATION_NAME"
        })
      ] = order.Location.Name
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.DEPARTMENT_NAME"
        })
      ] = order.DepartmentName
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.USER_NAME"
        })
      ] = order.UserName
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.USER_MOBILE"
        })
      ] = order.UserMobile
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.ITEM_NAME"
        })
      ] = order.ItemName
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.ITEM_QUANTITY"
        })
      ] = order.Quantity
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.ORDER_DATE"
        })
      ] = formatDateTime(order.OrderCreated)
      optionGroups.forEach(optionGroup => {
        const optionData = order.OptionGroups.find(
          el => el.GroupName === optionGroup.GroupName
        )
        formattedOrder[optionGroup.GroupName] = optionData
          ? optionData.SelectedOptios
          : ""
      })
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.COMMENTS"
        })
      ] = order.Notes
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.IS_CLOSED"
        })
      ] = renderBoolean(intl, order.IsClose)
      formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.IS_READY"
        })
      ] = renderBoolean(intl, order.IsReady)
      if (searchParams.isDateToday) {
        formattedOrder[
          intl.formatMessage({
            id: "ORDERS_TABLE.LABELS.STATUS"
          })
        ] = renderStatus(
          intl,
          order.Status,
          moment()
            .startOf("day")
            .subtract(6, "hours")
            .isBefore(order.StatusUpdated)
        )
      }
      formattedOrder[
          intl.formatMessage({
            id: "ORDERS_TABLE.LABELS.DELIVERY_DATE_AND_TIME"
          })
          ] = `${moment(order.OrderToDate).format("DD/MM/YYYY ") +
            (order.DeliveryTime?.From ? moment('2020-10-10 ' + order.DeliveryTime?.From).format("HH:mm") : '')}`
      return formattedOrder
    })


    const formattedOrder = {}
    formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.SUPPLIER_NAME"
        })
        ] =  intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedOrder[
        intl.formatMessage({
          id: "ORDERS_TABLE.LABELS.ITEM_QUANTITY"
        })
        ] = quantity
    items.push(formattedOrder);

    return items;
  }

  return (
    <>
      {searchParams.mealType && (
        <FilterPanel
          onParamsChangeSave={handleSearchParamsSave}
          locationsData={locationsData.data}
          suppliersData={suppliersData}
          deliveryTimesData={deliveryTimesData.data}
          userRole={userRole}
          searchParams={searchParams}
          itemsTotal={ordersData.data.Orders.reduce(
            (sum, order) => (![2, 3].includes(order.Status) ? sum + order.Quantity : sum),
            0
          )}
        />
      )}
      <div className="mt-3">
        {ordersData.data.Orders.length !== 0 && (
          <div className="d-flex justify-content-end">
            <ExportButton
              exportData={getExportData()}
              fileName={exportFileName}
            />
          </div>
        )}
        <OrdersList
          ordersData={ordersData}
          markReady={handleMarkReady}
          isDateToday={searchParams.isDateToday}
        />
      </div>
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(OrdersPanel))
)
