import React, { useEffect, useState } from "react"
import { dayCollection } from "../../../Common/dayCollection"
import {
  makeStyles,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  TextField,
  TableRow,
  TableCell,
  Collapse,
  FormControl,
  Select,
  FilledInput,
  MenuItem,
  Button
} from "@material-ui/core"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { formatDate } from "../../../Common/momentFunctions"
import { FormattedMessage, injectIntl } from "react-intl"
import { getMealsOptions, saveMealsOptions } from "../../_axios/customerCrud"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import axios from "axios"
import DishLoader from "../../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../../redux/snackbar/snackbarHandlers"

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 0,
    minWidth: 110
  },
  optionGroupField: {
    margin: 0,
    minWidth: 90
  },
  priorityField: {
    margin: 0,
    width: 80
  },
  input: {
    padding: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(0, 1)
  },
  iconDisplay: {
    maxHeight: 100,
    maxWidth: 174,
    width: "100%"
  },
  collapsibleRow: {
    "& > *": {
      borderBottom: "unset"
    }
  }
}))

function MealItem({
  locationID,
  mealID,
  row,
  isSelected,
  onSelect,
  onItemFieldChange,
  onDaysChange,
  setDateRangeDialogState,
  intl
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [mealOptionsData, setMealOptionsData] = useState({
    data: [],
    isLoading: false
  })

  function fetchMealsOptions(locationID, mealID, itemID, cancelToken) {
    setMealOptionsData({ ...mealOptionsData, isLoading: true })
    getMealsOptions(locationID, mealID, itemID, cancelToken.token)
      .then(({ data }) => {
        setMealOptionsData({
          data: row.Item.OptionGroups.map(optionGroup => {
            const mealOption = data.find(el => optionGroup.Lable === el.Label)
            return (
              mealOption || {
                optionGroupId: optionGroup.id,
                Label: optionGroup.Lable,
                Type: optionGroup.IsFree ? 1 : 2,
                Order: optionGroup.Order,
                MealItemId: row.id
              }
            )
          }),
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_MEAL_OPTIONS"
          })
        )
      )
  }

  function handleChangeOptionGroupType(event, label) {
    const value = event.target.value
    const changedOptionGroups = [...mealOptionsData.data]
    const changedOptionGroup = changedOptionGroups.find(
      optionGroup => optionGroup.Label === label
    )
    changedOptionGroup.Type = value

    setMealOptionsData({ ...mealOptionsData, data: changedOptionGroups })
  }

  function handleSubmit() {
    setIsSubmitting(true)
    saveMealsOptions(locationID, mealID, row.id, {
      options: mealOptionsData.data
    })
      .then(() => {
        setIsSubmitting(false)
        setOpen(false)
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_UPDATE_MEAL_OPTIONS_DATA"
          })
        )
      )
  }

  useEffect(() => {
    if (open) {
      const cancelToken = axios.CancelToken.source()
      fetchMealsOptions(locationID, mealID, row.id, cancelToken)
      return () => cancelToken.cancel()
    }
  }, [open])

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.Item.ItemID}
        className={classes.collapsibleRow}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          width={24}
          padding="none"
        >
          <Checkbox
            checked={isSelected}
            style={{ padding: 12 }}
            onChange={() => onSelect(row.Item.ItemID)}
            color="secondary"
          />
          <Tooltip
            title={intl.formatMessage({
              id: "OPTION.SEE_OPTION_GROUPS.TOOLTIP"
            })}
          >
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <img
            src={
              row.Item.Image && row.Item.Image.length !== 0
                ? row.Item.Image[0].URL
                : ""
            }
            onError={e => {
              e.target.onerror = null
              e.target.src = toAbsoluteUrl(`/media/svg/image_filler_icon.svg`)
            }}
            alt="Item"
            className={classes.iconDisplay}
          />
        </TableCell>
        <TableCell align="left">{row.Item.Name}</TableCell>
        <TableCell align="left">
          <img src={row.Item.ImageUrl} className={classes.iconDisplay}/>
        </TableCell>
        <TableCell align="left">{row.Item.SupplierName}</TableCell>
        <TableCell align="left">
          <TextField
            className={classes.priorityField}
            margin="normal"
            variant="filled"
            type="number"
            hiddenLabel
            inputProps={{
              min: 0,
              className: classes.input
            }}
            fullWidth
            value={row.Priority}
            onChange={event => onItemFieldChange(event, "Priority", row.Item.ItemID)}
          />
        </TableCell>
        <TableCell align="left">
          {dayCollection.map(day => (
            <FormControlLabel
              key={day.value}
              style={{ margin: 0 }}
              control={
                <Checkbox
                  checked={row.Days.includes(day.value)}
                  disabled={
                    row.Item.AvilabiltyDays.length > 0
                      ? !row.Item.AvilabiltyDays.includes(day.value)
                      : false
                  }
                  style={{ padding: 0 }}
                  onChange={event =>
                    onDaysChange(event, row.Item.ItemID, day.value)
                  }
                  color="secondary"
                />
              }
              label={day.label}
              labelPlacement="end"
            />
          ))}
        </TableCell>
        <TableCell align="left" width={180}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <TextField
              label={intl.formatMessage({
                id: "OPTION.LABELS.CLIENT_PRICE"
              })}
              margin="normal"
              variant="filled"
              type="number"
              inputProps={{
                min: 0
              }}
              fullWidth
              value={row.ClientPrice}
              onChange={event => onItemFieldChange(event, "ClientPrice", row.Item.ItemID)}
            />
            <TextField
              label={intl.formatMessage({
                id: "OPTION.LABELS.CUSTOMER_PRICE"
              })}
              margin="normal"
              variant="filled"
              type="number"
              inputProps={{
                min: 0
              }}
              fullWidth
              value={row.CustomerPrice}
              onChange={event => onItemFieldChange(event, "CustomerPrice", row.Item.ItemID)}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="position-relative" style={{ minHeight: 30 }}>
              {mealOptionsData.isLoading ? (
                <DishLoader height={30} minHeight={30} />
              ) : (
                mealOptionsData.data.length !== 0 && (
                  <div className="row">
                    <div className="col-5">
                      {mealOptionsData.data.map(optionGroup => (
                        <div className="row" key={optionGroup.Label}>
                          <div className="col-8 d-flex justify-content-end align-items-center pr-0 text-right">
                            {optionGroup.Label}
                          </div>
                          <div className="col-4 pl-3 pt-3 pb-3">
                            <FormControl
                              variant="filled"
                              className={classes.optionGroupField}
                              fullWidth
                            >
                              <Select
                                value={optionGroup.Type}
                                onChange={event =>
                                  handleChangeOptionGroupType(
                                    event,
                                    optionGroup.Label
                                  )
                                }
                                input={
                                  <FilledInput
                                    name="optionGroupStatus"
                                    inputProps={{
                                      className: classes.input
                                    }}
                                  />
                                }
                              >
                                <MenuItem value={0}>
                                  <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.HIDDEN" />
                                </MenuItem>
                                <MenuItem value={1}>
                                  <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.FREE" />
                                </MenuItem>
                                <MenuItem value={2}>
                                  <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.PAID" />
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-2 d-flex justify-content-start align-items-center pr-0 text-left">
                      <Button
                        variant="contained"
                        type="button"
                        size="large"
                        color="secondary"
                        className={classes.button}
                        onClick={() => handleSubmit()}
                        disabled={isSubmitting}
                      >
                        <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                        {isSubmitting && (
                          <span className="ml-1 spinner spinner-white"></span>
                        )}
                      </Button>
                    </div>
                    <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                      <FormattedMessage id="MEAL_ITEM.LABELS.AVAILABILITY" />
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <TextField
                          placeholder={intl.formatMessage({
                            id: "DATE_FIELD.FROM"
                          })}
                          className={classes.textField}
                          inputProps={{
                            className: classes.input,
                            readOnly: true
                          }}
                          margin="normal"
                          variant="filled"
                          fullWidth
                          value={row.From ? formatDate(row.From) : ""}
                          onClick={() =>
                            setDateRangeDialogState({
                              show: true,
                              mealItemID: row.Item.ItemID,
                              maxDate: row.Item.AvilableTo
                                ? new Date(`${row.Item.AvilableTo}.000Z`)
                                : null,
                              minDate: row.Item.AvilableFrom
                                ? new Date(`${row.Item.AvilableFrom}.000Z`)
                                : null,
                              from: row.From,
                              to: row.To
                            })
                          }
                        />
                        <div className={classes.divider}>-</div>
                        <TextField
                          placeholder={intl.formatMessage({
                            id: "DATE_FIELD.TO"
                          })}
                          className={classes.textField}
                          inputProps={{
                            className: classes.input,
                            readOnly: true
                          }}
                          margin="normal"
                          variant="filled"
                          fullWidth
                          value={row.To ? formatDate(row.To) : ""}
                          onClick={() =>
                            setDateRangeDialogState({
                              show: true,
                              mealItemID: row.Item.ItemID,
                              maxDate: row.Item.AvilableTo
                                ? new Date(`${row.Item.AvilableTo}.000Z`)
                                : null,
                              minDate: row.Item.AvilableFrom
                                ? new Date(`${row.Item.AvilableFrom}.000Z`)
                                : null,
                              from: row.From,
                              to: row.To
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default injectIntl(MealItem)
