/* eslint-disable no-restricted-imports */

import React, { useState } from "react";
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../Common/_styles/elementListStyles"
import { formatDate } from "../Common/momentFunctions"

const headRows = [
  {
    id: "date",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.DATE" />
  },
  {
    id: "description",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.DESCRIPTION" />
  },
  {
    id: "amount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.AMOUNT" />
  },
  {
    id: "balance",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.BALANCE" />
  }
]

function TransactionsList({ itemsData, intl, type }) {
  const classes = useStyles()
  const rows = itemsData.data.sort((a, b) => a.date > b.date ? 1 : -1)
  const [sortBy, setSortBy] = useState("from")
  const [orderBy, setOrderBy] = useState("desc")
  function handleRequestSort(event, property) {

  }



  return (
    <div className={classes.tableWrapper}>


      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.DATE" /></TableCell>
            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.DESCRIPTION" /></TableCell>
            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.AMOUNT" /></TableCell>
            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.BALANCE" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
            >

              <TableCell align="left">{formatDate(row.orderToDate || row.createdDate)}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left"><span className={classes.priceDefinition}>{row.amount}₪</span></TableCell>
              <TableCell align="left"><span className={classes.priceDefinition}>{row.balance}₪</span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </div>
  )
}

export default injectIntl(TransactionsList)
