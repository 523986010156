/* eslint-disable no-restricted-imports */

import React, {useState} from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import {FormattedMessage, injectIntl} from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import {stableSort, getSorting} from "../../Common/tableSortingFunctions"
import {Button} from "@material-ui/core"
import {formatDate} from "../../Common/momentFunctions"
import DishLoader from "../../Common/DishLoader"
import {useStyles} from "../../Common/_styles/elementListStyles"

function paymentStatus(statusValue) {
    switch (statusValue) {
        case 1: {
            return <FormattedMessage id="REPORT.STATUS.ACTIVE"/>
        }
        case 2: {
            return <FormattedMessage id="REPORT.STATUS.CANCELLED"/>
        }
    }
}

const headRows = [
    {
        id: "CustomerName",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.CUSTOMER"/>
    },
    {
        id: "UserName",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.USER_NAME"/>
    },
    {
        id: "Amount",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.AMOUNT"/>
    },
    {
        id: "ApprovalNumber",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.APPROVAL_NUMBER"/>
    },
    {
        id: "LastCCDigits",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.LAST_CREDIT_CARD_DIGITS"/>
    },
    {
        id: "Date",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.ORDER_DATE"/>
    },
    {
        id: "OrderToDate",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="REPORT.ORDER_TO_DATE" />
    },
    {
        id: "SupplierName",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.SUPPLIER_NAME"/>
    },
    {
        id: "ItemName",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="REPORT.ITEM_NAME"/>,
    },
    {
        id: "Quantity",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="REPORT.ITEM_QUANTITY" />,
        join: 2
    },
    //{
    //    id: "ItemRefundedCount",
    //    disablePadding: false,
    //    notSortable: true,
    //    label: <FormattedMessage id="REPORT.ITEM_REFUNDED_COUNT"/>,
    //},
    //{
    //    id: "ItemCancelledCount",
    //    disablePadding: false,
    //    notSortable: true,
    //    label: <FormattedMessage id="REPORT.ITEM_CANCELLED_COUNT"/>,
    //},
    //{
    //    id: "ItemTotal",
    //    disablePadding: false,
    //    notSortable: true,
    //    label: <FormattedMessage id="REPORT.ITEM_TOTAL_COUNT"/>,
    //},
    {
        id: "Invoice",
        disablePadding: false,
        notSortable: true,
        label: ""
    },
    {
        id: "CancelledInvoice",
        disablePadding: false,
        notSortable: true,
        label: ""
    }
]

function ReportsList({reportsData, intl}) {
    const classes = useStyles()

    const amount = reportsData.reports.reduce((sum, item) => sum += item.Amount, 0)
    const quantity = reportsData.reports.reduce((sum, item) => sum += item.Items.reduce((s, i) => s + i.quantity, 0), 0)
    const quantityRefunded = reportsData.reports.reduce((sum, item) => sum += item.Items.filter(t => !!t.RefundType).reduce((s, i) => s + i.quantity, 0), 0)
    const quantityCancelled = reportsData.reports.reduce((sum, item) => sum += item.Items.filter(t => t.Status === 2).reduce((s, i) => s + i.quantity, 0), 0)
    const total = quantity - quantityCancelled - quantityRefunded;

    const rows = reportsData.reports
    const [sortBy, setSortBy] = useState("Date")
    const [orderBy, setOrderBy] = useState("asc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    return (
        <Paper className={classes.paper}>
            {reportsData.isLoading && <DishLoader centered/>}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {
                            rows?.length > 0 && (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    className={classes.summary}
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row" align="left">
                                        <FormattedMessage id="REPORT.TOTAL" />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {amount}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {quantity}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )
                        }
                        {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                            const items = Object.keys(row.ItemStatistics);
                            const refundCount = row.Items.filter(it => it.RefundReason).length
                            const cancelledCount = row.Items.filter(it => it.Status === 2).length
                            const total = row.Items.length - cancelledCount - refundCount;
                            if (row.Items.filter(it => it.RefundReason).length > 0) {
                                console.log(row)
                            }
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={`${row.CustomerName}-${index}`}
                                >
                                    <TableCell component="th" scope="row" align="left">
                                        {row.CustomerName}
                                    </TableCell>
                                    <TableCell>{row.UserName}</TableCell>
                                    <TableCell>{row.Amount}</TableCell>
                                    <TableCell>{row.ApprovalNumber}</TableCell>
                                    <TableCell>{row.LastCCDigits}</TableCell>
                                    <TableCell>{formatDate(row.Date)}</TableCell>
                                    <TableCell>
                                        {
                                            row.ItemStatistics && (
                                                items.map((item, index) => {
                                                    const date = row.ItemStatistics[item][0].OrderToDate;
                                                    return (
                                                        <div key={`${item}-${index}`}>
                                                            <span>{formatDate(date)}</span>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>{row.SupplierName}</TableCell>
                                    <TableCell>
                                        {row.ItemStatistics &&
                                            (
                                                items.map((item, index) => (
                                                    <div key={`${item}-${index}`}>
                                                        <span>{item}</span>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {row.ItemStatistics &&
                                            (
                                                items.map((item, index) => {
                                                    const refundCount = row.ItemStatistics[item].filter(it => it.RefundReason).length;
                                                    const refundTotal = row.ItemStatistics[item].filter(it => it.RefundReason).reduce((total, it) => total + it.RefundTotal, 0);
                                                    const cancelledCount = row.ItemStatistics[item].filter(it => it.Status === 2).length;
                                                    return (
                                                        <div key={`${item}-${index}-${row.ItemStatistics[item].length}`}>
                                                            <span className={classes.quantityField}>
                                                                {row.ItemStatistics[item].length}
                                                                {refundCount > 0 && (
                                                                    <span>
                                                                        {" ("}
                                                                        <FormattedMessage id="REFUND" /> {refundCount}: ₪{refundTotal}
                                                                        {")"}
                                                                    </span>
                                                                )}
                                                                {cancelledCount > 0 && (
                                                                    <span>
                                                                        {" ("}
                                                                        <FormattedMessage id="CANCELLED" />: {cancelledCount}
                                                                        {")"}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    );
                                                })
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        {row.InvoiceLink && (
                                            <Button
                                                variant="contained"
                                                type="button"
                                                size="small"
                                                onClick={() => (window.location.href = row.InvoiceLink)}
                                                className="mr-3"
                                            >
                                                <FormattedMessage id="INVOICE"/>
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {row.History && row.History.map(r => {
                                            return (
                                                r.InvoiceLink && (
                                                    <Button
                                                        variant="contained"
                                                        type="button"
                                                        size="small"
                                                        onClick={() => (window.location.href = r.InvoiceLink)}
                                                        className="mr-3"
                                                    >
                                                        <FormattedMessage id="REFUND_INVOICE"/>
                                                    </Button>
                                                )
                                            )
                                        })}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(ReportsList)