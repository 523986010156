/* eslint-disable no-restricted-imports */

import {Typography} from "@material-ui/core";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import CreateCustomerFormDialog from "../modules/Common/CreateWithNameFormDialog.js";
import SupplierList from "../modules/Common/DisplayList.js";
import {withRouter} from "react-router-dom";
import {
    createSupplier,
    getSuppliers,
    supplierSettings
} from "../modules/Suppliers/_axios/supplierCrud.js";
import StateFormDialog from "../modules/Suppliers/StateFormDialog";
import {useDispatch} from "react-redux";
import {handleApiError} from "../../redux/snackbar/snackbarHandlers.js";
import {Tab, Tabs} from "react-bootstrap";
import {useStyles} from "../modules/Common/_styles/editPageStyles"

function SuppliersListPage({intl, ...props}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [suppliersData, setSuppliersData] = useState({
        data: [],
        requestCount: 0,
        isLoading: true
    });
    const [showStatePopup, setShowStatePopup] = useState(false)
    const [supplier, setSupplier] = useState()
    const [tab, setTab] = useState(1);

    const [showCreateCustomerPanel, setShowCreateCustomerPanel] = useState(false);

    function saveSupplier(newSupplier) {
        return createSupplier(newSupplier);
    }

    function fetchSuppliers(state, cancelToken) {
        setSuppliersData({...suppliersData, isLoading: true});
        getSuppliers(cancelToken.token, state)
            .then(({data}) => {
                setSuppliersData({
                    ...suppliersData,
                    data: data,
                    isLoading: false
                });
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function getSuppliersHandler(state) {
        const cancelToken = axios.CancelToken.source()
        fetchSuppliers(state, cancelToken)
        return () => cancelToken.cancel()
    }

    const handleNewSupplierSubmit = (values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        const newCustomer = {Name: values.name};
        saveSupplier(newCustomer)
            .then(({data}) => {
                setSubmitting(false);
                resetForm();
                props.history.push(`supplier/${data.Id}`);
            })
            .catch(error => {
                setSubmitting(false);
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_CREATE_SUPPLIER"
                    })
                );
            });
    };

    useEffect(() => {
        if (tab) {
            getSuppliersHandler(tab)
        }
    }, [tab])

    function changeStatus(ev) {
        setShowStatePopup(true);
        setSupplier(ev);
    }

    function handleState(ev, supplierID) {
        return supplierSettings(ev, supplierID);
    }


    return (
        <>
            <CreateCustomerFormDialog
                show={showCreateCustomerPanel}
                title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                closeDialog={() => setShowCreateCustomerPanel(false)}
                handleSubmit={handleNewSupplierSubmit}
                checkIfNameUnique={value => {
                    const supplier = suppliersData.data.find(
                        supplier => supplier.Name === value
                    );
                    return supplier === undefined;
                }}
            />
            <Typography variant="h3" style={{textAlign: "right"}}>
                <FormattedMessage id="TITLE"/>
            </Typography>

            <Tabs id="main-tab"
                  activeKey={tab}
                  onSelect={(k) => setTab(k)}
                  unmountOnExit>
                <Tab eventKey="1"
                     title={<FormattedMessage id="TABS.SUPPLIERS"/>}>
                </Tab>
                <Tab
                    eventKey="0"
                    title={<FormattedMessage id="TABS.SUPPLIERS_ARCHIVE"/>}
                >
                </Tab>
            </Tabs>
            <SupplierList
                variant="suppliers"
                hideNewActionButton={tab == '0'}
                showActionButton={true}
                action={ev => changeStatus(ev)}
                displayedData={suppliersData}
                openCreatePanel={() => setShowCreateCustomerPanel(true)}
            />
            <StateFormDialog
                show={showStatePopup}
                title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                closeDialog={() => {
                    setShowStatePopup(false);
                    setSupplier(undefined);
                }}
                submitData={ev => handleState({
                    state: supplier.State === 'active' ? 0 : 1,
                }, supplier.SupplierID)}
                afterSubmitCloseDialog={() => {
                    getSuppliersHandler(tab)
                    setShowStatePopup(false);
                    setSupplier(undefined);
                }}
                isSubmitting={false}
                intl={intl}
                message={
                    supplier?.State === 'active' ? intl.formatMessage({
                        id: "ADMIN.CONFIRMATION_FORM.REQUEST_ARCHIVE"
                    }) : intl.formatMessage({
                        id: "ADMIN.CONFIRMATION_FORM.REQUEST_ACTIVE"
                    })
                }
            />
        </>
    );
}

export default withRouter(injectIntl(SuppliersListPage));
