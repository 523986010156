import axios from "axios"

export function getOrders(
  customerID,
  mealTypeID,
  date,
  supplierID,
  locationID,
  deliveryTimeId,
  cancelToken
) {
  const params = {
    customerID,
    supplierID,
    locationID,
    date,
    mealTypeID,
  };
  if (deliveryTimeId) {
    deliveryTimeId.split(',').forEach((item, index) => {
      params[`deliveryTimeId[${index}]`] = item;
    })
  } else {
    params['deliveryTimeId'] = [];
  }
  return axios.get("/Customers/Orders", {
    params,
    cancelToken
  })
}
