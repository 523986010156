/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    fontSize: 60
  },
  paper: {
    width: "100%",
    position: "relative"
  },
  summaryLine: {
    background: 'lightgrey'
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2)
  },
  line: {
    borderBottom: '1px solid lightgrey',
  },
  summary: {
    background: "lightgray"
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tableWrapperSummary: {
    overflowX: "auto",
    background: '#e4e6ef'
  },
  maxHeight: {
    maxHeight: "50vh"
  },
  button: {
    width: "100%",
    margin: theme.spacing(1, 0)
  },
  iconDisplay: {
    height: 45
  },
  itemInfo: {
    height: 24
  },
  summaryContainer: {
    paddingRight: '5%'
  },
  itemName: {

  },
  summaryTotal: {
    marginTop: '3rem'
  },
  itemQuantity: {
    width: '100%',
    display: 'inline-block'
  },
  summaryQuantityTitle: {
    marginLeft: 8,
    marginRight: 8
  },
  itemQuantity: {
    width: '50%'
  },
  itemName: {

  },
  uploadedItemIconDisplay: {
    maxHeight: 100,
    maxWidth: 174,
    width: "100%"
  },
  collapseIcon: {
    width: 26
  },
  collapsibleRow: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  redRow: {
    backgroundColor: "rgba(193, 66, 66, 0.2) !important"
  },
  label: {
    fontWeight: "bold",
    marginLeft: '0.5rem'
  },
  priceDefinition: {
    direction: 'ltr!important',
    display: "inline-block"
  },
  totalListCount: {
    fontWeight: 'bold',
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
  },
  imgContainer: {
    width: '120px'
  },
  summaryLength: {
    padding: '1rem',
    boxSizing: 'border-box',
  },
  summaryLengthTitle: {
    marginRight: '0.5rem'
  },
  quantityField: {
    display: 'block',
    width: 'max-content'
  }
}))
