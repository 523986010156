import axios from "axios"

export function getCustomers(cancelToken) {
  return axios.get("/Customers", {
    cancelToken
  })
}
export function getCustomersList(cancelToken, detailsIncluded, state) {
  return axios.get("/Customers/List", {
    params: { detailsIncluded, state: state || '' },
    cancelToken
  })
}

export function getCustomer(cancelToken, customerID, isIncludedDetails) {
  return axios.get(`/Customers/${customerID}`, {
    params: { isIncludedDetails },
    cancelToken
  })
}
export function createCustomer(data) {
  return axios.post("/Customer", data)
}
export function updateCustomer(data, customerID) {
  return axios.patch(`/Customers/${customerID}`, data)
}
export function saveCustomerLogo(data, customerID) {
  return axios.post(`/Customer/Logo/${customerID}`, data)
}
export function deleteCustomer(customerID) {
  return axios.delete(`/Customers/${customerID}`)
}

export function updateDepartment(data, departmentID) {
  return axios.patch(`/Department/${departmentID}`, data)
}
export function deleteDepartment(departmentID) {
  return axios.delete(`/Department/${departmentID}`)
}
export function createDepartment(data, customerID) {
  return axios.post(`/Customer/${customerID}/Department`, data)
}

export function updateLocation(data, locationID) {
  return axios.patch(`/Location/${locationID}`, data)
}
export function deleteLocation(locationID) {
  return axios.delete(`/Location/${locationID}`)
}
export function deleteBannerById(bannerId) {
    return axios.delete(`/Banner/${bannerId}`)
}
export function createLocation(data) {
  return axios.post(`/Location`, data)
}

export function getDepartments(id) {
  return axios.get(`/Customer/${id}/Departments`)
}

export function updateMeal(locationID, submittedMeal) {
  return axios.patch(
    `/Location/${locationID}/Meals/${submittedMeal.id}`,
    submittedMeal
  )
}

export function saveMealItems(locationID, mealType, mealItems, deletedMealItems, cancelToken) {
  return axios.patch(
      `/Location/${locationID}/Meals/${mealType}/Items`,
      {
        mealItems: mealItems.filter(item => item.id && item.changed).filter(item => item.IsCombo !== true),
        addedMealItems: mealItems.filter(item => !item.id).filter(item => item.IsCombo !== true),
        deletedMealItems: deletedMealItems.filter(item => item.IsCombo !== true),
        
        mealCombos: mealItems.filter(item => item.id && item.changed).filter(item => item.IsCombo === true),
        addedMealCombos: mealItems.filter(item => !item.id).filter(item => item.IsCombo == true),
        deletedMealCombos: deletedMealItems.filter(item => item.IsCombo === true)
      },
      {
        cancelToken
      }
  )
}

export function getMeals(locationID, cancelToken) {
  return axios.get(`/Location/${locationID}/Meals`, {
    cancelToken
  })
}
export function getMealsByLocation(locationID, mealType, supplier, priceCategoryId, cancelToken) {
  return axios.get(`/Location/${locationID}/Meals/Types/${mealType}?supplierID=${supplier || ''}&priceCategoryId=${priceCategoryId || ''}`, {
    cancelToken
  })
}
export function getMealsOptions(locationID, mealID, itemID, cancelToken) {
  return axios.get(
    `/Location/${locationID}/Meals/${mealID}/Items/${itemID}/Options`,
    {
      cancelToken
    }
  )
}
export function getCustomerLocations(customerID, cancelToken) {
  return axios.get(`/Customers/${customerID}/Locations`, {
    cancelToken
  })
}
export function saveMealsOptions(locationID, mealID, itemID, data) {
  return axios.post(
    `/Location/${locationID}/Meals/${mealID}/Items/${itemID}/Options`,
    data
  )
}
export function getItems(supplierID, cancelToken) {
  return axios.get(`/Supplier/${supplierID}/Items`, {
    cancelToken
  })
}

export function getItemLocations(mealID, itemID, cancelToken) {
  return axios.get(`/Meals/${mealID}/Items/${itemID}/Locations`, {
    cancelToken
  })
}

export function getBanners(cancelToken) {
    return axios.get(`/Banner/list`, {
        cancelToken
    })
}

export function getLocations(locationIds, mealType, cancelToken) {
  return axios.get(`/Locations`, {
    cancelToken
  })
}

export function customerSettings(data, customerID) {
  return axios.patch(`/Customers/${customerID}/Settings`, data)
}

export function calculateLocationUsers(locationIds, mealType, role, customerID, departmentID, ordered, orderDate, cancelToken) {
  return axios.post(`/users/notification/count`, {
    locationIds,
    mealType,
    role,
    customerID,
    departmentID,
    ordered: !!ordered,
    orderDate,
  })
}

export function sendPushToUsers(data, cancelToken) {
  return axios.post(`/users/notification/send`, data)
}

export function sendBannerAssignToLocation(data, cancelToken) {
    return axios.post(`/Banner/Assign/To/Locations`, data)
}

export function sendBanner(data, cancelToken) {
    return axios.post(`/Banner`, data)
}

export function updateBanner(data, bannerId, cancelToken) {
    return axios.patch(`/Banner/${bannerId}`, data)
}
export function getBannerById(bannerId, cancelToken) {
    return axios.get(`/Banner/${bannerId}`)
}

export function sendPushToUsersSingle(data, cancelToken) {
  return axios.post(`/users/notification/send/single`, data)
}

export function saveNotificationImage(data, cancelToken) {
  return axios.post(`/users/notification/media`, data)
}


export function saveBatchMealItems(locationID, mealType, data, cancelToken) {
  return axios.put(
      `/Location/${locationID}/Meals/${mealType}/Items`,
      data,
      {
        cancelToken
      }
  )
}
