import usersMock from "../../Auth/__mocks__/userTableMock.js"
import { customers } from "../../Customers/__mocks__/mockCustomers.js"
import { suppliers } from "../../Suppliers/__mocks__/mockSuppliers.js"

let idMock = 9

function createUserData(
  firstName,
  lastName,
  role,
  customerID,
  locationID,
  departmentID,
  supplierID,
  active
) {
  return {
    UserID: `b21bb82c-feb4-4454-ace0-e423b4f16a${++idMock}`,
    FirstName: firstName,
    LastName: lastName,
    Mobile: "0522222222",
    Email: "neri@demo.il",
    Role: role,
    LastLoginDate: "string",
    BlockedUntil: "string",
    Active: active,
    CustomerID: customerID,
    SupplierID: supplierID,
    UnitID: "36af0616-06bd-49aa-9e60-fb8301ccb74c",
    LocationID: locationID,
    DepartmentID: departmentID,
    ExternalID: "111222333",
    PermanentNote: "deliver to 2nd floor"
  }
}

let users = [
  createUserData(
    "User1",
    "Connor",
    "Admin",
    customers[12].CustomerID,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[0].DepartmentID,
    null,
    true
  ),
  createUserData(
    "User2",
    "Connor",
    "Admin",
    null,
    customers[0].Locations[1].LocationID,
    customers[0].Departments[0].DepartmentID,
    suppliers[0].SupplierID,
    true
  ),
  createUserData(
    "User3",
    "Connor",
    "Admin",
    customers[10].CustomerID,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[0].DepartmentID,
    null,
    true
  ),
  createUserData(
    "User4",
    "Connor",
    "Admin",
    null,
    customers[0].Locations[1].LocationID,
    customers[0].Departments[0].DepartmentID,
    suppliers[1].SupplierID,
    true
  ),
  createUserData(
    "User5",
    "Connor",
    "Admin",
    null,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[0].DepartmentID,
    suppliers[1].SupplierID,
    true
  ),
  createUserData(
    "User6",
    "Connor",
    "Admin",
    null,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[0].DepartmentID,
    suppliers[2].SupplierID,
    true
  ),
  createUserData(
    "User7",
    "Connor",
    "Client",
    customers[6].CustomerID,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[0].DepartmentID,
    null,
    true
  ),
  createUserData(
    "User8",
    "Connor",
    "Client",
    customers[5].CustomerID,
    customers[0].Locations[1].LocationID,
    customers[0].Departments[1].DepartmentID,
    null,
    false
  ),
  createUserData(
    "User9",
    "Connor",
    "Client",
    null,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[1].DepartmentID,
    suppliers[2].SupplierID,
    false
  ),
  createUserData(
    "User10",
    "Connor",
    "Client",
    customers[3].CustomerID,
    customers[0].Locations[1].LocationID,
    customers[0].Departments[1].DepartmentID,
    null,
    false
  ),
  createUserData(
    "User11",
    "Connor",
    "Client",
    null,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[1].DepartmentID,
    suppliers[2].SupplierID,
    false
  ),
  createUserData(
    "User12",
    "Connor",
    "Client",
    null,
    customers[0].Locations[0].LocationID,
    customers[0].Departments[1].DepartmentID,
    suppliers[1].SupplierID,
    false
  ),
  createUserData(
    "User13",
    "Connor",
    "Client",
    customers[0].CustomerID,
    customers[0].Locations[1].LocationID,
    customers[0].Departments[1].DepartmentID,
    null,
    true
  )
]

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export default function mockUsers(mock) {
  mock
    .onGet("/users")
    .reply(
      ({
        headers: { Authorization },
        params: {
          offset,
          limit,
          sortBy,
          orderBy,
          text,
          role,
          customerid,
          locationid,
          departmentid,
          supplierid,
          active
        }
      }) => {
        const accessToken =
          Authorization &&
          Authorization.startsWith("Bearer ") &&
          Authorization.slice("Bearer ".length)

        if (accessToken) {
          const user = usersMock.find(x => x.Token === accessToken)
          if (user) {
            let result = users
            if (active === true) {
              result = result.filter(user => user.Active === true)
            } else if (active === false) {
              result = result.filter(user => user.Active === false)
            }
            if (role) {
              result = result.filter(user => user.Role === role)
            }
            if (text) {
              let search = text.toLowerCase()
              result = result.filter(
                user =>
                  user.FirstName.toLowerCase().includes(search) ||
                  user.Mobile.toLowerCase().includes(search) ||
                  user.Email.toLowerCase().includes(search)
              )
            }
            if (customerid) {
              result = result.filter(user => user.CustomerID === customerid)
              if (locationid) {
                result = result.filter(user => user.LocationID === locationid)
              }
              if (departmentid) {
                result = result.filter(
                  user => user.DepartmentID === departmentid
                )
              }
            } else if (supplierid) {
              result = result.filter(user => user.SupplierID === supplierid)
            }
            result = result.map(user =>
              user.CustomerID
                ? {
                    ...user,
                    Customer: customers.find(
                      customer => customer.CustomerID === user.CustomerID
                    )
                  }
                : user.SupplierID
                ? {
                    ...user,
                    Supplier: suppliers.find(
                      supplier => supplier.SupplierID === user.SupplierID
                    )
                  }
                : user
            )
            return [
              200,
              {
                usersCount: result.length,
                displayedUsers: stableSort(
                  result,
                  getSorting(orderBy, sortBy)
                ).slice(offset, offset + limit)
              }
            ]
          }
        }
        return [400]
      }
    )

  mock.onGet("/Suppliers").reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        return [200, suppliers]
      }
    }
    return [400]
  })

  mock.onGet(RegExp(`/user/*`)).reply(({ headers: { Authorization }, url }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    const userID = url.slice(url.lastIndexOf("/user/") + 6, url.length)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        const result = users.find(x => x.UserID === userID)
        return [200, result]
      }
    }
    return [400]
  })

  mock.onPost("/user").reply(({ headers: { Authorization }, data }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    const {
      "First Name": firstName,
      "Last Name": lastName,
      Email: email,
      Mobile: phone,
      CustomerID: customer,
      LocationID: location,
      DepartmentID: department,
      SupplierID: supplier,
      Role: role
    } = JSON.parse(data)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        let EmailTaken = email === "demo@demo.com"
        let MobileTaken = phone === "111-1111111"
        if (EmailTaken || MobileTaken) {
          return [
            409,
            {
              EmailTaken,
              MobileTaken
            }
          ]
        }
        let newUser = {
          UserID: `b21bb82c-feb4-4454-ace0-e423b4f16a${++idMock}`,
          FirstName: firstName,
          LastName: lastName,
          Mobile: phone,
          Email: email,
          Role: role,
          LastLoginDate: "string",
          BlockedUntil: "string",
          Active: true,
          CustomerID: customer,
          SupplierID: supplier,
          UnitID: "36af0616-06bd-49aa-9e60-fb8301ccb74c",
          LocationID: location,
          DepartmentID: department,
          ExternalID: "111222333",
          PermanentNote: "deliver to 2nd floor"
        }
        users.push(newUser)
        return [
          200,
          {
            UserID: newUser.UserID
          }
        ]
      }
    }
    return [400]
  })
}
