import React, {useEffect, useState} from "react"
import moment from "moment"
import {connect} from "react-redux"
// import { setError } from "../../redux/actions/errorHandlerActions"
import {getCreditCardToken, getPaymentStatus} from "./_axios/ordersAdminCrud"
import DishLoader from "../Common/DishLoader"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";

function TranzilaWindow({
                            show,
                            src,
                            saveCard,
                            onClose,
                            // setError,
                            searchUser,
                        }) {
    const [loadCount, setLoadCount] = useState(0)
    useEffect(() => {
        if (show) {
            setLoadCount(0)
        }
    }, [show])

    useEffect(() => {
        if (loadCount > 1) {
            // Wait for response for 10 seconds
            let duration = moment.duration(10000, "milliseconds")
            // Every 2 seconds send request to get result
            let interval = 2000

            const intervalRef = setInterval(function () {
                duration = moment.duration(duration - interval, "milliseconds")
                getCreditCardToken(searchUser.UserID).then(({data}) => {
                    if (
                        data.tranzilaTK && +data.expmonth < 10
                            ? data.expmonth[0] === "0"
                            : +data.expmonth <= 12
                    ) {
                        // Save new card
                        saveCard(data.tranzilaTK, data.expmonth, data.expyear)
                        // No further need in requests
                        clearInterval(intervalRef)
                        onClose()
                    }
                })
                // If there is no result after 10 seconds
                if (duration <= 0) {
                    clearInterval(intervalRef)
                    // Set iframe back to form page
                    const iframe = document.getElementById("tranzila-iframe")
                    iframe.src = src

                    setLoadCount(0)

                    // Show a message that request has failed
                    //setError(new Error(), t("order.errors.failed_to_add_card"))
                }
            }, interval)
        }
    }, [loadCount])

    return (
        <Dialog open={show} onClose={onClose} disableBackdropClick>
          <DialogContent>
            <div className="tranzila-wrapper">
                <iframe
                    title="tranzila-iframe"
                    id="tranzila-iframe"
                    width="100%"
                    height="100%"
                    scrolling="no"
                    src={src}
                    style={{visibility: loadCount !== 1 ? "hidden" : "visible"}}
                    onLoad={() => setLoadCount(loadCount + 1)}
                    sandbox="allow-modals allow-forms allow-popups allow-scripts"
                    frameBorder="0"
                ></iframe>
                {loadCount !== 1 &&
                    <div className='loader-container'>
                      <DishLoader/>
                    </div>
                }
            </div>
          </DialogContent>
          <DialogActions>
            <Button
                variant="outlined"
                type="button"
                size="large"
                onClick={onClose}
                className="mr-3"
            >
              <FormattedMessage id="CLOSE" />
            </Button>
          </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(TranzilaWindow)
