/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage, injectIntl } from "react-intl"
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function DeleteComboDialog({ intl, show, onClose, sendRequest, comboData }) {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = React.useState(false)

  function handleAccept() {
    setSubmitting(true)
    sendRequest(comboData.Id)
      .then(() => {
        setSubmitting(false)
        dispatch(
          snackbarActions.setSnackbarData(
            intl.formatMessage({
              id: "API.DELETE_SUCCESS"
            })
          )
        )
        onClose(comboData.Id)
      })
      .catch(error => {
        setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_DELETE_COMBO"
          })
        )
      })
  }

  function handleClose() {
    onClose(null)
  }

  if (!comboData) {
    return null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="DELETE_DIALOG.TITLE" />
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <FormattedMessage id="DELETE_DIALOG.TEXT" />
            <div>
              <b>
                {comboData.Name}
              </b>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={handleClose}
            className="mr-3"
          >
            <FormattedMessage id="DELETE_DIALOG.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            disabled={submitting}
            onClick={handleAccept}
            autoFocus
          >
            <FormattedMessage id="DELETE_DIALOG.SUBMIT_BUTTON" />
            {submitting && <span className="ml-1 spinner spinner-white"></span>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(DeleteComboDialog)
