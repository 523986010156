import { combineReducers } from "redux"
import { all } from "redux-saga/effects"

import * as auth from "../app/modules/Auth/_redux/authRedux"
import * as snackbar from "./snackbar/snackbarRedux"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  snackbar: snackbar.reducer
})

export function* rootSaga() {
  yield all([auth.saga()])
}
