/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import DishLoader from "../../Common/DishLoader"
import { useStyles } from "../../Common/_styles/elementListStyles"
import moment from "moment"

const headRows = [
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "OrderToDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.PURCHASE_DATE" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  },
  {
    id: "RefoundQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "NetQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NET_QUANTITY" />
  },
  {
    id: "CustomerPrice",
    disablePadding: false,
    hidden: true,
    label: <FormattedMessage id="REPORT.CUSTOMER_PRICE" />
  },
  {
    id: "CostPrice",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.COST_PRICE" />
  },
  {
    id: "TotalCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />
  }
]

function StoreReportsList({ reports, isLoading, showCustomerPrice }) {
  const classes = useStyles()
  const rows = reports
  const [sortBy, setSortBy] = useState("ItemName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      {isLoading && <DishLoader centered />}
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows.filter(row => !row.hidden || showCustomerPrice)}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.ItemName + row.OrderToDate}
                >
                  <TableCell component="th" scope="row" align="left">
                    {row.ItemName}
                  </TableCell>
                  <TableCell>{moment(row.OrderToDate).format("DD/MM/YYYY ")}</TableCell>
                  <TableCell>{row.Quantity}</TableCell>
                  <TableCell>{row.RefoundQuantity}</TableCell>
                  <TableCell>{row.NetQuantity}</TableCell>
                  {showCustomerPrice && (
                    <TableCell>{row.CustomerPrice}</TableCell>
                  )}
                  <TableCell>{row.CostPrice}</TableCell>
                  <TableCell>{row.TotalCost}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default StoreReportsList
