/* eslint-disable no-restricted-imports */

import { Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import CreatePriceCategoryButton from "../modules/Common/CreateNewButton.js"
import DeletePriceCategoryDialog from "../modules/PriceCategories/DeletePriceCategoryDialog.js"
import CreatePriceCategoryFormDialog from "../modules/PriceCategories/CreatePriceCategoryFormDialog.js"
import PriceCategoryList from "../modules/PriceCategories/PriceCategoryList.js"
import {
  createPriceCategory,
  editPriceCategory,
  deletePriceCategory,
  getPriceCategories,
} from "../modules/PriceCategories/_axios/priceCategoryCrud.js"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"

function PriceCategoryAdminPage( { intl, ...props }) {
  const dispatch = useDispatch()

  const [editPriceCategoryState, setEditPriceCategoryState] = useState({})
  const [deletePriceCategoryState, setDeletePriceCategoryState] = useState({ show: false })
  const [showCreatePriceCategoryPanel, setShowCreatePriceCategoryPanel] = useState(false)
  const [priceCategoriesData, setPriceCategoriesData] = useState({
    priceCategories: [],
    isLoading: true
  })

  function handleCloseDeletePriceCategoryPanel(priceCategoryID) {
    if (priceCategoryID) {
      setPriceCategoriesData({
        ...priceCategoriesData,
        priceCategories: [...priceCategoriesData.priceCategories.filter(priceCategory => priceCategory.Id !== priceCategoryID)]
      })
    }
    setDeletePriceCategoryState({ show: false })
  }

  function handleOpenCreatePriceCategoryPanel() {
    setShowCreatePriceCategoryPanel(true)
    setEditPriceCategoryState(null);
  }

  function handleEditPriceCategory(priceCategory) {
    setEditPriceCategoryState({ ...priceCategory })
    setShowCreatePriceCategoryPanel(true);
  }

  function handleDeletePriceCategory(priceCategory) {
    setDeletePriceCategoryState({ show: true, priceCategory })
  }

  function createOrEditPriceCategory(submittedPriceCategory) {
    if (submittedPriceCategory.tokenData.Id) {
      return editPriceCategory(submittedPriceCategory.tokenData.Id, submittedPriceCategory.tokenData)
    }
    return createPriceCategory(submittedPriceCategory.tokenData)
  }
  
  function fetchPriceCategories(cancelToken) {
    setPriceCategoriesData({ ...priceCategoriesData, isLoading: true })
    getPriceCategories(cancelToken.token)
        .then(({ data }) => {
          setPriceCategoriesData({
            priceCategories: data,
            isLoading: false
          })
        })
        .catch(error => {
          setPriceCategoriesData({
            ...priceCategoriesData,
            isLoading: false
          })
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_PRICE_CATEGORY"
              })
          )
        })
  }
  
  useEffect(() => {
    loadPriceCategories();
  }, [])

  function loadPriceCategories() {
    const cancelToken = axios.CancelToken.source()
    fetchPriceCategories(cancelToken)
    return () => cancelToken.cancel()
  }

  useEffect(() => {
    if (editPriceCategoryState && editPriceCategoryState.Id) {
      setShowCreatePriceCategoryPanel(true)
    }
  }, [editPriceCategoryState])

  function handleCloseCategoryPanel() {
    setShowCreatePriceCategoryPanel(false);
    setEditPriceCategoryState(null);
  }

  function processPriceCategories() {
    setShowCreatePriceCategoryPanel(false);
    setEditPriceCategoryState(null)
    handleCloseCategoryPanel();
    loadPriceCategories();
  }
  
  return (<>
    <CreatePriceCategoryButton onClick={handleOpenCreatePriceCategoryPanel} />
    <DeletePriceCategoryDialog
        show={deletePriceCategoryState.show}
        onClose={handleCloseDeletePriceCategoryPanel}
        sendRequest={deletePriceCategory}
        priceCategoryData={deletePriceCategoryState.priceCategory}
    />
    {
        showCreatePriceCategoryPanel && (
            <CreatePriceCategoryFormDialog
                show={showCreatePriceCategoryPanel}
                onClose={handleCloseCategoryPanel}
                sendRequest={editPriceCategoryState?.Id ? editPriceCategory : createPriceCategory}
                handleSubmit={processPriceCategories}
                submitData={createOrEditPriceCategory}
                closeDialog={() => {
                  handleCloseCategoryPanel()
                }}
                initialPriceCategoryState={editPriceCategoryState}
            />
        )
    }
    <Typography
        variant="h3"
        id="userAdminTitle"
        style={{ textAlign: "right" }}
    >
      <FormattedMessage id="PRICE_CATEGORY.TITLE" />
    </Typography>
    <PriceCategoryList
        priceCategoriesData={priceCategoriesData}
        onEdit={handleEditPriceCategory}
        onDelete={handleDeletePriceCategory}
    />
  </>)
}

export default injectIntl(PriceCategoryAdminPage)
