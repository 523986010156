/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import DeleteIcon from "@material-ui/icons/Delete"
import ErrorIcon from "@material-ui/icons/Error"
import { FormattedMessage, injectIntl } from "react-intl"
import { FormControl, IconButton, InputBase, Tooltip } from "@material-ui/core"
import clsx from "clsx"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"

const headRows = [
  {
    id: "Delete",
    disablePadding: true,
    notSortable: true,
    label: ""
  },
  {
    id: "FirstName",
    disablePadding: true,
    label: (
      <FormattedMessage id="ADMIN_USERS.CREATE_FORM.FIRST_NAME_FIELD.LABEL" />
    )
  },
  {
    id: "LastName",
    disablePadding: true,
    label: (
      <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LAST_NAME_FIELD.LABEL" />
    )
  },
  {
    id: "Mobile",
    disablePadding: true,
    label: <FormattedMessage id="ADMIN_USERS.CREATE_FORM.MOBILE_FIELD.LABEL" />
  },
  {
    id: "Email",
    disablePadding: true,
    label: <FormattedMessage id="ADMIN_USERS.CREATE_FORM.EMAIL_FIELD.LABEL" />
  },
  {
    id: "ExternalID",
    disablePadding: true,
    label: <FormattedMessage id="ADMIN_USERS.CREATE_FORM.EXTERNAL_ID_FIELD.LABEL" />
  },
  {
    id: "Location",
    disablePadding: true,
    label: (
      <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LOCATION_FIELD.LABEL" />
    )
  },
  {
    id: "Department",
    disablePadding: true,
    label: (
      <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.LABEL" />
    )
  },
  {
    id: "Error",
    disablePadding: true,
    notSortable: true,
    label: ""
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 1, 1, 0)
  },
  input: { fontSize: "0.875rem", padding: 3, width: "calc(100% - 20px)" },
  textRight: {
    textAlign: "left",
    width: "100%"
  },
  paper: {
    width: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  }
}))

function UploadedUsersList({
  intl,
  users,
  onUserDataChange,
  isDataInvalid,
  onDelete,
  variant
}) {
  const classes = useStyles()
  const rows = users
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleDelete(option) {
    onDelete(option)
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.TempID}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        width={24}
                        padding="none"
                      >
                        {variant !== "created" && (
                          <Tooltip
                            title={intl.formatMessage({
                              id: "OPTION.DELETE.TOOLTIP"
                            })}
                          >
                            <IconButton
                              aria-label={intl.formatMessage({
                                id: "OPTION.DELETE.TOOLTIP"
                              })}
                              onClick={() => handleDelete(row.TempID)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.FirstName && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: classes.input
                          }}
                          readOnly={variant === "created"}
                          value={row.FirstName}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "FirstName")
                          }
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.LastName && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: classes.input
                          }}
                          readOnly={variant === "created"}
                          value={row.LastName}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "LastName")
                          }
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.Email && !row.Mobile && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: clsx(classes.input, classes.textRight)
                          }}
                          readOnly={variant === "created"}
                          value={row.Mobile}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "Mobile")
                          }
                          dir="ltr"
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.Email && !row.Mobile && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: clsx(classes.input, classes.textRight)
                          }}
                          readOnly={variant === "created"}
                          value={row.Email}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "Email")
                          }
                          dir="ltr"
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.ExternalID && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: clsx(classes.input, classes.textRight)
                          }}
                          readOnly={variant === "created"}
                          value={row.ExternalID}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "ExternalID")
                          }
                          dir="ltr"
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {!row.Locations && <ErrorIcon />}
                        <InputBase
                          inputProps={{
                            className: classes.input
                          }}
                          readOnly={variant === "created"}
                          value={row.Locations}
                          onChange={event =>
                            onUserDataChange(row.TempID, event, "Locations")
                          }
                        />
                      </TableCell>
                      <TableCell align="left" padding="none">
                        <FormControl className={classes.textField}>
                          <InputBase
                            inputProps={{
                              className: classes.input
                            }}
                            readOnly={variant === "created"}
                            value={row.DepartmentName}
                            onChange={event =>
                              onUserDataChange(
                                row.TempID,
                                event,
                                "DepartmentName"
                              )
                            }
                          />
                        </FormControl>
                      </TableCell>
                      {variant === "created" && (
                        <TableCell align="left" padding="none">
                          {row.errorCode}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(UploadedUsersList)
