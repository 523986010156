/* eslint-disable no-restricted-imports */
import axios from "axios";
import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import FilterPanel from "../modules/Management/Meals/FilterPanel";
import MealItemList from "../modules/Management/Meals/MealItemList";
import MealSelectorDialogContainer from "../modules/Management/Meals/MealSelectorDialogContainer";
import {
    getCustomerLocations, getCustomersList,
    getMealsByLocation, saveMealItems,
    saveBatchMealItems
} from "../modules/Customers/_axios/customerCrud";
import {getSuppliers} from "../modules/Suppliers/_axios/supplierCrud";
import {getPriceCategories} from "../modules/PriceCategories/_axios/priceCategoryCrud";
import DishLoader from "../../app/modules/Common/DishLoader";
import {handleApiError} from "../../redux/snackbar/snackbarHandlers";
import moment from "moment/moment";
import ExportButton from "../modules/Reports/ExportButton"
import {dayCollection} from "../modules/Common/dayCollection"
import {formatDate} from "../modules/Common/momentFunctions"

function MealsManagementAdminPage({intl, ...props}) {
    const dispatch = useDispatch();

    const [exportFileName, setExportFileName] = useState("")
    const [searchParams, setSearchParams] = useState({});
    const [customerData, setCustomerData] = useState({
        data: [], isLoading: true, backup_data: [],
    });
    const [priceCategoryData, setPriceCategoryData] = useState({
        data: [], isLoading: true, backup_data: [],
    });
    const [locationsData, setLocationsData] = useState({
        data: [], isLoading: true, backup_data: [],
    });
    const [supplierData, setSupplierData] = useState({
        data: [], isLoading: true, backup_data: [],
    });
    const [mealItemData, setMealItemData] = useState({
        data: [], selected: [], deleted: [], added: [], isLoading: false, isSubmitLoading: false, backup_data: [],
    });

    function handleSearchParamsSave(changes) {
        const newParams = {
            ...searchParams, ...changes
        };
        setSearchParams(newParams);
    }

    useEffect(() => {
        function fetchPriceCategories(cancelToken) {
            setPriceCategoryData({...priceCategoryData, isLoading: true});
            getPriceCategories(cancelToken.token)
                .then(({data}) => {
                    setPriceCategoryData({
                        ...priceCategoryData, data, isLoading: false
                    });
                })
                .catch(error => handleApiError(dispatch, error, intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_PRICE_CATEGORY"
                })));
        }
        const cancelToken = axios.CancelToken.source();
        fetchPriceCategories(cancelToken);
        return () => cancelToken.cancel();
    }, []);

    useEffect(() => {
        function fetchCustomers(cancelToken) {
            setCustomerData({...customerData, isLoading: true});
            getCustomersList(cancelToken.token, true, "active")
                .then(({data}) => {
                    setCustomerData({
                        ...customerData, data, isLoading: false
                    });
                })
                .catch(error => handleApiError(dispatch, error, intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                })));
        }

        const cancelToken = axios.CancelToken.source();
        fetchCustomers(cancelToken);
        return () => cancelToken.cancel();
    }, []);

    useEffect(() => {
        function fetchSuppliers(cancelToken) {
            setSupplierData({...supplierData, isLoading: true});
            getSuppliers(cancelToken.token, "active")
                .then(({data}) => {
                    setSupplierData({
                        ...supplierData, data, isLoading: false
                    });
                })
                .catch(error => handleApiError(dispatch, error, intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                })));
        }

        const cancelToken = axios.CancelToken.source();
        fetchSuppliers(cancelToken);
        return () => cancelToken.cancel();
    }, []);

    useEffect(() => {
        if (searchParams.customer && searchParams.customer.CustomerID) {
            function fetchLocations(cancelToken) {
                setLocationsData({...locationsData, isLoading: true});
                getCustomerLocations(searchParams.customer.CustomerID, cancelToken.token)
                    .then(({data}) => {
                        setLocationsData({data, isLoading: false, notRequested: false});
                    })
                    .catch(error => handleApiError(dispatch, error, intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
                    })));
            }

            const cancelToken = axios.CancelToken.source();
            fetchLocations(cancelToken);
            return () => cancelToken.cancel();
        }
    }, [searchParams.customer]);

    useEffect(() => {
        if (searchParams.customer) {
            props.history.push({
                pathname: "/management-meals", search: "?" + new URLSearchParams({
                    customer: searchParams.customer.CustomerID,
                    supplier: searchParams.supplier?.SupplierID,
                    location: searchParams.location,
                    mealType: searchParams.mealType,
                    priceCategory: searchParams.priceCategory || ''
                }).toString()
            });
            setExportFileName(
                intl.formatMessage({
                    id: "REPORT.EXPORT_FILE_NAME"
                }) +
                "_" +
                searchParams.customer.CustomerID +
                "_" +
                searchParams.supplier?.Name +
                "_" +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_-_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
        }
    }, [searchParams]);

    useEffect(() => {
        if (customerData.data.length) {
            const urlParams = new URLSearchParams(window.location.search);
            const initialParams = {
                mealType: +urlParams.get("mealType") || 2,
                priceCategory: urlParams.get("priceCategory") || '',
                date: urlParams.get("date") ? new Date(urlParams.get("date")) : new Date(),
                customer: customerData.data.find(customer => customer.CustomerID === urlParams.get("customer")) || {},
                location: urlParams.get("location") || "",
                supplier: supplierData.data.find(supplier => supplier.SupplierID === urlParams.get("supplier")) || {}
            };
            setSearchParams(initialParams);
        }
    }, [customerData.data, supplierData.data]);

    useEffect(() => {
        setMealItemData({
            ...mealItemData, isLoading: false, data: [], selected: [], isSubmitLoading: false, backup_data: [],
        });
        if (searchParams.customer && searchParams.location && searchParams.mealType) {
            getMealItems();
        }
    }, [searchParams.customer, searchParams.location, searchParams.mealType,
        searchParams.supplier, searchParams.priceCategory]);

    useEffect(() => {
        setMealItemData({
            ...mealItemData, data: searchParams.text ?
                mealItemData.backup_data
                    .filter(item => item.ItemName && item.ItemName.indexOf(searchParams.text) !== -1) :
                mealItemData.backup_data
        });
    }, [searchParams.text])

    function getMealItems() {
        setMealItemData({
            ...mealItemData, isLoading: true, data: [], selected: [], backup_data: [],
        });
        const cancelToken = axios.CancelToken.source();
        getMealsByLocation(searchParams.location, searchParams.mealType,
            searchParams.supplier?.SupplierID, searchParams.priceCategory, cancelToken.token).then(({data}) => {
            const items = data.data.map((d) => {
                return {
                    ...d,
                    From: d.From === '0001-01-01T00:00:00' ? null : d.From,
                    To: d.To === '0001-01-01T00:00:00' ? null : d.To,
                    ItemPrice: d.IsCombo ? 0 : d.Item.Price,
                    ItemName: d.IsCombo ? d.Combo.Name : d.Item.Name,
                }
            })
            setMealItemData({
                ...mealItemData, isLoading: false, data: items, backup_data: items, selected: [],
            });
        });
        return () => cancelToken.cancel();
    }

    function handleSelectMealItem(selectedItem) {
        const selected = [...mealItemData.selected];
        const index = mealItemData.selected.indexOf(selectedItem.itemId);
        if (index === -1) {
            selected.push(selectedItem.itemId);
        } else {
            selected.splice(index, 1);
        }
        setMealItemData({
            ...mealItemData, selected,
        });
    }

    function handleAddMealItems(items) {
        const newItems = [...mealItemData.data];
        items.forEach(item => {
            newItems.push({
                ...item, Options: []
            })
        });

        setMealItemData({
            ...mealItemData, data: newItems, backup_data: newItems
        });
    }

    function handleAddMealCombos(combos) {
        const newItems = [...mealItemData.data];
        combos.forEach(combo => {
            newItems.push({
                ...combo, Options: []
            })
        });
        setMealItemData({
            ...mealItemData, data: newItems, backup_data: newItems
        });
    }

    function applyChanges() {
        const cancelToken = axios.CancelToken.source();
        setMealItemData({
            ...mealItemData,
            isSubmitLoading: true,
        });
        saveMealItems(
            searchParams.location,
            searchParams.mealType, mealItemData.data, mealItemData.deleted, cancelToken.token)
            .then(({data}) => {
                getMealItems();
            }).finally(() => {
            setMealItemData({
                ...mealItemData,
                isSubmitLoading: false,
            });
        });
        return () => cancelToken.cancel();
    }

    function applyBatchChanges(data) {
        const cancelToken = axios.CancelToken.source();
        setMealItemData({
            ...mealItemData,
            isSubmitLoading: true,
        });
        saveBatchMealItems(
            searchParams.location,
            searchParams.mealType, data, cancelToken.token)
            .then(({data}) => {
                getMealItems();
            }).finally(() => {
            setMealItemData({
                ...mealItemData,
                isSubmitLoading: false,
            });
        });
        return () => cancelToken.cancel();
    }

    function handleToggleAll(checked) {
        setMealItemData({
            ...mealItemData, selected: checked ? mealItemData.data.map(item => item.Item.ItemID) : []
        })
    }

    function handleUpdateItems(data) {
        setMealItemData({
            ...mealItemData, data: [], backup_data:[]
        });
        const meals = [];
        for (let i = 0; i < mealItemData.data.length; i++) {
            const item = mealItemData.data[i];
            if (mealItemData.selected.indexOf(item.Item.ItemID) !== -1) {
                if (data.clientPrice) {
                    item.ClientPrice = data.clientPrice;
                    item.changed = true;
                }
                if (data.customerPrice) {
                    item.CustomerPrice = data.customerPrice;
                    item.changed = true;
                }
                if (data.priority) {
                    item.Priority = data.priority;
                    item.changed = true;
                }
                if (data.days) {
                    item.Days = data.days;
                    item.changed = true;
                }
                
                meals.push({
                    ...item
                })
            } else {
                meals.push(item)
            }
        }
        setMealItemData({
            ...mealItemData, data: meals, backup_data: meals
        });
    }

    function getExportData() {
        const formattedItems = [];
        mealItemData.data.forEach(report => {
            const formattedRating = {}
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.NAME"
                })
                ] = report.IsCombo ? report.Combo.Name : report.Item?.Name
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.SUPPLIER_NAME"
                })
                ] = report.IsCombo ? report.Combo?.SupplierName : report.Item?.SupplierName
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.PRIORITY"
                })
                ] = report.Priority
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.OPTIONS"
                })
                ] =
                (report.Options && report.Options.length > 0
                    ? [...report.Options]
                    : report.IsCombo
                        ? []
                        : [
                            ...report.Item.OptionGroups.map(optionGroup => ({
                                OptionGroupId: optionGroup.id,
                                Label: optionGroup.Lable,
                                Type: optionGroup.isFree ? 1 : 2,
                                Order: optionGroup.Order || 0,
                                MealItemId: report.id
                            }))
                        ]).map(optionGroup => `${optionGroup.Label}`).join(',')
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.COST_PRICE"
                })
                ] = report.IsCombo ? 0 : report.Item.Price
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.CUSTOMER_PRICE"
                })
                ] = report.CustomerPrice
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.EMPLOYEE_PRICE"
                })
                ] = report.ClientPrice
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.DAYS_AVAILABILITY"
                })
                ] = report?.Days?.join(',')
            formattedRating[
                intl.formatMessage({
                    id: "MEAL_ITEM.LABELS.DATE_AVAILABILITY"
                })
                ] = `${report.From ? formatDate(report.From) : ""}-${report.To ? formatDate(report.To) : ""}`
            formattedItems.push(formattedRating)
        })
        return formattedItems
    }

    function handleDeleteItems() {
        const currentItems = [...mealItemData.data];
        const meals = mealItemData.data.filter(item => mealItemData.selected.indexOf(item.IsCombo !== true ? item.Item.ItemID : item.Combo.Id) !== -1);
        let i = 0;
        while (i < currentItems.length) {
            if (mealItemData.selected.indexOf(currentItems[i].IsCombo !== true ? currentItems[i].Item.ItemID : currentItems[i].Combo.Id) !== -1) {
                currentItems.splice(i, 1);
            } else {
                ++i;
            }
        }
        const deletedItems = mealItemData.deleted.concat(meals);
        const deletedItemIds = deletedItems.map(item =>item.id);
        setMealItemData({
            ...mealItemData,
            data: currentItems,
            backup_data: currentItems,
            selected: mealItemData.selected.filter(item => deletedItemIds.indexOf(item.id) == -1).map(item => item.id),
            deleted: deletedItems,
        })
    }


    function onItemFieldChange(value) {
        const data = mealItemData.data.map(item => {
            const isMatch = value.id
                ? item.id === value.id
                : item.Item.ItemID === value.Item.ItemID;

            return isMatch
                ? { ...item, ...value, changed: true }
                : item;
        });

        setMealItemData({
            ...mealItemData, data: data, backup_data: data
        });
    }

    return (<>
        {searchParams.customer && (<div>
            <div>
                {mealItemData.data.length !== 0 && (
                    <div className="text-right">
                        <ExportButton exportData={getExportData()} fileName={exportFileName} />
                    </div>
                )}
                <FilterPanel
                    onParamsChangeSave={handleSearchParamsSave}
                    customerData={customerData}
                    locationsData={locationsData}
                    priceCategoryData={priceCategoryData}
                    suppliersData={supplierData}
                    searchParams={searchParams}
                />
            </div>
            <div>
                {!mealItemData.isLoading && searchParams.customer && searchParams.location && !supplierData.isLoading && supplierData.data.length !== 0 && (
                    <MealSelectorDialogContainer
                        suppliers={supplierData.data}
                        priceCategoryData={priceCategoryData}
                        addItems={handleAddMealItems}
                        mealTypeIndex={searchParams.mealType}
                        applyChanges={applyChanges}
                        deleteItemAvailable={mealItemData.data.length && mealItemData.selected.length}
                        updateItemAvailable={mealItemData.data.length && mealItemData.selected.length}
                        saveItemAvailable={(mealItemData.data.filter(item => item.id && item.changed).length || mealItemData.data.filter(item => !item.id).length || mealItemData.deleted.length) && !mealItemData.isSubmitLoading}
                        addItemAvailable={supplierData.data.length && searchParams.customer && searchParams.location && !mealItemData.isSubmitLoading}
                        updateItems={handleUpdateItems}
                        intl={intl}
                        deleteItems={handleDeleteItems}
                        takenItemsIDs={mealItemData.data}
                        updateBatchItems={applyBatchChanges}


                        addComboAvailable={supplierData.data.length && searchParams.customer && searchParams.location && !mealItemData.isSubmitLoading}
                        addCombos={handleAddMealCombos}
                    />)}
            </div>
            <div className="col-12">
                {mealItemData.isLoading && <DishLoader height={30}/>}
                {!mealItemData.isLoading && mealItemData.data?.length !== 0 && (<MealItemList
                    mealItems={mealItemData.data}
                    onSelect={handleSelectMealItem}
                    intl={intl}
                    onItemFieldChange={onItemFieldChange}
                    selected={mealItemData.selected}
                    onToggleAll={handleToggleAll}
                    loading={mealItemData.isLoading}
                    takenItemsIDs={mealItemData.data}
                />)}
            </div>
        </div>)}
    </>);
}

export default withRouter(injectIntl(MealsManagementAdminPage));
