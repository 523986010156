import React, { useEffect, useRef, useState } from "react";
import Radio from '@material-ui/core//Radio';
import "./PaymentSelectionForm.scss"
import {Checkbox} from "@material-ui/core";

function PaymentSelectionForm({intl, id, name, selected, availableAmount, onSelect, fullAmount, selectedType, selectedAmount, onChangeAmount, isFullAmountSelected, onSelectOption, isInvalid, amountInputRef}) {

    useEffect(() => {
        if (!selected) {
            onSelectOption(id, '');
            onChangeAmount(id, 0)
        }
    }, [selected])

    useEffect(() => {
        if (selectedType === 'all') {
            onChangeAmount(id, fullAmount)
        }
    }, [selectedType])

  return (
    <div className={`payment-selection-form payment-selection-form${selected ? "--selected" : ""}`} >
      <div>
          <span>
              <Checkbox selected={selected} onChange={ev => onSelect(!selected)}></Checkbox>
          </span>
          {
              availableAmount && (
                  <span>
                      {name} {availableAmount < 0 ? intl.formatMessage({ id: "CREATE_FORM.NO_LIMITATION" }) : '(' + availableAmount + ')'}
                  </span>
              )
          }
          {
              <span>
                  {name}
              </span>
          }
      </div>
        {
            selected && (
                <>
                    <div className="payment-selection-form__option-selector">
                        <div className={`payment-selection-form__option ${isInvalid ? "--error" : ""}`}>
                            <Radio
                                disableRipple
                                disable={!selected}
                                checked={selectedType === 'all'}
                                onChange={(ev) => {
                                    ev.stopPropagation();
                                    if (availableAmount < fullAmount && availableAmount > 0) {
                                        onSelectOption(id, "part");
                                        onChangeAmount(id, availableAmount);
                                    } else {
                                        onSelectOption(id, "all");
                                    }
                                }}
                            />
                              <span className="payment-selection-form__option-text">{`₪${availableAmount < fullAmount && availableAmount > 0 ? availableAmount : fullAmount}`}</span>
                        </div>
                        <div className="payment-selection-form__option" onClick={() => onSelectOption(id, "part")}>
                            <Radio
                                disable={!selected}
                                disableRipple
                                checked={selectedType === 'part'}
                                onChange={(ev) => {
                                    ev.stopPropagation();
                                    onSelectOption(id, "part")
                                }}
                            />
                            <span className="payment-selection-form__option-text">{intl.formatMessage({ id: "order.payment_selection_form.partial_amount" })}</span>
                        </div>
                    </div>
                    {
                        selectedType==='part' && (
                            <div ref={amountInputRef} className="payment-selection-form__amount-input-wrapper">
                                <input
                                    type="number"
                                    value={selectedAmount}
                                    disabled={isFullAmountSelected}
                                    onChange={(event) => onChangeAmount(id, event.target.value)}
                                    placeholder={intl.formatMessage({ id: "order.payment_selection_form.enter_amount" })}
                                    className={`payment-selection-form__amount-input ${isInvalid ? "--error" : ""}`}
                                    dir="ltr"
                                />
                            </div>
                        )
                    }
                </>
            )
        }
    </div>
  )
}

export default PaymentSelectionForm
