/* eslint-disable no-restricted-imports */
import React from "react"
import { Paper } from "@material-ui/core"
import { injectIntl } from "react-intl"
import Downshift from "downshift"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../Common/downshiftFunctions"
import { useStyles } from "../Common/_styles/headerDownshiftStyles"

function SupplierDownshift({
  intl,
  selectedSupplier,
  selectSupplier,
  suppliersData
}) {
  const classes = useStyles()

  function handleSelectedSupplierChange(value) {
    selectSupplier(value)
  }

  const suppliers = suppliersData.suppliers.filter(item => item.State === 'active');;

  return (
    <div className="position-relative">
      {suppliersData.isLoading && (
        <span
          className={`ml-1 spinner spinner-black ${classes.spinner}`}
        ></span>
      )}
      <Downshift
        id="downshift-for-supplier"
        selectedItem={selectedSupplier.Name ? selectedSupplier : null}
        onChange={selected => handleSelectedSupplierChange(selected || {})}
        itemToString={item => (item ? item.Name : "")}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem
        }) => {
          const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
            onChange: event => {
              if (event.target.value === "") {
                clearSelection()
              }
            },
            onFocus: openMenu,
            placeholder: intl.formatMessage({
              id: "SUPPLIER_FIELD.PLACEHOLDER"
            })
          })

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                label: intl.formatMessage({
                  id: "SUPPLIER_FIELD.LABEL"
                }),
                disabled: suppliersData.isLoading,
                InputLabelProps: getLabelProps({
                  shrink: true
                }),
                InputProps: { onBlur, onChange, onFocus },
                inputProps
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue, suppliers, {
                      showEmpty: true
                    }).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion
                        }),
                        highlightedIndex,
                        selectedItem
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}
export default injectIntl(SupplierDownshift)
