/* eslint-disable no-restricted-imports */

import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {
    Button,
    Chip,
    FilledInput,
    FormControl,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@material-ui/core"
import {FormattedMessage, injectIntl} from "react-intl"
import {useFormik} from "formik"
import * as Yup from "yup"
import DeleteIcon from "@material-ui/icons/Delete"
import MealInfoForm from "./Meals/MealInfoForm"
import {Tab, Tabs} from "react-bootstrap"
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { he, enUS } from "date-fns/esm/locale"
import PlacesAutocomplete from "react-places-autocomplete"
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete"
import moment from "moment"
import {formatDate, formatDateForApi} from "../../Common/momentFunctions"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../../redux/snackbar/snackbarHandlers"
import DishLoader from "../../Common/DishLoader"
import PriceRangesPanel from "../PriceRanges/PriceRangesPanel"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    priceRoot: {
        width: "100%",
        marginTop: theme.spacing(3),
        maxHeight: 300,
        overflowY: "auto"
    },
    item: {
        marginBottom: theme.spacing(1),
        border: "1px solid #ebedf3"
    },
    paper: {
        position: "absolute",
        zIndex: 10,
        left: 0,
        right: 0
    },
    textField: {
        margin: 0
    },
    input: {
        padding: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    divider: {
        margin: theme.spacing(0, 1)
    },
    containerInput: {
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.09)'
    },
    error: {position: "absolute", bottom: -5, left: 10, color: "#F018A6"},
    loaderContainer: {
        position: "relative"
    },
    loaderContainerItem: {
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    supplierItem: {
        padding: '1rem',
        borderBottom: '1px solid lightgrey'
    },
    supplierItemContainer: {
        maxHeight: '300px',
        overflowY: 'auto'
    },
    mealContainer: {
        margin: '1.5rem'
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    timeItem: {
        width: '45%'
    },
    timeItemDelimiter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        boxSizing: 'border-box'
    }
}))

const mealTypes = ["Breakfast", "Lunch", "Dinner", "Store"]
const mealInfoFieldNames = {}
mealTypes.forEach(
    mealType =>
        (mealInfoFieldNames[mealType.toLocaleLowerCase()] = {
            days: `daysFor${mealType}`,
            deliveryMinTime: `deliveryMinTimeFor${mealType}`,
            deliveryMaxTime: `deliveryMaxTimeFor${mealType}`,

            userCloseCancelTime: `userCloseCancelTimeFor${mealType}`,
            userCloseOrderTime: `userCloseOrderTimeFor${mealType}`,
            userCloseEditTime: `userCloseEditTimeFor${mealType}`,
            managerCloseCancelTime: `managerCloseCancelTimeFor${mealType}`,
            managerCloseOrderTime: `managerCloseOrderTimeFor${mealType}`,
            managerCloseEditTime: `managerCloseEditTimeFor${mealType}`,

            menuFavouriteTags: `menuFavouriteTagsFor${mealType}`,
            mealTimes: `mealTimesFor${mealType}`,
            mealDeliveryTimes: `mealDeliveryTimesFor${mealType}`,

            minOrderQuantity: `minOrderQuantityFor${mealType}`,

            mealItems: `mealItemsFor${mealType}`,
            mealCombos: `mealCombosFor${mealType}`,

            mealID: `mealIDFor${mealType}`,
            ignoreOrderType: `ignoreOrderTypeFor${mealType}`
        })
)

function LocationForm({
                          intl,
                          onSubmit,
                          selectedLocation,
                          locations,
                          allSuppliers,
                          allTags,
                          saveMeal,
                          updateLocations,
                          handleDelete,
                          loading,
                          saveLocation
                      }) {
    const locale = intl.locale === "en" ? enUS : he;
    const dispatch = useDispatch()
    const classes = useStyles()

    const [mealsData, setMealsData] = useState({
        meals: [],
        isLoading: false
    })

    const [allTakenItems, setAllTakenItems] = useState({
        1: [],
        2: [],
        3: [],
        4: []
    })
    const [allTakenCombos, setAllTakenCombos] = useState({
        1: [],
        2: [],
        3: [],
        4: []
    })
    const [mealsSearchText, setMealsSearchText] = useState("")

    const initialValues = {
        name: selectedLocation.Name || "",
        supplierDisplayName: selectedLocation.SupplierDisplayName || "",
        address: selectedLocation.Address || "",
        googleAddress: selectedLocation.GoogleAddress || "",

        country: selectedLocation.Country || "",
        street: selectedLocation.Street || "",
        city: selectedLocation.City || "",
        houseNumber: selectedLocation.HouseNumber || "",
        entrance: selectedLocation.Entrance || "",
        floor: selectedLocation.Floor || "",
        apartment: selectedLocation.Apartment || "",
        comments: selectedLocation.Comments || "",

        geoPoint: selectedLocation.GeoPoint || {},
        orderType: selectedLocation.OrderType || 1,
        suppliers: selectedLocation.Suppliers
            ? selectedLocation.Suppliers.map(supplier => supplier.SupplierID)
            : [],
        orderTime:
            selectedLocation.OrderTime && selectedLocation.OrderTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedLocation.OrderTime}`)
                : null,
        reportEmails: selectedLocation.ReportEmails || "",
        reportDeliveryTime:
            selectedLocation.ReportDeliveryTime &&
            selectedLocation.ReportDeliveryTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedLocation.ReportDeliveryTime}`)
                : null
    }
    mealTypes.forEach((mealType, index) => {
        const {
            days,
            deliveryMinTime,
            deliveryMaxTime,
            userCloseCancelTime,
            userCloseOrderTime,
            userCloseEditTime,
            managerCloseCancelTime,
            managerCloseOrderTime,
            managerCloseEditTime,
            menuFavouriteTags,
            mealTimes,
            mealDeliveryTimes,
            minOrderQuantity,
            mealItems,
            mealCombos,
            mealID,
            ignoreOrderType
        } = mealInfoFieldNames[mealType.toLocaleLowerCase()]
        const mealInfoData = selectedLocation.MealsInfo.find(
            mealInfo => mealInfo.MealType === index + 1
        )

        if (mealInfoData) {
            initialValues[mealID] = mealInfoData.id

            initialValues[days] = [...mealInfoData.Days]
            initialValues[ignoreOrderType] = mealInfoData.IgnoreOrderType

            initialValues[deliveryMinTime] = mealInfoData.DeliveryMinTime
                ? new Date(`2020-04-20T${mealInfoData.DeliveryMinTime}`)
                : null
            initialValues[deliveryMaxTime] = mealInfoData.DeliveryMaxTime
                ? new Date(`2020-04-20T${mealInfoData.DeliveryMaxTime}`)
                : null

            initialValues[userCloseCancelTime] =
                +mealInfoData.UserCloseCancelTime || 0
            initialValues[userCloseOrderTime] = +mealInfoData.UserCloseOrderTime || 0
            initialValues[userCloseEditTime] = +mealInfoData.UserCloseEditTime || 0
            initialValues[managerCloseCancelTime] =
                +mealInfoData.ManagerCloseCancelTime || 0
            initialValues[managerCloseOrderTime] =
                +mealInfoData.ManagerCloseOrderTime || 0
            initialValues[managerCloseEditTime] =
                +mealInfoData.ManagerCloseEditTime || 0

            initialValues[menuFavouriteTags] = mealInfoData.MenuFavoritTags
                ? mealInfoData.MenuFavoritTags.map(tag => tag.TagID)
                : [] //Remove after fix

            initialValues[mealTimes] = (selectedLocation.Suppliers || []).slice().map(item => {
                const mealTime = mealInfoData.MealTimes ?
                    mealInfoData.MealTimes.find(x => x.SupplierId === item.SupplierID)?.Time ?? null : null
                const mealPrintTime = mealInfoData.MealTimes ?
                    mealInfoData.MealTimes.find(x => x.SupplierId === item.SupplierID)?.PrintTime ?? null : null
                return {
                    SupplierId: item.SupplierID,
                    Name: item.Name,
                    Time: mealTime ? `2020-10-12 ${mealTime}` : null,
                    PrintTime: mealPrintTime ? `2020-10-12 ${mealPrintTime}` : null,
                }
            })
            initialValues[mealDeliveryTimes] = (mealInfoData.MealDeliveryTimes || []).slice().map(item => {
                return {
                    ...item,
                    From: item.From ? `2020-10-12 ${item.From}` : null,
                    To: item.To ? `2020-10-12 ${item.To}` : null,
                }
            })


            initialValues[minOrderQuantity] = mealInfoData.MinOrderQuantity
                ? mealInfoData.MinOrderQuantity
                : 0

            initialValues[mealItems] = [...mealInfoData.MealItems]
            initialValues[mealCombos] = [...mealInfoData.MealCombos || []]
        } else {
            initialValues[days] = []
            initialValues[ignoreOrderType] = true
            initialValues[deliveryMinTime] = null
            initialValues[deliveryMaxTime] = null

            initialValues[userCloseCancelTime] = 0
            initialValues[userCloseOrderTime] = 0
            initialValues[userCloseEditTime] = 0
            initialValues[managerCloseCancelTime] = 0
            initialValues[managerCloseOrderTime] = 0
            initialValues[managerCloseEditTime] = 0

            initialValues[menuFavouriteTags] = []
            initialValues[mealTimes] = []
            initialValues[mealDeliveryTimes] = []

            initialValues[minOrderQuantity] = 1

            initialValues[mealItems] = []
            initialValues[mealCombos] = []
        }
    })

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            value => {
                const location = locations.find(location => location.Name === value)
                return (
                    location === undefined ||
                    location.LocationID === selectedLocation.LocationID
                )
            }
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const stringValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const orderTypeValidationSchema = Yup.number().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const numberValidationSchema = Yup.number()
        .min(0, intl.formatMessage({id: "VALIDATION.MIN_VALUE"}))
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    //const arrayValidationSchema = Yup.array().test(
    //    "NotEmpty",
    //    intl.formatMessage({
    //        id: "VALIDATION.REQUIRED_FIELD"
    //    }),
    //    value => {
    //        return value.length > 0
    //    }
    //)

    const dateValidationSchema = Yup.date()
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )
        .nullable()

    const validationSchemas = {
        name: nameValidationSchema,
        address: stringValidationSchema,
        orderType: orderTypeValidationSchema,
        //suppliers: arrayValidationSchema
    }

    const validationSchemaFieldNames = [
        "name",
        "address",
        "orderType",
        //"suppliers",
        "userCloseCancelTime",
        "userCloseOrderTime",
        "userCloseEditTime",
        "managerCloseCancelTime",
        "managerCloseOrderTime",
        "managerCloseEditTime"
    ]

    mealTypes.forEach(mealType => {
        const {
            deliveryMinTime,
            orderCloseInterval,
            orderCloseManagerInterval,
            userCloseCancelTime,
            userCloseOrderTime,
            userCloseEditTime,
            managerCloseCancelTime,
            managerCloseOrderTime,
            managerCloseEditTime,
            minOrderQuantity
        } = mealInfoFieldNames[mealType.toLocaleLowerCase()]
        validationSchemaFieldNames.push(deliveryMinTime)
        validationSchemaFieldNames.push(orderCloseInterval)
        validationSchemaFieldNames.push(orderCloseManagerInterval)
        validationSchemaFieldNames.push(userCloseCancelTime)
        validationSchemaFieldNames.push(userCloseOrderTime)
        validationSchemaFieldNames.push(userCloseEditTime)
        validationSchemaFieldNames.push(managerCloseCancelTime)
        validationSchemaFieldNames.push(managerCloseOrderTime)
        validationSchemaFieldNames.push(managerCloseEditTime)

        validationSchemaFieldNames.push(minOrderQuantity)

        validationSchemas[deliveryMinTime] = dateValidationSchema

        validationSchemas[userCloseCancelTime] = numberValidationSchema
        validationSchemas[userCloseOrderTime] = numberValidationSchema
        validationSchemas[userCloseEditTime] = numberValidationSchema
        validationSchemas[managerCloseCancelTime] = numberValidationSchema
        validationSchemas[managerCloseOrderTime] = numberValidationSchema
        validationSchemas[managerCloseEditTime] = numberValidationSchema

        validationSchemas[minOrderQuantity] = numberValidationSchema
    })

    const validationSchema = Yup.object().shape(
        validationSchemas,
        validationSchemaFieldNames
    )

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            const {
                name,
                supplierDisplayName,
                address,
                googleAddress,

                country,
                street,
                city,
                houseNumber,
                entrance,
                floor,
                apartment,
                comments,

                geoPoint,
                orderType,
                suppliers,
                reportEmails,
                reportDeliveryTime
            } = values

            let newLocation = {}
            let mealsInfo = []
            mealTypes.forEach((mealType, index) => {
                const {
                    days,
                    deliveryMinTime,
                    deliveryMaxTime,
                    orderCloseInterval,
                    orderCloseManagerInterval,
                    menuFavouriteTags,
                    mealTimes,
                    mealDeliveryTimes,
                    mealItems,
                    mealCombos,
                    userCloseCancelTime,
                    userCloseOrderTime,
                    userCloseEditTime,
                    managerCloseCancelTime,
                    managerCloseOrderTime,
                    managerCloseEditTime,
                    minOrderQuantity,
                    ignoreOrderType
                } = mealInfoFieldNames[mealType.toLocaleLowerCase()]

                mealsInfo[index] = {...selectedLocation.MealsInfo[index]} || {
                    MealType: index + 1
                }
                mealsInfo[index].Days = values[days]
                mealsInfo[index].DeliveryMinTime = values[deliveryMinTime]
                    ? moment(values[deliveryMinTime]).format("HH:mm:ss")
                    : null
                mealsInfo[index].DeliveryMaxTime = values[deliveryMaxTime]
                    ? moment(values[deliveryMaxTime]).format("HH:mm:ss")
                    : null
                mealsInfo[index].OrderCloseInterval = values[orderCloseInterval]
                mealsInfo[index].OrderCloseManagerInterval =
                    values[orderCloseManagerInterval]
                mealsInfo[index].MenuFavoritTags = allTags.filter(tag =>
                    values[menuFavouriteTags].includes(tag.TagID)
                )
                mealsInfo[index].MealTimes = values[mealTimes].slice().filter(mealItem => mealItem.Time ||
                        mealItem.PrintTime)
                    .map(t => {
                        return {
                            ...t,
                            Time: t.Time ? moment(t.Time).format("HH:mm:ss") : null,
                            PrintTime: t.PrintTime ? moment(t.PrintTime).format("HH:mm:ss") : null
                        }
                    });
                mealsInfo[index].MealDeliveryTimes = values[mealDeliveryTimes]
                    .slice().filter(mealItem => mealItem.From ||
                    mealItem.To)
                    .map(t => {
                        return {
                            ...t,
                            From: t.From ? moment(t.From).format("HH:mm:ss") : null,
                            To: t.To ? moment(t.To).format("HH:mm:ss") : null
                        }
                    });
                mealsInfo[index].MealItems = values[mealItems].map(mealItem => ({
                    ...mealItem,
                    From: mealItem.From ? formatDateForApi(mealItem.From) : null,
                    To: mealItem.To ? formatDateForApi(mealItem.To) : null
                }))
                mealsInfo[index].MealCombos = values[mealCombos].map(mealCombo => ({
                    ...mealCombo,
                    From: mealCombo.From ? formatDateForApi(mealCombo.From) : null,
                    To: mealCombo.To ? formatDateForApi(mealCombo.To) : null
                }))

                mealsInfo[index].UserCloseCancelTime = values[userCloseCancelTime]
                mealsInfo[index].UserCloseOrderTime = values[userCloseOrderTime]
                mealsInfo[index].UserCloseEditTime = values[userCloseEditTime]
                mealsInfo[index].ManagerCloseCancelTime = values[managerCloseCancelTime]
                mealsInfo[index].ManagerCloseOrderTime = values[managerCloseOrderTime]
                mealsInfo[index].ManagerCloseEditTime = values[managerCloseEditTime]
                mealsInfo[index].MinOrderQuantity = values[minOrderQuantity]
                mealsInfo[index].IgnoreOrderType = values[ignoreOrderType]
            })

            newLocation.LocationID = selectedLocation.LocationID
            newLocation.CustomerID = selectedLocation.CustomerID
            newLocation.Name = name
            newLocation.SupplierDisplayName = supplierDisplayName
            newLocation.Address = address
            newLocation.GoogleAddress = JSON.stringify({
                country,
                city,
                street,
                house_number: houseNumber,
                entrance: entrance ?? null,
                floor: floor ?? null,
                apartment: apartment ?? null,
                company_name: name,
                remark: comments ?? null,
                latitude: geoPoint?.lat ?? null,
                longitude: geoPoint.lng ?? null,
            });
            newLocation.Country = country
            newLocation.Street = street
            newLocation.City = city
            newLocation.HouseNumber = houseNumber
            newLocation.Entrance = entrance
            newLocation.Floor = floor
            newLocation.Apartment = apartment
            newLocation.Comments = comments

            newLocation.GeoPoint = geoPoint
            newLocation.OrderType = orderType
            newLocation.Suppliers = allSuppliers.filter(supplier =>
                suppliers.includes(supplier.SupplierID))
            newLocation.ReportEmails = reportEmails
            newLocation.ReportDeliveryTime = reportDeliveryTime ?
                moment(reportDeliveryTime).format("HH:mm:ss") : "00:00:00"


            setMealsData({meals: [], isLoading: true})

            const promises = []
            mealsInfo.forEach(mealInfo => promises.push(saveMeal(mealInfo)))
            Promise.all(promises)
                .then(results => {
                    onSubmit(newLocation)
                        .then(() => {
                            updateLocations({
                                ...newLocation,
                                MealsInfo: mealsInfo
                            })
                            setMealsData({meals: [], isLoading: false})
                        })
                        .catch(error => {
                            handleApiError(
                                dispatch,
                                error,
                                intl.formatMessage({
                                    id: "API.ERROR.FAILED_TO_UPDATE_LOCATION"
                                })
                            )
                            setSubmitting(false)
                            setMealsData({meals: [], isLoading: false})
                        })
                })
                .catch(error => {
                    setSubmitting(false)
                    setMealsData({meals: [], isLoading: false})
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_UPDATE_MEAL_INFO"
                        })
                    )
                })
        }
    })


    function saveMealCombos(mealID, valueName, newMealCombos, closeDialog) {
        const supplierNames = [...new Set(newMealCombos.flatMap(combo =>
            combo.Combo.Items.map(item => item.SupplierName)
        ))];

        const supplierIds = supplierNames.map(name => {
            const supplier = allSuppliers.find(s => s.Name === name);
            return supplier ? supplier.SupplierID : null;
        }).filter(id => id !== null);

        const newSupplierIds = supplierIds.filter(id => !formik.values.suppliers.includes(id));

        if (newSupplierIds.length > 0) {
            formik.setFieldValue('suppliers', [...formik.values.suppliers, ...newSupplierIds]);
        }

        closeDialog()
        formik.setFieldValue(
            valueName,
            newMealCombos.map(mealCombo => ({
                ...mealCombo,
                id: formik.getFieldProps(valueName).value.find(
                    el => el.Combo.Id === mealCombo.Combo.Id
                )?.id
            }))
        )
    }

    function saveMealItems(mealID, valueName, newMealItems, closeDialog) {
        setMealsData({ meals: [], isLoading: true })

        const supplierNames = [...new Set(newMealItems.map(item => item.Item.SupplierName))];
        const supplierIds = supplierNames.map(name => {
            const supplier = allSuppliers.find(s => s.Name === name);
            return supplier ? supplier.SupplierID : null;
        }).filter(id => id !== null);

        const newSupplierIds = supplierIds.filter(id => !formik.values.suppliers.includes(id));

        if (newSupplierIds.length > 0) {
            formik.setFieldValue('suppliers', [...formik.values.suppliers, ...newSupplierIds]);
        }
        selectedLocation.Suppliers = allSuppliers.filter(supplier =>
            formik.values.suppliers.includes(supplier.SupplierID) || newSupplierIds.includes(supplier.SupplierID))
        saveLocation(selectedLocation)
        saveMeal({
            ...selectedLocation.MealsInfo.find(mealInfo => mealInfo.id === mealID),
            MealItems: newMealItems.map(mealItem => ({
                ...mealItem,
                From: mealItem.From ? formatDateForApi(mealItem.From) : null,
                To: mealItem.To ? formatDateForApi(mealItem.To) : null
            }))
        })
            .then(({data}) => {                                                                                                                                                                                             
                setMealsData({meals: [], isLoading: false})
                closeDialog()
                formik.setFieldValue(
                    valueName,
                    newMealItems.map(mealItem => ({
                        ...mealItem,
                        id: data.MealItems.find(
                            el => el.Item.ItemID === mealItem.Item.ItemID
                        ).id
                    }))
                )
            })
            .catch(error => {
                closeDialog()
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UPDATE_MEAL_INFO"
                    })
                )
            })
    }

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span className={classes.error}>{formik.errors[fieldName]}</span>
        ) : null
    }

    function handleSelectPlace(value, placeId, suggestion) {
        formik.setFieldValue("address", value)
        geocodeByAddress(value)
            .then(results => {
                const country =
                    results[0].address_components.find(component => component.types.indexOf('country') !== -1)?.long_name;
                const city =
                    results[0].address_components.find(component => component.types.indexOf('locality') !== -1)?.long_name;
                const street =
                    results[0].address_components.find(component => component.types.indexOf('route') !== -1)?.long_name;
                const streetNumber =
                    results[0].address_components.find(component => component.types.indexOf('street_number') !== -1)?.long_name;

                formik.setFieldValue("country", country ?? null);
                formik.setFieldValue("city", city ?? null);
                formik.setFieldValue("street", street ?? null);
                formik.setFieldValue("houseNumber", streetNumber ?? null);

                return getLatLng(results[0])
            })
            .then(({lat, lng}) => {
                formik.setFieldValue("geoPoint", {lat, lng})
            })
    }

    useEffect(() => {
        const takenItems = {}
        takenItems["1"] = formik.values[mealInfoFieldNames.breakfast.mealItems].map(mealItem => mealItem.Item.ItemID)
        takenItems["2"] = formik.values[mealInfoFieldNames.lunch.mealItems].map(mealItem => mealItem.Item.ItemID)
        takenItems["3"] = formik.values[mealInfoFieldNames.dinner.mealItems].map(mealItem => mealItem.Item.ItemID)
        takenItems["4"] = formik.values[mealInfoFieldNames.store.mealItems].map(mealItem => mealItem.Item.ItemID)
        setAllTakenItems(takenItems)
    }, [
        formik.values[mealInfoFieldNames.breakfast.mealItems],
        formik.values[mealInfoFieldNames.lunch.mealItems],
        formik.values[mealInfoFieldNames.dinner.mealItems],
        formik.values[mealInfoFieldNames.store.mealItems]
    ])

    useEffect(() => {
        const takenCombos = {}
        takenCombos["1"] = formik.values[mealInfoFieldNames.breakfast.mealCombos].map(mealCombo => mealCombo.Combo.Id)
        takenCombos["2"] = formik.values[mealInfoFieldNames.lunch.mealCombos].map(mealCombo => mealCombo.Combo.Id)
        takenCombos["3"] = formik.values[mealInfoFieldNames.dinner.mealCombos].map(mealCombo => mealCombo.Combo.Id)
        takenCombos["4"] = formik.values[mealInfoFieldNames.store.mealCombos].map(mealCombo => mealCombo.Combo.Id)
        setAllTakenCombos(takenCombos)
    }, [
        formik.values[mealInfoFieldNames.breakfast.mealCombos],
        formik.values[mealInfoFieldNames.lunch.mealCombos],
        formik.values[mealInfoFieldNames.dinner.mealCombos],
        formik.values[mealInfoFieldNames.store.mealCombos]
    ])

    function randomizePriority(items) {
        const priorities = [...Array(items.length).keys()];

        for (let i = priorities.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [priorities[i], priorities[j]] = [priorities[j], priorities[i]];
        }

        const updatedItems = items.map((item, index) => ({
            ...item,
            Priority: priorities[index]
        }));

        return updatedItems;
    }


    const filterDispayedItems = item =>
        item.Item.Name.toLowerCase().includes(mealsSearchText.toLowerCase())

    const filterDispayedCombos = combo => {
        return combo.Combo.Name?.toLowerCase().includes(mealsSearchText.toLowerCase())
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={locale}>
            <div className={classes.loaderContainer}>
                <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                    <div className={classes.root}>
                        <div className="row">
                            <div className="col-5">
                                <div className="row">
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.NAME_FIELD.LABEL"/>
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <TextField
                                            id="name"
                                            className={classes.textField}
                                            margin="normal"
                                            variant="filled"
                                            hiddenLabel
                                            inputProps={{
                                                className: classes.input
                                            }}
                                            fullWidth
                                            {...formik.getFieldProps("name")}
                                            error={checkForError("name")}
                                        />
                                        {renderErrors("name")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.SUPPLIER_DISPLAY_NAME_FIELD.LABEL"/>
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <TextField
                                            id="supplier-display-name"
                                            className={classes.textField}
                                            margin="normal"
                                            variant="filled"
                                            hiddenLabel
                                            inputProps={{
                                                className: classes.input
                                            }}
                                            fullWidth
                                            {...formik.getFieldProps("supplierDisplayName")}
                                            error={checkForError("supplierDisplayName")}
                                        />
                                        {renderErrors("supplierDisplayName")}
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.ADDRESS_FIELD.LABEL"/>
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <PlacesAutocomplete
                                            value={formik.values.address}
                                            onChange={value => formik.setFieldValue("address", value)}
                                            onSelect={handleSelectPlace}
                                            searchOptions={{country: "il", language: "he", types: ['address'], fields: ["address_components", "street_number"],}}
                                        >
                                            {({
                                                  getInputProps,
                                                  suggestions,
                                                  getSuggestionItemProps,
                                                  loading
                                              }) => (
                                                <div className="position-relative">
                                                    <TextField
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="filled"
                                                        fullWidth
                                                        inputProps={{
                                                            className: classes.input
                                                        }}
                                                        InputProps={{
                                                            ...getInputProps({
                                                                language: 'he'
                                                            })
                                                        }}
                                                        error={checkForError("address")}
                                                    />
                                                    <Paper className={classes.paper} square>
                                                        {loading && (
                                                            <div className="spinner spinner-blue mt-3"></div>
                                                        )}
                                                        {suggestions.map(suggestion => {
                                                            return (
                                                                <MenuItem
                                                                    component="div"
                                                                    {...getSuggestionItemProps(suggestion)}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    {suggestion.description}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Paper>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {renderErrors("address")}
                                    </div>
                                </div>


                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.COUNTRY_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("country")}*/}
                                {/*            error={checkForError("country")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("country")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.STREET_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("street")}*/}
                                {/*            error={checkForError("street")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("street")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.CITY_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("city")}*/}
                                {/*            error={checkForError("city")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("city")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.HOUSE_NUMBER_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("houseNumber")}*/}
                                {/*            error={checkForError("houseNumber")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("houseNumber")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.ENTRANCE_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("entrance")}*/}
                                {/*            error={checkForError("entrance")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("entrance")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.FLOOR_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("floor")}*/}
                                {/*            error={checkForError("floor")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("floor")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.APARTMENT_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("apartment")}*/}
                                {/*            error={checkForError("apartment")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("apartment")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div*/}
                                {/*        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">*/}
                                {/*        <FormattedMessage id="CREATE_FORM.COMMENTS_FIELD.LABEL"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-8 pl-3 pt-3 pb-3">*/}
                                {/*        <TextField*/}
                                {/*            id="supplier-display-name"*/}
                                {/*            className={classes.textField}*/}
                                {/*            margin="normal"*/}
                                {/*            variant="filled"*/}
                                {/*            hiddenLabel*/}
                                {/*            inputProps={{*/}
                                {/*                className: classes.input*/}
                                {/*            }}*/}
                                {/*            fullWidth*/}
                                {/*            {...formik.getFieldProps("comments")}*/}
                                {/*            error={checkForError("comments")}*/}
                                {/*        />*/}
                                {/*        {renderErrors("comments")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>
                            <div className="row" hidden>
                                <div
                                    className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                    <FormattedMessage id="CREATE_FORM.SUPPLIERS_FIELD.LABEL" />
                                </div>
                                <div className="col-8 pl-3 pt-3 pb-3">
                                    <FormControl
                                        variant="filled"
                                        className={classes.textField}
                                        fullWidth
                                        error={checkForError("suppliers")}
                                    >
                                        <Select
                                            {...formik.getFieldProps("suppliers")}
                                            multiple
                                            input={
                                                <FilledInput
                                                    name="suppliers"
                                                    inputProps={{
                                                        className: classes.input
                                                    }}
                                                    id="suppliers-select"
                                                />
                                            }
                                            renderValue={selected => (
                                                <div className={classes.chips}>
                                                    {selected.map(value => (
                                                        <Chip
                                                            key={value}
                                                            className={classes.chip}
                                                            label={
                                                                allSuppliers.find(
                                                                    supplier => supplier.SupplierID === value
                                                                ).Name
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {allSuppliers.map(supplier => (
                                                <MenuItem
                                                    key={supplier.Name}
                                                    value={supplier.SupplierID}
                                                >
                                                    {supplier.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {renderErrors("suppliers")}
                                </div>
                                {process.env.REACT_APP_FLAVOR != 'NY' ?
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.PRICE_RANGES_FIELD.LABEL" />
                                    </div>
                                    : <div></div>}
                                <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="col-8 pl-3 pt-3 pb-3">
                                    <PriceRangesPanel
                                        selectedLocation={selectedLocation}

                                    />

                                </div>
                            </div>

                            <div className="col-5">

                                <div className="row">
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.ORDER_TYPE_FIELD.LABEL" />
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <FormControl
                                            variant="filled"
                                            className={classes.textField}
                                            fullWidth
                                        >
                                            <Select
                                                {...formik.getFieldProps("orderType")}
                                                input={
                                                    <FilledInput
                                                        name="orderType"
                                                        inputProps={{
                                                            className: classes.input
                                                        }}
                                                        id="order-type-select"
                                                    />
                                                }
                                            >
                                                <MenuItem value={1}>
                                                    <FormattedMessage id="CREATE_FORM.TYPE_FIELD.VALUE.DAY_TO_DAY" />
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    <FormattedMessage
                                                        id="CREATE_FORM.TYPE_FIELD.VALUE.TODAY_TO_TOMORROW" />
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    <FormattedMessage id="CREATE_FORM.TYPE_FIELD.VALUE.WEEKLY" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row">

                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.REPORT_DELIVERY_TIME.LABEL" />
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <TimePicker
                                            autoOk
                                            ampm={false}
                                            value={formik.values.reportDeliveryTime}

                                            className={classes.textField}
                                            onChange={value => {
                                                formik.setFieldValue("reportDeliveryTime", value)
                                            }}
                                            clearable
                                            TextFieldComponent={({
                                                helperText,
                                                InputProps,
                                                ...props
                                            }) => (
                                                <FormControl
                                                    variant="filled"
                                                    className={classes.textField}
                                                    fullWidth
                                                >
                                                    <TextField
                                                        name="reportDeliveryTime"
                                                        margin="normal"
                                                        variant="filled"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            className: classes.containerInput
                                                        }}
                                                        hiddenLabel
                                                        {...props}
                                                        error={checkForError("reportDeliveryTime")}
                                                    />
                                                </FormControl>
                                            )}
                                        />

                                    </div>

                                </div>

                                <div className="row">
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="LOCATION_DETAIL.LABELS.REPORT_EMAILS" />
                                    </div>
                                    <div className="col-8 pl-3 pt-3 pb-3">
                                        <div className="position-relative">
                                            <TextField
                                                id="report-emails"
                                                name="reportEmails"
                                                className={classes.textField}
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    className: classes.containerInput
                                                }}
                                                hiddenLabel
                                                {...formik.getFieldProps("reportEmails")}
                                                error={checkForError("reportEmails")}
                                            />
                                            {renderErrors("reportEmails")}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-2 d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <Button
                                            variant="contained"
                                            type="button"
                                            size="large"
                                            className={classes.button}
                                            disabled={formik.isSubmitting}
                                            startIcon={<DeleteIcon/>}
                                            onClick={handleDelete}
                                        >
                                            <FormattedMessage id="CREATE_FORM.DELETE_BUTTON"/>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            size="large"
                                            color="secondary"
                                            className={classes.button}
                                            disabled={formik.isSubmitting}
                                        >
                                            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON"/>
                                            {formik.isSubmitting && (
                                                <span className="ml-1 spinner spinner-white"></span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 d-flex">
                                {process.env.REACT_APP_FLAVOR != 'NY' ?
                                    <div
                                        className="col-4 d-flex justify-content-end align-items-center pr-0 text-right">
                                        <FormattedMessage id="CREATE_FORM.PRICE_RANGES_FIELD.LABEL" />
                                    </div>
                                    : <div></div>}
                                <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="col-8 pt-3 pb-3">
                                    <PriceRangesPanel
                                        selectedLocation={selectedLocation}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 position-relative">
                                <Tabs
                                    defaultActiveKey="lunch"
                                    id="location-tab"
                                    unmountOnExit
                                >
                                    <Tab
                                        eventKey="breakfast"
                                        title={<FormattedMessage id="TABS.BREAKFAST"/>}
                                    >
                                        <MealInfoForm
                                            classes={classes}
                                            formik={formik}
                                            exportFileName={`${intl.formatMessage({
                                                id: "EXPORT_FILE_NAME.MEALS.BREAKFAST"
                                            })}_${formatDate(new Date())}`}
                                            locationID={selectedLocation.LocationID}
                                            fieldNames={mealInfoFieldNames.breakfast}
                                            suppliers={allSuppliers}
                                            takenItemsIDs={allTakenItems["1"]}
                                            takenCombosIDs={allTakenCombos["1"]}
                                            mealTypeIndex={"1"}
                                            displayedItems={formik.values[
                                                mealInfoFieldNames.breakfast.mealItems
                                                ].filter(item => filterDispayedItems(item))}
                                            displayedCombos={
                                                formik.values[
                                                    mealInfoFieldNames.breakfast.mealCombos
                                                    ].filter(item => filterDispayedCombos(item))
                                            }
                                            saveMealItems={saveMealItems}
                                            saveMealCombos={saveMealCombos}
                                            checkForError={checkForError}
                                            renderErrors={renderErrors}
                                            mealsSearchText={mealsSearchText}
                                            onMealsSearchTextChange={setMealsSearchText}
                                            tags={allTags}
                                            randomizePriority={() => {
                                                const updatedItems = randomizePriority(
                                                    formik.values[mealInfoFieldNames.breakfast.mealItems]
                                                        .filter(item => filterDispayedItems(item))
                                                );
                                                formik.setFieldValue(mealInfoFieldNames.breakfast.mealItems, updatedItems);
                                            }}          
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="lunch"
                                        title={<FormattedMessage id="TABS.LUNCH"/>}
                                    >
                                        <MealInfoForm
                                            classes={classes}
                                            formik={formik}
                                            exportFileName={`${intl.formatMessage({
                                                id: "EXPORT_FILE_NAME.MEALS.LUNCH"
                                            })}_${formatDate(new Date())}`}
                                            locationID={selectedLocation.LocationID}
                                            fieldNames={mealInfoFieldNames.lunch}
                                            suppliers={allSuppliers}
                                            takenItemsIDs={allTakenItems["2"]}
                                            takenCombosIDs={allTakenCombos["2"]}
                                            mealTypeIndex={"2"}
                                            displayedItems={formik.values[
                                                mealInfoFieldNames.lunch.mealItems
                                                ].filter(item => filterDispayedItems(item))}
                                            saveMealItems={saveMealItems}

                                            displayedCombos={formik.values[
                                                mealInfoFieldNames.lunch.mealCombos
                                                ].filter(item => filterDispayedCombos(item))}
                                            saveMealCombos={saveMealCombos}

                                            checkForError={checkForError}
                                            renderErrors={renderErrors}
                                            mealsSearchText={mealsSearchText}
                                            onMealsSearchTextChange={setMealsSearchText}
                                            tags={allTags}
                                            randomizePriority={() => {
                                                const updatedItems = randomizePriority(
                                                    formik.values[mealInfoFieldNames.lunch.mealItems]
                                                        .filter(item => filterDispayedItems(item))
                                                );
                                                formik.setFieldValue(mealInfoFieldNames.lunch.mealItems, updatedItems);
                                            }}
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="dinner"
                                        title={<FormattedMessage id="TABS.DINNER"/>}
                                    >
                                        <MealInfoForm
                                            classes={classes}
                                            formik={formik}
                                            exportFileName={`${intl.formatMessage({
                                                id: "EXPORT_FILE_NAME.MEALS.DINNER"
                                            })}_${formatDate(new Date())}`}
                                            locationID={selectedLocation.LocationID}
                                            fieldNames={mealInfoFieldNames.dinner}
                                            suppliers={allSuppliers}
                                            takenItemsIDs={allTakenItems["3"]}
                                            takenCombosIDs={allTakenCombos["3"]}
                                            mealTypeIndex={"3"}
                                            displayedItems={formik.values[
                                                mealInfoFieldNames.dinner.mealItems
                                                ].filter(item => filterDispayedItems(item))}
                                            saveMealItems={saveMealItems}

                                            displayedCombos={formik.values[
                                                mealInfoFieldNames.dinner.mealCombos
                                                ].filter(item => filterDispayedCombos(item))}
                                            saveMealCombos={saveMealCombos}

                                            checkForError={checkForError}
                                            renderErrors={renderErrors}
                                            mealsSearchText={mealsSearchText}
                                            onMealsSearchTextChange={setMealsSearchText}
                                            tags={allTags}
                                            randomizePriority={() => {
                                                const updatedItems = randomizePriority(
                                                    formik.values[mealInfoFieldNames.dinner.mealItems]
                                                        .filter(item => filterDispayedItems(item))
                                                );
                                                formik.setFieldValue(mealInfoFieldNames.dinner.mealItems, updatedItems);
                                            }}
                                        />
                                    </Tab>
                                    {process.env.REACT_APP_FLAVOR!='NY' ?
                                    <Tab 
                                        eventKey="store"
                                        title={<FormattedMessage id="TABS.STORE"/>}
                                    >
                                        <MealInfoForm
                                            classes={classes}
                                            formik={formik}
                                            exportFileName={`${intl.formatMessage({
                                                id: "EXPORT_FILE_NAME.MEALS.STORE"
                                            })}_${formatDate(new Date())}`}
                                            locationID={selectedLocation.LocationID}
                                            fieldNames={mealInfoFieldNames.store}
                                            suppliers={allSuppliers}
                                            takenItemsIDs={allTakenItems["4"]}
                                            takenCombosIDs={allTakenCombos["4"]}
                                            mealTypeIndex={"4"}
                                            displayedItems={formik.values[
                                                mealInfoFieldNames.store.mealItems
                                                ].filter(item => filterDispayedItems(item))}
                                            saveMealItems={saveMealItems}

                                            displayedCombos={formik.values[
                                                mealInfoFieldNames.store.mealCombos
                                                ].filter(item => filterDispayedCombos(item))}
                                            saveMealCombos={saveMealCombos}

                                            checkForError={checkForError}
                                            renderErrors={renderErrors}
                                            mealsSearchText={mealsSearchText}
                                            onMealsSearchTextChange={setMealsSearchText}
                                                tags={allTags}
                                                randomizePriority={() => {
                                                    const updatedItems = randomizePriority(
                                                        formik.values[mealInfoFieldNames.store.mealItems]
                                                            .filter(item => filterDispayedItems(item))
                                                    );
                                                    formik.setFieldValue(mealInfoFieldNames.store.mealItems, updatedItems);
                                                }}
                                        />
                                    </Tab> : null}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </form>
                {
                    (mealsData.isLoading || loading) &&
                    (
                        <div className={classes.loaderContainerItem}>
                            <DishLoader/>
                        </div>
                    )
                }

            </div>


        </MuiPickersUtilsProvider>
    )
}

export default injectIntl(LocationForm)
