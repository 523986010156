export function getStatusText(intl, value) {
  switch (value) {
    case 1:
      return intl.formatMessage({
        id: "CREATE_FORM.STATUS_FIELD.VALUE.DRAFT"
      })
    case 2:
      return intl.formatMessage({
        id: "CREATE_FORM.STATUS_FIELD.VALUE.WAITING"
      })
    case 3:
      return intl.formatMessage({
        id: "CREATE_FORM.STATUS_FIELD.VALUE.APPROVED"
      })
    case 4:
      return intl.formatMessage({
        id: "CREATE_FORM.STATUS_FIELD.VALUE.REJECTED"
      })
    default:
      return null
  }
}
