import axios from "axios"
export function saveUsers(data, customerId, cancelToken) {
  return axios.patch(`/users?customerid=${customerId}`, data , {
    cancelToken: cancelToken,
  })
}
export function getUsers(params, cancelToken) {
  return axios.get("/users", {
    cancelToken: cancelToken,
    params
  })
}
export function getUsersGeneralInfo(params, customerId, cancelToken) {
  return axios.get(`/users/general/info?customerid=${customerId}`, {
    cancelToken: cancelToken,
    params
  })
}
export function getUserByID(UserID, cancelToken) {
  return axios.get(`/users/${UserID}`, {
    cancelToken: cancelToken
  })
}
export function createUser(data) {
  return axios.post("/user", data)
}
export function updateUser(data, UserID) {
  return axios.patch(`/users/${UserID}`, data)
}

export function unlockUser(userID) {
  return axios.post("/user/locking/reset/userID", { userID })
}
export function getUserInfo(token) {
  return axios.get('/Login/GetUserInfo', {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}
export function deleteUser(userID) {
  return axios.delete(`/users/${userID}`)
}
