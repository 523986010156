/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  tableWrapper: {
    overflowX: "auto"
  },
  itemNameCell: {
    maxWidth: 90
  },
  rowHeight: {
    minHeight: 45
  },
  groupNameCell: {
    maxWidth: 150
  },
  iconDisplay: {
    height: 33,
    width: 33
  }
}))
