export const limitTypes = [
    "No limitation",
    "Daily",
    "Weekly",
    "Monthly"
]

export function getLimitTypeID(limitType) {
    let limitTypeValue = limitType.toUpperCase().replace(/ /g, "_")
    return `LIMIT_TYPES.${limitTypeValue}`
}