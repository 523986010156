import axios from "axios"

export function getOptionGroups(supplierID, cancelToken) {
  return axios.get(`/Supplier/${supplierID}/OptionsGroups`, {
    cancelToken
  })
}
export function getOptionGroupVersion(
  supplierID,
  groupID,
  version,
  cancelToken
) {
  return axios.get(
    `/Supplier/${supplierID}/OptionsGroup/${groupID}/${version}`,
    {
      cancelToken
    }
  )
}
export function updateOptionGroupVersion(data, supplierID, groupID, version) {
  return axios.patch(
    `/Supplier/${supplierID}/OptionsGroup/${groupID}/${version}`,
    data
  )
}
export function deleteOptionGroupVersion(supplierID, groupID, version) {
  return axios.delete(
    `/Supplier/${supplierID}/OptionsGroup/${groupID}/${version}`
  )
}
export function createNewOptionGroupVersion(supplierID, groupID) {
  return axios.post(
    `/Supplier/${supplierID}/OptionsGroup/${groupID}/NewVersion`
  )
}
export function createOptionGroup(data, supplierID) {
  return axios.post(`/Supplier/${supplierID}/OptionsGroup`, data)
}
