/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "MealType",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MEAL_TYPE" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  },
  {
    id: "ToMinQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TO_MIN_QUANTITY" />
  },
  {
    id: "RefoundQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "NetQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NET_QUANTITY" />
  },
  {
    id: "MealCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MEAL_COST" />
  },
  {
    id: "TotalCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />
  }
]

function OrderMealsList({ mealsData, searchParams, intl }) {
  const classes = useStyles()
  const rows = mealsData.Meals
  const [sortBy, setSortBy] = useState("MealType")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function renderMealType(type) {
    return getMealTypeText(intl, type)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.Date + "_" + index}
                >
                  <TableCell component="th" scope="row" align="left">
                    {renderMealType(row.MealType)}
                  </TableCell>
                  <TableCell>{row.Quantity}</TableCell>
                  <TableCell>{row.ToMinQuantity}</TableCell>
                  <TableCell>{row.RefoundQuantity}</TableCell>
                  <TableCell>{row.NetQuantity}</TableCell>
                  <TableCell>{row.MealCost.toFixed(2)}</TableCell>
                  <TableCell>{row.TotalCost.toFixed(2)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(OrderMealsList)
