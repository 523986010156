/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage, injectIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { getMealItemUserInfo, getMealComboUserInfo, updateOrder, updateComboOrder } from "./_axios/ordersAdminCrud"
import { formatDateForApi } from "../Common/momentFunctions"
import OrderForm from "./OrderForm"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"
import DishLoader from "../Common/DishLoader"

function OrderDialog({
  show,
  closeDialog,
    type,
  intl,
  itemID,
  mealID,
  userID,
  editData,
  mealDeliveryTimeId,
  date,
  isStore,
  customerName
}) {

  const dispatch = useDispatch()

  const [itemData, setItemData] = useState({
    item: null,
    isLoading: true
  })
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (!show) {
      setItemData({
        item: null,
        isLoading: true
      })
      setSelected([])
      return
    }
    function fetchMealComboUserInfo() {
      getMealComboUserInfo(
          itemID,
          mealID,
          userID,
          formatDateForApi(date),
          editData && editData.Id ? editData.Id : undefined
      )
          .then(({ data }) => {
            const formattedSelected = [];
            data.Items.forEach((it) => {
              if (editData.SelectedOptions) {
                editData.SelectedOptions.forEach(option => {
                  const optionGroup = it.OptionGroups.find(
                      group => group.OptionGroup.id === option.OptionGroupID
                  )
                  if (optionGroup) {
                    const isFree = optionGroup.IsFree
                    formattedSelected.push({
                      ...option,
                      OptionGroupName: optionGroup.OptionGroup.Name,
                      Icon: optionGroup.OptionGroup.Icon,
                      ClientPrice: isFree ? 0 : option.ClientPrice,
                      CustomerPrice: isFree ? 0 : option.CustomerPrice,
                      Price: isFree ? 0 : option.Price,
                      ItemID: it.ItemID
                    })
                  }
                })
              }
            })
            setSelected(formattedSelected)
            setItemData({ item: data, isLoading: false })
          })
          .catch(error =>
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_MEAL_COMBO_USER_INFO"
                  })
              )
          )
    }
    function fetchMealItemUserInfo() {
      getMealItemUserInfo(
        itemID,
        mealID,
        userID,
        formatDateForApi(date),
        editData && editData.Id ? editData.Id : undefined
      )
        .then(({ data }) => {
          if (editData.SelectedOptions) {
            const formattedSelected = []
            editData.SelectedOptions.forEach(option => {
              const optionGroup = data.OptionGroups.find(
                group => group.OptionGroup.id === option.OptionGroupID
              )
              if (optionGroup) {
                const isFree = optionGroup.IsFree
                formattedSelected.push({
                  ...option,
                  OptionGroupName: optionGroup.OptionGroup.Name,
                  Icon: optionGroup.OptionGroup.Icon,
                  ClientPrice: isFree ? 0 : option.ClientPrice,
                  CustomerPrice: isFree ? 0 : option.CustomerPrice,
                  Price: isFree ? 0 : option.Price
                })
              }
            })
            setSelected(formattedSelected)
          }
          setItemData({ item: data, isLoading: false })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_MEAL_ITEM_USER_INFO"
            })
          )
        )
    }
    if (type !== 'combo') {
      fetchMealItemUserInfo();
    } else {
      fetchMealComboUserInfo()
    }
  }, [show])

  function editComboOrder(data) {
    return updateComboOrder(userID, editData.OrderId, editData.OrderComboID, data)
  }

  function editOrder(data) {
    return updateOrder(userID, editData.OrderId, editData.OrderItemID, data)
  }

  return (
    <div>
      <Dialog open={show} onClose={closeDialog} disableBackdropClick>
        <DialogTitle disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="ORDER_FORM.TITLE" />
          </h3>
        </DialogTitle>
        {itemData.item && !itemData.isLoading ? (
          <OrderForm
            initialData={{
              quantity: !editData ? 1 : editData.OrderItemQuantity,
              note: !editData ? "" : editData.OrderItemNotes,
              selected: selected,
            }}
            variant={editData.Id ? "edit" : "new"}
            submitEdit={editOrder}
            submitComboEdit={editComboOrder}
            type={type}
            itemData={itemData.item}
            userID={userID}
            itemID={itemID}
            mealDeliveryTimeId={mealDeliveryTimeId}
            mealID={mealID}
            customerName={customerName}
            date={date}
            isStore={isStore}
            onClose={closeDialog}
          />
        ) : (
          <DishLoader height={200} />
        )}
      </Dialog>
    </div>
  )
}
export default injectIntl(OrderDialog)
