/* eslint-disable no-restricted-imports */

import { Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import CreateQuestionButton from "../modules/Common/CreateNewButton.js"
import CreateQuestionFormDialog from "../modules/Faq/CreateQuestionFormDialog.js"
import DeleteQuestionDialog from "../modules/Faq/DeleteQuestionDialog.js"
import FaqList from "../modules/Faq/FaqList.js"
import {
  createFaq,
  deleteFaq,
  getFaq,
  updateFaq
} from "../modules/Faq/_axios/faqCrud.js"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"

function FaqAdminPage({ intl }) {
  const dispatch = useDispatch()
  const [editQuestionState, setEditQuestionState] = useState({})
  const [deleteQuestionState, setDeleteQuestionState] = useState({
    show: false
  })
  const [showCreateQuestionPanel, setShowCreateQuestionPanel] = useState(false)
  const [questionsData, setQuestionsData] = useState({
    questions: [],
    isLoading: true
  })

  function handleCloseDeleteQuestionPanel(id) {
    if (id) {
      setQuestionsData({
        ...questionsData,
        questions: [
          ...questionsData.questions.filter(question => question.ID !== id)
        ]
      })
    }
    setDeleteQuestionState({ show: false })
  }

  function handleOpenCreateQuestionPanel() {
    setShowCreateQuestionPanel(true)
  }

  function handleCloseCreateQuestionPanel(questionData) {
    if (questionData) {
      if (
        !questionsData.questions.find(
          question => question.ID === questionData.ID
        )
      ) {
        setQuestionsData({
          ...questionsData,
          questions: [...questionsData.questions, questionData]
        })
      } else {
        const questions = [...questionsData.questions]
        let changedQuestionDataIndex = questions.findIndex(
          question => question.ID === questionData.ID
        )
        questions[changedQuestionDataIndex] = {
          ...questions[changedQuestionDataIndex],
          ...questionData
        }
        setQuestionsData({
          ...questionsData,
          questions: [...questions]
        })
      }
    }
    setEditQuestionState({})
    setShowCreateQuestionPanel(false)
  }

  function handleEditQuestion(question) {
    setEditQuestionState({ ...question })
  }

  function handleDeleteQuestion(question) {
    setDeleteQuestionState({ show: true, question })
  }

  function createOrEditQuestion(submittedQuestion) {
    if (submittedQuestion.ID) {
      return updateFaq(submittedQuestion, submittedQuestion.ID)
    }
    return createFaq(submittedQuestion)
  }

  function deleteQuestion(ID) {
    return deleteFaq(ID)
  }

  function fetchQuestions(cancelToken) {
    setQuestionsData({ ...questionsData, isLoading: true })
    getFaq(cancelToken.token)
      .then(({ data }) => {
        setQuestionsData({
          questions: data.map(question => ({
            ID: question.id,
            Answer: question.answer,
            Order: question.order,
            Question: question.question
          })),
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_FAQ"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchQuestions(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (editQuestionState.ID) {
      setShowCreateQuestionPanel(true)
    }
  }, [editQuestionState])

  return (
    <>
      <CreateQuestionButton onClick={handleOpenCreateQuestionPanel} />
      <CreateQuestionFormDialog
        show={showCreateQuestionPanel}
        initialQuestionState={editQuestionState}
        closeDialog={handleCloseCreateQuestionPanel}
        submitData={createOrEditQuestion}
        questionsData={questionsData}
      />
      <DeleteQuestionDialog
        show={deleteQuestionState.show}
        onClose={handleCloseDeleteQuestionPanel}
        sendRequest={deleteQuestion}
        questionData={deleteQuestionState.question}
      />
      <Typography variant="h3" style={{ textAlign: "right" }}>
        <FormattedMessage id="TITLE" />
      </Typography>

      <FaqList
        questionsData={questionsData}
        onEdit={handleEditQuestion}
        onDelete={handleDeleteQuestion}
      />
    </>
  )
}

export default injectIntl(FaqAdminPage)
