/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import axios from "axios"
import CreateItemButton from "../../Common/CreateNewButton"
import ItemList from "./ItemList"
import ItemForm from "./ItemForm"
import {
  getItems,
  getItemVersion,
  updateItemVersion,
  createNewItemVersion,
  deleteItemVersion,
  createItem,
  saveItemImage,
  getExportItemData,
  activateItems,
  activateItem,
  getMealItemPrices
} from "../_axios/itemCrud"
import CreateItemFormDialog from "../../Common/CreateWithNameFormDialog"
import { FormattedMessage, injectIntl } from "react-intl"
import ConfirmationDialog from "../../Common/ConfirmationDialog"
import { getTags } from "../../Tags/_axios/tagCrud"
import { getPriceCategories } from "../../PriceCategories/_axios/priceCategoryCrud"
import { TextField } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import ExportButton from "../../Reports/ExportButton"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import { dayCollection } from "../../Common/dayCollection"
import { formatDate } from "../../Common/momentFunctions"
import { renderBoolean } from "../Orders/OrdersList"
import moment from "moment"
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DishLoader from "../../Common/DishLoader"
function ItemPanel({
  selectedSupplierID,
  selectedSupplierName,
  isUserAdmin,
  isLoading,
  optionGroups,
  intl,
  loadOptionGroups
}) {
  const dispatch = useDispatch()
  const [exportData, setExportData,] = useState({
    data: [],
    isLoading: false
  })

  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [isActionAllSelected, setIsActionAllSelected] = React.useState(false);
  const [isAllSelectedLoading, setIsAllSelectedLoading] = React.useState(false);

  const [selectedItem, setSelectedItem] = useState({})
  const [itemsData, setItemsData] = useState({
    items: [],
    isLoading: true
  })
  const [tagsData, setTagsData] = useState({
    tags: [],
    isLoading: true
  })
  const [priceCategoriesData, setPriceCategoriesData] = useState({
    data: [],
    isLoading: true
  })
  const [mealItemPricesData, setMealItemPricesData] = useState({
    prices: [],
    isLoading: false
  })
  const [showCreateItemPanel, setShowCreateItemPanel] = useState(false)
  const [deleteDialogData, setDeleteDialogData] = useState({
    show: false,
    itemID: null,
    version: null,
    isSubmitting: false
  })
  const [activateDialogData, setActivateDialogData] = useState({
    show: false,
    itemID: null,
    version: null,
    isSubmitting: false
  })
  const [searchText, setSearchText] = useState("")

  function selectItem(ItemID) {
    if (selectedItem.ItemID !== ItemID) {
      setSelectedItem({
        ItemID,
        availableVersions: itemsData.items
          .find(item => item.ItemID === ItemID)
          .Versions.map(version => version.Version),
        Version: itemsData.items
          .find(item => item.ItemID === ItemID)
          .Versions.reduce(
            (max, version) =>
              version.Status === 3 && version.Version > max
                ? version.Version
                : max,
            1
          )
      })
    }
  }

  function calculateActiveItems(data) {
    setIsAllSelected(data.length > 0 && data.every(item => item.Active));
  }

  function fetchItems(cancelToken) {
    setItemsData({ ...itemsData, isLoading: true })
    getItems(selectedSupplierID, cancelToken.token)
      .then(({ data }) => {
        setItemsData({
          ...itemsData,
          items: data,
          isLoading: false
        })
        calculateActiveItems(data);
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_MEAL_ITEMS"
          })
        )
      )
  }

  function saveItem(submittedItem) {
    setItemsData({ ...itemsData, isLoading: true })
    if (submittedItem.ItemID) {
      return updateItemVersion(
        submittedItem,
        selectedSupplierID,
        selectedItem.versionID,
        submittedItem.Version
      )
    }
    return createItem(submittedItem, selectedSupplierID)
  }

  function saveImage(itemID, version, imageFile) {
    const data = new FormData()
    data.append("file", imageFile)
    return saveItemImage(data, selectedSupplierID, itemID, version)
  }

  function fetchItemVersion(cancelToken) {
    setSelectedItem({
      ...selectedItem,
      isLoading: true
    })
    getItemVersion(
      selectedSupplierID,
      selectedItem.ItemID,
      selectedItem.Version,
      cancelToken.token
    )
      .then(({ data }) => {
        setSelectedItem({
          ...selectedItem,
          ...data,
          versionID: data.ItemID,
          ItemID: selectedItem.ItemID,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ITEM_VERSION_INFO"
          })
        )
      )
  }

  function updateItems(submittedItem) {
    const items = [...itemsData.items]
    const changedItem = items.find(item => item.ItemID === submittedItem.ItemID)
    if (changedItem) {
      // changedItem.Name = submittedItem.Name
      changedItem.Image = submittedItem.Image
      if (selectedItem.availableVersions.includes(submittedItem.Version)) {
        const changedVersion = changedItem.Versions.find(
          version => version.Version === submittedItem.Version
        )
        changedVersion.Status = submittedItem.Status
        changedItem.Active = submittedItem.Active
      } else {
        changedItem.Active = submittedItem.Active

        changedItem.Versions.push({
          Version: submittedItem.Version,
          Status: submittedItem.Status
        })
      }
      setItemsData({
        ...itemsData,
        items: [...items],
        isLoading: false
      })
      setSelectedItem({})
      calculateActiveItems(items);
    } else {
      const newItem = {
        ItemID: submittedItem.ItemID,
        Name: submittedItem.Name,
        Image: null,
        Active: false
      }
      newItem.Versions = [
        {
          Version: 1,
          Status: 1
        }
      ]
      const itemsNew = [...itemsData.items, newItem];
      setItemsData({
        ...itemsData,
        items: itemsNew,
        isLoading: false
      })
      setShowCreateItemPanel(false)
      calculateActiveItems(itemsNew);
      setSelectedItem({
        ItemID: newItem.ItemID,
        availableVersions: [1],
        Version: 1
      })
    }
  }

  function removeItemVersion(itemID, itemVersion) {
    setSelectedItem({})
    const items = [...itemsData.items]
    const changedItem = items.find(item => item.ItemID === itemID)

    const remainingVersions = changedItem.Versions.filter(
      version => version.Version !== itemVersion
    )
    changedItem.Versions = remainingVersions
    setItemsData({
      ...itemsData,
      items:
        remainingVersions.length !== 0
          ? [...items]
          : [...items.filter(item => item.ItemID !== itemID)],
      isLoading: false
    })
    calculateActiveItems(remainingVersions.length !== 0
        ? [...items]
        : [...items.filter(item => item.ItemID !== itemID)]);
  }

  function fetchTags(cancelToken) {
    setTagsData({ ...tagsData, isLoading: true })
    getTags(cancelToken.token)
      .then(({ data }) => {
        setTagsData({
          tags: data,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_TAGS"
          })
        )
      )
  }

  function fetchPriceCategories(cancelToken) {
    setPriceCategoriesData({ ...priceCategoriesData, isLoading: true })
    getPriceCategories(cancelToken.token)
        .then(({ data }) => {
          setPriceCategoriesData({
            data,
            isLoading: false
          })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_PRICE_CATEGORY"
                })
            )
        )
  }

    function fetchMealItemPrices(cancelToken) {
        setMealItemPricesData({ ...mealItemPricesData, isLoading: true });
        var neededItemID = null;
        if (selectedItem.ItemIdByVersion == null) {
            neededItemID = itemsData.items
                .find(item => item.ItemID === selectedItem.ItemID).Versions
                .find(vers => vers.Version === selectedItem.Version)?.ItemID;
        }
        getMealItemPrices(
            neededItemID
                ? neededItemID
                : selectedItem.ItemIdByVersion
                    ? selectedItem.ItemIdByVersion
                    : selectedItem.ItemID,
            cancelToken.token)
            .then(({ data }) => {
                setMealItemPricesData({
                    prices: data,
                    isLoading: false
                });
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_MEAL_PRICES"
                    })
                )
            );
    }

  function formatExportData(data) {
    const optionGroups = []
    data.forEach(item =>
      item.OptionGroups.forEach(optionGroup => {
        if (!optionGroups.find(el => el.Lable === optionGroup.Lable)) {
          optionGroups.push(optionGroup)
        }
      })
    )
    return data.map(item => {
      const formattedItem = {}
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.NAME_FIELD.LABEL"
        })
      ] = item.Name
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.DESCRIPTION_FIELD.LABEL"
        })
      ] = item.Description
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.NOTE_FIELD.LABEL"
        })
      ] = item.Note
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.TYPE_FIELD.LABEL"
        })
      ] = getMealTypeText(intl, item.Type)
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.TAGS_FIELD.LABEL"
        })
      ] = item.Tags.reduce((str, tag) => `${str && `${str}, `}${tag.Name}`, "")
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.IMAGE_TAGS_FIELD.LABEL"
        })
      ] = item.ImageTags.reduce(
        (str, tag) => `${str && `${str}, `}${tag.Name}`,
        ""
      )
      formattedItem[
        intl.formatMessage({
          id: "OPTION.LABELS.DAYS"
        })
      ] = dayCollection.reduce(
        (str, day) =>
          item.AvilabiltyDays.includes(day.value)
            ? `${str && `${str}, `}${intl.formatMessage({
                id: day.label.props.id
              })}`
            : str,
        ""
      )
      if (item.AvilableFrom) {
        formattedItem[
          intl.formatMessage({
            id: "AVAILABLE_FROM.PLACEHOLDER"
          })
        ] = formatDate(item.AvilableFrom)
        formattedItem[
          intl.formatMessage({
            id: "AVAILABLE_TO.PLACEHOLDER"
          })
        ] = formatDate(item.AvilableTo)
      }
      optionGroups.forEach(optionGroup => {
        const optionData = item.OptionGroups.find(
          el => el.Lable === optionGroup.Lable
        )
        formattedItem[optionGroup.Lable] = optionData
          ? optionData.OptionGroup.Options.reduce(
              (str, option) => `${str && `${str}, `}${option.Name}`,
              ""
            )
          : ""
      })
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.MAX_QUANTITY_NUMBER_FIELD.LABEL"
        })
      ] = item.MaxQuantity

      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.PRICE_FIELD.LABEL"
        })
      ] = item.Price
      formattedItem[
        intl.formatMessage({
          id: "OPTION.LABELS.CUSTOMER_PRICE"
        })
      ] = item.CustomerPrice
      formattedItem[
        intl.formatMessage({
          id: "OPTION.LABELS.CLIENT_PRICE"
        })
      ] = item.ClientPrice
      formattedItem[
        intl.formatMessage({
          id: "OPTION_GROUP_TABLE.HEAD_LABELS.VERSION"
        })
      ] = item.Version
      formattedItem[
        intl.formatMessage({
          id: "CREATE_FORM.ACTIVE_FIELD.LABEL"
        })
      ] = renderBoolean(intl, item.Active)
      if (item.Rating) {
        formattedItem[
          intl.formatMessage({
            id: "OPTION_GROUP_TABLE.HEAD_LABELS.RATING"
          })
        ] = +item.Rating.toFixed(1)
      }
      return formattedItem
    })
  }

  function handleExportData() {
    setExportData({ ...exportData, isLoading: true })

    getExportItemData(selectedSupplierID)
      .then(({ data }) => {
        setExportData({
          data: formatExportData(data),
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_EXPORT_DATA"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchTags(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchPriceCategories(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (selectedSupplierID) {
      const cancelToken = axios.CancelToken.source()
      fetchItems(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [selectedSupplierID])

  useEffect(() => {
    if (selectedItem.ItemID) {
      const cancelToken = axios.CancelToken.source()
      if (selectedItem.ItemIdByVersion != null) {
          fetchItems(cancelToken)
          loadOptionGroups()
      }
      fetchItemVersion(cancelToken)
      if (isUserAdmin) {
        fetchMealItemPrices(cancelToken)
      }
      return () => cancelToken.cancel()
    }
  }, [selectedItem.ItemID, selectedItem.Version])

  const handleNewOptionGroupSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const newItem = { Name: values.name }
    createItem(newItem, selectedSupplierID)
      .then(({ data }) => {
        setSubmitting(false)
        updateItems({
          ...newItem,
          ItemID: data.Id
        })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_ITEM"
          })
        )
      })
  }

  const handleAcceptDeleteItemVersion = () => {
    setDeleteDialogData({ ...deleteDialogData, isSubmitting: true })

    deleteItemVersion(
      selectedSupplierID,
      deleteDialogData.itemID,
      deleteDialogData.version
    )
      .then(() => {
        removeItemVersion(deleteDialogData.itemID, deleteDialogData.version)
        setDeleteDialogData({
          show: false,
          itemID: null,
          version: null,
          isSubmitting: false
        })
      })
      .catch(error => {
        setDeleteDialogData({
          ...deleteDialogData,
          isSubmitting: false
        })
        error.response.data && error.response.data.Message === "Item is in use"
          ? handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.ITEM_IS_USED"
              })
            )
          : handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_DELETE_ITEM"
              })
            )
      })
  }

  function handleChange(event) {
    event.preventDefault();
    setIsActionAllSelected(event.target.checked);
    setActivateDialogData({
      show: true,
      isSubmitting: false
    })
  }

  function handleAllActiveItemsAfterSubmit() {
    setIsAllSelected(isActionAllSelected);
    handleAllActiveItems(isActionAllSelected);
  }

  function statusUpdate(c) {
    setIsAllSelectedLoading(c);
  }

  function handleActiveItem(event) {
    setItemsData({ ...itemsData, isLoading: true })
    activateItem(selectedSupplierID, event.row.ItemID, event.row.Versions.reduce(
        (max, version) =>
            version.Version > max ? version.Version : max,
        1
    ), {active: event.active})
        .then(({ data }) => {
          const itemsDataList = itemsData.items.slice();
          const element = itemsDataList.find(t => t.ItemID === event.row.ItemID);
          element.Active = event.active;
          setItemsData({ ...itemsData, data: itemsDataList, isLoading: false })
          calculateActiveItems(itemsData.items);
        })
        .catch(error => {
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_UPDATE_DATA"
              })
          )
        })
  }

  function handleAllActiveItems(active) {
    setActivateDialogData({
      ...activateDialogData,
      isSubmitting: true,
    })
    const cancelToken = axios.CancelToken.source()
    statusUpdate(true);
    activateItems(selectedSupplierID, {active: active})
        .then(({ data }) => {
          statusUpdate(false);
          fetchItems(axios.CancelToken.source());
          setActivateDialogData({
            show: false,
            itemID: null,
            version: null,
            isSubmitting: false
          })
        })
        .catch(error => {
              handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_UPDATE_DATA"
                  })
              )
        })
  }

  return (
    <>
      <ConfirmationDialog
          show={activateDialogData.show}
          onSubmit={handleAllActiveItemsAfterSubmit}
          onClose={() =>
              setActivateDialogData({
                show: false,
                itemID: null,
                version: null,
                isSubmitting: false
              })
          }
          isSubmitting={activateDialogData.isSubmitting}
          dialogTitle={<FormattedMessage id="ACTIVATE_ITEM_DIALOG.TITLE" />}
          contentText={intl.formatMessage({
            id: "ACTIVATE_ITEM_DIALOG.TEXT"
          })}
      />
      <ConfirmationDialog
        show={deleteDialogData.show}
        onSubmit={handleAcceptDeleteItemVersion}
        onClose={() =>
          setDeleteDialogData({
            show: false,
            itemID: null,
            version: null,
            isSubmitting: false
          })
        }
        isSubmitting={deleteDialogData.isSubmitting}
        dialogTitle={<FormattedMessage id="DELETE_ITEM_VERSION_DIALOG.TITLE" />}
        contentText={intl.formatMessage({
          id: "DELETE_ITEM_VERSION_DIALOG.TEXT"
        })}
      />
      <CreateItemButton onClick={() => setShowCreateItemPanel(true)} />
      <CreateItemFormDialog
        show={showCreateItemPanel}
        title={<FormattedMessage id="CREATE_ITEM_FORM.TITLE" />}
        closeDialog={() => setShowCreateItemPanel(false)}
        handleSubmit={handleNewOptionGroupSubmit}
        checkIfNameUnique={value => {
          const item = itemsData.items.find(item => item.Name === value)
          return item === undefined
        }}
      />
      <div className="row">
        <div className="col-4">
          {
            !itemsData.isLoading && <>
              <div className="text-right">
                <ExportButton
                    exportData={exportData.data}
                    fileName={`${intl.formatMessage({
                      id: "REPORT.EXPORT_FILE_NAME.ITEMS"
                    })}_${selectedSupplierName}_${moment().format("DD-MM-YYYY")}`}
                    onLoadData={handleExportData}
                    isLoading={exportData.isLoading}
                />
              </div>

              <div className="text-right">
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isAllSelected}
                          disabled={itemsData.isLoading || isAllSelectedLoading}
                          onChange={event => handleChange(event)}
                      />
                    }
                    label="Active All"
                />
              </div>

              <TextField
                  fullWidth
                  label={intl.formatMessage({
                    id: "SEARCH_FIELD.LABEL"
                  })}
                  value={searchText}
                  onChange={event => setSearchText(event.target.value)}
              />
              <ItemList
                  items={itemsData.items.filter(item =>
                      item.Name.toLowerCase().includes(searchText.toLowerCase())
                  )}
                  selected={selectedItem.ItemID}
                  setSelected={selectItem}
                  handleActiveItem={event => handleActiveItem(event)}
                  isUserAdmin={isUserAdmin}
              />
            </>
          }

          {
            itemsData.isLoading && <DishLoader center/>
          }


         </div>
        <div className="col-8">
          {
            selectedItem.isLoading && <DishLoader center/>
          }
          {!selectedItem.isLoading && Object.keys(selectedItem).includes("Name") && !mealItemPricesData.isLoading && (
            <ItemForm
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              mealItemPricesData={mealItemPricesData}
              setMealItemPricesData={setMealItemPricesData}
              items={itemsData.items}
              optionGroups={optionGroups}
              allTags={tagsData.tags}
              onSubmit={saveItem}
              sendActive={(data) => {
                const selectedCurrentItem = itemsData.items.find(item => item.ItemID === selectedItem.ItemID);
                handleActiveItem({
                  active: data.active,
                  row: {
                    ...selectedCurrentItem,
                    Active: data.active
                  }
                });
              }}
              allPriceCategories={priceCategoriesData.data}
              onDelete={(itemID, version) =>
                setDeleteDialogData({
                  show: true,
                  itemID,
                  version,
                  isSubmitting: false
                })
              }
              saveImage={saveImage}
              updateItems={updateItems}
              createNewItemVersion={() =>
                createNewItemVersion(selectedSupplierID, selectedItem.ItemID)
              }
              isUserAdmin={isUserAdmin}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default injectIntl(ItemPanel)
