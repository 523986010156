/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Collapse, IconButton } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "ItemName",
    disablePadding: false,
    label: ''
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="ORDERS_TABLE.LABELS.QUANTITY" />
  },
  {
    id: "ItemPriceTotal",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_COST" />
  },
  {
    id: "CustomerPriceTotal",
    disablePadding: false,
    label: <FormattedMessage id="ORDERS_TABLE.LABELS.COSTUMER_TOTAL_COST" />
  },
  {
    id: "CustomerPriceTotal",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.PROFIT" />
  }
]

function OrderItemsSummary({ ordersData }) {
  const classes = useStyles()
  const rows = ordersData.Items
  const [sortBy, setSortBy] = useState("ItemName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }
  const supplier = ordersData;
  const quantity = 0;
  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapperSummary}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.collapsibleRow}
            >
              <TableCell></TableCell>
              <TableCell>{supplier.OrderQuantity}</TableCell>
              <TableCell>{supplier.SupplierPrice}</TableCell>
              <TableCell>{supplier.TotalAmount}</TableCell>
              <TableCell>
                <span className='value-direction'>
                  {Math.round(((supplier.TotalAmount) - (supplier.SupplierPrice )) * 100) / 100}
                </span>
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsSummary
