/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { useStyles } from "../Common/_styles/formDialogStyles";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers";
import { TextField } from "@material-ui/core";
import { getMealTypeText } from "../Common/mealTypesTranslation";
import TranzilaWindow from "./TranzilaWindow";
import ComboForm from "./ComboForm";
import {
  getSummary,
  saveItemToTempStorage,
  saveComboToTempStorage,
  sendOrder,
  finduserInfo,
  findpaymentmethods
} from "./_axios/ordersAdminCrud";
import { formatDateForApi } from "../Common/momentFunctions";
import OrderPaymentForm from "./OrderPaymentForm";

function OrderForm({
  intl,
  submitEdit,
  submitComboEdit,
  initialData,
  variant,
  type,
  itemData,
   mealDeliveryTimeId,
  userID,
  customerName,
  itemID,
  mealID,
  date,
  isStore,
  onClose
}) {
  const tabs = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "CREATE_FORM.ITEM_INFO"
      })
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "CREATE_FORM.SUMMARY"
      })
    }
  ];

  const dispatch = useDispatch();

  const classes = useStyles();

  const initialValues = {
    quantity: initialData.quantity || 1,
    note: initialData.note || ""
  };

  const [userInfo, setUserInfo] = useState({});
  const [sumIsInvalid, setSumIsInvalid] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState(false);

  const [walletBalance, setWalletBalance] = useState(0);
  const [budgetAmount, setBudgetAmount] = useState(0);
  const [companyAmount, setCompanyAmount] = useState(0);
  const [creditCard, setCreditCard] = useState(undefined);
  const [showTranzilaWindow, setShowTranzilaWindow] = useState(false);
  const [loading, setLoading] = useState({
    orderPayment: false
  });

  const [currentTab, setCurrentTab] = useState(1);
  const [summaryData, setSummaryData] = useState(null);

  const optionGroupsValidationSchemas = {};
  const optionGroupsItemValidationSchemas = {};

  if (itemData.OptionGroups) {
    itemData.OptionGroups.forEach(optionGroup => {
      if (initialData.selected) {
        initialValues[optionGroup.Lable] = initialData.selected
            .filter(el => el.OptionGroupID === optionGroup.OptionGroup.id)
            .map(el => el.id);
      } else {
        initialValues[optionGroup.Lable] = [];
      }
      if (!isStore) {
        optionGroupsValidationSchemas[optionGroup.Lable] = Yup.array()
            .when({
              is: () => {
                return optionGroup.IsMandatory;
              },
              then: Yup.array().min(
                  1,
                  intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })
              )
            })
            .when({
              is: () => {
                return optionGroup.OptionGroup.Min !== 0;
              },
              then: Yup.array().min(
                  optionGroup.OptionGroup.Min,
                  intl.formatMessage(
                      { id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" },
                      { min: optionGroup.OptionGroup.Min }
                  )
              )
            })
            .when({
              is: () => {
                return optionGroup.OptionGroup.Max !== 0;
              },
              then: Yup.array().max(
                  optionGroup.OptionGroup.Max,
                  intl.formatMessage(
                      { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
                      { max: optionGroup.OptionGroup.Max }
                  )
              )
            });
      }
    });
  }

    if (type === 'combo') {
        itemData.Items.forEach((it) => {
            it.OptionGroups.forEach(optionGroup => {
                const key = `${it.ItemName}_${optionGroup.Lable}`
                if (initialData.selected) {
                    initialValues[key] = initialData.selected
                        .filter(el => el.OptionGroupID === optionGroup.OptionGroup.id && el.ItemID === it.ItemID)
                        .map(el => el.id);
                } else {
                    initialValues[key] = [];
                }
                if (!isStore) {
                    optionGroupsItemValidationSchemas[key] = Yup.array()
                        .when({
                            is: () => {
                                return optionGroup.IsMandatory;
                            },
                            then: Yup.array().min(
                                1,
                                intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })
                            )
                        })
                        .when({
                            is: () => {
                                return optionGroup.OptionGroup.Min !== 0;
                            },
                            then: Yup.array().min(
                                optionGroup.OptionGroup.Min,
                                intl.formatMessage(
                                    { id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" },
                                    { min: optionGroup.OptionGroup.Min }
                                )
                            )
                        })
                        .when({
                            is: () => {
                                return optionGroup.OptionGroup.Max !== 0;
                            },
                            then: Yup.array().max(
                                optionGroup.OptionGroup.Max,
                                intl.formatMessage(
                                    { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
                                    { max: optionGroup.OptionGroup.Max }
                                )
                            )
                        });
                }
            });
        })
    }


  const quantityValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    );

  const noteValidationSchema = Yup.string().max(
    70,
    intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: 70 })
  );

  const itemsFields = [];
  if (type === 'combo') {
    itemData.Items.forEach(it => {
        itemsFields.push(...it.OptionGroups.map(optionGroup => `${it.ItemName}_${optionGroup.Lable}`))
    })
  }

  const validationSchema = type === 'item' ? Yup.object().shape(
    {
      quantity: quantityValidationSchema,
      note: noteValidationSchema,
      ...optionGroupsValidationSchemas
    },
    [
      "quantity",
      "note",
      ...itemData.OptionGroups.map(optionGroup => optionGroup.Lable)
    ]
  ) : Yup.object().shape(
      {
          quantity: quantityValidationSchema,
          note: noteValidationSchema,
          ...optionGroupsItemValidationSchemas
      },
      [
          "quantity",
          "note",
          ...itemsFields
      ]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { note, quantity } = values;
      if (type === 'item') {
        saveItem(note, quantity, setSubmitting, resetForm, values);
      } else {
        saveCombo(note, quantity, setSubmitting, resetForm, values);
      }
    }
  });

  function saveCombo(note, quantity, setSubmitting, resetForm, values) {
      itemData.Items.forEach(it => {
          const selectedOptions = [];
          it.OptionGroups.map(optionGroup => {
              const selected = values[`${it.ItemName}_${optionGroup.Lable}`];
              selectedOptions.push(
                  ...selected.map(optionID => {
                      const option = optionGroup.OptionGroup.Options.find(
                          el => el.id === optionID
                      );
                      return {
                          ...option,
                          OptionGroupName: optionGroup.OptionGroup.Name,
                          Icon: optionGroup.OptionGroup.Icon,
                          ClientPrice: optionGroup.IsFree ? 0 : option.ClientPrice,
                          ItemOptionId: optionGroup.id
                      };
                  })
              );
          });
          it.SelectedOptions = selectedOptions;
      })
      if (variant === "edit") {
          const data = {
              notes: note,
              Items: itemData.Items,
              quantity: quantity
          };
          submitComboEdit(data)
              .then(() => {
                  onClose(true);
              })
              .catch(error => {
                  handleApiError(
                      dispatch,
                      error,
                      intl.formatMessage({
                          id: "API.ERROR.FAILED_TO_EDIT_ORDER"
                      })
                  );
              });
      } else {
          let orderItem;
          if (isStore) {
              orderItem = {
                  ComboID: itemID,
                  SelectedOptions: [],
                  Notes: "",
                  Status: null,
                  MealID: mealID,
                  OrderToDate: formatDateForApi(new Date()),
                  SupplierID: "",
                  Quantity: quantity,
                  MealDeliveryTimeId: mealDeliveryTimeId,
                  ItemName: itemData.ComboName
              };
          } else {
              orderItem = {
                  ComboID: itemID,
                  Items: itemData.Items,
                  Notes: note,
                  Status: null,
                  MealID: mealID,
                  Cost: 0,
                  CustomerPatment: 0,
                  MealDeliveryTimeId: mealDeliveryTimeId,
                  ClientPayment: itemData.ItemPrice,
                  OrderToDate: formatDateForApi(date),
                  SupplierID: itemData.SupplierID,
                  Quantity: quantity,
                  ItemName: itemData.ComboName,
                  ItemImages: itemData.ItemPhoto,
                  MealTypeName: getMealTypeText(intl, itemData.MealType)
              };
          }
          setSubmitting(true);
          saveComboToTempStorage(userID, orderItem)
              .then(({ data: storageData }) => {
                  getSummary(userID, storageData.Id)
                      .then(({ data: summaryData }) => {
                          const orderData = {
                              UserID: userID,
                              OrderId: storageData.Id,
                              ChargeAmount: summaryData.EmployeeSum
                          };
                          setCurrentTab(2);
                          setSummaryData({
                              summaryData,
                              orderData,
                              userID
                          });
                          getUserInfo(userID, storageData.Id, () => {
                              setSubmitting(false);
                          });
                      })
                      .catch(error => {
                          setSubmitting(false);
                          handleApiError(
                              dispatch,
                              error,
                              intl.formatMessage({
                                  id: "API.ERROR.FAILED_TO_GET_SUMMARY"
                              })
                          );
                      });
              })
              .catch(error => {
                  setSubmitting(false);
                  handleApiError(
                      dispatch,
                      error,
                      intl.formatMessage({
                          id: "API.ERROR.FAILED_TO_SAVE_ITEM"
                      })
                  );
              });
      }
  }

  function saveItem(note, quantity, setSubmitting, resetForm, values) {
    const selectedOptions = [];
    itemData.OptionGroups.map(optionGroup => {
      const selected = values[optionGroup.Lable];
      selectedOptions.push(
          ...selected.map(optionID => {
            const option = optionGroup.OptionGroup.Options.find(
                el => el.id === optionID
            );
            return {
              ...option,
              OptionGroupName: optionGroup.OptionGroup.Name,
              Icon: optionGroup.OptionGroup.Icon,
              ClientPrice: optionGroup.IsFree ? 0 : option.ClientPrice,
              ItemOptionId: optionGroup.id
            };
          })
      );
    });

    if (variant === "edit") {
      const data = {
        notes: note,
        options: selectedOptions,
        quantity: quantity
      };
      submitEdit(data)
          .then(() => {
            onClose(true);
          })
          .catch(error => {
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_EDIT_ORDER"
                })
            );
          });
    } else {
      let orderItem;
      if (isStore) {
        orderItem = {
          ItemID: itemID,
          SelectedOptions: [],
          Notes: "",
          Status: null,
          MealID: mealID,
          OrderToDate: formatDateForApi(new Date()),
          SupplierID: "",
          Quantity: quantity,
          MealDeliveryTimeId: mealDeliveryTimeId,
          ItemName: itemData.ItemName
        };
      } else {
        orderItem = {
          ItemID: itemID,
          SelectedOptions: selectedOptions,
          Notes: note,
          Status: null,
          MealID: mealID,
          Cost: 0,
          CustomerPatment: 0,
          MealDeliveryTimeId: mealDeliveryTimeId,
          ClientPayment: itemData.ItemPrice,
          OrderToDate: formatDateForApi(date),
          SupplierID: itemData.SupplierID,
          Quantity: quantity,
          ItemName: itemData.ItemName,
          ItemImages: itemData.ItemPhoto,
          MealTypeName: getMealTypeText(intl, itemData.MealType)
        };
      }
      setSubmitting(true);
      saveItemToTempStorage(userID, orderItem)
          .then(({ data: storageData }) => {
            getSummary(userID, storageData.Id)
                .then(({ data: summaryData }) => {
                  const orderData = {
                    UserID: userID,
                    OrderId: storageData.Id,
                    ChargeAmount: summaryData.EmployeeSum
                  };
                  setCurrentTab(2);
                  setSummaryData({
                    summaryData,
                    orderData,
                    userID
                  });
                  getUserInfo(userID, storageData.Id, () => {
                    setSubmitting(false);
                  });
                })
                .catch(error => {
                  setSubmitting(false);
                  handleApiError(
                      dispatch,
                      error,
                      intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUMMARY"
                      })
                  );
                });
          })
          .catch(error => {
            setSubmitting(false);
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_SAVE_ITEM"
                })
            );
          });
    }
  }

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    return false;
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null;
  }

  function getUserInfo(userId, orderId, callback) {
    findpaymentmethods(userId, orderId)
      .then(response => {
        setBudgetAmount(response.data.Budget);
        setWalletBalance(response.data.Wallet);
        setCompanyAmount(response.data.CompanyAmount);
        if (callback) {
          callback();
        }
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_PAYMENT_INFO"
          })
        );
      });
    finduserInfo(userId)
      .then(response => {
        setUserInfo(response.data);
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_USER_INFO"
          })
        );
      });
  }

  function makePayment() {
    setLoading({
      orderPayment: true
    });
    sendOrder(summaryData.userID, summaryData.orderData, paymentMethods, creditCard)
      .then(() => {
        onClose(true);
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_SEND_ORDER"
          })
        );
      })
      .finally(() => {
        setLoading({
          orderPayment: false
        });
      });
  }

  function saveCard(creditCardToken, expirationMonth, expirationYear) {
    const newCard = {creditCardToken, expirationMonth, expirationYear}
    setCreditCard(newCard);
  }

  function isPaymentInvalid() {
    const cardPayment = paymentMethods.find(it => it.type === '4');
    return (!!cardPayment && !creditCard)
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <DialogContent>
        <div className="main-tab-container">
          {tabs.map(item => {
            return (
              <div
                className={`${currentTab === item.id ? "item-selected" : ""}`}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {currentTab === 1 && (
          <>
            <TextField
              name="quantity"
              className={classes.textField}
              inputProps={{
                min: 0
              }}
              margin="normal"
              variant="filled"
              type="number"
              label={intl.formatMessage({
                id: "LABELS.QUANTITY"
              })}
              fullWidth
              {...formik.getFieldProps("quantity")}
              error={checkForError("quantity")}
            />
            <div>{renderErrors("quantity")}</div>
              <>
                  {!isStore && type === 'item' && (
                      <>
                          {itemData.OptionGroups.map(optionGroup => (
                              <>
                                  <div className={classes.conditionContainer}>
                                      {optionGroup.IsMandatory && (
                                          <>
                                              <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MANDATORY"
                              })}
                            </span>
                                                  <span>{optionGroup.IsMandatory}</span>
                                              </div>
                                          </>
                                      )}
                                      {optionGroup.OptionGroup.Min > 0 && (
                                          <>
                                              <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MIN"
                              })}
                            </span>
                                                  <span>{optionGroup.OptionGroup.Min}</span>
                                              </div>
                                          </>
                                      )}
                                      {optionGroup.OptionGroup.Max > 0 && (
                                          <>
                                              <div>
                            <span className={classes.label}>
                              {intl.formatMessage({
                                  id: "LABELS.MAX"
                              })}
                            </span>
                                                  <span>{optionGroup.OptionGroup.Max}</span>
                                              </div>
                                          </>
                                      )}
                                  </div>

                                  <FormControl
                                      variant="filled"
                                      className={classes.textField}
                                      fullWidth
                                      error={checkForError(optionGroup.Lable)}
                                  >
                                      <InputLabel>{optionGroup.Lable}</InputLabel>
                                      <Select
                                          {...formik.getFieldProps(optionGroup.Lable)}
                                          multiple
                                          input={<FilledInput name={optionGroup.Lable} />}
                                      >
                                          {optionGroup.OptionGroup.Options.map(option => (
                                              <MenuItem key={option.id} value={option.id}>
                                                  {option.Name}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                                  <div>{renderErrors(optionGroup.Lable)}</div>
                              </>
                          ))}
                          <TextField
                              name="note"
                              label={intl.formatMessage({
                                  id: "LABEL.NOTE"
                              })}
                              className={classes.textField}
                              margin="normal"
                              variant="filled"
                              fullWidth
                              {...formik.getFieldProps("note")}
                              error={checkForError("note")}
                          />
                          <div>{renderErrors("note")}</div>
                      </>
                  )}
                  {
                      !isStore && type === 'combo' && (
                          <>
                              <ComboForm
                                  intl={intl}
                              items={itemData.Items}
                              checkForError={checkForError}
                              formik={formik} renderErrors={renderErrors}

                              />
                              <TextField
                                  name="note"
                                  label={intl.formatMessage({
                                      id: "LABEL.NOTE"
                                  })}
                                  className={classes.textField}
                                  margin="normal"
                                  variant="filled"
                                  fullWidth
                                  {...formik.getFieldProps("note")}
                                  error={checkForError("note")}
                              />
                              <div>{renderErrors("note")}</div>
                          </>
                      )
                  }
              </>

          </>
        )}
        {currentTab === 2 && summaryData && summaryData.summaryData && (
          <div>
            <OrderPaymentForm
              intl={intl}
              sumIsInvalid={ev => setSumIsInvalid(ev)}
              userInfo={userInfo}
              summaryData={summaryData.summaryData}
              walletBalance={walletBalance}
              budgetAmount={budgetAmount}
              onOpenTranzilla={ev => setShowTranzilaWindow(true)}
              companyAmount={companyAmount}
              paymentCreditCard={creditCard}
              paymentMethodSubmit={ev => setPaymentMethods(ev)}
            ></OrderPaymentForm>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          size="large"
          onClick={() => onClose()}
          className={classes.button}
        >
          <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
        </Button>
        {currentTab === 1 && (
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            className={classes.button}
            disabled={formik.isSubmitting}
          >
            <FormattedMessage id="CREATE_FORM.GO_TO_SUMMARY_BUTTON" />
            {formik.isSubmitting && (
              <span className="ml-1 spinner spinner-white"></span>
            )}
          </Button>
        )}
        {currentTab === 2 && (
          <Button
            variant="contained"
            type="button"
            onClick={ev => makePayment()}
            size="large"
            color="secondary"
            className={classes.button}
            disabled={
              formik.isSubmitting || sumIsInvalid || loading.orderPayment || isPaymentInvalid()
            }
          >
            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
            {loading.orderPayment && (
              <span className="ml-1 spinner spinner-white"></span>
            )}
          </Button>
        )}

        {
          <TranzilaWindow
            show={showTranzilaWindow}
            src={`https://direct.tranzila.com/${
                process.env.REACT_APP_TRANZIL_TREMINAL
            }/iframenew.php?hidesum=1&sum=1&currency=1&cred_type=1&lang=il&tranmode=VK&nologo=1&notify_url_address=${encodeURIComponent(
                `${process.env.REACT_APP_BASE_PATH}/User/Orders/Notify`
            )}&userId=${userInfo.UserID}&company=${encodeURIComponent(
                customerName
            )}&contact=${encodeURIComponent(
              userInfo.FirstName + " " + userInfo.LastName
            )}&email=${encodeURIComponent(userInfo.Email)}&phone=${
              userInfo.Mobile
            }`}
            saveCard={saveCard}
            searchUser={userInfo}
            onClose={() => setShowTranzilaWindow(false)}
          />
        }
      </DialogActions>
    </form>
  );
}
export default injectIntl(OrderForm);
