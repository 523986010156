/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Link } from "react-router-dom"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import moment from "moment"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "MealType",
    disablePadding: false,
    label: ""
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />,
    disableSortBy:  true
  },
  {
    id: "RefoundQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />,
    disableSortBy:  true
  },
  {
    id: "NetQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NET_QUANTITY" />,
    disableSortBy:  true
  },
  {
    id: "TotalCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />,
    disableSortBy:  true
  }
]

function SupplierMealsSummaryList({ mealsData, searchParams, intl }) {
  const classes = useStyles();

  const rows = mealsData.reports || [];
  const [sortBy, setSortBy] = useState("MealType")
  const [orderBy, setOrderBy] = useState("asc")

  const quantity = rows.reduce((sum, it) => sum + it.Meals.reduce((s, m) => s + m.Quantity, 0), 0);
  const refoundQuantity = rows.reduce((sum, it) => sum + it.Meals.reduce((s, m) => s + m.RefoundQuantity, 0), 0);
  const netQuantity = rows.reduce((sum, it) => sum + it.Meals.reduce((s, m) => s + m.NetQuantity, 0), 0);
  const totalCost = rows.reduce((sum, it) => sum + it.Meals.reduce((s, m) => s + m.TotalCost, 0), 0);

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            onRequestSort={() => {}}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                className={classes.summary}
                tabIndex={-1}
            >
              <TableCell component="th" scope="row" align="left">
                {
                  <FormattedMessage id="REPORT.TOTAL" />
                }
              </TableCell>
              <TableCell>{quantity}</TableCell>
              <TableCell>{refoundQuantity}</TableCell>
              <TableCell>{netQuantity}</TableCell>
              <TableCell>{totalCost}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(SupplierMealsSummaryList)
