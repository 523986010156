export function getMealTypeText(intl, type) {
  switch (+type) {
    case 1:
      return intl.formatMessage({
        id: "MEAL_TYPE.VALUE.BREAKFAST"
      })
    case 2:
      return intl.formatMessage({
        id: "MEAL_TYPE.VALUE.LUNCH"
      })
    case 3:
      return intl.formatMessage({
        id: "MEAL_TYPE.VALUE.DINNER"
      })
    case 4:
      return intl.formatMessage({
        id: "MEAL_TYPE.VALUE.STORE"
      })
    default:
      return null
  }
}
