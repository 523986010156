/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(0, 1)
  },
  headerPinned: {
    position: "fixed",
    top: 0,
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(992)]: {
      marginLeft: 171,
      color: "white"
    },
    height: 65
  },
  tabHeader: {
    position: "sticky",
    paddingTop: "2.25rem",
    top: 0,
    zIndex: 10,
    backgroundColor: "#fff"
  },
  button: {
    margin: theme.spacing(0, 1)
  }
}))
