/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort } from "../Common/tableSortingFunctions"
import DishLoader from "../Common/DishLoader"
import clsx from "clsx"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "name",
    label: <FormattedMessage id="ADMIN_DETAIL.LABELS.NAME" />
  },
  {
    id: "Icon",
    notSortable: true,
    label: <FormattedMessage id="ADMIN_DETAIL.LABELS.BANNER_IMAGE" />
  },
  {
    id: "active",
    notSortable: false,
    label: <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ACTIVE_FIELD.LABEL" />
  },
  {
    id: "Actions",
    notSortable: true,
    label: ""
  }
]

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  textField: {
    margin: theme.spacing(1),
    width: 250
  }
}))

function EnhancedTableToolbar(props) {
  const { searchText, onSearchTextChange } = props
  const classes = useToolbarStyles()

  return (
    <Toolbar className={classes.root}>
      <TextField
        id="standard-search"
        label={<FormattedMessage id="SEARCH_FIELD.LABEL" />}
        className={classes.textField}
        value={searchText}
        onChange={onSearchTextChange}
      />
      <div className={classes.spacer} />
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  onSearchTextChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired
}

function desc(a, b, orderBy) {
  if (orderBy === "ParentTag") {
    if (!b.ParentTag) {
      return -1
    }
    if (!a.ParentTag) {
      return 1
    }
    if (b.ParentTag.Name < a.ParentTag.Name) {
      return -1
    }
    if (b.ParentTag.Name > a.ParentTag.Name) {
      return 1
    }
    return 0
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export default function BannerList({ bannerData, onEdit, onDelete }) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [sortBy, setSortBy] = useState("name")
  const [orderBy, setOrderBy] = useState("asc")
  const [searchText, setSearchText] = useState("")

  function handleSearchTextChange(event) {
    const value = event.target.value
    setSearchText(value.toLowerCase())
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function handleEdit(tag) {
    onEdit(tag)
  }

  function handleDelete(tag) {
    onDelete(tag)
  }

  useEffect(() => {
    let result
    if (searchText.length !== 0) {
      result = bannerData.banners.filter(row =>
        row.name.toLowerCase().includes(searchText)
      )
    } else {
      result = bannerData.banners
    }
    setRows(result)
  }, [searchText, bannerData])

  if (bannerData.banners.length === 0 && bannerData.loading) {
    return <DishLoader centered/>
  }

  return (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
        <EnhancedTableToolbar
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        <img
                          src={row.bannerImageUrl}
                          alt="Icon"
                          className={classes.iconDisplay}
                        />
                      </TableCell>
                       <TableCell align="left">
                              {
                                  (!row.active) && ("false")
                              }
                              {
                                  (row.active) && ("true")
                              }
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Edit tag">
                          <IconButton
                            aria-label="Edit tag"
                            onClick={() => handleEdit(row)}
                          >
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                        {!bannerData.banners.find(
                          tag =>
                            tag.ParentTag && tag.ParentTag.TagID === row.id
                        ) && (
                          <Tooltip title="Delete tag">
                            <IconButton
                              aria-label="Delete tag"
                              onClick={() => handleDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}
