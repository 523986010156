/* eslint-disable no-restricted-imports */

import React from "react"
import { FormattedMessage } from "react-intl"
import Typography from "@material-ui/core/Typography"
import ExportButton from "./ExportButton"
import { useStyles } from "../Common/_styles/listWrapperStyles"
import DishLoader from "../Common/DishLoader"
import { injectIntl } from "react-intl"
function ListsWrapper({ reportsData, contents, exportData, exportFileName, hideExportBtn, showSummary, totalQuantity, dynamicMinHeight, customExportFunction }) {
  const classes = useStyles({ dynamicMinHeight  })

  return (
    <div className={classes.listWrapper}>
      {reportsData.isLoading && <DishLoader centered />}
      {reportsData.reports.length !== 0 && !hideExportBtn && (
          <div className="text-right">
            <ExportButton exportData={exportData} customExportFunction={customExportFunction} fileName={exportFileName} />
          </div>
      )}
        { showSummary && (
            totalQuantity !== 0 && (
              <div className={classes.summaryQuantity}>
                <span className={classes.summaryQuantityTitle}>
                  {
                    totalQuantity
                  }
                </span>
                <span className={classes.summaryQuantityTitle}>
                  <FormattedMessage id="REPORT.QUANTITY" />
                </span>
              </div>
            )
        )
      }
      {reportsData.reports.length !== 0 ? (
        contents
      ) : reportsData.notRequested ? null : (
        <Typography variant="h4" className={classes.heading}>
          <FormattedMessage id="COMMON.NO_RESULTS" />
        </Typography>
      )}

    </div>
  )
}

export default ListsWrapper
