/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import DataFormDialog from "./DataFormDialog"
import ConfirmationResendDialog from "./ConfirmationResendDialog"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"
import {Button} from "@material-ui/core";
import moment from "moment"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import axios from "axios";


const headRows = [
    {
        id: "Status",
        disablePadding: false,
        label: <FormattedMessage id="POS.STATUS" />
    },
    {
        id: "Date",
        disablePadding: false,
        label: <FormattedMessage id="POS.DATE" />
    },
    {
        id: "POS",
        disablePadding: false,
        label: <FormattedMessage id="POS.POS" />
    },
    {
        id: "MealType",
        disablePadding: false,
        label: <FormattedMessage id="POS.MEAL_TYPE" />
    },
    {
        id: "OrderNumber",
        disablePadding: false,
        label: <FormattedMessage id="POS.ORDER_NUMBER" />
    },
    {
        id: "OrderId",
        disablePadding: false,
        label: <FormattedMessage id="POS.ORDER_ID" />
    },
    {
        id: "SupplierName",
        disablePadding: false,
        label: <FormattedMessage id="POS.SUPPLIER_NAME" />
    },
    {
        id: "CustomerName",
        disablePadding: false,
        label: <FormattedMessage id="POS.CUSTOMER_NAME" />
    },
    {
        id: "UserName",
        disablePadding: false,
        label: <FormattedMessage id="POS.USER_NAME" />
    },
    {
        id: "Error",
        disablePadding: false,
        label: <FormattedMessage id="POS.ERROR" />
    },
    {
        id: "ErrorRetryNumber",
        disablePadding: false,
        label: <FormattedMessage id="POS.RETRY_ERROR_COUNT" />
    },
    {
        id: "ErrorRetryDate",
        disablePadding: false,
        label: <FormattedMessage id="POS.RETRY_ERROR_DATE" />
    },
    {
        id: "RetryNumber",
        disablePadding: false,
        label: <FormattedMessage id="POS.RETRY_NUMBER" />
    },
    {
        id: "LastRetryDate",
        disablePadding: false,
        label: <FormattedMessage id="POS.LAST_RETRY_DATE" />
    },
    {
        id: "Action",
        disablePadding: false,
        label: <></>
    },
]


function OrderItemsList({ data, searchParams, intl, handleResend, reload }) {
    const today = new Date();
    const classes = useStyles()
    const rows = data.reports || []
    const [sortBy, setSortBy] = useState("Name")
    const [orderBy, setOrderBy] = useState("asc")
    const [showPopup, setShowPopup] = useState({
        show: false,
        data: undefined
    })
    const [confirmationShowPopup, setConfirmationShowPopup] = useState({
        show: false,
        data: undefined
    })
    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }
    function showRequest(item) {
        setShowPopup({
            show: true,
            data: JSON.parse(item.Request)
        })
    }
    function showResponse(item) {
        setShowPopup({
            show: true,
            data: JSON.parse(item.Response)
        })
    }
    function resend(item) {
        setConfirmationShowPopup({
            show: true,
            data: item
        })
    }

    return (
        <>
            <div className={classes.summaryLength}>
                <span>
                    {rows.length}
                </span>
                <span className={classes.summaryLengthTitle}>
                    <FormattedMessage id="POS.COUNT_ELEMENTS" />
                </span>
            </div>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <EnhancedTableHead
                            headRows={headRows}
                            orderBy={orderBy}
                            sortBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                                return (
                                    <>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component="th" scope="row" align="left">
                                                {row.Status === 'true' ? <FormattedMessage id="POS.SUCCESS" /> : <FormattedMessage id="POS.ERROR" />}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                { moment(row.CreatedDate).locale("he").format("HH:mm:ss")  }
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.POSType === 1 ? <FormattedMessage id="POS.BETEAVON" /> : <FormattedMessage id="POS.OTHER" />}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {getMealTypeText(intl, row.MealType)}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.OrderNumber}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.OrderId}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.SupplierName}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.CustomerName}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.UserName}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.Error}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.ErrorRetryNumber}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {
                                                    row.ErrorRetryDate ?
                                                        moment(row.ErrorRetryDate).locale("he").format("DD/MM/YYYY HH:mm:ss") : ''
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row.RetryNumber}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {
                                                    row.LastRetryDate ?
                                                        moment(row.LastRetryDate).isSame(today, 'day') ?
                                                            moment(row.LastRetryDate).locale("he").format("HH:mm:ss") :
                                                            moment(row.LastRetryDate).locale("he").format("DD/MM/YYYY HH:mm:ss") : ''
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    size="large"
                                                    color="secondary"
                                                    className={classes.button}
                                                    onClick={() => showRequest(row)}
                                                >
                                                    <FormattedMessage id="POS.REQUEST" />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    size="large"
                                                    color="secondary"
                                                    className={classes.button}
                                                    onClick={() => showResponse(row)}
                                                >
                                                    <FormattedMessage id="POS.RESPONSE" />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    size="large"
                                                    color="secondary"
                                                    className={classes.button}
                                                    onClick={() => resend(row)}
                                                >
                                                    <FormattedMessage id="POS.RESEND" />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <ConfirmationResendDialog
                    show={confirmationShowPopup.show}
                    title={<FormattedMessage id="POS.RESEND_CONFIRMATION"/>}
                    submitData={(data) => {
                        reload();
                    }}
                    closeDialog={() => {
                        setConfirmationShowPopup({
                            show: false,
                            data: undefined
                        });
                    }}
                    handleResend={handleResend}
                    intl={intl}
                    data={confirmationShowPopup.data}
                />
                <DataFormDialog
                    show={showPopup.show}
                    title={<FormattedMessage id="CREATE_FORM.TITLE"/>}
                    closeDialog={() => {
                        setShowPopup({
                            show: false,
                            data: undefined
                        });
                    }}
                    intl={intl}
                    data={showPopup.data}
                />
            </Paper>
        </>
    )
}

export default injectIntl(OrderItemsList)
