/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import OptionGroupList from "./OptionGroupList"
import OptionGroupForm from "./OptionGroupForm"
import axios from "axios"
import CreateNewButton from "../../Common/CreateNewButton"
import {
  getOptionGroupVersion,
  updateOptionGroupVersion,
  deleteOptionGroupVersion,
  createNewOptionGroupVersion,
  createOptionGroup
} from "../_axios/optionGroupCrud"
import CreateOptionGroupFormDialog from "../../Common/CreateWithNameFormDialog"
import { FormattedMessage, injectIntl } from "react-intl"
import ConfirmationDialog from "../../Common/ConfirmationDialog"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import DishLoader from "../../Common/DishLoader"

function OptionGroupPanel({
  selectedSupplierID,
  isUserAdmin,
  isLoading,
  optionGroupsData,
  setOptionGroupsData,
  intl,
  suppliers
}) {
  const dispatch = useDispatch()
  const [selectedOptionGroup, setSelectedOptionGroup] = useState({})
  const [showCreateOptionGroupPanel, setShowCreateOptionGroupPanel] = useState(
    false
  )
  const [deleteDialogData, setDeleteDialogData] = useState({
    show: false,
    optionGroupID: null,
    version: null,
    isSubmitting: false
  })

  function selectOptionGroup(id) {
    if (selectedOptionGroup.id !== id) {
      setSelectedOptionGroup({
        id,
        availableVersions: optionGroupsData.optionGroups
          .find(optionGroup => optionGroup.id === id)
          .Versions.map(version => version.Version),
        Version: optionGroupsData.optionGroups
          .find(optionGroup => optionGroup.id === id)
          .Versions.reduce(
            (max, version) =>
              version.Status === 3 && version.Version > max
                ? version.Version
                : max,
            1
          )
      })
    }
  }

  function saveOptionGroup(submittedGroup) {
    setOptionGroupsData({ ...optionGroupsData, isLoading: true })
    if (submittedGroup.id) {
      return updateOptionGroupVersion(
        submittedGroup,
        selectedSupplierID,
        selectedOptionGroup.versionID,
        submittedGroup.Version
      )
    }
    return createOptionGroup(submittedGroup, selectedSupplierID)
  }

  function fetchOptionGroupVersion(cancelToken) {
    setSelectedOptionGroup({
      ...selectedOptionGroup,
      isLoading: true
    })
    getOptionGroupVersion(
      selectedSupplierID,
      selectedOptionGroup.id,
      selectedOptionGroup.Version,
      cancelToken.token
    )
      .then(({ data }) => {
        setSelectedOptionGroup({
          ...selectedOptionGroup,
          ...data,
          versionID: data.id,
          id: selectedOptionGroup.id,
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_OPTION_GROUP_VERSION_INFO"
          })
        )
      )
  }

  function updateOptionGroups(submittedGroup) {
    const optionGroups = [...optionGroupsData.optionGroups]
    const changedGroup = optionGroups.find(
      optionGroup => optionGroup.id === submittedGroup.id
    )
    if (changedGroup) {
      // changedGroup.Name = submittedGroup.Name
      changedGroup.Icon = submittedGroup.Icon
      if (
        selectedOptionGroup.availableVersions.includes(submittedGroup.Version)
      ) {
        const changedVersion = changedGroup.Versions.find(
          version => version.Version === submittedGroup.Version
        )
        changedVersion.Status = submittedGroup.Status
      } else {
        changedGroup.Versions.push({
          Version: submittedGroup.Version,
          Status: submittedGroup.Status
        })
      }
      setOptionGroupsData({
        ...optionGroupsData,
        optionGroups: [...optionGroups],
        isLoading: false
      })
      setSelectedOptionGroup({})
    } else {
      const newGroup = {
        id: submittedGroup.id,
        Name: submittedGroup.Name,
        Icon: null
      }
      newGroup.Versions = [
        {
          Version: 1,
          Status: 1
        }
      ]
      setOptionGroupsData({
        ...optionGroupsData,
        optionGroups: [...optionGroupsData.optionGroups, newGroup],
        isLoading: false
      })
      setShowCreateOptionGroupPanel(false)
      setSelectedOptionGroup({
        id: newGroup.id,
        availableVersions: [1],
        Version: 1
      })
    }
  }

  function removeOptionGroupVersion(optionGroupID, optionGroupVersion) {
    setSelectedOptionGroup({})
    const optionGroups = [...optionGroupsData.optionGroups]
    const changedGroup = optionGroups.find(
      optionGroup => optionGroup.id === optionGroupID
    )

    const remainingVersions = changedGroup.Versions.filter(
      version => version.Version !== optionGroupVersion
    )
    changedGroup.Versions = remainingVersions
    setOptionGroupsData({
      ...optionGroupsData,
      optionGroups:
        remainingVersions.length !== 0
          ? optionGroups
          : optionGroups.filter(
              optionGroup => optionGroup.id !== optionGroupID
            ),
      isLoading: false
    })
  }

  useEffect(() => {
    if (selectedOptionGroup.id) {
      const cancelToken = axios.CancelToken.source()
      fetchOptionGroupVersion(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [selectedOptionGroup.id, selectedOptionGroup.Version])

  const handleNewOptionGroupSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const newOptionGroup = { Name: values.name }
    createOptionGroup(newOptionGroup, selectedSupplierID)
      .then(({ data }) => {
        setSubmitting(false)
        updateOptionGroups({
          ...newOptionGroup,
          id: data.OptionsGroupID
        })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_OPTION_GROUP"
          })
        )
      })
  }

  const handleAcceptDeleteOptionGroupVersion = () => {
    setDeleteDialogData({ ...deleteDialogData, isSubmitting: true })

    deleteOptionGroupVersion(
      selectedSupplierID,
      deleteDialogData.optionGroupID,
      deleteDialogData.version
    )
      .then(() => {
        removeOptionGroupVersion(
          deleteDialogData.optionGroupID,
          deleteDialogData.version
        )
        setDeleteDialogData({
          show: false,
          optionGroupID: null,
          version: null,
          isSubmitting: false
        })
      })
      .catch(error => {
        setDeleteDialogData({
          ...deleteDialogData,
          isSubmitting: false
        })
        error.response.data &&
        error.response.data.Message === "ItemOption is in use"
          ? handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.OPTION_GROUP_IS_USED"
              })
            )
          : handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_DELETE_OPTION_GROUP"
              })
            )
      })
  }

  return (
    <>
      <ConfirmationDialog
        show={deleteDialogData.show}
        onSubmit={handleAcceptDeleteOptionGroupVersion}
        onClose={() =>
          setDeleteDialogData({
            show: false,
            optionGroupID: null,
            version: null,
            isSubmitting: false
          })
        }
        isSubmitting={deleteDialogData.isSubmitting}
        dialogTitle={
          <FormattedMessage id="DELETE_OPTION_GROUP_VERSION_DIALOG.TITLE" />
        }
        contentText={intl.formatMessage({
          id: "DELETE_OPTION_GROUP_VERSION_DIALOG.TEXT"
        })}
      />
      <CreateNewButton onClick={() => setShowCreateOptionGroupPanel(true)} />
      <CreateOptionGroupFormDialog
        show={showCreateOptionGroupPanel}
        title={<FormattedMessage id="CREATE_OPTION_GROUP_FORM.TITLE" />}
        closeDialog={() => setShowCreateOptionGroupPanel(false)}
        handleSubmit={handleNewOptionGroupSubmit}
        checkIfNameUnique={value => {
          const optionGroup = optionGroupsData.optionGroups.find(
            optionGroup => optionGroup.Name === value
          )
          return optionGroup === undefined
        }}
      />
      {
        optionGroupsData.isLoading && <DishLoader centered />
      }
      {
        !optionGroupsData.isLoading &&  <div className="row">
          <div className="col-4">
            <OptionGroupList
                optionGroups={optionGroupsData.optionGroups}
                selected={selectedOptionGroup.id}
                setSelected={selectOptionGroup}
            />
          </div>
          <div className="col-8">
            {
              selectedOptionGroup.isLoading  && <DishLoader centered />
            }
            {!selectedOptionGroup.isLoading && Object.keys(selectedOptionGroup).includes("Name") && (
                <OptionGroupForm
                    selectedOptionGroup={selectedOptionGroup}
                    setSelectedOptionGroup={setSelectedOptionGroup}
                    optionGroups={optionGroupsData.optionGroups}
                    onSubmit={saveOptionGroup}
                    onDelete={(optionGroupID, version) =>
                        setDeleteDialogData({
                          show: true,
                          optionGroupID,
                          version,
                          isSubmitting: false
                        })
                    }
                    updateOptionGroups={updateOptionGroups}
                    createNewOptionGroupVersion={() =>
                        createNewOptionGroupVersion(
                            selectedSupplierID,
                            selectedOptionGroup.id
                        )
                    }
                    isUserAdmin={isUserAdmin}
                    suppliers={suppliers}
                />
            )}
          </div>
        </div>
      }

    </>
  )
}
export default injectIntl(OptionGroupPanel)
