import axios from "axios"

export function getOrders(
  mealTypeID,
  supplierID,
  date,
  locationID,
  departmentID,
  deliveryTimeId,
  cancelToken
) {
  const params = {
    supplierID,
    date,
    mealTypeID,
    locationID,
    departmentID,
  }
  if (deliveryTimeId) {
    deliveryTimeId.split(',').forEach((item, index) => {
      params[`deliveryTimeId[${index}]`] = item;
    })
  } else {
    params['deliveryTimeId'] = [];
  }
  return axios.get("/Suppliers/Orders", {
    params,
    cancelToken
  })
}

export function markOrderPrinted(ids) {
  return axios.post(`/Suppliers/Orders/MarkPrinted` , {ids})
}

export function markOrderReady(id) {
  return axios.get(`/Suppliers/Orders/MarkReady/${id}`)
}

export function sendReportsToEmails(SupplierID, Date) {
  return axios.post(`/Supplier/Report/Send`, { SupplierID, Date })
}
