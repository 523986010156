/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  listWrapper: {
    position: "relative",
    width: "100%",
    minHeight: props => props.dynamicMinHeight ? 'auto' : '100%'
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  paper: {
    width: "100%",
    margin: theme.spacing(2, 0)
  },
  heading: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2, 1)
  },
  headingInfo: {
    margin: 10
  },
  iconDisplay: {
    height: 45
  },
  orderDetailsTotalContainer: {
    fontWeight: 600
  },
  orderDetailsTotal: {
    marginRight: 16
  },
  supplierDetailsOrder: {
    margin: 16,
    borderTop: '1px solid gray',
    display: 'flex'
  },
  summaryQuantityTitle: {
    marginLeft: 8,
    marginRight: 8,
    fontWeight: "bold"
  },
  summaryData: {
    display: 'flex'
  },
  summaryDataItem: {
    padding: 8
  },
  supplierDetailsOrderItem: {
    fontWeight: 600,
    marginLeft: 16
  },
  reportName: {
    fontWeight: 'bold',
    fontSize: 32
  }
}))
