import axios from "axios"

export function createPriceRange(data) {
    return axios.post(`/PriceRanges`, data)
}
export function deletePriceRange(id) {
    return axios.delete(`/PriceRanges/${id}`)
}
export function getPriceRanges(id) {
    return axios.get(`/PriceRanges?locationId=${id}`)
}