import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getItems, getItemVersion } from "../../Suppliers/_axios/itemCrud.js";
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers.js";
import Downshift from "downshift";
import UploadedItemsList from "./UploadedItemsList";
import {
  getSuggestions,
  renderInput,
  renderSuggestion
} from "../../Common/downshiftFunctions.js";
import axios from "axios";
import DishLoader from "../../Common/DishLoader.js";

const useStyles = makeStyles(theme => ({}));

function SupplierItemSelectorDialog( {
  intl,
  show,
  closeDialog,
  submitData,
  suppliers
}) {
  const dispatch = useDispatch();

  const itemsIDs = [];

  const [selectedSupplier, setSelectedSupplier] = useState({});

  function fetchItems(cancelToken) {
    setItemsData({ ...itemsData, isLoading: true, notRequested: false });
    getItems(selectedSupplier.SupplierID, cancelToken.token)
      .then(({ data }) => {
        const items = data.filter(
          item =>
            !item.Versions.map(version => version.ItemID).some(id =>
                itemsIDs.includes(id)
            )
        );
        setItemsData({
          items: items,
          selected: items.map(item => item.ItemID),
          isLoading: false,
          notRequested: false
        });
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ITEMS"
          })
        )
      );
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (selectedSupplier.SupplierID) {
      fetchItems(cancelToken);
    }
    return () => cancelToken.cancel();
  }, [selectedSupplier]);

  const [itemsData, setItemsData] = useState({
    items: [],
    selected: [],
    isLoading: false,
    notRequested: true
  });

  useEffect(() => {
    if (show) {
      setSelectedSupplier({});
      setItemsData({
        items: [],
        selected: [],
        isLoading: false,
        notRequested: true
      });
      setSubmitting(false);
    }
  }, [show]);

  const [isSubmitting, setSubmitting] = useState(false);

  const classes = useStyles();

  function getVersionData(newMealItems, chosenItems, index) {
    let after;
    if (index < chosenItems.length - 1) {
      after = () => {
        getVersionData(newMealItems, chosenItems, index + 1);
      };
    } else {
      after = () => {
        setSubmitting(false);
        submitData(newMealItems);
        closeDialog();
      };
    }

    getItemVersion(
      selectedSupplier.SupplierID,
      chosenItems[index].ItemID,
      chosenItems[index].Versions.reduce(
        (max, version) =>
          version.Active && version.Status === 3 && version.Version > max
            ? version.Version
            : max,
        1
      )
    )
      .then(({ data }) => {
        newMealItems.push({
          Item: {
            ...data,
            SupplierName: selectedSupplier.Name
          },
          ClientPrice: data.ClientPrice,
          CustomerPrice: data.CustomerPrice,
          IsFree: false,
          Priority: 0,
          Days: [...data.AvilabiltyDays],
          From: data.AvilableFrom ? new Date(`${data.AvilableFrom}.000Z`) : "",
          To: data.AvilableTo ? new Date(`${data.AvilableTo}.000Z`) : ""
        });
        after();
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ITEM_VERSION_INFO"
          })
        );
        after();
      });
  }

  function handleSubmit() {
    setSubmitting(true);

    const chosenItems = itemsData.items.filter(item =>
      itemsData.selected.includes(item.ItemID)
    );

    let index = 0;
    const newMealItems = [];
    getVersionData(newMealItems, chosenItems, index);
  }

  function handleToggle(checked, ItemID) {
    if (checked) {
      setItemsData({
        ...itemsData,
        selected: [...itemsData.selected, ItemID]
      })
    } else {
      setItemsData({
        ...itemsData,
        selected: itemsData.selected.filter(value => value !== ItemID)
      })
    }
  }

  function handleToggleAll(checked) {
    if (checked) {
      setItemsData({
        ...itemsData,
        selected: itemsData.items.map(item => item.ItemID)
      })
    } else {
      setItemsData({
        ...itemsData,
        selected: []
      })
    }
  }

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: "100%" } }}
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="COPY_ITEM_FORM.TITLE" />
        </h3>
      </DialogTitle>
      <DialogContent style={{ height: 500 }}>
        <div className="position-relative">
          <Downshift
            id="downshift-for-supplier"
            selectedItem={selectedSupplier.Name ? selectedSupplier : null}
            onChange={selected => setSelectedSupplier(selected || {})}
            itemToString={item => (item ? item.Name : "")}
          >
            {({
              clearSelection,
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              openMenu,
              selectedItem
            }) => {
              const {
                onChange,
                onBlur,
                onFocus,
                ...inputProps
              } = getInputProps({
                onChange: event => {
                  if (event.target.value === "") {
                    clearSelection();
                  }
                },
                onFocus: openMenu
              });

              return (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    label: intl.formatMessage({
                      id: "FILTER.SUPPLIER_FIELD.LABEL"
                    }),
                    InputLabelProps: getLabelProps({
                      shrink: true
                    }),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue, suppliers, {
                          showEmpty: true
                        }).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion
                            }),
                            highlightedIndex,
                            selectedItem
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </Downshift>
        </div>
        {itemsData.isLoading ? (
          <div>
            <DishLoader height={30}/>
          </div>
        ) : itemsData.items.length > 0 ? (
          <UploadedItemsList
            items={itemsData.items}
            selected={itemsData.selected}
            onToggle={handleToggle}
            onToggleAll={handleToggleAll}
          />
        ) : itemsData.notRequested ? null : (
          <Typography variant="h4">
            <FormattedMessage id="COMMON.NO_RESULTS" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          size="large"
          onClick={closeDialog}
          className={classes.button}
          disabled={isSubmitting}
        >
          <FormattedMessage id="COPY_ITEM_FORM.CANCEL_BUTTON" />
        </Button>
        <Button
          variant="contained"
          type="submit"
          size="large"
          color="secondary"
          className={classes.button}
          disabled={isSubmitting || itemsData.selected.length === 0}
          onClick={handleSubmit}
        >
          <FormattedMessage id="COPY_ITEM_FORM.SUBMIT_BUTTON" />
          {isSubmitting && <span className="ml-1 spinner spinner-white"></span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(SupplierItemSelectorDialog);
