/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { FormattedMessage } from "react-intl"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import { DateRange } from "react-date-range"
import { he, enUS } from "date-fns/esm/locale"
import moment from "moment"
import { useStyles } from "../../Common/_styles/formDialogStyles"

function SetDateRangeDialog({
    show,
    closeDialog,
    saveRange,
    maxDate,
    minDate,
    maxRange,
    initialStartDate,
    initialEndDate,
    intl
}) {
  const locale = intl?.locale === "en" ? enUS : he;
  const classes = useStyles()
  const [range, setRange] = useState({})

  const [min, setMin] = useState(minDate)
  const [max, setMax] = useState(maxDate)

  function handleSumbit() {
    saveRange(range.startDate, range.endDate)
  }

  function handleClear() {
    if (maxRange) {
      setMin(undefined)
      setMax(undefined)
      setRange({
        startDate: initialStartDate ? initialStartDate : undefined,
        endDate: initialEndDate ? initialEndDate : undefined
      })
    } else if (!max) {
      saveRange(null, null)
    } else {
      saveRange(min, max)
    }
  }

  function handleClose() {
    closeDialog()
  }

  useEffect(() => {
    if (show) {
      setRange({
        startDate: initialStartDate ? initialStartDate : undefined,
        endDate: initialEndDate ? initialEndDate : undefined
      })
    }
  }, [show])

  return (
      <div>
        <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <div dir="ltr">
              <DateRange
                  locale={locale}
                  editableDateInputs={true}
                  onChange={item => {
                    // if (maxRange) {
                    //   if (
                    //     moment(item.selection.endDate).diff(
                    //       item.selection.startDate,
                    //       "days",
                    //       true
                    //     ) > maxRange
                    //   ) {
                    //     setMin(item.selection.endDate)
                    //     setMax(
                    //       new Date(
                    //         moment(item.selection.endDate).add(maxRange, "days")
                    //       )
                    //     )
                    //     setRange({
                    //       startDate: item.selection.endDate,
                    //       endDate: item.selection.endDate
                    //     })
                    //   } else {
                    //     setMin(item.selection.startDate)
                    //     setMax(
                    //       new Date(
                    //         moment(item.selection.startDate).add(maxRange, "days")
                    //       )
                    //     )
                    //     setRange({
                    //       startDate: item.selection.startDate,
                    //       endDate: item.selection.endDate
                    //     })
                    //   }
                    // } else {
                    setRange({
                      startDate: item.selection.startDate,
                      endDate: item.selection.endDate
                    })
                    // }
                  }}
                  moveRangeOnFirstSelection={false}
                  maxDate={max}
                  minDate={min}
                  ranges={[
                    {
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: "selection"
                    }
                  ]}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
                variant="outlined"
                type="button"
                size="large"
                onClick={handleClear}
                className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CLEAR_BUTTON" />
            </Button>
            <Button
                variant="outlined"
                type="button"
                size="large"
                onClick={handleClose}
                className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
                variant="contained"
                type="submit"
                size="large"
                color="secondary"
                onClick={handleSumbit}
                disabled={range.startDate === undefined}
                className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  )
}
export default SetDateRangeDialog
