/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Button, Paper, TablePagination } from "@material-ui/core"
import clsx from "clsx"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { formatDate } from "../../Common/momentFunctions"
import { he } from "date-fns/esm/locale"

const headRows = [
  {
    id: "UserName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.EMPLOYEE_NAME" />
  },
  {
    id: "SupplierName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.RESTAURANT" />
  },
  {
    id: "CustomerName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER" />
  },
  {
    id: "LocationName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.LOCATION" />
  },
  {
    id: "OrderDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDER_DATE" />
  },
  {
    id: "OrderToDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDER_TO_DATE" />
  },
  {
    id: "RefundDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.REFUND_DATE" />
  },
  {
    id: "EmployeeRefundAmount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.EMPLOYEE_REFUND_AMOUNT" />
  },
  {
    id: "SubsidyRefundAmount",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUBSIDY_REFUND_AMOUNT" />
  },
  {
    id: "RefundType",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.REFUND_TYPE" />
  },
  {
    id: "RefundComment",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.REFUND_COMMENT" />
  }
]


function ItemList({
                    itemsData,
                    paginationState,
                    onPageChange,
                    openRefundDialog,
                    openCreditCardRefundDialog,
                    intl
                  }) {
  const classes = useStyles()
  const rows = itemsData.displayedData
  const [emptyRows, setEmptyRows] = useState(0)

  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function handleChangePage(event, newPage) {
    onPageChange({ page: newPage })
  }

  function handleChangeRowsPerPage(event) {
    const rowsPerPageValue = +event.target.value
    onPageChange({ rowsPerPage: rowsPerPageValue })
  }

  function renderRefundType(value) {
    switch (value) {
      case 1:
        return `${intl.formatMessage({
          id: "REFUND_TYPE.CUSTOMER"
        })}`
      case 2:
        return `${intl.formatMessage({
          id: "REFUND_TYPE.SUPPLIER"
        })}`
      case 3:
        return `${intl.formatMessage({
          id: "REFUND_TYPE.BOTH"
        })}`
      default:
        return null
    }
  }

  useEffect(() => {
    if (paginationState) {
      let value =
          paginationState.rowsPerPage -
          Math.min(
              paginationState.rowsPerPage,
              itemsData.itemsCount -
              paginationState.page * paginationState.rowsPerPage
          )

      setEmptyRows(value)
    }
  }, [itemsData.displayedData])

  return (
      <div className={classes.root}>
        <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
          <div className={clsx(classes.tableWrapper, classes.maxHeight)}>
            <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
            >
              <EnhancedTableHead
                  headRows={headRows}
                  orderBy={orderBy}
                  sortBy={sortBy}
                  onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(rows, getSorting(orderBy, sortBy)).map(
                    (row, index) => {
                      return (
                          <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.OrderItemID}
                          >
                            {/*<TableCell component="th" scope="row" align="left">*/}
                            {/*  {row.ItemName}*/}
                            {/*</TableCell>*/}
                            <TableCell align="left">{row.UserName}</TableCell>
                            <TableCell align="left">{row.SupplierName}</TableCell>
                            <TableCell align="left">{row.CustomerName}</TableCell>
                            <TableCell align="left">{row.LocationName}</TableCell>
                            <TableCell align="left">{formatDate(row.OrderDate)}</TableCell>
                            <TableCell align="left">{formatDate(row.OrderToDate)}</TableCell>
                            <TableCell align="left">{formatDate(row.RefundDate)}</TableCell>
                            <TableCell align="left">{row.WalletRefundAmount + row.CostumerCreditRefundAmount + row.CreditCardRefundAmount + row.BudgetRefundAmount}</TableCell>
                            <TableCell align="left">{row.SubsidyRefundAmount}</TableCell>
                            <TableCell align="left">{renderRefundType(row.RefundType)}</TableCell>
                            <TableCell align="left">{row.RefundReason}</TableCell>

                          </TableRow>
                      )
                    }
                )}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 29 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
              rowsPerPageOptions={[50, 100]}
              component="div"
              count={itemsData.itemsCount}
              rowsPerPage={paginationState.rowsPerPage}
              page={paginationState.page}
              labelRowsPerPage={intl.formatMessage({
                id: "TABLE.PAGINATION.ROWS_PER_PAGE"
              })}
              backIconButtonProps={{
                "aria-label": intl.formatMessage({
                  id: "TABLE.PAGINATION.PREVIOUS_PAGE"
                })
              }}
              nextIconButtonProps={{
                "aria-label": intl.formatMessage({
                  id: "TABLE.PAGINATION.NEXT_PAGE"
                })
              }}
              labelDisplayedRows={({ from, to, count }) =>
                  intl.formatMessage(
                      {
                        id: "TABLE.PAGINATION.DISPLAYED_ROWS_LABEL"
                      },
                      {
                        from,
                        to,
                        count
                      }
                  )
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
  )
}


export default injectIntl(ItemList)
