/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort } from "../Common/tableSortingFunctions"
import DishLoader from "../Common/DishLoader"
import clsx from "clsx"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Name",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "Icon",
    notSortable: true,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.IMAGE" />
  },
  {
    id: "ClientPrice",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CLIENT_PRICE" />
  },
  {
    id: "CustomerPrice",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER_PRICE" />
  },
  {
    id: "Active",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ACTIVE" />
  },
  {
    id: "Actions",
    notSortable: true,
    label: ""
  }
]

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  textField: {
    margin: theme.spacing(1),
    width: 250
  }
}))

function EnhancedTableToolbar(props) {
  const { searchText, onSearchTextChange } = props
  const classes = useToolbarStyles()

  return (
    <Toolbar className={classes.root}>
      <TextField
        id="standard-search"
        label={<FormattedMessage id="SEARCH_FIELD.LABEL" />}
        className={classes.textField}
        value={searchText}
        onChange={onSearchTextChange}
      />
      <div className={classes.spacer} />
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  onSearchTextChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

export default function ComboList({ combosData, onEdit, onDelete }) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")
  const [searchText, setSearchText] = useState("")

  function handleSearchTextChange(event) {
    const value = event.target.value
    setSearchText(value.toLowerCase())
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function handleEdit(combo) {
    onEdit(combo)
  }

  function handleDelete(combo) {
    onDelete(combo)
  }

  useEffect(() => {
    let result
    if (searchText.length !== 0) {
      result = combosData.combos.filter(row =>
        row.Name.toLowerCase().includes(searchText)
      )
    } else {
      result = combosData.combos
    }
    setRows(result)
  }, [searchText, combosData])

  if (combosData.combos.length === 0 && combosData.isLoading) {
    return <DishLoader />
  }

  return (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
        <EnhancedTableToolbar
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.Id}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.Name}
                      </TableCell>
                      <TableCell align="left">
                        <img src={row.ImageUrl} className={classes.imgContainer}/>
                      </TableCell>
                      <TableCell align="left">
                        {row.ClientPrice}
                      </TableCell>
                      <TableCell align="left">
                        {row.CustomerPrice}
                      </TableCell>
                      <TableCell align="left">
                        {
                          row.Active ?
                              <FormattedMessage id="TABLE.HEAD_LABELS.ACTIVE_YES" /> :
                              <FormattedMessage id="TABLE.HEAD_LABELS.ACTIVE_NO" />
                        }
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Edit combo">
                          <IconButton
                            aria-label="Edit combo"
                            onClick={() => handleEdit(row)}
                          >
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete combo">
                          <IconButton
                              aria-label="Delete combo"
                              onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}
