/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/OrdersAdmin/FilterPanel"
import { FormattedMessage, injectIntl } from "react-intl"
import moment from "moment"
import OrdersList from "../modules/OrdersAdmin/OrdersList"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import { connect } from "react-redux"
import {
  deleteOrder,
  deleteOrders,
  getStoreMealInfo,
  getUserMealInfo
} from "../modules/OrdersAdmin/_axios/ordersAdminCrud"
import { useDispatch } from "react-redux"
import {
  getCustomerLocations,
  getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import { getUsers } from "../modules/Users/_axios/userCrud"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import MealsList from "../modules/OrdersAdmin/MealsList"
import OrderDialog from "../modules/OrdersAdmin/OrderDialog"
import ConfirmationDialog from "../modules/Common/ConfirmationDialog"
import { TextField } from "@material-ui/core"
import {Tab, Tabs} from "react-bootstrap";
import { useStyles } from "../modules/Common/_styles/elementListStyles"
import { IconButton, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from "@material-ui/core/Button"
import { useSelector } from "react-redux"

function OrdersAdminPage({ intl, ...props }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tab, setTab] = useState('items');
  const userRole = useSelector(state => state.auth.user.Role)
  const [editData, setEditData] = useState({})
  const [newOrderItemID, setNewOrderItemID] = useState(null)
  const [selected, setSelected] = useState([])

  const [newOrderComboID, setNewOrderComboID] = useState(null)
  const [cancelOrderConfirmation, setCancelOrderConfirmation] = useState({
    show: false,
    OrderId: "",
    OrderItemID: "",
    isSubmitting: false
  })

  const [searchText, setSearchText] = useState("")

  const [customerData, setCustomerData] = useState({
    data: [],
    isLoading: true
  })
  const [locationsData, setLocationsData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [usersData, setUsersData] = useState({
    data: [],
    isLoading: false
  })


  const [mealsData, setMealsData] = useState({
    dishes: [],
    comboDishes: [],
    userOrder: [],
    isLoading: false
  })
  const [searchParams, setSearchParams] = useState({})

  const selectedLocation = locationsData.data.find(
    location => location.LocationID === searchParams.location
  )

  function handleSearchParamsSave(changes) {
    const newParams = {
      ...searchParams,
      ...changes,
      isDateToday: moment(changes.date).isSame(new Date(), "day")
    }
    setSearchParams(newParams)
  }

  useEffect(() => {
    function fetchCustomers(cancelToken) {
      setCustomerData({ ...customerData, isLoading: true })

      getCustomersList(cancelToken.token, true, "active")
        .then(({ data }) => {
          setCustomerData({
            ...customerData,
            data,
            isLoading: false
          })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
            })
          )
        )
    }

    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (searchParams.location) {
      function fetchUsers(cancelToken) {
        const { customer, location, mealDeliveryTime } = searchParams

        const params = {}
        params.customerid = customer.CustomerID
        params.locationid = location
        params.mealDeliveryTimeId = mealDeliveryTime
        params.active = true

        setUsersData({ ...usersData, isLoading: true })
        getUsers(params, cancelToken.token)
          .then(({ data }) => {
            setUsersData({
              data: data.items.map(el => ({
                ...el,
                Name: `${el.FirstName} ${el.LastName}`
              })),
              isLoading: false
            })
          })
          .catch(error =>
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_USERS"
              })
            )
          )
      }

      const cancelToken = axios.CancelToken.source()
      fetchUsers(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [searchParams.location])

  useEffect(() => {
    if (searchParams.customer && searchParams.customer.CustomerID) {
      function fetchLocations(cancelToken) {
        setLocationsData({ ...locationsData, isLoading: true })
        getCustomerLocations(
          searchParams.customer.CustomerID,
          cancelToken.token
        )
          .then(({ data }) => {
            setLocationsData({ data, isLoading: false, notRequested: false })
          })
          .catch(error =>
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
              })
            )
          )
      }
      const cancelToken = axios.CancelToken.source()
      fetchLocations(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [searchParams.customer])

  useEffect(() => {
    if (
      searchParams.mealType !== 4 &&
      searchParams.mealDeliveryTimeId &&
      selectedLocation &&
      selectedLocation.MealsInfo &&
      searchParams.date &&
      searchParams.user
    ) {
      if (selectedLocation.MealsInfo.length) {
        const selectedMeal = selectedLocation.MealsInfo.find(
          mealInfo => +mealInfo.MealType === +searchParams.mealType
        )
        if (!selectedMeal) {
          return
        }

        props.history.push({
          pathname: "/order-admin",
          search:
            "?" +
            new URLSearchParams({
              customer: searchParams.customer.CustomerID,
              user: searchParams.user,
              location: searchParams.location,
              mealType: searchParams.mealType,
              mealDeliveryTimeId: searchParams.mealDeliveryTimeId,
              date: searchParams.date
            }).toString()
        })

        setMealsData({
          ...mealsData,
          isLoading: true
        })
        const cancelToken = axios.CancelToken.source()
        getUserMealInfo(
          searchParams.user,
          selectedMeal.id,
          formatDateForApi(searchParams.date),
          searchParams.mealDeliveryTimeId,
          cancelToken.token
        )
          .then(({ data }) => {
            data.MealItems.sort((a, b) => b.Priority - a.Priority)
            setMealsData({
              dishes: data.MealItems,
              comboDishes: data.MealCombos,
              userOrder: data.UserOrder,
              selected: [],
              isLoading: false
            })
          })
          .catch(error => {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_USER_MEAL_INFO"
              })
            )
          })
        return () => cancelToken.cancel()
      }
    }
  }, [searchParams, selectedLocation])

  useEffect(() => {
    if (
      searchParams.mealType === 4 &&
      searchParams.mealDeliveryTimeId &&
      selectedLocation &&
      selectedLocation.MealsInfo &&
      searchParams.user
    ) {
      const selectedMeal = selectedLocation.MealsInfo.find(
        mealInfo => +mealInfo.MealType === 4
      )
      if (selectedMeal) {
        setMealsData({
          ...mealsData,
          isLoading: true
        })
        const cancelToken = axios.CancelToken.source()
        getStoreMealInfo(searchParams.user, selectedMeal.id, formatDateForApi(searchParams.date), searchParams.mealDeliveryTimeId, cancelToken.token)
          .then(({ data }) => {
            data.MealItems.sort((a, b) => b.Priority - a.Priority)

            setMealsData({
              dishes: data.MealItems,
              comboDishes: data.MealCombos,
              userOrder: data.UserOrder,
              isLoading: false
            })
          })
          .catch(error => {
            console.error(error)
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_STORE_MEAL_INFO"
              })
            )
          })
        return () => cancelToken.cancel()
      } else {
        setMealsData({
          dishes: [],
          comboDishes: [],
          userOrder: [],
          isLoading: false
        })
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (customerData.data.length) {
      const urlParams = new URLSearchParams(window.location.search)

      const initialParams = {
        mealType: +urlParams.get("mealType") || 2,
        mealDeliveryTimeId: urlParams.get("mealDeliveryTimeId") || '',
        date: urlParams.get("date")
          ? new Date(urlParams.get("date"))
          : new Date(),
        customer:
          customerData.data.find(
            customer => customer.CustomerID === urlParams.get("customer")
          ) || {},
        location: urlParams.get("location") || "",
        user: urlParams.get("user") || ""
      }

      setSearchParams(initialParams)
    }
  }, [customerData.data])

  function handleToggleAll(checked) {
    setMealsData({
        ...mealsData.userOrder, selected: checked ? mealsData.userOrder.map(item => item.OrderItemID) : []
    })
}

function deleteSelected (){
  setCancelOrderConfirmation({
    show: false,
    isSubmitting: true
  })
  deleteOrders({OrderIds : selected}).then(() =>{
    var filterdData = mealsData.userOrder.filter(e => !selected.includes(e.Id))

    setMealsData({
      ...mealsData,
      userOrder: filterdData
    })
    setSelected(
      []);
    setCancelOrderConfirmation({
      show: false,
      isSubmitting: false
    })
  })



}

  function cancelOrder(orderID, itemID) {
    setCancelOrderConfirmation({
      ...cancelOrderConfirmation,
      isSubmitting: true
    })
    deleteOrder(searchParams.user, orderID, itemID)
      .then(() => {
        setCancelOrderConfirmation({
          show: false,
          isSubmitting: false
        })
        setMealsData({
          ...mealsData,
          userOrder: mealsData.userOrder.filter(
            el =>  el.OrderItemID !== itemID
          )
        })
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CANCEL_ORDER"
          })
        )
      })
  }

  function handleSelectMealItem(selectedItem) {
    const selectedItems = [...selected];
    const index = selectedItems.indexOf(selectedItem.itemId);
    if (index === -1) {
      selectedItems.push(selectedItem.itemId);
    } else {
      selectedItems.splice(index, 1);
    }
    setSelected(
      selectedItems);
}

  return (
    <>
      <ConfirmationDialog
        show={cancelOrderConfirmation.show}
        isSubmitting={cancelOrderConfirmation.isSubmitting}
        dialogTitle={<FormattedMessage id="DELETE_ORDER_DIALOG.TITLE" />}
        contentText={intl.formatMessage({
          id: "DELETE_ORDER_DIALOG.TEXT"
        })}
        onClose={() =>
          setCancelOrderConfirmation({
            show: false
          })
        }
        onSubmit={() => {
          deleteSelected()
        }}
      />
      <OrderDialog
        closeDialog={refreshPage => {
          setNewOrderItemID(null)
          setEditData({})
          if (refreshPage) {
            setSearchParams({ ...searchParams })
          }
        }}
        type='item'
        show={editData.OrderItemID || newOrderItemID}
        editData={editData}
        customerName={searchParams?.customer?.Name}
        itemID={editData.OrderItemID || newOrderItemID}
        mealID={
          selectedLocation &&
          selectedLocation.MealsInfo &&
          selectedLocation.MealsInfo.find(
            mealInfo => +mealInfo.MealType === +searchParams.mealType
          ).id
        }
        mealDeliveryTimeId={searchParams.mealDeliveryTimeId}
        userID={searchParams.user}
        date={searchParams.date}
       
      />
      <OrderDialog
          type='combo'
          closeDialog={refreshPage => {
            setNewOrderComboID(null)
            setEditData({})
            if (refreshPage) {
              setSearchParams({ ...searchParams })
            }
          }}
          show={editData.OrderComboID || newOrderComboID}
          editData={editData}
          customerName={searchParams?.customer?.Name}
          itemID={editData.OrderComboID || newOrderComboID}
          mealID={
              selectedLocation &&
              selectedLocation.MealsInfo &&
              selectedLocation.MealsInfo.find(
                  mealInfo => +mealInfo.MealType === +searchParams.mealType
              ).id
          }
          mealDeliveryTimeId={searchParams.mealDeliveryTimeId}
          userID={searchParams.user}
          date={searchParams.date}
      />
      {searchParams.customer && (
        <FilterPanel
          onParamsChangeSave={handleSearchParamsSave}
          customerData={customerData}
          locationsData={locationsData}
          usersData={usersData}
          searchParams={searchParams}
          role={userRole}
        />
      )}
      {mealsData.userOrder.length !== 0 && (
        <div className="mt-3">
          <FormattedMessage id="LABELS.ORDERS" />
                      <Button
          id="delets_orders"
          onClick={() => setCancelOrderConfirmation({
            show: true,
          })}
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={
            selected.length == 0
          }
          className={`font-weight-bold my-3 ml-10`}
        >
          <DeleteIcon />
        </Button>
          <OrdersList
            ordersData={mealsData}
            onEdit={setEditData}
            onToggleAll={handleToggleAll}
            onSelect={handleSelectMealItem}
            selected = {selected}
          />
        </div>
      )}
      <div className="mt-3">
      <TextField
          fullWidth
          label={intl.formatMessage({
            id: "SEARCH_FIELD.LABEL"
          })}
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
      />
        <Tabs id="main-tab"
              activeKey={tab}
              onSelect={(k) => setTab(k)}
              unmountOnExit>
          <Tab eventKey="items"
               title={<FormattedMessage id="LABELS.MEALS"/>}>
              <span className={classes.tabTitle}>
                  <FormattedMessage id="LABELS.MEALS" />
              </span>
              <MealsList
                  ordersData={mealsData}
                  searchText={searchText.toLowerCase()}
                  onOrder={item => item.ItemId ? setNewOrderItemID(item.ItemId) : setNewOrderComboID(item.ComboId)}
              />
                  </Tab>
        </Tabs>
      </div>
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(OrdersAdminPage))
)
