import React, { useEffect } from "react"
import { useImmer } from "use-immer"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import InputMask from "react-input-mask"
import { connect } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import {
    sendOtp,
    validateOtp,
    resendOtp,
    getUserInfo
} from "../_redux/authCrud"

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    email: "",
    phone: ""
}

function Login(props) {
    const { intl } = props
    const [state, setState] = useImmer({
        isContactDataSubmitted: false,
        isOtpSending: false,
        showError: false,
        error: { show: false, message: "" },
        changeInProgress: false,
        email: "",
        phone: "",
        chosen: "",
        otpData: {},
        otp: "",
        isLoading: false,
        isOtpResend: false,
        timer: 0,
        attemptCount: 0,
        isLocked: false
    })

    const acceptableRoles = [
        "Admin",
        "Customer Admin",
        "Supplier Admin",
        "Supplier User",
        "Delivery",
        "Owner"
    ]


    useEffect(() => {
        let timerInterval;
        if (state.timer > 0) {
            timerInterval = setInterval(() => {
                setState((draft) => {
                    draft.timer -= 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [state.timer, setState]);

    const handleResendOtp = (setStatus) => {
        if (state.isLocked) return;

        resendOtp({ OTPID: props.otp.OTPID })
            .then(() => {
                setState((draft) => {
                    draft.isOtpResend = true;
                    draft.attemptCount += 1;
                    draft.timer = 60;
                });

                setTimeout(() => {
                    setState((draft) => {
                        draft.isOtpResend = false;
                    });
                }, 60000);
            })
            .catch(() => {
                setState((draft) => {
                    draft.isLocked = true;
                });
                setStatus(intl.formatMessage({ id: "AUTH.LOGIN.USER_LOCKED" }))
            })
    };


    const emailValidationSchema = Yup.string()
        .email(
            intl.formatMessage({
                id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT"
            })
        )
        .min(
            3,
            intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 3 })
        )
        .max(
            50,
            intl.formatMessage(
                { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
                { max: 50 }
            )
        )
        .when("phone", {
            is: phone => {
                return !phone
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const phoneValidationSchema = Yup.string()
        .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            intl.formatMessage({
                id: "AUTH.VALIDATION.WRONG_PHONE_FORMAT"
            })
        )
        .when("email", {
            is: email => {
                return !email
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const oneTimePasswordValidationSchema = Yup.string()
        .matches(
            /^[0-9] [0-9] [0-9] [0-9] [0-9] [0-9]$/,
            intl.formatMessage({
                id: "AUTH.VALIDATION.WRONG_OTP_FORMAT"
            })
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const otpValidationSchema = Yup.object().shape(
        {
            email: emailValidationSchema,
            phone: phoneValidationSchema,
            otp: oneTimePasswordValidationSchema
        },
        ["email", "phone", "otp"]
    )
    const ContactDataSchema = Yup.object().shape(
        {
            email: emailValidationSchema,
            phone: phoneValidationSchema
        },
        ["email", "phone"]
    )

    const enableLoading = () => {
        setState(draft => {
            draft.isLoading = true
        })
    }

    const disableLoading = () => {
        setState(draft => {
            draft.isLoading = false
        })
    }

    const getInputClasses = fieldname => {
        if (
            formikForContactData.touched[fieldname] &&
            formikForContactData.errors[fieldname]
        ) {
            return "is-invalid"
        }

        if (
            formikForContactData.touched[fieldname] &&
            !formikForContactData.errors[fieldname]
        ) {
            return "is-valid"
        }

        return ""
    }

    const formikForOtpValidation = useFormik({
        initialValues: {
            email: state.otpData.Email,
            phone: state.otpData.Mobile,
            otp: ""
        },
        validationSchema: otpValidationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading()
            setTimeout(() => {
                let data = {
                    OTPID: props.otp.OTPID,
                    OTP: values.otp.replace(/\D/g, "")
                }
                validateOtp(data)
                    .then(({ data }) => {
                        getUserInfo(data.Token)
                            .then(({ data }) => {
                                disableLoading()
                                setSubmitting(false)
                                if (!acceptableRoles.includes(data.UserInfo.Role)) {
                                    setStatus(
                                        intl.formatMessage({
                                            id: "AUTH.VALIDATION.INVALID_ROLE"
                                        })
                                    )
                                } else {
                                    props.login(data.Token, data.UserInfo)
                                }
                            })
                            .catch((e) => {
                                const statusCode = e.response.status;
                                disableLoading()
                                setSubmitting(false)
                                if (statusCode === 409)
                                    setStatus(intl.formatMessage({ id: "AUTH.LOGIN.USER_LOCKED" }))
                                else
                                    setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" }))
                            })
                    })
                    .catch((e) => {
                        const statusCode = e.response.status;
                        disableLoading()
                        setSubmitting(false)
                        if (statusCode === 409)
                            setStatus(intl.formatMessage({ id: "AUTH.LOGIN.USER_LOCKED" }))
                        else
                            setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" }))
                    })
            }, 1000)
        }
    })

    const formikForContactData = useFormik({
        initialValues,
        validationSchema: ContactDataSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading()
            setTimeout(() => {
                let data
                if (values.email.length === 0) {
                    data = {
                        Mobile: values.phone.replace(/\D/g, "")
                    }
                    formikForOtpValidation.setFieldValue("phone", values.phone)
                } else {
                    data = {
                        Email: values.email
                    }
                    formikForOtpValidation.setFieldValue("email", values.email)
                }
                setState(draft => {
                    draft.otpData = data
                })
                sendOtp(data)
                    .then(({ data }) => {
                        disableLoading()
                        props.sendOtp(data)
                        setStatus()
                        setState(draft => {
                            draft.isContactDataSubmitted = true
                            draft.isOtpResend = false
                        })
                        setSubmitting(false)
                    })
                    .catch((e) => {
                        const statusCode = e.response.status;
                        disableLoading()
                        setSubmitting(false)
                        if (statusCode === 409)
                            setStatus(intl.formatMessage({ id: "AUTH.LOGIN.USER_LOCKED" }))
                        else
                            setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" }))
                    })
            }, 1000)
        }
    })

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            {/* start:: Aside header */}
            <Link to="/" className="text-center mb-10 mb-lg-20 display-block">
                <img alt="Logo" className="max-h-100px" src="/media/logos/dish.svg" />
            </Link>
            {/* end:: Aside header */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    <FormattedMessage id="AUTH.LOGIN.AUTHORIZED_ACCOUNTS_ONLY" />
                </p>
                {state.isContactDataSubmitted && (
                    <p className="text-muted font-weight-bold">
                        {state.otpData.Email ? (
                            <FormattedMessage id="AUTH.LOGIN.OTP_SENT_TO_EMAIL" />
                        ) : (
                            <FormattedMessage id="AUTH.LOGIN.OTP_SENT_TO_MOBILE" />
                        )}
                    </p>
                )}
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {state.isContactDataSubmitted ? (
                <form
                    onSubmit={formikForOtpValidation.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {formikForOtpValidation.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {formikForOtpValidation.status}
                            </div>
                        </div>
                    )}
                    {state.otpData.Email ? (
                        <>
                            <div className="form-group fv-plugins-icon-container">
                                <input
                                    placeholder={intl.formatMessage({
                                        id: "AUTH.LOGIN.EMAIL.PLACEHOLDER"
                                    })}
                                    type="email"
                                    className={`form-control form-control-solid h-auto py-5 px-6 text-left ${getInputClasses(
                                        "email"
                                    )}`}
                                    name="email"
                                    {...formikForOtpValidation.getFieldProps("email")}
                                    disabled={true}
                                    dir="ltr"
                                />
                                {formikForOtpValidation.touched.email &&
                                    formikForOtpValidation.errors.email ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formikForOtpValidation.errors.email}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="form-group fv-plugins-icon-container">
                                <InputMask
                                    name="phone"
                                    mask="999-9999999"
                                    className={`form-control form-control-solid h-auto py-5 px-6 text-left ${getInputClasses(
                                        "phone"
                                    )}`}
                                    placeholder={intl.formatMessage({
                                        id: "AUTH.LOGIN.MOBILE.PLACEHOLDER"
                                    })}
                                    {...formikForOtpValidation.getFieldProps("phone")}
                                    disabled={true}
                                    dir="ltr"
                                ></InputMask>
                                {formikForOtpValidation.touched.phone &&
                                    formikForOtpValidation.errors.phone ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formikForOtpValidation.errors.phone}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )}
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            id="kt_login_change_contact_data"
                            type="button"
                            disabled={formikForOtpValidation.isSubmitting}
                            onClick={e =>
                                setState(draft => {
                                    draft.isContactDataSubmitted = false
                                })
                            }
                            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
                        >
                            <FormattedMessage id="AUTH.LOGIN.CHANGE_CONTACT_DATA" />
                        </button>
                        <button
                            id="kt_login_resend_otp"
                            type="button"
                            disabled={formikForOtpValidation.isSubmitting || state.isOtpResend || state.timer > 0 || state.isLocked}
                            onClick={() =>  handleResendOtp(formikForOtpValidation.setStatus)}
                            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
                        >
                            {state.isLocked
                                ? (<FormattedMessage id="AUTH.LOGIN.RESEND_OTP" />)
                                : state.timer > 0
                                    ? (<span>
                                        <FormattedMessage id="AUTH.LOGIN.RESEND_OTP_IN" /> {state.timer}
                                    </span>)
                                    : state.isOtpResend
                                        ? (<FormattedMessage id="AUTH.LOGIN.OTP_WAS_RESEND" />)
                                        : (<FormattedMessage id="AUTH.LOGIN.RESEND_OTP" />)
                            }
                        </button>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <InputMask
                            name="otp"
                            mask="9 9 9 9 9 9"
                            className={`form-control form-control-solid h-auto py-5 px-6 text-left ${getInputClasses(
                                "otp"
                            )}`}
                            placeholder={intl.formatMessage({
                                id: "AUTH.LOGIN.OTP.PLACEHOLDER"
                            })}
                            {...formikForOtpValidation.getFieldProps("otp")}
                            dir="ltr"
                        ></InputMask>
                    </div>
                    <div className="form-group text-right">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formikForOtpValidation.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                            {state.isLoading && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </button>
                    </div>
                </form>
            ) : (
                <form
                    onSubmit={formikForContactData.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {formikForContactData.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {formikForContactData.status}
                            </div>
                        </div>
                    )}

                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder={intl.formatMessage({
                                id: "AUTH.LOGIN.EMAIL.PLACEHOLDER"
                            })}
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 text-left ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formikForContactData.getFieldProps("email")}
                            dir="ltr"
                        />
                        {formikForContactData.touched.email &&
                            formikForContactData.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formikForContactData.errors.email}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container text-center">
                        <h3>
                            <FormattedMessage id="AUTH.GENERAL.OR" />
                        </h3>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <InputMask
                            name="phone"
                            mask="999-9999999"
                            className={`form-control form-control-solid h-auto py-5 px-6 text-left ${getInputClasses(
                                "phone"
                            )}`}
                            placeholder={intl.formatMessage({
                                id: "AUTH.LOGIN.MOBILE.PLACEHOLDER"
                            })}
                            {...formikForContactData.getFieldProps("phone")}
                            dir="ltr"
                        ></InputMask>
                        {formikForContactData.touched.phone &&
                            formikForContactData.errors.phone ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formikForContactData.errors.phone}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group text-right">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formikForContactData.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                            {state.isLoading && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </button>
                    </div>
                </form>
            )}
            {/*end::Form*/}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        otp: state.auth.otp
    }
}
export default injectIntl(connect(mapStateToProps, auth.actions)(Login))
