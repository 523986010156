/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import LockIcon from "@material-ui/icons/Lock"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { Link } from "react-router-dom"
import AssignmentIcon from "@material-ui/icons/Assignment"
import {TextField, Tooltip} from "@material-ui/core"
import DishLoader from "../Common/DishLoader"
import {
  Checkbox,
} from "@material-ui/core"

const headRows = [
  {
    id: "Selected",
    label: "",
    notSortable: true
  },
  {
    id: "Name",
    label: <FormattedMessage id="ADMIN_USERS.TABLE.HEAD_LABELS.NAME" />,
    notSortable: true
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    position: "relative"
  },
  tableWrapper: {
    maxHeight: 390,
    overflowY: "auto"
  },
  locationSearch: {
    marginTop: 8,
    marginBottom: 8
  }
}))

function LocationList({
  selected,
  setSelected,
  locationsData,
  selectedAll,
  onChange,
  handleSelection,
  intl
}) {
  const classes = useStyles()

  const rowsLocations = locationsData.locations || []
  //const rows = locationsData.locations || []
  const [emptyRows, setEmptyRows] = useState(0)
  const [rows, setRows] = useState(rowsLocations)

  if (locationsData.isLoading) {
    return <DishLoader />
  }

  function handleClick(event, id) {
    setSelected(id)
  }

  function handleRequestSort(event, property) {
    /*const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)*/
  }

  function locationSearch(event) {
    setRows(rowsLocations.filter(item => item.Name.indexOf(event.currentTarget.value) !== -1));
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div>
          <Checkbox
              onClick={(event) => handleSelection(event)}
              checked={selectedAll}
              name="breakfast"
              color="secondary"
          />
          <span>
            {
              intl.formatMessage({
                id: "ADMIN_DETAIL.LABELS.SELECT_ALL"
              })
            }
          </span>

        </div>

        <div className={classes.locationSearch}>
          <TextField
              id="message"
              name="message"
              label={intl.formatMessage({
                id: "ADMIN_DETAIL.LABELS.LOCATION"
              })}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                className: classes.textRight
              }}
              onChange={locationSearch}
          />
        </div>

        {locationsData.isLoading && <DishLoader centered />}
        <div className={classes.tableWrapper}>
          <Table stickyHeader aria-labelledby="tableTitle" size="small">
            {
              rows && rows.length > 0 &&
                <EnhancedTableHead
                    headRows={headRows}
                    sortBy={'name'}
                    orderBy={'asc'}
                    onRequestSort={handleRequestSort}
                />
            }
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.LocationID}
                    onClick={(event) => setSelected(row.LocationID)}
                  >
                    <TableCell
                      component="th"
                      align="center"
                      padding="none"
                      scope="row"
                    >
                      {
                        row.IsSelected && <Checkbox
                            checked={true}
                            disabled={true}
                            name="breakfast"
                            color="secondary"
                        />
                      }
                      {
                        !row.IsSelected && <Checkbox
                            checked={false}
                            disabled={true}
                            name="breakfast"
                            color="secondary"
                        />
                      }
                    </TableCell>
                    <TableCell align="left">
                      {row.Name}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(LocationList)
