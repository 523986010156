/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputMask from "react-input-mask"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import {useFormik} from "formik"
import * as Yup from "yup"
import {FormattedMessage, injectIntl} from "react-intl"
import {useStyles} from "../Common/_styles/formDialogStyles"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../redux/snackbar/snackbarHandlers"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { he, enUS } from "date-fns/esm/locale";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {
    getLocations,
} from "../../modules/Customers/_axios/customerCrud"
import axios from "axios";
import DishLoader from "../../../app/modules/Common/DishLoader"

function StateFreezeFormDialog({
                                   show,
                                   closeDialog,
                                   intl,
                                   variant,
                                   submitData,
                                   afterSubmitCloseDialog,
                                   message,
                                   freezed
                               }) {
    const locale = intl.locale === "en" ? enUS : he;
    const dispatch = useDispatch()
    const [locationData, setLocationData] = useState({
        locations: [],
        loading: false,
    });
    const classes = useStyles()

    const initialValues = {
        freezeDateTo: new Date(), isPermanent: "",
    }

    function handleSumbit(userID) {
        closeDialog(userID)
    }

    function handleClose() {
        closeDialog(null)
    }

    const formik = useFormik({
        initialValues, onSubmit: (values, {setSubmitting, setFieldError, resetForm}) => {
            const {
                freezeDateTo,
            } = values
            const data = {
                FreezeDateTo:
                    (!freezed && !formik.values.isPermanent) ? freezeDateTo : undefined,
                Freezed: !freezed,
                FreezedLocations: locationData
                    .locations.filter((location) => location.active)
                    .map(l => l.LocationID).join(',')
            };
            setSubmitting(true)
            submitData(data)
                .then(() => {
                    setSubmitting(false)
                    resetForm();
                    afterSubmitCloseDialog(data);
                    closeDialog();
                })
                .catch(error => {
                    setSubmitting(false)
                    handleApiError(dispatch, error, intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UPDATE_FREEZE"
                    }))
                })

        }
    })

    useEffect(() => {
        if (show) {
            if (!freezed) {
                const cancelToken = axios.CancelToken.source()
                setLocationData({
                    locations: [],
                    loading: true,
                })
                getLocations(cancelToken.token)
                    .then(({data}) => {
                        setLocationData({
                            locations: data,
                            loading: false
                        })
                    })
            }
        }
    }, [show])

    function change(index, active) {
        const locations = [...locationData.locations];
        const location = locations[index];
        location.active = active;
        setLocationData({
            ...locationData,
            ...locations
        });
    }

    return (<div>
        <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
        >
            <DialogTitle id="form-dialog-title" disableTypography={true}>
                <h3 style={{fontWeight: "bold", textAlign: "center"}}>
                    <FormattedMessage id="ADMIN.CONFIRMATION_FORM.TITLE"/>
                </h3>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <DialogContent>
                    <div>
                        {message}
                    </div>
                    {!freezed && (<>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formik.values.isPermanent}
                                name="isPermanent"
                                color="secondary"
                                {...formik.getFieldProps("isPermanent")}
                                onChange={(ev => {
                                    formik.getFieldProps("isPermanent").onChange(ev);
                                    if (ev.target.checked) {
                                        formik.setFieldValue('freezeDateTo', new Date())
                                    } else {
                                        formik.setFieldValue('freezeDateTo', null)
                                    }
                                })}
                            />}
                            label={intl.formatMessage({
                                id: "ADMIN_BANKS.CREATE_FORM.PERMANENTLY.LABEL"
                            })}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}
                            locale={locale}>
                            <DatePicker
                                autoOk
                                clearable
                                format="dd/MM/yyyy"
                                disabled={formik.values.isPermanent}
                                value={formik.values.freezeDateTo}
                                onChange={value => {
                                    formik.setFieldValue("freezeDateTo", value)
                                }}
                                minDate={new Date()}
                                TextFieldComponent={({helperText, InputProps, ...props}) => (<TextField
                                    id="freeze-date"
                                    label={intl.formatMessage({
                                        id: "ADMIN_BANKS.CREATE_FORM.FREEZE_DATE_FIELD.LABEL"
                                    })}
                                    className={classes.textField}
                                    {...props}
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                />)}
                            />
                        </MuiPickersUtilsProvider>
                    </>)}
                    {
                        locationData.loading && <DishLoader height={30}/>
                    }
                    {
                        !freezed && !locationData.loading && (
                            <div className={classes.locationContrainer}>
                                {
                                    locationData.locations.map((location, index) => {
                                        return <div>
                                            <span>
                                                <Checkbox
                                                    checked={location.active}
                                                    name="active"
                                                    color="secondary"
                                                    onChange={ev => change(index, !location.active)}
                                                />
                                            </span>
                                            <span>
                                                {
                                                    location.Name
                                                }
                                            </span>
                                        </div>
                                    })
                                }
                            </div>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        type="button"
                        size="large"
                        onClick={handleClose}
                        className={classes.button}
                    >
                        <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.CANCEL_BUTTON"/>
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        color="secondary"
                        className={classes.button}
                        disabled={formik.isSubmitting}
                    >
                        <FormattedMessage id="ADMIN_BANKS.TRANSFER_FORM.SUBMIT_BUTTON"/>
                        {formik.isSubmitting && (<span className="ml-1 spinner spinner-white"></span>)}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>)
}

export default injectIntl(StateFreezeFormDialog)
