/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"
import { InputAdornment } from "@material-ui/core"
import DishLoader from "../Common/DishLoader"

function WalletDialog({
  show,
  isEditAllowed,
  currentBalance,
  isLoading,
  closeDialog,
  submitData,
  refreshBalance,
  intl
}) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const initialValues = {
    addAmount: 0
  }

  function handleClose() {
    closeDialog()
  }

  const addAmountValidationSchema = Yup.number().min(
    -currentBalance,
    intl.formatMessage({ id: "VALIDATION.MIN_VALUE_FIELD" }, { min: -currentBalance })
  )

  const validationSchema = Yup.object().shape(
    {
      addAmount: addAmountValidationSchema,
    },
    [
      "addAmount",
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
      const {
        addAmount,
      } = values

      setSubmitting(true)
      submitData(addAmount)
        .then(() => {
          setSubmitting(false)
          refreshBalance()
          resetForm()
        })
        .catch(error => {
          setSubmitting(false)
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_UPDATE_WALLET"
            })
          )
        })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <div style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</div>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        PaperProps={{ style: { width: 300 } }}
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="ADMIN_USERS.WALLET_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent>
            {isLoading || formik.isSubmitting ? (
              <div>
                <DishLoader minHeight={100} height={100}/>
              </div>
            ) : <>
              <div className="row justify-content-center">
                <FormattedMessage id="ADMIN_USERS.WALLET_FORM.BALANCE" /> {currentBalance}₪
              </div>
              {isEditAllowed && <>
                <TextField
                  id="add-amount"
                  name="addAmount"
                  className={classes.textField}
                  inputProps={{
                    className: classes.textRight
                  }}
                  label={intl.formatMessage({
                    id: "ADMIN_USERS.WALLET_FORM.ADD_TO_BALANCE_FIELD.LABEL"
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₪</InputAdornment>
                    )
                  }}
                  margin="normal"
                  variant="filled"
                  type="number"
                  fullWidth
                  {...formik.getFieldProps("addAmount")}
                  error={checkForError("addAmount")}
                  dir="ltr"
                />
                {renderErrors("addAmount")}
              </>}
            </>}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CLOSE_BUTTON" />
            </Button>
            {isEditAllowed && (
              <Button
                variant="contained"
                type="submit"
                size="large"
                color="secondary"
                className={classes.button}
                disabled={formik.isSubmitting || isLoading || formik.values.addAmount === 0}
              >
                <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                {(isLoading || formik.isSubmitting) && (
                  <span className="ml-1 spinner spinner-white"></span>
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(WalletDialog)
