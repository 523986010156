/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import { formatDate } from "../Common/momentFunctions"
import { useStyles } from "../Common/_styles/elementListStyles"

const Types = {
  1: "CREDIT",
  2: "CUSTOMER_CREDIT",
  3: "BUDGET_CREDIT",
  4: "WALLET"
}

const headRows = [
  {
    id: "date",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDERDATE" />
  },
  {
    id: "type",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.TYPE" />
  },
  {
    id: "supplierName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUPPLIER" />
  },
  {
    id: "description",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.DESCRIPTION" />
  },
  {
    id: "creditCard",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CREDIT_CARD" />
  },
  {
    id: "budget",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.BUDGET" />
  },
  {
    id: "wallet",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.WALLET" />
  },
  {
    id: "costumerCredit",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER_CREDIT" />
  },
  {
    id: "subsidy",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUBSIDY" />
  },
  // {
  //   id: "refunded",
  //   notSortable: true,
  //   label: ""
  // },
  // {
  //   id: "comments",
  //   label: <FormattedMessage id="TABLE.HEAD_LABELS.COMMENTS" />
  // },
]

export default function UserActivityList({ userActivityData }) {
  const classes = useStyles()
  const rows = userActivityData
  const [sortBy, setSortBy] = useState("OrderDate")
  const [orderBy, setOrderBy] = useState("asc")

  const creditCard = rows.reduce((sum, it) => sum + it.creditCard, 0)
  const budget = rows.reduce((sum, it) => sum + it.budget, 0)
  const wallet = rows.reduce((sum, it) => sum + it.wallet, 0)
  const costumerCredit = rows.reduce((sum, it) => sum + it.costumerCredit, 0)
  const subsidy = rows.reduce((sum, it) => sum + it.subsidy, 0)

  const creditCardRefund = rows.reduce((sum, it) => sum + it.creditCardRefund, 0)
  const budgetRefund = rows.reduce((sum, it) => sum + it.budgetRefund, 0)
  const walletRefund = rows.reduce((sum, it) => sum + it.walletRefund, 0)
  const costumerCreditRefund = rows.reduce((sum, it) => sum + it.costumerCreditRefund, 0)
  const subsidyRefund = rows.reduce((sum, it) => sum + it.subsidyRefund, 0)


  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
        >
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {
              <TableRow
                  hover
                  role="checkbox"
                  className={classes.summary}
                  tabIndex={-1}
              >
                <TableCell component="th" scope="row" align="left">
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                </TableCell>
                <TableCell><span className={classes.priceDefinition}>{creditCardRefund !== 0 && <>(-{creditCardRefund}₪)</>}{creditCard}₪</span></TableCell>
                <TableCell><span className={classes.priceDefinition}>{budgetRefund !== 0 && <>(-{budgetRefund}₪)</>}{budget}₪</span></TableCell>
                <TableCell><span className={classes.priceDefinition}>{walletRefund !== 0 && <>(-{walletRefund}₪)</>}{wallet}₪</span></TableCell>
                <TableCell><span className={classes.priceDefinition}>{costumerCreditRefund !== 0 && <>(-{costumerCreditRefund}₪)</>}{costumerCredit}₪</span></TableCell>
                <TableCell><span className={classes.priceDefinition}>{subsidyRefund !== 0 && <>(-{subsidyRefund}₪)</>}{subsidy}₪</span></TableCell>
              </TableRow>
            }
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`user-activity-row-${index}`}>
                  <TableCell align="left">
                    {formatDate(row.date)}
                  </TableCell>
                  <TableCell align="left">
                    {!!row.type && <FormattedMessage id={`ACTIVITY_TYPES.${Types[row.type]}`} />}
                  </TableCell>
                  { <TableCell align="left">
                    {row.supplierName}
                  </TableCell> }
                  <TableCell align="left">
                    {row.description}
                  </TableCell>
                  <TableCell align="left">
                    {row.creditCard}₪{row.creditCardRefund !== 0 && <>({row.creditCardRefund}₪)</>}
                  </TableCell>
                  <TableCell align="left">
                    {row.budget}₪{row.budgetRefund !== 0 && <>({row.budgetRefund}₪-)</>}
                  </TableCell>
                  <TableCell align="left">
                    {row.wallet}₪{row.walletRefund !== 0 && <>({row.walletRefund}₪-)</>}
                  </TableCell>
                  <TableCell align="left">
                    {row.costumerCredit}₪{row.costumerCreditRefund !== 0 && <>({row.costumerCreditRefund}₪-)</>}
                  </TableCell>
                  <TableCell align="left">
                    {row.subsidy}₪{row.subsidyRefund !== 0 && <>({row.subsidyRefund}₪-)</>}
                  </TableCell>
                  {/* <TableCell align="left">
                    <Checkbox
                      checked={row.IsRefunded}
                      color="secondary"
                      disableRipple
                    />
                  </TableCell>
                  <TableCell align="left">
                    {row.Comments}
                  </TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
