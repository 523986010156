const renderRefundType = (value, intl) => {
    switch (value) {
        case 1:
            return `${intl.formatMessage({
                id: "REFUND_TYPE.CUSTOMER"
            })}`
        case 2:
            return `${intl.formatMessage({
                id: "REFUND_TYPE.SUPPLIER"
            })}`
        case 3:
            return `${intl.formatMessage({
                id: "REFUND_TYPE.BOTH"
            })}`
        default:
            return null
    }
}
export default renderRefundType
