/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import MealsList from "./MealsList"
import { withRouter } from "react-router-dom"
import FilterPanel from "./FilterPanel"
import { getMeals } from "../_axios/itemCrud"
import { getCustomersList } from "../../Customers/_axios/customerCrud"
import { formatDateForApi } from "../../Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import { injectIntl } from "react-intl"
import DishLoader from "../../Common/DishLoader"
function MealsPanel({ intl, isActiveTab, selectedSupplierID, ...props }) {
  const dispatch = useDispatch()
  const [mealsData, setMealsData] = useState({
    meals: [],
    isLoading: true
  })
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function fetchMeals(cancelToken) {
    const { mealType, date, customer } = searchParams

    const params = {
      tab: "meals",
      mealType,
      date: formatDateForApi(date),
      customer: customer.CustomerID
    }

    if (isActiveTab) {
      props.history.push({
        pathname: `/supplier/${selectedSupplierID}`,
        search: "?" + new URLSearchParams(params).toString()
      })
    }

    setMealsData({ ...mealsData, isLoading: true })
    getMeals(selectedSupplierID, mealType, params.date, cancelToken.token)
      .then(({ data }) => {
        setMealsData({
          meals: data.Orders.filter(order =>
            customer.CustomerID
              ? order.CustomerID === customer.CustomerID
              : true
          ),
          isLoading: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIER_MEALS"
          })
        )
      )
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true, "active")
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const areParamsPresent = urlParams.get("tab") === "meals"

      let initialParams = {}
      if (areParamsPresent) {
        initialParams = {
          mealType: urlParams.get("mealType") || 1,
          date: urlParams.get("date")
            ? new Date(urlParams.get("date"))
            : new Date(),
          customer:
            customerData.customers.find(
              customer => customer.CustomerID === urlParams.get("customer")
            ) || {}
        }
      } else {
        initialParams = {
          mealType: 1,
          date: new Date(),
          customer: {}
        }
      }
      setSearchParams(initialParams)
    }
  }, [customerData])

  useEffect(() => {
    if (searchParams.customer) {
      const cancelToken = axios.CancelToken.source()
      fetchMeals(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  return (
    <>
      {searchParams.customer && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {
        !mealsData.isLoading && <MealsList meals={mealsData.meals} />
      }
      {
        mealsData.isLoading && <DishLoader center/>
      }
    </>
  )
}
export default injectIntl(withRouter(MealsPanel))
