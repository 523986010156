/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import { Checkbox } from "@material-ui/core"
import EnhancedTableHead from "../../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../../Common/tableSortingFunctions"
import { useStyles } from "../../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Select",
    disablePadding: true,
    notSortable: true,
    notForReadOnly: true,
    label: ""
  },
  {
    id: "Image",
    disablePadding: false,
    notSortable: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: false,
    label: <FormattedMessage id="CREATE_FORM.COMBO_FIELD.LABEL" />
  }
]

function UploadedCombosList({ combos, selected, onToggle, onToggleAll }) {
  const classes = useStyles()
  const rows = combos
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={[
                {
                  ...headRows[0],
                  label: (
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      style={{ padding: 0 }}
                      checked={selected.length === rows.length}
                      onChange={event => {
                        onToggleAll(event.target.checked)
                      }}
                    />
                  )
                },
                ...headRows.slice(1)
              ]}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.Id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        width={24}
                        padding="none"
                      >
                        <Checkbox
                          checked={selected.includes(row.Id)}
                          style={{ padding: 0 }}
                          onChange={event => {
                            onToggle(event.target.checked, row.Id)
                          }}
                          color="secondary"
                        />
                      </TableCell>
                      <TableCell>
                        {row.ImageUrl && (
                          <img
                            src={row.ImageUrl}
                            alt="Item"
                            className={classes.uploadedItemIconDisplay}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.Name}</TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default UploadedCombosList
