/* eslint-disable no-restricted-imports */

import {Typography} from "@material-ui/core"
import axios from "axios"
import React, {useEffect, useState} from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import CreatePriceRangeButton from "../../../modules/Common/CreateNewButton.js"
import CreatePriceRangeFormDialog from "../../../modules/PriceRanges/CreatePriceRangeFormDialog.js"
import DeletePriceRangeDialog from "../../../modules/PriceRanges/DeletePriceRangeDialog.js"
import PriceRangeList from "../../../modules/PriceRanges/PriceRangeList.js"
import {
    createPriceRange,
    deletePriceRange,
    getPriceRanges
} from "../../../modules/PriceRanges/_axios/priceRangeCrud"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../../redux/snackbar/snackbarHandlers.js"
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core/styles";

function PriceRangesPanel({intl, selectedLocation, ...props}) {
    const dispatch = useDispatch()
    const [deletePriceRangeState, setDeletePriceRangeState] = useState({show: false})
    const [showCreatePriceRangePanel, setShowCreatePriceRangePanel] = useState(false)
    const [priceRangesData, setPriceRangesData] = useState({
        priceRanges: [],
        isLoading: true
    })
    const useStyles = makeStyles(theme => ({
        fab: {
            bottom: theme.spacing(1),
            opacity: 0.5,
            zIndex: 10,
            "&:hover": {
                opacity: 1
            },
            float: "left"
        }
    }))

    function handleCloseDeletePriceRangePanel(priceRangeID) {
        if (priceRangeID) {
            setPriceRangesData({
                ...priceRangesData,
                priceRanges: [...priceRangesData.priceRanges.filter(sd => sd.PriceRangeID !== priceRangeID)]
            })
        }
        setDeletePriceRangeState({show: false})
    }

    function handleOpenCreatePriceRangePanel() {
        setShowCreatePriceRangePanel(true)
    }

    function handleCloseCreatePriceRangePanel(priceRangeData) {
        const cancelToken = axios.CancelToken.source()
        fetchPriceRanges(cancelToken)
        setShowCreatePriceRangePanel(false)
    }

    function handleDeletePriceRange(priceRange) {
        setDeletePriceRangeState({show: true, priceRange})
    }

    function createPriceRanges(submittedPriceRange) {
        return createPriceRange(submittedPriceRange.tokenData)
    }

    function fetchPriceRanges(cancelToken) {
        setPriceRangesData({...priceRangesData, isLoading: true})
        getPriceRanges(selectedLocation.LocationID, cancelToken.token)
            .then(({data}) => {
                setPriceRangesData({
                    priceRanges: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_TAGS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchPriceRanges(selectedLocation.LocationID, cancelToken)
        return () => cancelToken.cancel()
    }, [selectedLocation.LocationID])


    const classes = useStyles()
    return (
        <>

            <CreatePriceRangeFormDialog
                show={showCreatePriceRangePanel}
                closeDialog={handleCloseCreatePriceRangePanel}
                submitData={createPriceRanges}
                priceRangesData={priceRangesData}
                locationID={selectedLocation.LocationID}
            />
            <DeletePriceRangeDialog
                show={deletePriceRangeState.show}
                onClose={handleCloseDeletePriceRangePanel}
                sendRequest={deletePriceRange}
                priceRangeData={deletePriceRangeState.priceRange}
            />

            <div>
                <PriceRangeList
                    priceRangesData={priceRangesData}
                    onDelete={handleDeletePriceRange}
                />
                <Fab
                    color="secondary"
                    aria-label="Create new"
                    className={classes.fab}
                    onClick={handleOpenCreatePriceRangePanel}
                >
                    <AddIcon/>
                </Fab>
            </div>
        </>
    )
}

export default injectIntl(PriceRangesPanel)
