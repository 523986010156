/* eslint-disable no-restricted-imports */
import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import ErrorIcon from "@material-ui/icons/Error"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

const useStylesContent = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginLeft: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}))

function SnackbarContentWrapper(props) {
  const classes = useStylesContent()
  const { className, variant, message, onClose, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "error"]).isRequired
}

export default function CustomSnackbar({ data, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={data.show}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContentWrapper
        onClose={onClose}
        variant={data.variant}
        message={data.message}
      />
    </Snackbar>
  )
}
