import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { put, takeLatest } from "redux-saga/effects"
import { getUserByToken } from "./authCrud"

export const actionTypes = {
  SendOtp: "[SendOtp] Action",
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action"
}

const initialAuthState = {
  user: undefined,
  authToken: undefined
}

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken", "otp"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SendOtp: {
        const { otp } = action.payload

        return { otp, user: undefined }
      }

      case actionTypes.Login: {
        const { token, user } = action.payload
        return { authToken: token, user }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const { user } = action.payload
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  sendOtp: otp => ({ type: actionTypes.SendOtp, payload: { otp } }),
  login: (token, user) => ({
    type: actionTypes.Login,
    payload: { token, user }
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } })
}

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser())
  // })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken()

    yield put(actions.fulfillUser(user))
  })
}
