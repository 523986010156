/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles as listStyles } from "../../Common/_styles/elementListStyles"
import { useStyles as formStyles } from "../../Common/_styles/formDialogStyles"
import { InputAdornment, Paper, TextField } from "@material-ui/core"

const headRows = [
  {
    id: "customerName",
    label: <FormattedMessage id="MEAL_ITEM_PRICE.HEAD_LABEL.CUSTOMER_NAME" />
  },
  {
    id: "locationName",
    label: <FormattedMessage id="MEAL_ITEM_PRICE.HEAD_LABEL.LOCATION_NAME" />
  },
  {
    id: "mealType",
    label: <FormattedMessage id="MEAL_ITEM_PRICE.HEAD_LABEL.MEAL_TYPE" />
  },
  {
    id: "customerPrice",
    label: <FormattedMessage id="MEAL_ITEM_PRICE.HEAD_LABEL.CUSTOMER_PRICE" />
  },
  {
    id: "clientPrice",
    label: <FormattedMessage id="MEAL_ITEM_PRICE.HEAD_LABEL.EMPLOYEE_PRICE" />
  }
]

function MealItemPricesList({ pricesData, onChangeMealPrice, intl }) {
  const tableClasses = listStyles()
  const formClasses = formStyles()
  const rows = pricesData.prices
  const [sortBy, setSortBy] = useState("from")
  const [orderBy, setOrderBy] = useState("desc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function renderPriceInput(row, name) {
    return (
      <TextField
        value={row[name]}
        name={name}
        onChange={(event) => onChangeMealPrice(event, row.id)}
        className={formClasses.textField}
        inputProps={{
          className: formClasses.textRight
        }}
        hiddenLabel
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">₪</InputAdornment>
          )
        }}
        margin="normal"
        type="number"
        dir="ltr"
      />
    )
  }

  return (
    <div className={tableClasses.root}>
      <Paper>
        <div className={tableClasses.tableWrapper}>
          <Table
            className={tableClasses.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row" align="left">
                      {row.customerName}
                    </TableCell>
                    <TableCell align="left">{row.locationName}</TableCell>
                    <TableCell align="left">{row.mealType}</TableCell>
                    <TableCell align="left">
                      {renderPriceInput(row, "customerPrice")}
                    </TableCell>
                    <TableCell align="left">
                      {renderPriceInput(row, "clientPrice")}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(MealItemPricesList)
