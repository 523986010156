/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { getStatusText } from "../statusTranslation"
import { useStyles } from "../_styles/elementListStyles"

const headRows = [
  {
    id: "Icon",
    disablePadding: false,
    notSortable: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "Status",
    disablePadding: false,
    notSortable: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.STATUS" />
  }
]

function OptionGroupList({ optionGroups, selected, setSelected, intl }) {
  const classes = useStyles()
  const rows = optionGroups
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  const isSelected = id => selected === id

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const lastVersion = row.Versions.reduce(
                    (max, version) =>
                      version.Version > max ? version.Version : max,
                    1
                  )
                  return (
                    <TableRow
                      hover
                      onClick={() => setSelected(row.id)}
                      tabIndex={-1}
                      key={`${row.Name}${row.id}`}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        width={24}
                        align="center"
                        className={classes.rowHeight}
                      >
                        {row.Icon !== null && (
                          <img
                            src={toAbsoluteUrl(
                              `/media/svg/option-icons/${row.Icon}.svg`
                            )}
                            alt="Icon"
                            className={classes.iconDisplay}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        padding="none"
                        className={classes.groupNameCell}
                      >
                        {row.Name}
                      </TableCell>
                      <TableCell align="left">
                        {getStatusText(
                          intl,
                          row.Versions.find(
                            version => version.Version === lastVersion
                          ).Status
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(OptionGroupList)
