import usersMock from "../../Auth/__mocks__/userTableMock.js"

let customerIDMock = 1
let departmentIDMock = 1
let locationIDMock = 1
let itemIDMock = 1

function createCustomerData(name) {
  return {
    CustomerID: `3fa85f64-5717-4562-b3fc-2c963f66af${customerIDMock++}`,
    Name: name,
    CompanyID: "string",
    Address: "string",
    City: "string",
    Locations: [
      {
        LocationID: `3fa85f64-5717-4562-b3fc-2c963f66afa${locationIDMock++}`,
        CustomerID: `3fa85f64-5717-4562-b3fc-2c963f66afa${customerIDMock}`,
        Name: "location1",
        Address: "string",
        OrderType: 1,
        GeoPoint: {
          GeoPointID: "6b0243ca-a92b-45dc-8fcf-cd6a80605d36",
          lat: 41.12,
          lon: -71.34
        },
        NumberOfDaysToOrder: 0,
        MaxOrderDate: "",
        SupplierDisplayName: "string",
        MealsInfo: [
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 1,
            Days: [1],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: [
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                Item: {
                  Name: "Item1",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              },
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
                Item: {
                  Name: "Item2",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              }
            ]
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 2,
            Days: [2],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: [
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                Item: {
                  Name: "Item1",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              },
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
                Item: {
                  Name: "Item2",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              }
            ]
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 3,
            Days: [3],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: [
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                Item: {
                  Name: "Item1",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              },
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
                Item: {
                  Name: "Item2",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              }
            ]
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 4,
            Days: [4],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: [
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                Item: {
                  Name: "Item1",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              },
              {
                id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
                Item: {
                  Name: "Item2",
                  Image: {
                    URL:
                      "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg"
                  }
                },
                Days: [],
                From: "",
                To: ""
              }
            ]
          }
        ],

        Suppliers: []
      },
      {
        LocationID: `3fa85f64-5717-4562-b3fc-2c963f66afa${locationIDMock++}`,
        CustomerID: `3fa85f64-5717-4562-b3fc-2c963f66afa${customerIDMock}`,
        Name: "location2",
        Address: "string",
        OrderType: 1,
        GeoPoint: {
          GeoPointID: "6b0243ca-a92b-45dc-8fcf-cd6a80605d36",
          lat: 41.12,
          lon: -71.34
        },
        NumberOfDaysToOrder: 0,
        MaxOrderDate: "",
        SupplierDisplayName: "string",
        MealsInfo: [
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 1,
            Days: [0],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: []
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 2,
            Days: [0],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: []
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 3,
            Days: [0],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: []
          },
          {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            MealType: 4,
            Days: [0],
            DeliveryMinTime: "",
            DeliveryMaxTime: "",
            OrderCloseInterval: 0,
            OrderCloseManagerInterval: 0,
            MenuTags: [],
            MenuFavouriteTags: [],
            MealItems: []
          }
        ],
        Suppliers: []
      }
    ],
    Departments: [
      {
        DepartmentID: `db983b19-502b-4977-9a10-62ccf255fb${departmentIDMock++}`,
        CustomerID: `3fa85f64-5717-4562-b3fc-2c963f66af${customerIDMock}`,
        Name: "HR",
        Comment: "",
        BreakfastSubsidy: 0,
        Breakfast: true,
        LunchSubsidy: 0,
        Lunch: true,
        DinnerSubsidy: 0,
        Dinner: true,
        MealPerDay: 0
      },
      {
        DepartmentID: `db983b19-502b-4977-9a10-62ccf255fb${departmentIDMock++}`,
        CustomerID: `3fa85f64-5717-4562-b3fc-2c963f66af${customerIDMock}`,
        Name: "HR2",
        Comment: "",
        BreakfastSubsidy: 0,
        Breakfast: true,
        LunchSubsidy: 0,
        Lunch: true,
        DinnerSubsidy: 0,
        Dinner: true,
        MealPerDay: 0
      }
    ],
    Logo: [
      {
        URL:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/PNG_transparency_demonstration_2.png",
        Type: "PNG",
        Size: 0
      }
    ],
    ContactName: "string",
    ContactPhone: "222-2222222",
    ContactEmail: "test@t.com"
  }
}

export const customers = [
  createCustomerData("Customer1"),
  createCustomerData("Customer2"),
  createCustomerData("Customer3"),
  createCustomerData("Customer4"),
  createCustomerData("Customer5"),
  createCustomerData("Customer6"),
  createCustomerData("Customer7"),
  createCustomerData("Customer8"),
  createCustomerData("Customer9"),
  createCustomerData("Customer10"),
  createCustomerData("Customer11"),
  createCustomerData("Customer12"),
  createCustomerData("Customer13")
]

export default function mockCustomers(mock) {
  mock.onGet("/Customers").reply(({ headers: { Authorization }, params }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        let result = customers
        if (params && params.text) {
          let search = params.text.toLowerCase()
          result = result.filter(user =>
            user.Name.toLowerCase().includes(search)
          )
        }
        return [200, result]
      }
    }
    return [400]
  })

  mock.onPost("/Customer").reply(({ headers: { Authorization }, data }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    const { Name } = JSON.parse(data)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        customers.push(createCustomerData(Name))
        let result = customers[customers.length - 1]
        return [200, { CustomerID: result.CustomerID, Name: result.Name }]
      }
    }
    return [400]
  })

  mock
    .onPatch(RegExp(`/Customers/(.*)$`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const CustomerID = url.slice(
        url.lastIndexOf("/Customers/") + 11,
        url.length
      )

      const {
        Name,
        CompanyID,
        Address,
        City,
        ContactName,
        ContactPhone,
        ContactEmail
      } = JSON.parse(data)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedCustomer = customers.find(
            customer => customer.CustomerID === CustomerID
          )

          if (Name) {
            changedCustomer.Name = Name
          }
          if (CompanyID) {
            changedCustomer.CompanyID = CompanyID
          }
          if (Address) {
            changedCustomer.Address = Address
          }
          if (City) {
            changedCustomer.City = City
          }
          if (ContactName) {
            changedCustomer.ContactName = ContactName
          }
          if (ContactPhone) {
            changedCustomer.ContactPhone = ContactPhone
          }
          if (ContactEmail) {
            changedCustomer.ContactEmail = ContactEmail
          }
          return [200]
        }
      }
      return [400]
    })

  mock
    .onPost(RegExp(`/Customer/Logo/(.*)`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const logo = data.get("Logo")

      const CustomerID = url.slice(url.lastIndexOf("/Logo/") + 6, url.length)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          return new Promise(function(resolve, reject) {
            const reader = new FileReader()
            reader.onloadend = e => {
              customers.find(
                customer => customer.CustomerID === CustomerID
              ).Logo[0].URL = e.target.result
              resolve([200])
            }
            reader.onerror = e => {
              console.error(e)
              resolve([500])
            }
            reader.readAsDataURL(logo)
          })
        }
      }
      return [400]
    })

  mock
    .onPost(RegExp(`/Customer/(.*)/Department$`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const CustomerID = url.slice(
        url.lastIndexOf("/Customer/") + 10,
        url.lastIndexOf("/Department")
      )

      const { Name } = JSON.parse(data)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedCustomer = customers.find(
            customer => customer.CustomerID === CustomerID
          )

          changedCustomer.Departments.push({
            DepartmentID: `db983b19-502b-4977-9a10-62ccf255fb${departmentIDMock++}`,
            CustomerID,
            Name,
            Comment: "",
            BreakfastSubsidy: 0,
            Breakfast: true,
            LunchSubsidy: 0,
            Lunch: true,
            DinnerSubsidy: 0,
            Dinner: true,
            MealPerDay: 0
          })
          return [
            200,
            {
              DepartmentID:
                changedCustomer.Departments[
                  changedCustomer.Departments.length - 1
                ].DepartmentID
            }
          ]
        }
      }
      return [400]
    })

  mock
    .onPatch(RegExp(`/Department/(.*)$`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const DepartmentID = url.slice(
        url.lastIndexOf("/Department/") + 12,
        url.length
      )

      const {
        Name,
        Comment,
        MealPerDay,
        Breakfast,
        BreakfastSubsidy,
        Lunch,
        LunchSubsidy,
        Dinner,
        DinnerSubsidy
      } = JSON.parse(data)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedDepartment = customers
            .find(
              customer =>
                customer.Departments.find(
                  department => department.DepartmentID === DepartmentID
                ) !== undefined
            )
            .Departments.find(
              department => department.DepartmentID === DepartmentID
            )

          if (Name) {
            changedDepartment.Name = Name
          }
          if (Comment) {
            changedDepartment.Comment = Comment
          }
          if (MealPerDay) {
            changedDepartment.MealPerDay = MealPerDay
          }
          if (Breakfast !== undefined) {
            changedDepartment.Breakfast = Breakfast
          }
          if (BreakfastSubsidy) {
            changedDepartment.BreakfastSubsidy = BreakfastSubsidy
          }
          if (Lunch !== undefined) {
            changedDepartment.Lunch = Lunch
          }
          if (LunchSubsidy) {
            changedDepartment.LunchSubsidy = LunchSubsidy
          }
          if (Dinner !== undefined) {
            changedDepartment.Dinner = Dinner
          }
          if (DinnerSubsidy) {
            changedDepartment.DinnerSubsidy = DinnerSubsidy
          }
          return [200]
        }
      }
      return [400]
    })

  mock
    .onDelete(RegExp(`/Department/(.*)$`))
    .reply(({ headers: { Authorization }, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const DepartmentID = url.slice(
        url.lastIndexOf("/Department/") + 12,
        url.length
      )

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedCustomer = customers.find(
            customer =>
              customer.Departments.find(
                department => department.DepartmentID === DepartmentID
              ) !== undefined
          )
          changedCustomer.Departments = changedCustomer.Departments.filter(
            department => department.DepartmentID !== DepartmentID
          )
          return [200]
        }
      }
      return [400]
    })

  mock
    .onPost(RegExp(`/Location$`))
    .reply(({ headers: { Authorization }, data }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const { CustomerID, Name } = JSON.parse(data)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedCustomer = customers.find(
            customer => customer.CustomerID === CustomerID
          )

          changedCustomer.Locations.push({
            LocationID: `db983b19-502b-4977-9a10-62ccf255fb${locationIDMock++}`,
            CustomerID,
            Name,
            Address: "",
            OrderType: 1,
            NumberOfDaysToOrder: 0,
            MaxOrderDate: "",
            SupplierDisplayName: "",
            MealsInfo: [
              {
                MealType: 1,
                Days: [],
                DeliveryMinTime: "",
                DeliveryMaxTime: "",
                OrderCloseInterval: 0,
                OrderCloseManagerInterval: 0,
                MenuTags: [],
                MenuFavouriteTags: [],
                MealItems: []
              },
              {
                MealType: 2,
                Days: [],
                DeliveryMinTime: "",
                DeliveryMaxTime: "",
                OrderCloseInterval: 0,
                OrderCloseManagerInterval: 0,
                MenuTags: [],
                MenuFavouriteTags: [],
                MealItems: []
              },
              {
                MealType: 3,
                Days: [],
                DeliveryMinTime: "",
                DeliveryMaxTime: "",
                OrderCloseInterval: 0,
                OrderCloseManagerInterval: 0,
                MenuTags: [],
                MenuFavouriteTags: [],
                MealItems: []
              },
              {
                MealType: 4,
                Days: [],
                DeliveryMinTime: "",
                DeliveryMaxTime: "",
                OrderCloseInterval: 0,
                OrderCloseManagerInterval: 0,
                MenuTags: [],
                MenuFavouriteTags: [],
                MealItems: []
              }
            ],

            Suppliers: []
          })
          return [
            200,
            {
              LocationID:
                changedCustomer.Locations[changedCustomer.Locations.length - 1]
                  .LocationID
            }
          ]
        }
      }
      return [400]
    })

  mock
    .onPatch(RegExp(`/Location/(.*)$`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const LocationID = url.slice(
        url.lastIndexOf("/Location/") + 10,
        url.length
      )

      const {
        CustomerID,
        Name,
        SupplierDisplayName,
        Address,
        GeoPoint,
        OrderType,
        MaxOrderDate,
        NumberOfDaysToOrder,
        Suppliers,
        MealsInfo
      } = JSON.parse(data)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          const changedLocation = customers
            .find(
              customer =>
                customer.Locations.find(
                  location => location.LocationID === LocationID
                ) !== undefined
            )
            .Locations.find(location => location.LocationID === LocationID)

          if (Name) {
            changedLocation.Name = Name
          }
          if (SupplierDisplayName) {
            changedLocation.SupplierDisplayName = SupplierDisplayName
          }
          if (Address) {
            changedLocation.Address = Address
            changedLocation.GeoPoint = GeoPoint
          }
          if (OrderType) {
            changedLocation.OrderType = OrderType
          }
          if (MaxOrderDate) {
            changedLocation.MaxOrderDate = MaxOrderDate
          }
          if (NumberOfDaysToOrder) {
            changedLocation.NumberOfDaysToOrder = NumberOfDaysToOrder
          }
          if (Suppliers) {
            changedLocation.Suppliers = Suppliers
          }
          if (MealsInfo) {
            const mealsInfo = []
            MealsInfo.forEach(mealInfo =>
              mealsInfo.push({
                ...changedLocation.MealsInfo.find(
                  mealInfo => mealsInfo.MealType === mealInfo.MealType
                ),
                ...mealInfo
              })
            )
            changedLocation.MealsInfo = mealsInfo
          }
          return [200]
        }
      }
      return [400]
    })

  mock
    .onDelete(RegExp(`/Location/(.*)$`))
    .reply(({ headers: { Authorization }, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const LocationID = url.slice(
        url.lastIndexOf("/Location/") + 10,
        url.length
      )

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          let changedCustomer = customers.find(
            customer =>
              customer.Locations.find(
                location => location.LocationID === LocationID
              ) !== undefined
          )
          changedCustomer.Locations = changedCustomer.Locations.filter(
            location => location.LocationID !== LocationID
          )
          return [200]
        }
      }
      return [400]
    })
}
