/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { makeStyles } from "@material-ui/core/styles"
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete"
import { MenuItem, Paper } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    form: {
        minWidth: 300
    },
    button: {
        margin: theme.spacing(1)
    },
    textField: {
        margin: theme.spacing(1, 0)
    },
    error: { color: "#F018A6" }
}))

function CreateLocationDialog({
    show,
    title,
    closeDialog,
    handleSubmit,
    checkIfNameUnique,
    intl
}) {
    const classes = useStyles()
    const initialValues = {
        name: ""
    }

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            checkIfNameUnique
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
    )

    const stringValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const validationSchema = Yup.object().shape(
        {
            name: nameValidationSchema,
            address: stringValidationSchema
        },
        ["name", "address"]
    )


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })

    function checkForError(fieldName) {
        return Boolean(formik.errors[fieldName]);
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span className={classes.error}>{formik.errors[fieldName]}</span>
        ) : null
    }

    function handleSelectPlace(value, placeId, suggestion) {
        formik.setFieldValue("address", value)
        formik.setFieldTouched("address", true, false);
        geocodeByAddress(value)
            .then(results => {
                const country =
                    results[0].address_components.find(component => component.types.indexOf('country') !== -1)?.long_name;
                const city =
                    results[0].address_components.find(component => component.types.indexOf('locality') !== -1)?.long_name;
                const street =
                    results[0].address_components.find(component => component.types.indexOf('route') !== -1)?.long_name;
                const streetNumber =
                    results[0].address_components.find(component => component.types.indexOf('street_number') !== -1)?.long_name;

                formik.setFieldValue("country", country ?? null);
                formik.setFieldValue("city", city ?? null);
                formik.setFieldValue("street", street ?? null);
                formik.setFieldValue("houseNumber", streetNumber ?? null);

                return getLatLng(results[0])
            })
            .then(({ lat, lng }) => {
                formik.setFieldValue("geoPoint", { lat, lng })
            })
    }

    return (
        <Dialog
            open={show}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" disableTypography={true}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>{title}</h3>
            </DialogTitle>
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className={classes.form}
            >
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        label={intl.formatMessage({
                            id: "CREATE_FORM.NAME_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("name")}
                        error={checkForError("name")}
                    />
                    <div>{renderErrors("name")}</div>
                </DialogContent>

                <DialogContent>
                    <PlacesAutocomplete
                        value={formik.values.address}
                        onChange={value => formik.setFieldValue("address", value)}
                        onSelect={handleSelectPlace}
                        searchOptions={{ country: "il", language: "he", types: ['address'], fields: ["address_components", "street_number"], }}
                    >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                        }) => (
                            <div className="position-relative">
                                <TextField
                                    id="address"
                                    name="address"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    label={intl.formatMessage({
                                        id: "CREATE_FORM.ADDRESS_FIELD.LABEL"
                                    })}
                                    fullWidth
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    InputProps={{
                                        ...getInputProps({
                                            language: 'he'
                                        })
                                    }}
                                    error={checkForError("address")}
                                />
                                <Paper className={classes.paper} square>
                                    {loading && (
                                        <div className="spinner spinner-blue mt-3"></div>
                                    )}
                                    {suggestions.map(suggestion => {
                                        return (
                                            <MenuItem
                                                component="div"
                                                {...getSuggestionItemProps(suggestion)}
                                                key={suggestion.placeId}
                                            >
                                                {suggestion.description}
                                            </MenuItem>
                                        )
                                    })}
                                </Paper>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    {renderErrors("address")}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        type="button"
                        size="large"
                        onClick={closeDialog}
                        className={classes.button}
                    >
                        <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        color="secondary"
                        className={classes.button}
                        disabled={formik.isSubmitting}
                    >
                        <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
                        {formik.isSubmitting && (
                            <span className="ml-1 spinner spinner-white"></span>
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default injectIntl(CreateLocationDialog)
