/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import DishLoader from "../Common/DishLoader"
import clsx from "clsx"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Question",
    label: <FormattedMessage id="LABELS.QUESTION" />
  },
  {
    id: "Answer",
    notSortable: true,
    label: <FormattedMessage id="LABELS.ANSWER" />
  },
  {
    id: "Order",
    label: <FormattedMessage id="LABELS.ORDER" />
  },
  {
    id: "Actions",
    notSortable: true,
    label: ""
  }
]

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  textField: {
    margin: theme.spacing(1),
    width: 250
  }
}))

function EnhancedTableToolbar(props) {
  const { searchText, onSearchTextChange } = props
  const classes = useToolbarStyles()

  return (
    <Toolbar className={classes.root}>
      <TextField
        id="standard-search"
        label={<FormattedMessage id="SEARCH_FIELD.LABEL" />}
        className={classes.textField}
        value={searchText}
        onChange={onSearchTextChange}
      />
      <div className={classes.spacer} />
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  onSearchTextChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired
}

export default function FaqList({ questionsData, onEdit, onDelete }) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")
  const [searchText, setSearchText] = useState("")

  function handleSearchTextChange(event) {
    const value = event.target.value
    setSearchText(value.toLowerCase())
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function handleEdit(question) {
    onEdit(question)
  }

  function handleDelete(question) {
    onDelete(question)
  }

  useEffect(() => {
    let result
    if (searchText.length !== 0) {
      result = questionsData.questions.filter(row =>
        row.Question.toLowerCase().includes(searchText)
      )
    } else {
      result = questionsData.questions
    }
    setRows(result)
  }, [searchText, questionsData])

  if (questionsData.questions.length === 0 && questionsData.isLoading) {
    return <DishLoader />
  }

  return (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
        <EnhancedTableToolbar
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
                      <TableCell component="th" scope="row" align="left">
                        {row.Question}
                      </TableCell>
                      <TableCell align="left">{row.Answer}</TableCell>
                      <TableCell align="left">{row.Order}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit question">
                          <IconButton
                            aria-label="Edit question"
                            onClick={() => handleEdit(row)}
                          >
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete question">
                          <IconButton
                            aria-label="Delete question"
                            onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}
