import axios from "axios"

export function createPriceCategory(data) {
    return axios.post(`/PriceCategories`, data)
}
export function deletePriceCategory(id) {
    return axios.delete(`/PriceCategories/${id}`)
}
export function getPriceCategories() {
    return axios.get(`/PriceCategories`)
}

export function editPriceCategory(id, data) {
    return axios.patch(`/PriceCategories/${id}`, data)
}

