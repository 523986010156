/* eslint-disable no-restricted-imports */

import React, {useEffect, useState} from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { he, enUS } from "date-fns/esm/locale"
import RefreshIcon from "@material-ui/icons/Refresh"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../../Common/downshiftFunctions"
import Downshift from "downshift"
import { Paper } from "@material-ui/core"
import { useStyles } from "../../Common/_styles/orderFilterStyles"
import moment from "moment/moment";

function FilterPanel({
  userRole,
  onParamsChangeSave,
  searchParams,
  locationsData,
  suppliersData,
         deliveryTimesData,
  itemsTotal,
  intl
}) {
  const locale = intl.locale === "en" ? enUS : he;
  const classes = useStyles()
  const [filteredDeliveryTimes, setFilteredDeliveryTimes] = useState();
  useEffect(() => {
    const data = deliveryTimesData;
    const out = Object.values(
        data.reduce( (c, e) => {
          if (!c[moment('2020-10-10 ' + e.From).format("HH:mm").toString()]) c[moment('2020-10-10 ' + e.From).format("HH:mm").toString()] = e;
          return c;
        }, {})
    );
    setFilteredDeliveryTimes(out);
  }, [deliveryTimesData])

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    onParamsChangeSave({
      [name]: value
    })
  }

  useEffect(() => {
    handleSearchParamChange("deliveryTime", {
      target: {
        value: ''
      }
    })
  }, [searchParams.location])

  function handleRefresh(event) {
    event.preventDefault()
    onParamsChangeSave(searchParams)
  }

  return (
    <>
      <form
        className={classes.root}
        onSubmit={handleRefresh}
        autoComplete="off"
      >
              <MuiPickersUtilsProvider utils={DateFnsUtils}
                  locale={locale}>
          <DatePicker
            autoOk
            format="dd/MM/yyyy"
            value={searchParams.date}
            onChange={handleSearchParamChange("date")}
            label={intl.formatMessage({
              id: "DATE_FIELD.LABEL"
            })}
          />
        </MuiPickersUtilsProvider>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="mealType">
            <FormattedMessage id="ORDER_ITEMS.TYPE_FIELD.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.mealType}
            onChange={handleSearchParamChange("mealType")}
            inputProps={{
              name: "mealType",
              id: "mealType"
            }}
          >
            <MenuItem value={1}>
              <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
            </MenuItem>
            <MenuItem value={2}>
              <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
            </MenuItem>
            <MenuItem value={3}>
              <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
            </MenuItem>
          </Select>
        </FormControl>

        {
          locationsData.length > 1 && (
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="location">
                    <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL" />
                  </InputLabel>
                  <Select
                      value={searchParams.location}
                      onChange={event => {
                        handleSearchParamChange("location")(event)
                      }}
                      inputProps={{
                        name: "location",
                        id: "location"
                      }}
                  >
                    <MenuItem value="">
                      <FormattedMessage id="FILTER.COMMON.ALL" />
                    </MenuItem>
                    {locationsData &&
                        locationsData.map(location => (
                            <MenuItem key={location.Name} value={location.LocationID}>
                              {location.Name}
                            </MenuItem>
                        ))}
                  </Select>
                </FormControl>
            )
        }


        {
          deliveryTimesData?.length > 1 && (
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="deliveryTimeValue">
                    <FormattedMessage id="FILTER.DELIVERY_TIME_FIELD.LABEL" />
                  </InputLabel>
                  <Select
                      value={searchParams.deliveryTimeValue}
                      onChange={event => {
                        if (event.target.value) {
                          onParamsChangeSave({
                            deliveryTime: deliveryTimesData.filter(item =>
                                moment('2020-10-10 ' + item.From).format("HH:mm") === event.target.value).map(i => i.Id).join(','),
                            deliveryTimeValue: event.target.value
                          })
                        } else {
                          onParamsChangeSave({
                            deliveryTime: '',
                            deliveryTimeValue: ''
                          })
                        }
                      }}
                      inputProps={{
                        name: "deliveryTimeValue",
                        id: "deliveryTimeValue"
                      }}
                      disabled={!searchParams.location}
                  >
                    <MenuItem value="">
                      <FormattedMessage id="FILTER.COMMON.ALL" />
                    </MenuItem>
                    {filteredDeliveryTimes &&
                        filteredDeliveryTimes.map(deliveryTime => (
                            <MenuItem key={deliveryTime.Name} value={moment('2020-10-10 ' + deliveryTime.From).format("HH:mm").toString()}>
                              {moment('2020-10-10 ' + deliveryTime.From).format("HH:mm")}
                            </MenuItem>
                        ))}
                  </Select>
                </FormControl>
            )
        }

        {
          ["Admin", "Owner"].includes(userRole) && <Downshift
              id="downshift-for-supplier"
              selectedItem={
                searchParams.supplier.Name ? searchParams.supplier : null
              }
              onChange={selected =>
                  handleSearchParamChange("supplier")(selected || {})
              }
              itemToString={item => (item ? item.Name : "")}
          >
            {({
                clearSelection,
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                selectedItem
              }) => {
              const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
                onChange: event => {
                  if (event.target.value === "") {
                    clearSelection()
                  }
                },
                onFocus: openMenu
              })

              return (
                  <div className={classes.container}>
                    {renderInput({
                      fullWidth: true,
                      classes,
                      label: intl.formatMessage({
                        id: "FILTER.SUPPLIER_FIELD.LABEL"
                      }),
                      nested: true,
                      InputLabelProps: getLabelProps({
                        shrink: true
                      }),
                      InputProps: { onBlur, onChange, onFocus },
                      inputProps
                    })}

                    <div {...getMenuProps()}>
                      {isOpen ? (
                          <Paper className={classes.paper} square>
                            {getSuggestions(inputValue, suppliersData.data, {
                              showEmpty: true
                            }).map((suggestion, index) =>
                                renderSuggestion({
                                  suggestion,
                                  index,
                                  itemProps: getItemProps({
                                    item: suggestion
                                  }),
                                  highlightedIndex,
                                  selectedItem
                                })
                            )}
                          </Paper>
                      ) : null}
                    </div>
                  </div>
              )
            }}
          </Downshift>
        }


        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={!searchParams.mealType || !searchParams.date}
          className={`font-weight-bold my-3 ml-10`}
        >
          <RefreshIcon />
        </Button>
        <div className={`font-weight-bold ml-10 align-self-center`}>
          {itemsTotal !== 0 && (
            <>
              <FormattedMessage id="FILTER.ITEMS_TOTAL" /> {itemsTotal}
            </>
          )}
        </div>
      </form>
    </>
  )
}

export default injectIntl(FilterPanel)
