/* eslint-disable no-restricted-imports */
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import axios from "axios"
import * as FileSaver from "file-saver"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import * as xlsx from "xlsx"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { getCustomersList } from "../modules/Customers/_axios/customerCrud"
import CustomerLocationDateFilterPanel from "../modules/Reports/CustomerBillingReports/CustomerLocationDateFilterPanel"
import OrderItemsCreditList from "../modules/Reports/CustomerBillingReports/OrderItemsCreditList"
import OrderItemsCreditSummary from "../modules/Reports/CustomerBillingReports/OrderItemsCreditSummary"
import OrderItemsList from "../modules/Reports/CustomerBillingReports/OrderItemsList"
import OrderItemsSummary from "../modules/Reports/CustomerBillingReports/OrderItemsSummary"
import OrderItemsUsersList from "../modules/Reports/CustomerBillingReports/OrderItemsUsersList"
import OrderItemsUsersSummary from "../modules/Reports/CustomerBillingReports/OrderItemsUsersSummary"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getCustomerBillingCreditReports, getCustomerBillingReports, getCustomerBillingUsersReports } from "../modules/Reports/_axios/reportsCrud"

function CustomerBillingReportsPage({ intl, ...props }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [reportsData, setReportsData] = useState({
        reports: [],
        isLoading: false,
        notRequested: true
    })
    const [reportUsersData, setReportUsersData] = useState({
        reports: [],
        isLoading: false,
        notRequested: true
    })
    const [reportCreditData, setReportCreditData] = useState({
        reports: [],
        isLoading: false,
        notRequested: true
    })
    const [exportFileName, setExportFileName] = useState("")
    const [exportUsersFileName, setExportUsersFileName] = useState("")
    const [exportCreditFileName, setExportCreditFileName] = useState("")
    const [searchParams, setSearchParams] = useState({})
    const [customerData, setCustomerData] = useState({
        data: [],
        isLoading: true
    })

    function handleSearchParamsSave(changes) {
        const newParams = { ...searchParams, ...changes }
        setSearchParams(newParams)
    }

    function fetchReports(cancelToken) {
        const { customer, location, from, to } = searchParams
        const params = {
            from: formatDateForApi(from),
            to: formatDateForApi(to),
            location,
            customer: customer.CustomerID
        }

        props.history.push({
            pathname: "/reports-billing-customer",
            search: "?" + new URLSearchParams(params).toString()
        })

        setReportsData({ notRequested: true, reports: [], isLoading: true })
        setReportUsersData({ notRequested: true, reports: [], isLoading: true })
        setReportCreditData({ notRequested: true, reports: [], isLoading: true })

        getCustomerBillingUsersReports(
            customer.CustomerID,
            location,
            params.from,
            params.to,
            cancelToken.token
        )
            .then(({ data }) => {
                setReportUsersData({
                    reports: data,
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error => handleApiError(dispatch, error))

        getCustomerBillingCreditReports(
            customer.CustomerID,
            location,
            params.from,
            params.to,
            cancelToken.token
        )
            .then(({ data }) => {
                setReportCreditData({
                    reports: data,
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error => handleApiError(dispatch, error))

        getCustomerBillingReports(
            customer.CustomerID,
            location,
            params.from,
            params.to,
            cancelToken.token
        )
            .then(({ data }) => {
                setReportsData({
                    reports: data,
                    isLoading: false,
                    notRequested: false
                })
            })
            .catch(error => handleApiError(dispatch, error))
    }

    function fetchCustomers(cancelToken) {
        getCustomersList(cancelToken.token, true, "active")
            .then(({ data }) => {
                setCustomerData({ data: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchCustomers(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (!customerData.isLoading) {
            const urlParams = new URLSearchParams(window.location.search)
            const from = urlParams.get("from")
                ? new Date(urlParams.get("from"))
                : new Date()
            const to = urlParams.get("to")
                ? new Date(urlParams.get("to"))
                : new Date()


            const location = urlParams.get("location") || ""
            const customer = urlParams.get("customer")
                ? customerData.data.find(
                    customer => customer.CustomerID === urlParams.get("customer")
                )
                : {}

            setSearchParams({
                from,
                to,
                customer,
                location
            })
        }
    }, [customerData])

    useEffect(() => {
        if (
            searchParams.from &&
            searchParams.to &&
            searchParams.customer &&
            searchParams.customer.CustomerID
        ) {
            const cancelToken = axios.CancelToken.source()
            fetchReports(cancelToken)

            setExportFileName(
                intl.formatMessage({
                    id: "REPORT.EXPORT_FILE_NAME"
                }) +
                "_" +
                "OrderItems" +
                "_" +
                searchParams.customer.Name +
                "_" +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
            setExportUsersFileName(
                intl.formatMessage({
                    id: "REPORT.EXPORT_FILE_NAME"
                }) +
                "_" +
                "Users" +
                "_" +
                searchParams.customer.Name +
                "_" +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
            setExportCreditFileName(
                intl.formatMessage({
                    id: "REPORT.EXPORT_FILE_NAME"
                }) +
                "_" +
                "Credit" +
                "_" +
                searchParams.customer.Name +
                "_" +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
            return () => cancelToken.cancel()
        }
    }, [searchParams])

    function getExportDataForReportUsers() {
        const formattedReport = []
        reportUsersData.reports.sort((a, b) => new Date(a.OrderToDate) - new Date(b.OrderToDate)).forEach(user => {
            const formattedUser = {}
            formattedUser[
                intl.formatMessage({
                    id: "TABLE.HEAD_LABELS.NAME"
                })
            ] = user.Name
            formattedUser[
                intl.formatMessage({
                    id: "EXPORT_FORM.EXTERNAL_ID_FIELD.LABEL"
                })
            ] = user.ExternalId
            formattedUser[
                intl.formatMessage({
                    id: "EXPORT_FORM.MOBILE_FIELD.LABEL"
                })
            ] = user.Mobile
            formattedUser[
                intl.formatMessage({
                    id: "REPORT.EMAIL"
                })
            ] = user.Email
            formattedUser[
                intl.formatMessage({
                    id: "REPORT.DEPARTMENT"
                })
            ] = user.Department
            formattedUser[
                intl.formatMessage({
                    id: "REPORT.BREAKFAST"
                })
            ] = user.MealType1
            formattedUser[
                intl.formatMessage({
                    id: "REPORT.LUNCH"
                })
            ] = user.MealType2
            formattedUser[
                intl.formatMessage({
                    id: "REPORT.DINNER"
                })
            ] = user.MealType3
            formattedReport.push(formattedUser)
        })

        const totalBreakfast = reportUsersData.reports.reduce((sum, report) => sum + report.MealType1, 0)
        const totalLunch = reportUsersData.reports.reduce((sum, report) => sum + report.MealType2, 0)
        const totalDinner = reportUsersData.reports.reduce((sum, report) => sum + report.MealType3, 0)

        const summary = {}
        summary[
            intl.formatMessage({
                id: "TABLE.HEAD_LABELS.NAME"
            })
        ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        summary[
            intl.formatMessage({
                id: "REPORT.BREAKFAST"
            })
        ] = totalBreakfast
        summary[
            intl.formatMessage({
                id: "REPORT.LUNCH"
            })
        ] = totalLunch
        summary[
            intl.formatMessage({
                id: "REPORT.DINNER"
            })
        ] = totalDinner
        formattedReport.push(summary)

        return formattedReport
    }

    function getExportDataForReportsData() {
        const formattedReport = [];
        reportsData.reports.sort((a, b) => new Date(a.OrderToDate) - new Date(b.OrderToDate)).forEach(report => {
            const formattedItem = {};
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.DATE"
                })
            ] = moment(report.OrderToDate).format("DD/MM/YYYY");
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.MEAL_TYPE"
                })
            ] = report.MealType === 1
                    ? intl.formatMessage({ id: "REPORT.BREAKFAST" })
                    : report.MealType === 2
                        ? intl.formatMessage({ id: "REPORT.LUNCH" })
                        : report.MealType === 3
                            ? intl.formatMessage({ id: "REPORT.DINNER" })
                            : "";
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.ITEMS"
                })
            ] = (report.ItemsCount - report.RefundCount).toString() + (report.RefundCount !== 0 ? ` (${report.RefundCount})` : '');
            formattedItem[
                intl.formatMessage({
                    id: "TABLE.HEAD_LABELS.SUBSIDY" 
                })
            ] = (report.SubsidyAmount - report.SubsidyRefundAmount).toFixed(2).toString() + (report.SubsidyRefundAmount !== 0 ? ` (${report.SubsidyRefundAmount.toFixed(2)})` : '');
            formattedItem[
                intl.formatMessage({
                    id: "TABLE.HEAD_LABELS.BUDGET"
                })
            ] = (report.BudgetAmount - report.BudgetRefundAmount).toFixed(2).toString() + (report.BudgetRefundAmount !== 0 ? ` (${report.BudgetRefundAmount.toFixed(2)})` : '');
            formattedItem[
                intl.formatMessage({
                    id: "TABLE.HEAD_LABELS.CUSTOMER_CREDIT"
                })
            ] = (report.CustomerCreditAmount - report.CustomerCreditRefundAmount).toFixed(2).toString() + (report.CustomerCreditRefundAmount !== 0 ? ` (${report.CustomerCreditRefundAmount.toFixed(2)})` : '');
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.TOTAL_COST"
                })
            ] = (
                report.BudgetAmount +
                report.CustomerCreditAmount +
                report.SubsidyAmount -
                report.BudgetRefundAmount -
                report.CustomerCreditRefundAmount -
                report.SubsidyRefundAmount
            ).toFixed(2).toString()
            + (report.BudgetRefundAmount + report.CustomerCreditRefundAmount + report.SubsidyRefundAmount !== 0 ? ` (${(report.BudgetRefundAmount + report.CustomerCreditRefundAmount + report.SubsidyRefundAmount).toFixed(2)})` : '');
            formattedReport.push(formattedItem);
        });

        const totalItemsCount = reportsData.reports.reduce((sum, report) => sum + report.ItemsCount, 0);
        const totalRefundCount = reportsData.reports.reduce((sum, report) => sum + report.RefundCount, 0);
        const totalTotalCount = totalItemsCount - totalRefundCount;
        const totalSubsidyAmount = reportsData.reports.reduce((sum, report) => sum + (report.SubsidyAmount - report.SubsidyRefundAmount), 0);
        const totalRefundSubsidyAmount = reportsData.reports.reduce((sum, report) => sum + report.SubsidyRefundAmount, 0);
        const totalBudgetAmount = reportsData.reports.reduce((sum, report) => sum + (report.BudgetAmount - report.BudgetRefundAmount), 0);
        const totalRefundBudgetAmount = reportsData.reports.reduce((sum, report) => sum + report.BudgetRefundAmount, 0);
        const totalCustomerCreditAmount = reportsData.reports.reduce((sum, report) => sum + (report.CustomerCreditAmount - report.CustomerCreditRefundAmount), 0);
        const totalRefundCustomerCreditAmount = reportsData.reports.reduce((sum, report) => sum + report.CustomerCreditRefundAmount, 0);
        const totalTotal = reportsData.reports.reduce((sum, report) => sum + (
            report.BudgetAmount +
            report.CustomerCreditAmount +
            report.SubsidyAmount -
            report.BudgetRefundAmount -
            report.CustomerCreditRefundAmount -
            report.SubsidyRefundAmount
        ), 0);
        const totalRefundTotal = reportsData.reports.reduce((sum, report) => sum + (
            report.BudgetRefundAmount +
            report.CustomerCreditRefundAmount +
            report.SubsidyRefundAmount
        ), 0);

        const summary = {};
        summary[
            intl.formatMessage({
                id: "REPORT.DATE"
            })
        ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        });
        summary[
            intl.formatMessage({
                id: "REPORT.ITEMS"
            })
        ] = totalTotalCount.toString() + (totalRefundCount !== 0 ? ` (${totalRefundCount}) ` : ``);
        summary[
            intl.formatMessage({
                id: "TABLE.HEAD_LABELS.SUBSIDY"
            })
        ] = totalSubsidyAmount.toFixed(2).toString() + (totalRefundSubsidyAmount !== 0 ? ` (${totalRefundSubsidyAmount.toFixed(2)}) ` : ``);
        summary[
            intl.formatMessage({
                id: "TABLE.HEAD_LABELS.BUDGET"
            })
        ] = totalBudgetAmount.toFixed(2).toString() + (totalRefundBudgetAmount !== 0 ? ` (${totalRefundBudgetAmount.toFixed(2)}) ` : ``);
        summary[
            intl.formatMessage({
                id: "TABLE.HEAD_LABELS.CUSTOMER_CREDIT"
            })
        ] = totalCustomerCreditAmount.toFixed(2).toString() + (totalRefundCustomerCreditAmount !== 0 ? ` (${totalRefundCustomerCreditAmount.toFixed(2)}) ` : ``);
        summary[
            intl.formatMessage({
                id: "REPORT.TOTAL_COST"
            })
        ] = totalTotal.toFixed(2).toString() + (totalRefundTotal !== 0 ? ` (${totalRefundTotal.toFixed(2) }) ` : ``);
        formattedReport.push(summary);

        return formattedReport;
    }

    function getExportDataForReportCreditData() {
        const formattedReport = []
        reportCreditData.reports.sort((a, b) => new Date(a.OrderToDate) - new Date(b.OrderToDate)).forEach(report => {
            const formattedItem = {}
            formattedItem[
                intl.formatMessage({
                    id: "TABLE.HEAD_LABELS.NAME"
                })
            ] = report.UserName
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.CREDIT"
                })
            ] = report.CostumerCreditAmount
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.SUPPLIER_NAME"
                })
            ] = report.SupplierName
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.ITEM_NAME"
                })
            ] = report.ItemName
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.QUANTITY"
                })
            ] = report.Quantity
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.REFOUND_QUANTITY"
                })
            ] = (report.Refound === true ? report.Quantity : 0)
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.TOTAL"
                })
            ] = report.Total
            formattedItem[
                intl.formatMessage({
                    id: "REPORT.DATE"
                })
            ] = new Date(report.OrderToDate).toISOString().substring(0, 10)
            formattedReport.push(formattedItem)
        })

        const totalQuantity = reportCreditData.reports.reduce((sum, report) => sum + report.Quantity, 0);
        const totalRefound = reportCreditData.reports.reduce((sum, report) => sum + (report.Refound ? report.Quantity : 0), 0);
        const totalTotal = reportCreditData.reports.reduce((sum, report) => sum + report.Total, 0);

        const summary = {}
        summary[
            intl.formatMessage({
                id: "TABLE.HEAD_LABELS.NAME"
            })
        ] = intl.formatMessage({
            id: "REPORT.TOTAL"
        })
        summary[
            intl.formatMessage({
                id: "REPORT.QUANTITY"
            })
        ] = totalQuantity
        summary[
            intl.formatMessage({
                id: "REPORT.REFOUND_QUANTITY"
            })
        ] = totalRefound
        summary[
            intl.formatMessage({
                id: "REPORT.TOTAL"
            })
        ] = totalTotal.toFixed(2)
        formattedReport.push(summary)

        return formattedReport
    }

    function getCombinedExportData() {
        const usersData = getExportDataForReportUsers();
        const reportsData = getExportDataForReportsData();
        const creditData = getExportDataForReportCreditData();

        return {
            reportsData,
            usersData,
            creditData
        };
    }

    function downloadCombinedExportData(exportData, fileName) {
        const fileTypeXLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtensionXLSX = ".xlsx";

        const ws = xlsx.utils.aoa_to_sheet([]);
        let rowIndex = 0;

        const customerName = searchParams.customer.Name;
        const date = moment(searchParams.date).format("MMM YY");
        xlsx.utils.sheet_add_aoa(ws, [[null, date, null, customerName]], { origin: `A${rowIndex + 1}` });
        rowIndex += 1;

        Object.keys(exportData).forEach(key => {
            rowIndex += 1;
            xlsx.utils.sheet_add_aoa(ws, [[key]], { origin: `A${rowIndex + 1}` });
            rowIndex += 1;
            xlsx.utils.sheet_add_json(ws, exportData[key], { origin: `A${rowIndex + 1}`, skipHeader: false });
            rowIndex += exportData[key].length + 1;
        });

        ws['!direction'] = 'rtl';

        const sheetName = moment(searchParams.date).format("YYYY-MM");
        const wb = {
            Sheets: { [sheetName]: ws },
            SheetNames: [sheetName],
            Workbook: { Views: [{ RTL: true }] }
        };
        const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileTypeXLSX });
        FileSaver.saveAs(data, fileName + fileExtensionXLSX);
    }

    return (
        <>
            {
                (<div className={classes.reportName}>
                    <FormattedMessage id="REPORT.CUSTOMER_BILLING.TITLE" />
                </div>)
            }
            {searchParams.customer && (
                <CustomerLocationDateFilterPanel
                    onSearchParamsSave={handleSearchParamsSave}
                    customerData={customerData}
                    initialSearchParams={searchParams}
                />
            )}
            {
                <ListsWrapper
                    showSummary={false}
                    reportsData={reportsData}
                    contents={
                        reportsData && reportsData.reports && reportsData.reports.length > 0 && (
                            <Paper className={classes.paper} key={"1"}>
                                <Typography className={classes.heading}>
                                    <FormattedMessage id="REPORT.CUSTOMER_BILLING.GENERAL_TABLE" />
                                </Typography>
                                <OrderItemsList reportsData={reportsData.reports} />
                                <OrderItemsSummary reportsData={reportsData.reports} />
                            </Paper>
                        )
                    }
                    exportData={getCombinedExportData()}
                    customExportFunction={downloadCombinedExportData}
                    exportFileName={exportFileName}
                    dynamicMinHeight={true}
                />

            }
            {
                <ListsWrapper
                    showSummary={false}
                    reportsData={reportUsersData}
                    hideExportBtn={true}
                    contents={
                        reportUsersData && reportUsersData.reports && reportUsersData.reports.length > 0 && (
                            <Paper className={classes.paper} key={"2"}>
                                <Typography className={classes.heading}>
                                    <FormattedMessage id="REPORT.CUSTOMER_BILLING.USERS_TABLE" />
                                </Typography>
                                <OrderItemsUsersList reportsData={reportUsersData.reports} />
                                <OrderItemsUsersSummary reportsData={reportUsersData.reports} />
                            </Paper>
                        )
                    }
                    dynamicMinHeight={true}
                />
            }
            {
                <ListsWrapper
                    showSummary={false}
                    reportsData={reportCreditData}
                    hideExportBtn={true}
                    contents={
                        reportCreditData && reportCreditData.reports && reportCreditData.reports.length > 0 && (
                            <Paper className={classes.paper} key={"3"}>
                                <Typography className={classes.heading}>
                                    <FormattedMessage id="REPORT.CUSTOMER_BILLING.CREDIT_TABLE" />
                                </Typography>
                                <OrderItemsCreditList reportsData={reportCreditData.reports} />
                                <OrderItemsCreditSummary reportsData={reportCreditData.reports} />
                            </Paper>
                        )
                    }
                    dynamicMinHeight={true}
                />
            }
        </>
    )
}
export default withRouter(
    injectIntl(connect(null, snackbarRedux.actions)(CustomerBillingReportsPage))
)
