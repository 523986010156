/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Collapse, IconButton } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Icon",
    disablePadding: true,
    notSortable: true,
    label: ""
  },
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  }
]

function CollapsibleRow({ row, indexId, classes }) {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        onClick={() => setOpen(!open)}
        className={classes.collapsibleRow}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          padding="none"
          className={classes.collapseIcon}
        >
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.itemName}>{row.ItemName}</TableCell>
        <TableCell className={classes.itemQuantity}>{row.Quantity}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.Orders.map((order, index) => (
              <div key={`${order.UserId}_${index}`}>{order.ExternalID}  {order.UserName}</div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function OrderItemsList({ ordersData }) {
  const classes = useStyles()
  const rows = ordersData.Items
  const [sortBy, setSortBy] = useState("ItemName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map(row => {
              return (
                <CollapsibleRow key={row.ItemId} row={row} classes={classes} />
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsList
