/* eslint-disable no-restricted-imports */

import { Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import CreateSpecialDateButton from "../../../modules/Common/CreateNewButton.js"
import CreateSpecialDateFormDialog from "../../../modules/SpecialDates/CreateSpecialDateFormDialog.js"
import DeleteSpecialDateDialog from "../../../modules/SpecialDates/DeleteSpecialDateDialog.js"
import SpecialDateList from "../../../modules/SpecialDates/SpecialDateList.js"
import {specialDateTypes} from "../../../modules/SpecialDates/specialDateTypeTranslation"
import {
    createSpecialDate,
    editSpecialDate,
    deleteSpecialDate,
    getSpecialDates
} from "../../../modules/SpecialDates/_axios/specialDateCrud.js"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers.js"

function SpecialDatesPanel({ intl,selectedCustomer, ...props }) {
    const dispatch = useDispatch()
    const [editSpecialDateState, setEditSpecialDateState] = useState({})
    const [deleteSpecialDateState, setDeleteSpecialDateState] = useState({ show: false })
    const [showCreateSpecialDatePanel, setShowCreateSpecialDatePanel] = useState(false)
    const [specialDatesData, setSpecialDatesData] = useState({
        specialDates: [],
        isLoading: true
    })
    const [headerTextId, setHeaderTextId] = useState({})

    function handleCloseDeleteSpecialDatePanel(specialDateID) {
        if (specialDateID) {
            setSpecialDatesData({
                ...specialDatesData,
                specialDates: [...specialDatesData.specialDates.filter(sd => sd.SpecialDateID !== specialDateID)]
            })
        }
        setDeleteSpecialDateState({ show: false })
    }

    function handleOpenCreateSpecialDatePanel() {
        setHeaderTextId("SPECIAL_DATE.CREATE")
        setShowCreateSpecialDatePanel(true)
    }
    function getAvailableSpecialDateTypes() {
        return specialDateTypes
    }
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    function handleCloseCreateSpecialDatePanel(specialDateData) {
        const cancelToken = axios.CancelToken.source()
        fetchSpecialDates(selectedCustomer.CustomerID, cancelToken)

        setEditSpecialDateState({})
        setShowCreateSpecialDatePanel(false)
    }

    function handleEditSpecialDate(specialDate) {
        setHeaderTextId("SPECIAL_DATE.EDIT")
        setEditSpecialDateState({ ...specialDate })
    }

    function handleDeleteSpecialDate(specialDate) {
        setDeleteSpecialDateState({ show: true, specialDate })
    }

    function createOrEditSpecialDate(submittedSpecialDate) {
        if (submittedSpecialDate.tokenData.SpecialDateID !=null ) {
            return editSpecialDate(submittedSpecialDate.tokenData.SpecialDateID, submittedSpecialDate.tokenData)
        }
        return createSpecialDate(submittedSpecialDate.tokenData)
    }
    function fetchSpecialDates(cancelToken) {
        setSpecialDatesData({ ...specialDatesData, isLoading: true })
        getSpecialDates(selectedCustomer.CustomerID,"", "", cancelToken.token)
            .then(({ data }) => {
                setSpecialDatesData({
                    specialDates: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_TAGS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchSpecialDates(selectedCustomer.CustomerID,cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (editSpecialDateState.SpecialDateID) {
            setShowCreateSpecialDatePanel(true)
        }
    }, [editSpecialDateState])


    return (
        <>
            <CreateSpecialDateButton onClick={handleOpenCreateSpecialDatePanel} />
            <CreateSpecialDateFormDialog
                show={showCreateSpecialDatePanel}
                initialSpecialDateState={editSpecialDateState}
                closeDialog={handleCloseCreateSpecialDatePanel}
                submitData={createOrEditSpecialDate}
                specialDatesData={specialDatesData}
                specialDateTypes={getAvailableSpecialDateTypes()}
                customerID={selectedCustomer.CustomerID}
                headerTextId={headerTextId}
            />
            <DeleteSpecialDateDialog
                show={deleteSpecialDateState.show}
                onClose={handleCloseDeleteSpecialDatePanel}
                sendRequest={deleteSpecialDate}
                specialDateData={deleteSpecialDateState.specialDate}
            />
            <Typography
                variant="h3"
                id="userAdminTitle"
                style={{ textAlign: "right" }}
            >
                <FormattedMessage id="TITLE" />
            </Typography>

            <SpecialDateList
                specialDatesData={specialDatesData}
                onEdit={handleEditSpecialDate}
                onDelete={handleDeleteSpecialDate}
            />
        </>
    )
}

export default injectIntl(SpecialDatesPanel)
