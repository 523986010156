import axios from "axios"

export function getFaq(cancelToken) {
  return axios.get("/FAQ/list", {
    cancelToken
  })
}
export function createFaq(data) {
  return axios.post("/FAQ", data)
}
export function updateFaq(data, faqID) {
  return axios.patch(`/FAQ/${faqID}`, data)
}

export function deleteFaq(id) {
  return axios.delete(`/FAQ/${id}`)
}
