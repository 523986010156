/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"

function ConfirmationDialog({
  show,
  onClose,
  onSubmit,
  isSubmitting,
  dialogTitle,
  contentText
}) {
  return (
    <div>
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            {dialogTitle}
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={onClose}
            className="mr-3"
          >
            <FormattedMessage id="DIALOG.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            disabled={isSubmitting}
            onClick={onSubmit}
            autoFocus
          >
            {isSubmitting && <span className="spinner spinner-white"></span>}
            <FormattedMessage id="DIALOG.SUBMIT_BUTTON" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
