/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  form: {
    minHeight: 385.688
  },
  textField: {
    margin: theme.spacing(1, 0)
  },
  iconRow: {
    textAlign: "right"
  },
  chooseIconButton: {
    minWidth: "auto"
  },
  formControl: {
    margin: theme.spacing(0, 1),
    minWidth: 120
  },
  formControlSelect: {
    background: 'white'
  },
  paper: {
    position: "absolute",
    zIndex: 10,
    left: 0,
    right: 0
  },
  textRight: {
    textAlign: "left"
  },
  spinner: {
    position: "absolute",
    bottom: "50%",
    left: 10
  },
  error: { color: "#F018A6" },
  fileError: { position: "absolute", bottom: -20, left: 10, color: "#F018A6" },
  certificateButtonsRow: {
    marginTop: theme.spacing(1)
  },
  imageButtonsRow: {
    marginTop: theme.spacing(1)
  },
  buttonMarginLeft: {
    marginRight: theme.spacing(1)
  },
  settingsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}))
