/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    alignItems: "flex-start"
  },
  downshiftField: {
    margin: theme.spacing(0, 1),
    width: 200
  },
  textField: {
    margin: theme.spacing(0, 1),
    width: 150
  },
  textFieldsWrapper: {
    margin: theme.spacing(0, 1)
  },
  nestedTextField: {
    margin: theme.spacing(0)
  },
  formControl: {
    margin: theme.spacing(0, 1),
    minWidth: 120
  },
  container: {
    // flexGrow: 1,
    position: "relative"
  },
  divider: {
    margin: theme.spacing(0, 1)
  },
  paper: {
    position: "absolute",
    zIndex: 10,
    left: 0,
    right: 0,
    maxHeight: 525,
    overflowY: "scroll"
  },
  containerItem: {
    margin: theme.spacing(0, 0.5)
  }
}))
