/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Checkbox } from "@material-ui/core";

import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import { useStyles } from "../Common/_styles/elementListStyles"
import { IconButton, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import moment from "moment";

const headRows = [
    {
        id: "Checbox",
        label: ""
    },
    {
        id: "OrderItemName",
        label: <FormattedMessage id="LABELS.ITEM_NAME" />
    },
    {
        id: "LocationName",
        label: <FormattedMessage id="LABELS.LOCATION_NAME" />
    },
    {
        id: "Options",
        notSortable: true,
        label: <FormattedMessage id="LABELS.OPTIONS" />
    },
    {
        id: "TotalCost",
        notSortable: true,
        label: <FormattedMessage id="LABELS.SUMMARY" />
    },
    {
        id: "TotalRefund",
        notSortable: true,
        label: <FormattedMessage id="LABELS.REFUND" />
    },
    {
        id: "Notes",
        label: <FormattedMessage id="LABEL.NOTE" />
    },
    {
        id: "OrderItemQuantity",
        label: <FormattedMessage id="LABELS.QUANTITY" />
    },
    {
        id: "DeliveryTime",
        label: <FormattedMessage id="LABELS.DELIVERY_TIME" />
    },
    {
        id: "Actions",
        notSortable: true,
        label: ""
    }
]

function OrdersList({ ordersData, onToggleAll, onSelect, onEdit, onDelete, intl, selected }) {
    const classes = useStyles()
    const rows = ordersData.userOrder
    const [sortBy, setSortBy] = useState("OrderItemName")
    const [orderBy, setOrderBy] = useState("desc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.Id}
                                >
                                    <TableCell>
                                        {row.TotalRefund === 0 && <><Checkbox
                                            checked={selected.indexOf(row.Id) !== -1}
                                            style={{ padding: 12 }}
                                            onChange={() => {
                                                onSelect({
                                                    itemId: row.Id
                                                })
                                            }}
                                            color="secondary"
                                        /></>}
                                        
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                        <b>{row.OrderItemName}</b>
                                        <> {row.OrderSupplierName}</>
                                    </TableCell>
                                    <TableCell>{row.LocationName}</TableCell>
                                    <TableCell>
                                        <div className="d-flex flex-row">
                                            {row.SelectedOptions &&
                                                row.SelectedOptions.reduce(
                                                    (str, option) => `${str && `${str}, `}${option.Name}`,
                                                    ""
                                                )}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="INFO.TOTAL" />: {row.TotalCost} <br />
                                        {row.TotalRefound !== 0 && <><FormattedMessage id="INFO.TOTAL_REFOUND" />: {row.TotalRefound} <br /></>}
                                        {row.CostumerCreditAmount !== 0 && <><FormattedMessage id="INFO.CUSTOMER" />: {row.CostumerCreditAmount} <br /></>}
                                        {row.CreditCardAmount !== 0 && <><FormattedMessage id="INFO.CREDIT_CARD" />: {row.CreditCardAmount} <br /></>}
                                        {row.BudgetAmount !== 0 && <><FormattedMessage id="INFO.BUDGET" />: {row.BudgetAmount} <br /></>}
                                        {row.WalletAmount !== 0 && <><FormattedMessage id="INFO.WALLET" />: {row.WalletAmount} <br /></>}
                                        {row.SubsidyAmount !== 0 && <><FormattedMessage id="INFO.SUBSIDY" />: {row.SubsidyAmount} <br /></>}
                                    </TableCell>
                                    <TableCell>
                                        {row.TotalRefund !== 0 && <><FormattedMessage id="INFO.TOTAL" />: {row.TotalRefund} <br /></>}
                                        {row.CostumerCreditRefundAmount !== 0 && <><FormattedMessage id="INFO.CUSTOMER" />: {row.CostumerCreditRefundAmount} <br /></>}
                                        {row.CreditCardRefundAmount !== 0 && <><FormattedMessage id="INFO.CREDIT_CARD" />: {row.CreditCardRefundAmount} <br /></>}
                                        {row.BudgetRefundAmount !== 0 && <><FormattedMessage id="INFO.BUDGET" />: {row.BudgetRefundAmount} <br /></>}
                                        {row.WalletRefundAmount !== 0 && <><FormattedMessage id="INFO.WALLET" />: {row.WalletRefundAmount} <br /></>}
                                        {row.SubsidyRefundAmount !== 0 && <><FormattedMessage id="INFO.SUBSIDY" />: {row.SubsidyRefundAmount} <br /></>}
                                    </TableCell>
                                    <TableCell>{row.OrderItemNotes}</TableCell>
                                    <TableCell>{row.OrderItemQuantity}</TableCell>
                                    <TableCell>{moment('2020-10-10 ' + row.DeliveryTime?.From).format("HH:mm")}-{moment('2020-10-10 ' + row.DeliveryTime?.To).format("HH:mm")}</TableCell>
                                    <TableCell>
                                        {row.TotalRefund === 0 && <><Tooltip
                                            title={intl.formatMessage({
                                                id: "OPTION.EDIT.TOOLTIP"
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: "OPTION.EDIT.TOOLTIP"
                                                })}
                                                onClick={() => onEdit(row)}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Tooltip></>}
                                        
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(OrdersList)
