/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Checkbox from "@material-ui/core/Checkbox"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { getStatusText } from "../statusTranslation"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { useStyles } from "../_styles/elementListStyles"
import ConfirmationDialog from "../../Common/ConfirmationDialog"

const headRows = [
  {
    id: "Icon",
    disablePadding: false,
    notSortable: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.NAME" />
  },
  {
    id: "Rating",
    disablePadding: true,
    onlyForAdmin: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.RATING" />
  },
  {
    id: "Status",
    disablePadding: false,
    notSortable: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.STATUS" />
  },
  {
    id: "Active",
    disablePadding: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.HEAD_LABELS.ACTIVE" />
  }
]

function ItemList({ items, selected, setSelected, isUserAdmin, handleActiveItem, intl }) {
  const classes = useStyles()
  const rows = items
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  const [confirmationDialogData, setConfirmationDialogData] = useState({
    show: false,
    row: null,
    isSubmitting: false
  })

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  const isSelected = id => selected === id

  function processChange(event, row) {
    event.preventDefault();
    setConfirmationDialogData({
      ...confirmationDialogData,
      show: true,
      row,
      value: event.target.checked,
    });
  }

  const handleActiveItemHandler = () => {
    const row = confirmationDialogData.row;
    if (!isSelected(row.ItemID)) {
      row.Active = confirmationDialogData.value;
      handleActiveItem({active: confirmationDialogData.value, row: row})
    }
  }

  return (
      <>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
              <Table aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead
                    headRows={headRows.filter(
                        row => !row.onlyForAdmin || isUserAdmin
                    )}
                    orderBy={orderBy}
                    sortBy={sortBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rows, getSorting(orderBy, sortBy)).map(
                      (row, index) => {
                        const isItemSelected = isSelected(row.ItemID)
                        const lastVersion = row.Versions.reduce(
                            (max, version) =>
                                version.Version > max ? version.Version : max,
                            1
                        )
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`${row.Name}${row.ItemID}`}
                                selected={isItemSelected}
                                className={classes.rowHeight}
                            >
                              <TableCell
                                  component="th"
                                  scope="row"
                                  width={24}
                                  align="center"
                                  onClick={() => setSelected(row.ItemID)}
                              >
                                <img
                                    src={
                                      row.Image && row.Image.length !== 0
                                          ? row.Image[0].URL
                                          : ""
                                    }
                                    onError={e => {
                                      e.target.onerror = null
                                      e.target.src = toAbsoluteUrl(
                                          `/media/svg/image_filler_icon.svg`
                                      )
                                    }}
                                    alt="Item"
                                    className={classes.iconDisplay}
                                />
                              </TableCell>
                              <TableCell
                                  align="left"
                                  padding="none"
                                  className={classes.itemNameCell}
                                  onClick={() => setSelected(row.ItemID)}
                              >
                                {row.Name}
                              </TableCell>
                              {isUserAdmin && (
                                  <TableCell align="left" padding="none"
                                             onClick={() => setSelected(row.ItemID)}>
                                    {row.Rating && +row.Rating.toFixed(1)}
                                  </TableCell>
                              )}
                              <TableCell align="left" onClick={() => setSelected(row.ItemID)}>
                                {getStatusText(
                                    intl,
                                    row.Versions.find(
                                        version => version.Version === lastVersion
                                    ).Status
                                )}
                              </TableCell>
                              <TableCell align="center" padding="checkbox">
                                <Checkbox checked={row.Active}
                                          disabled={isSelected(row.ItemID)}
                                          onChange={(event) => processChange(event, row)} />
                              </TableCell>
                            </TableRow>
                        )
                      }
                  )}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
        <ConfirmationDialog
            show={confirmationDialogData.show}
            onSubmit={handleActiveItemHandler}
            onClose={() =>
                setConfirmationDialogData({
                  show: false,
                  itemID: null,
                  isSubmitting: false
                })
            }
            isSubmitting={confirmationDialogData.isSubmitting}
            dialogTitle={<FormattedMessage id="CHANGE_STATUS_ITEM_VERSION_DIALOG.TITLE" />}
            contentText={intl.formatMessage({
              id: "CHANGE_STATUS_ITEM_VERSION_DIALOG.TEXT"
            })}
        />
      </>
  )
}

export default injectIntl(ItemList)
