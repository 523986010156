import axios from "axios"

export function createTag(data) {
  return axios.post("/Tags", data)
}
export function editTag(id, data) {
  return axios.patch(`/Tags/${id}`, data)
}
export function deleteTag(id) {
  return axios.delete(`/Tags/${id}`)
}
export function getTags(cancelToken) {
  return axios.get("/Tags", {
    cancelToken
  })
}
export function setTagIcon(id, data) {
  return axios.post(`/Tags/Icon/${id}`, data)
}
