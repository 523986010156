/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { getMealTypeText } from "../../Common/mealTypesTranslation"
import { useStyles } from "../../Common/_styles/elementListStyles"
import moment from "moment"


function getPushType(type) {
  switch (type) {
    case 1: {
      return "REPORT.TYPE_GENERAL";
    }
    case 2: {
      return "REPORT.TYPE_ITEM";
    }
    default: {
      return "REPORT.TYPE_GENERAL";
    }
  }
}

const headRows = [
  {
    id: "Image",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.IMAGE" />
  },
  {
    id: "Type",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TYPE" />
  },
  {
    id: "Name",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NAME" />
  },
  {
    id: "DeliveryTime",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DELIVERY_TIME" />
  },
  {
    id: "UserSendAndroid",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.USER_SEND" />
  },
  {
    id: "UserClickAndroid",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.USER_CLICK" />
  },
  {
    id: "UserClickPercent",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.USER_CLICK_PERCENT" />
  },
]

function PushList({ pushData, searchParams, intl }) {
  const classes = useStyles()
  const [sortBy, setSortBy] = useState("PushType")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function renderPushType(type) {
    return getMealTypeText(intl, type)
  }

  const rows = pushData;

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.Date + "_" + index}
                >
                  <TableCell>
                    <img src={row.ImageUrl} className={classes.itemInfo}/>
                  </TableCell>
                  <TableCell>{
                      intl.formatMessage({
                        id: getPushType(row.Type)
                      })
                    }
                  </TableCell>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.DeliveryTime ? moment(row.DeliveryTime).format("DD/MM/YYYY HH:mm") : ''}</TableCell>
                  <TableCell>{(row.UserSendAndroid || 0) + (row.UserSendIos || 0)}</TableCell>
                  <TableCell>{(row.UserClickAndroid || 0) + (row.UserClickIos || 0)}</TableCell>
                  <TableCell>{
                        (+row.UserSendAndroid + +row.UserSendIos) ?
                        (((+row.UserClickAndroid + +row.UserClickIos) /
                        (+row.UserSendAndroid + +row.UserSendIos)) * 100) : ''
                  }</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(PushList)
