import React from "react"

export default function DishLoader({ centered, minHeight = 300, height }) {
  return (
    <lottie-player
      src="/media/lotties/dish_loader.json"
      speed="1"
      loop
      autoplay
      style={
        centered
          ? {
              left: "50%",
              transform: "translateX(-50%)",
              position: "absolute",
              zIndex: 9,
              minHeight,
              height
            }
          : { minHeight, height }
      }
    ></lottie-player>
  )
}
