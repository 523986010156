/* eslint-disable no-restricted-imports */
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {FormattedMessage} from "react-intl";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {useStyles} from "../../Common/_styles/formDialogStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {dayCollection} from "../../Common/dayCollection";

function MealUpdateDetailsDialog({show, closeDialog, handleSumbit, intl}) {
    const classes = useStyles();

    const [dateDetails, setDateDetails] = useState({
        companyPrice: 0,
        clientPrice: 0,
        priority: 0,
        days: []
    });

    function onItemFieldChange(value, field) {
        const data = {
            ...dateDetails,
        };
        data[field] = value
        setDateDetails(
            data
        )
    }

    function onDaysChange(event, id) {
        const newDays = [...dateDetails.days];
        const index = newDays.indexOf(id);
        if (index === -1) {
            newDays.push(id);
        } else {
            newDays.splice(index, 1);
        }
        setDateDetails(
            {
                ...dateDetails,
                days: newDays,
            }
        )
    }

    return (
        <div>
            <Dialog
                open={show}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" disableTypography={true}>
                    <h3 style={{fontWeight: "bold", textAlign: "center"}}>
                        <FormattedMessage id="MEAL_FORM.UPDATE.TITLE"/>
                    </h3>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className={classes.title}>
                            {intl.formatMessage({
                                id: "MEAL_ITEM.LABELS.PRIORITY"
                            })}
                        </div>
                        <TextField
                            className={classes.priorityField}
                            margin="normal"
                            variant="filled"
                            type="number"
                            hiddenLabel
                            inputProps={{
                                min: 0,
                                className: classes.input
                            }}
                            fullWidth
                            onChange={event =>
                                onItemFieldChange(+event.target.value, "priority")
                            }
                        />
                    </div>
                    <div className={classes.title}>
                        <div>
                            {intl.formatMessage({
                                id: "MEAL_ITEM.LABELS.AVAILABILITY_DAYS"
                            })}
                        </div>
                        <div className={classes.dayContainer}>
                            {dayCollection.map(day => (
                                <FormControlLabel
                                    key={day.value}
                                    style={{margin: 0}}
                                    control={
                                        <Checkbox
                                            style={{padding: 0}}
                                            onChange={event => onDaysChange(event, day.value)}
                                            color="secondary"
                                        />
                                    }
                                    label={day.label}
                                    labelPlacement="end"
                                />
                            ))}
                        </div>
                    </div>

                    <div>

                        <div className={classes.title}>
                            {intl.formatMessage({
                                id: "MEAL_ITEM.LABELS.EMPLOYEE_PRICE"
                            })}
                        </div>
                        <TextField
                            margin="normal"
                            variant="filled"
                            type="number"
                            inputProps={{
                                min: 0
                            }}
                            fullWidth
                            onChange={event =>
                                onItemFieldChange(
                                    +event.target.value,
                                    "clientPrice"
                                )
                            }
                        />

                    </div>


                    <div>
                        <div className={classes.title}>
                            {intl.formatMessage({
                                id: "MEAL_ITEM.LABELS.COMPANY_PRICE"
                            })}
                        </div>
                        <TextField
                            margin="normal"
                            variant="filled"
                            type="number"
                            inputProps={{
                                min: 0
                            }}
                            fullWidth
                            onChange={event =>
                                onItemFieldChange(
                                    +event.target.value,
                                    "customerPrice"
                                )
                            }
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        type="button"
                        size="large"
                        onClick={closeDialog}
                        className={classes.button}
                    >
                        <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON"/>
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        color="secondary"
                        onClick={ev => {
                            handleSumbit(dateDetails);
                            closeDialog();
                        }}
                        className={classes.button}
                    >
                        <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default MealUpdateDetailsDialog;
