/* eslint-disable no-restricted-imports */

import axios from "axios"
import React, { useState } from "react"
import Paper from "@material-ui/core/Paper"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Downshift from "downshift"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import Button from "@material-ui/core/Button"
import { getRoleID } from "./rolesTranslation"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  allUserRoles,
  allUserRolesExceptAdmin,
  customerUserRoles,
  supplierUserRoles
} from "./rolesTranslation"
import { getSuggestions, renderSuggestion } from "../Common/downshiftFunctions"
import { useStyles } from "../Common/_styles/filterPanelStyles"
import ExportButton from "../../modules/Reports/ExportButton"
import { formatDate } from "../../modules/Common/momentFunctions"
import { getUsers } from "./_axios/userCrud"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"
import { useDispatch } from "react-redux"

function renderInput(inputProps) {
  const { InputProps, nested, classes, disabled, ref, ...other } = inputProps

  return (
    <TextField
      disabled={disabled}
      className={nested ? classes.nestedTextField : classes.textField}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

function FilterPanel({
  userRole,
  adminRightSupport,
  customerData,
  supplierData,
  onSearchParamsSave,
  initialSearchParams,
  intl
}) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"
  const isUserCustomerAdmin = userRole === "Customer Admin"
  const isUserSupplierAdmin = userRole === "Supplier Admin"

  const defaultSearchParams = {
    role: "all",
    text: "",
    customer: isUserAdmin ? {} : initialSearchParams.customer,
    location: "",
    department: "",
    supplier: isUserAdmin ? {} : initialSearchParams.supplier,
    active: "all"
  }
  const [searchParams, setSearchParams] = useState(initialSearchParams)

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
    }

    function formatExportCSVData(data) {
        return data.map(item => {
            const formattedItem = {}
            formattedItem[
                intl.formatMessage({
                    id: "ADMIN_USERS.FIRST_NAME_FIELD.LABEL"
                })
            ] = item.FirstName
            formattedItem[
                intl.formatMessage({
                    id: "ADMIN_USERS.LAST_NAME_FIELD.LABEL"
                })
            ] = item.LastName
            formattedItem[
                intl.formatMessage({
                    id: "ADMIN_USERS.MOBILE_FIELD.LABEL"
                })
            ] = item.Mobile
            formattedItem[
                intl.formatMessage({
                    id: "ADMIN_USERS.EMAIL_FIELD.LABEL"
                })
            ] = item.Email
            formattedItem[
                intl.formatMessage({
                    id: "ADMIN_USERS.CUSTOMER_NAME_NAME_FIELD.LABEL"
                })
            ] = (item.Customer ? item.Customer.Name : '')
            return formattedItem
        })
    }

  function handleExportCSV(response) {
    setIsLoading(true);
    const params = {
        active: true
    }

    const cancelToken = axios.CancelToken.source();
    getUsers(params, cancelToken.token)
      .then(({ data }) => {
          response(formatExportCSVData(data.items), `${intl.formatMessage({
              id: "ADMIN_USERS.REPORT"
          })}_${formatDate(new Date())}`)
      })
      .catch(error =>
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                  id: "API.ERROR.FAILED_TO_GET_USERS"
              })
          )
      )
      .finally(() => {
          setIsLoading(false);
      });
    }

  function handleSearchParamsClear() {
    setSearchParams(defaultSearchParams)
    onSearchParamsSave(defaultSearchParams)
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }

  function getUserRoles() {
    if (isUserAdmin) {
      return adminRightSupport ? allUserRoles : allUserRolesExceptAdmin
    }
    if (isUserCustomerAdmin) {
      return customerUserRoles
    }
    if (isUserSupplierAdmin) {
      return supplierUserRoles
    }
  }

  return (
    <form
      className={classes.root}
      onSubmit={handleSearchParamsSubmit}
      autoComplete="off"
    >
      <TextField
        id="standard-search"
        label={intl.formatMessage({
          id: "ADMIN_USERS.FILTER.SEARCH_FIELD.LABEL"
        })}
        className={classes.textField}
        value={searchParams.text}
        onChange={handleSearchParamChange("text")}
      />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="role">
          <FormattedMessage id="ADMIN_USERS.FILTER.ROLE_FIELD.LABEL" />
        </InputLabel>
        <Select
          value={searchParams.role}
          onChange={handleSearchParamChange("role")}
          inputProps={{
            name: "role",
            id: "role"
          }}
        >
          <MenuItem value="all">
            <FormattedMessage id="ADMIN_USERS.FILTER.ROLE_FIELD.PLACEHOLDER" />
          </MenuItem>
          {getUserRoles().map(role => (
            <MenuItem key={role} value={role}>
              <FormattedMessage id={getRoleID(role)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(isUserCustomerAdmin || isUserAdmin) && (
        <div className={classes.textFieldsWrapper}>
          {isUserAdmin && (
            <Downshift
              id="downshift-for-customer"
              selectedItem={
                searchParams.customer.Name ? searchParams.customer : null
              }
              onChange={selected =>
                handleSearchParamChange("customer")(selected || {})
              }
              itemToString={item => (item ? item.Name : "")}
            >
              {({
                clearSelection,
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                selectedItem
              }) => {
                const {
                  onChange,
                  onBlur,
                  onFocus,
                  ...inputProps
                } = getInputProps({
                  onChange: event => {
                    if (event.target.value === "") {
                      clearSelection()
                    }
                  },
                  onFocus: openMenu,
                  placeholder: intl.formatMessage({
                    id: "ADMIN_USERS.FILTER.CUSTOMER_FIELD.PLACEHOLDER"
                  })
                })

                return (
                  <div className={classes.container}>
                    {renderInput({
                      fullWidth: true,
                      classes,
                      label: intl.formatMessage({
                        id: "ADMIN_USERS.FILTER.CUSTOMER_FIELD.LABEL"
                      }),
                      nested: true,
                      disabled: Object.keys(searchParams.supplier).length !== 0,
                      InputLabelProps: getLabelProps({
                        shrink: true
                      }),
                      InputProps: { onBlur, onChange, onFocus },
                      inputProps
                    })}

                    <div {...getMenuProps()}>
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {getSuggestions(inputValue, customerData.customers, {
                            showEmpty: true
                          }).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({
                                item: suggestion
                              }),
                              highlightedIndex,
                              selectedItem
                            })
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  </div>
                )
              }}
            </Downshift>
          )}
          <FormControl
            className={classes.formControl}
            disabled={!searchParams.customer.Name}
          >
            <InputLabel htmlFor="location">
              <FormattedMessage id="ADMIN_USERS.FILTER.LOCATION_FIELD.LABEL" />
            </InputLabel>
            <Select
              value={searchParams.location}
              onChange={handleSearchParamChange("location")}
              inputProps={{
                name: "location",
                id: "location"
              }}
            >
              <MenuItem value="">
                <FormattedMessage id="ADMIN_USERS.FILTER.COMMON.ALL" />
              </MenuItem>
              {searchParams.customer.Locations &&
                searchParams.customer.Locations.map(location => (
                  <MenuItem
                    key={location.LocationID}
                    value={location.LocationID}
                  >
                    {location.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            disabled={!searchParams.customer.Name}
          >
            <InputLabel htmlFor="department">
              <FormattedMessage id="ADMIN_USERS.FILTER.DEPARTMENT_FIELD.LABEL" />
            </InputLabel>
            <Select
              value={searchParams.department}
              onChange={handleSearchParamChange("department")}
              inputProps={{
                name: "department",
                id: "department"
              }}
            >
              <MenuItem value="">
                <FormattedMessage id="ADMIN_USERS.FILTER.COMMON.ALL" />
              </MenuItem>
              {searchParams.customer.Departments &&
                searchParams.customer.Departments.map(department => (
                  <MenuItem
                    key={department.DepartmentID}
                    value={department.DepartmentID}
                  >
                    {department.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      {isUserAdmin && (
        <Downshift
          id="downshift-for-supplier"
          selectedItem={
            searchParams.supplier.Name ? searchParams.supplier : null
          }
          onChange={selected =>
            handleSearchParamChange("supplier")(selected || {})
          }
          itemToString={item => (item ? item.Name : "")}
        >
          {({
            clearSelection,
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            openMenu,
            selectedItem
          }) => {
            const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
              onChange: event => {
                if (event.target.value === "") {
                  clearSelection()
                }
              },
              onFocus: openMenu,
              placeholder: intl.formatMessage({
                id: "ADMIN_USERS.FILTER.SUPPLIER_FIELD.PLACEHOLDER"
              })
            })

            return (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: intl.formatMessage({
                    id: "ADMIN_USERS.FILTER.SUPPLIER_FIELD.LABEL"
                  }),
                  disabled: Object.keys(searchParams.customer).length !== 0,
                  InputLabelProps: getLabelProps({
                    shrink: true
                  }),
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(inputValue, supplierData.suppliers, {
                        showEmpty: true
                      }).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion
                          }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )
          }}
        </Downshift>
      )}
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="active">
          <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.LABEL" />
        </InputLabel>
        <Select
          value={searchParams.active}
          onChange={handleSearchParamChange("active")}
          inputProps={{
            name: "active",
            id: "active"
          }}
        >
          <MenuItem value="active">
            <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.ACTIVE" />
          </MenuItem>
          <MenuItem value="not active">
            <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.NOT_ACTIVE" />
          </MenuItem>
          <MenuItem value="all">
            <FormattedMessage id="ADMIN_USERS.FILTER.ACTIVE_FIELD.ALL" />
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        id="search_params_submit"
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-10`}
      >
        <span>
          <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
        </span>
      </Button>
      <div className="d-inline mx-2">
          <ExportButton 
              fileName={`${intl.formatMessage({
                  id: "ADMIN_USERS.REPORT"
              })}_${formatDate(new Date())}`}
              onHandleData={handleExportCSV}
              isCSV={true}
              isLoading={isLoading}
              formatMessageId="GET_ALL_ACTIVE_BUTTON_CSV"/>
      </div>
      <Button
        id="search_params_clear"
        type="button"
        variant="outlined"
        color="secondary"
        size="large"
        dir="ltr"
        className={`font-weight-bold my-3 ml-20`}
        onClick={handleSearchParamsClear}
      >
        <ClearIcon />
      </Button>
    </form>
  )
}

export default injectIntl(FilterPanel)
