/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import StarRateIcon from "@material-ui/icons/StarRate"
import { Link } from "react-router-dom"
import { formatDate, formatDateForApi } from "../../Common/momentFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "CustomerName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.CUSTOMER" />
  },
  {
    id: "OrderDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DATE" />
  },
  {
    id: "UserName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.USER_NAME" />
  },
  {
    id: "Rating",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.RATING" />
  },
  {
    id: "Comment",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.COMMENT" />
  }
]

function RatingList({ ratingData }) {
  const classes = useStyles()
  const rows = ratingData.Items
  const [sortBy, setSortBy] = useState("CustomerName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={ratingData.Name + "_" + index}
                >
                  <TableCell component="th" scope="row" align="left">
                    {row.CustomerName} {row.LocationName}
                  </TableCell>
                  <TableCell>{formatDate(row.OrderToDate)}</TableCell>
                  <TableCell>
                    <Link
                      to={`/user/${
                        row.UserID
                      }/order-history?fromDate=${formatDateForApi(
                        row.OrderToDate
                      )}&toDate=${formatDateForApi(row.OrderToDate)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.UserName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {Array.from(Array(row.Rating)).map((el, index) => (
                      <StarRateIcon key={index} />
                    ))}
                  </TableCell>
                  <TableCell>{row.Comment}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default RatingList
