/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import { formatDate } from "../Common/momentFunctions"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "OrderNumber",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDER_NUMBER" />
  },
  {
    id: "OrderDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDERDATE" />
  },
  {
    id: "LocationName",
    notSortable: true,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.LOCATION" />
  },
  {
    id: "SupplierName",
    notSortable: true,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUPPLIER" />
  },
  {
    id: "ItemName",
    notSortable: true,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ITEM_NAME" />
  },
  {
    id: "Refunded",
    notSortable: true,
    label: ""
  }
]

export default function OrderList({ ordersData }) {
  const classes = useStyles()
  const rows = ordersData.data
  const [sortBy, setSortBy] = useState("OrderDate")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  function renderItemInfo(info) {
    return <div className={classes.itemInfo}>{info}</div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
        >
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.OrderID}>
                  <TableCell component="th" scope="row" align="left">
                    {row.OrderNumber}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(row.OrderDate)}
                  </TableCell>
                  <TableCell align="left">
                    {row.Items.map(item => renderItemInfo(item.LocationName))}
                  </TableCell>
                  <TableCell align="left">
                    {row.Items.map(item => renderItemInfo(item.SupplierName))}
                  </TableCell>
                  <TableCell align="left">
                    {row.Items.map(item => renderItemInfo(item.ItemName))}
                  </TableCell>
                  <TableCell align="left">
                    {row.Items.map(item =>
                        renderItemInfo(
                        item.IsPartialRefunded 
                          ? <><FormattedMessage id="ITEM.PARTIAL_REFUNDED" /> ({item.TotalRefund}₪)</>
                          : item.IsRefunded 
                            ? <><FormattedMessage id="ITEM.REFUNDED" /> ({item.TotalRefund}₪)</>
                            : ("")
                      )
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
