/* eslint-disable no-restricted-imports */
import axios from "axios"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import {
    getCustomersList
} from "../modules/Customers/_axios/customerCrud"
import BudgetUsageList from "../modules/Reports/BudgetUsageReports/BudgetUsageList"
import FilterPanel from "../modules/Reports/BudgetUsageReports/FilterPanel"
import ExportButton from "../modules/Reports/ExportButton"
import { getBudgetReports } from "../modules/Reports/_axios/reportsCrud"

function BudgetUsageReportsPage({ userRole, intl, ...props }) {
    const dispatch = useDispatch()
    const [reportsData, setReportsData] = useState({
        reports: [],
        isLoading: false
    })
    const [exportFileName, setExportFileName] = useState("")
    const [searchParams, setSearchParams] = useState({})
    const [customerData, setCustomerData] = useState({
        customers: [],
        isLoading: true
    })
    const headRows = [
        {
            id: "FullName",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.FULL_NAME" />
        },
        {
            id: "ExternalID",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.EXTERNAL_ID" />
        },
        {
            id: "DepartmentName",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.DEPARTMENT_NAME" />
        },
        {
            id: "Sum",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.BUDGET_USED" />
        },
        {
            id: "WalletTransfer",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.WALLET_TRANSFER" />
        },
        {
            id: "MealSum",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.MEAL_SUM" />
        },
        {
            id: "StoreSum",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.STORE_SUM" />
        },
        {
            id: "TotalMealSum",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.TOTAL_MEAL_SUM" />
        },
        {
            id: "RefundSum",
            disablePadding: false,
            label: <FormattedMessage id="REPORT.REFUND" />
        },
    ]

    function handleSearchParamsSave(changes) {
        const newParams = { ...searchParams, ...changes }
        setSearchParams(newParams)
    }

    function fetchReports(cancelToken) {
        const { customer, department, from, to } = searchParams
        const params = {
            from: formatDateForApi(from),
            to: formatDateForApi(to),
            customer: customer.CustomerID,
            department
        }

        props.history.push({
            pathname: "/reports-budget-usage",
            search: "?" + new URLSearchParams(params).toString()
        })

        setReportsData({ ...reportsData, isLoading: true })
        getBudgetReports(
            customer.CustomerID,
            department || undefined,
            params.from,
            params.to,
            cancelToken.token
        )
            .then(({ data }) => {
                setReportsData({
                    reports: data,
                    isLoading: false,
                    from: params.from,
                    to: params.to
                })
            })
            .catch(error => handleApiError(dispatch, error))
    }

    function fetchCustomers(cancelToken) {
        getCustomersList(cancelToken.token, true, "active")
            .then(({ data }) => {
                setCustomerData({ customers: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchCustomers(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (!customerData.isLoading) {
            const urlParams = new URLSearchParams(window.location.search)

            const from = urlParams.get("from")
                ? new Date(urlParams.get("from"))
                : new Date()
            const to = urlParams.get("to")
                ? new Date(urlParams.get("to"))
                : new Date()
            const department = urlParams.get("department") || ""
            const customer = urlParams.get("customer")
                ? customerData.customers.find(
                    customer => customer.CustomerID === urlParams.get("customer")
                )
                : {}
            setSearchParams({
                from,
                to,
                department,
                customer
            })
        }
    }, [customerData])

    useEffect(() => {
        if (
            searchParams.from &&
            searchParams.to &&
            searchParams.customer?.CustomerID
        ) {
            const cancelToken = axios.CancelToken.source()
            fetchReports(cancelToken)

            const departmentName = searchParams.department
                ? intl.formatMessage({
                    id: "EXPORT_FILE_DEPARTMENT"
                }) +
                "_" +
                searchParams.customer.Departments.find(
                    el => el.DepartmentID === searchParams.department
                ).Name +
                "_"
                : ""

            setExportFileName(
                intl.formatMessage({
                    id: "EXPORT_FILE_NAME.WALLET_BALANCE_REPORT"
                }) +
                "_" +
                intl.formatMessage({
                    id: "EXPORT_FILE_CUSTOMER"
                }) +
                "_" +
                searchParams.customer.Name +
                "_" +
                departmentName +
                moment(searchParams.from).format("DD-MM-YYYY") +
                "_-_" +
                moment(searchParams.to).format("DD-MM-YYYY")
            )
            return () => cancelToken.cancel()
        }
    }, [searchParams])

    function getExportData() {
        const totals = reportsData.reports.reduce((acc, report) => {
            headRows.forEach(row => {
                if (typeof report[row.id] === 'number') {
                    acc[row.id] = (acc[row.id] || 0) + report[row.id];
                }
            });
            return acc;
        }, {});

        const items = reportsData.reports.map(report => {
            const formattedReport = {};

            headRows.forEach(row => {
                const headerLabel = intl.formatMessage({ id: row.label.props.id });

                if (row.id === "FullName") {
                    formattedReport[headerLabel] = `${report.FirstName} ${report.LastName}`;
                } else {
                    formattedReport[headerLabel] = report[row.id];
                }
            });

            return formattedReport;
        });

        const formattedReport = {};
        headRows.forEach(row => {
            const headerLabel = intl.formatMessage({ id: row.label.props.id });

            if (row.id === "FullName") {
                formattedReport[headerLabel] = intl.formatMessage({ id: "REPORT.TOTAL" });
            } else {
                formattedReport[headerLabel] = totals[row.id];
            }
        });

        items.push(formattedReport);
        return items;
    }

    return (
        <>
            {searchParams.customer && (
                <FilterPanel
                    itemsData={reportsData.reports}
                    onSearchParamsSave={handleSearchParamsSave}
                    customerData={customerData}
                    initialSearchParams={searchParams}
                />
            )}
            {reportsData.reports.length !== 0 && (
                <div className="text-right">
                    <ExportButton
                        exportData={getExportData()}
                        fileName={exportFileName}
                    />
                </div>
            )}
            <BudgetUsageList
                userRole={userRole}
                headRows={headRows}
                reportsData={reportsData}
            />
        </>
    )
}

export default withRouter(
    injectIntl(
        connect(null, snackbarRedux.actions)(BudgetUsageReportsPage)
    )
)
