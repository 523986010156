/* eslint-disable no-restricted-imports */

import React from "react"
import deburr from "lodash/deburr"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

export function renderInput(inputProps) {
  const { InputProps, nested, classes, disabled, ref, ...other } = inputProps

  return (
    <TextField
      disabled={disabled}
      className={classes.downshiftField}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

export function getSuggestions(value, suggestions, { showEmpty = false } = {}) {
  const inputValue = value ? deburr(value.trim()).toLowerCase() : ''
  const inputLength = inputValue.length
  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        return suggestion.Name?.toLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1
      })
}

export function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected =
    (selectedItem ? selectedItem.Name : "")?.indexOf(suggestion?.Name) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.Name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.Name}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string
}
