/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="ORDER_ITEMS.LABELS.NAME" />
  },
  {
    id: "Quantity",
    disablePadding: false,
    label: <FormattedMessage id="ORDER_ITEMS.LABELS.QUANTITY" />
  }
]

function MealItemList({ meals, intl }) {
  const classes = useStyles()
  const rows = meals
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.itemID}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.ItemName}
                      </TableCell>
                      <TableCell>{row.Quantity}</TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(MealItemList)
