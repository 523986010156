/* eslint-disable no-restricted-imports */
import { withStyles } from "@material-ui/styles"
import React from "react"
import { formatDate } from "../../Common/momentFunctions"
import moment from "moment/moment";

const styles = theme => ({
  stickerWrapper: { display: "none", fontFamily: "Assistant" },
  spaceAroundRow: {
    display: "flex",
    justifyContent: "space-around"
  },
  spaceBetweenRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  ellipsisOverflow: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",

    "@supports (-webkit-line-clamp: 2)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "initial",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical"
    }
  },
  userName: {
    fontSize: 18
  },
  notes: {
    "@supports (-webkit-line-clamp: 5)": {
      "-webkit-line-clamp": 5
    }
  },
  fontSmall: {
    fontSize: 11
  },
  [`@media print`]: {
    pageWrapper: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    stickerWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",

      pageBreakAfter: "always"
    },
    sticker: {
      width: "90%",
      textAlign: "center",
      fontSize: "12pt",
      color: "black",
      fontWeight: "bold",
      fontFamily: "Arial Regular"
    }
  }
})

class StickerWrapper extends React.PureComponent {
  render() {
    const { classes, data } = this.props
    if (process.env.REACT_APP_FLAVOR=='NY'){
      return (
        <div className={classes.pageWrapper}>
          {data.Orders.map(order => (
            <div className={classes.stickerWrapper}>
              <div
                className={classes.sticker}
                key={order.OrderItemID + order.StickerIndex}
              >
                <div className={classes.userName}>{`${order.UserName}`}</div>
                <div className={classes.spaceBetweenRow}>
                </div>
                <div>{order.DepartmentName}</div>
                <div className={classes.spaceBetweenRow}>
                </div>
                <div>
                  <b>{`${order.ItemName}`}</b>
                </div>
                <div>
                {order.Notes}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
    else{
      return (
        <div className={classes.pageWrapper}>
          {data.Orders.map(order => (
            <div className={classes.stickerWrapper}>
              <div
                className={classes.sticker}
                key={order.OrderItemID + order.StickerIndex}
              >
                <div className={`${classes.spaceAroundRow} ${classes.fontSmall}`}>
                  {/* <div>{formatDate(data.Date)}</div> */}
                  <div>
                    <b>{order.ItemIndex}</b>
                  </div>
                </div>
                <div className={classes.userName}>{`${order.UserName}`}</div>
                <div className={classes.spaceBetweenRow}>
                  <div>{order.Location.Name}</div>
                  <div>{order.DepartmentName}</div>
                </div>
                <div>
                  <b>{`${order.ItemName}`} {` - ${order.Quantity}`}</b>
                </div>
                <div
                  className={classes.fontSmall}
                >
                  <b>{`${order.SupplierName || ''}`}</b>
                </div>
                <div>{
                  order.DeliveryTime?.From ?
                      moment('2020-10-10 ' + order.DeliveryTime?.From).format("HH:mm") : ''
                }-{order.DeliveryTime?.To ?
                    moment('2020-10-10 ' + order.DeliveryTime?.To).format("HH:mm") : ''}</div>
                <div
                  className={`${order.SelectedOptions.length > 30
                      ? `${classes.ellipsisOverflow} ${classes.fontSmall}`
                      : classes.ellipsisOverflow
                    }`}
                >
                  {order.SelectedOptions}
                </div>
                <div
                  className={`${classes.ellipsisOverflow} ${classes.notes} ${classes.fontSmall}`}
                >
                  {order.Notes}
                </div>
                <div className={classes.fontSmall}>{order.StickerIndex}</div>
              </div>
            </div>
          ))}
        </div>
      )
    }

  }
}
export default withStyles(styles)(StickerWrapper)
