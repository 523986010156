/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  FormControlLabel,
  IconButton,
  Tooltip,
  Checkbox
} from "@material-ui/core"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { dayCollection } from "../../Common/dayCollection"

const headRows = [
  {
    id: "Delete",
    disablePadding: true,
    notSortable: true,
    notForReadOnly: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: true,
    label: <FormattedMessage id="OPTION.LABELS.NAME" />
  },
  {
    id: "CatalogNumber",
    disablePadding: false,
    label: <FormattedMessage id="OPTION.LABELS.CATALOG_NUMBER" />
  },
  {
    id: "Days",
    disablePadding: false,
    notSortable: true,
    label: <FormattedMessage id="OPTION.LABELS.DAYS" />
  },
  {
    id: "Stiker",
    disablePadding: false,
    label: <FormattedMessage id="OPTION.LABELS.STRIKER" />
  },
  {
    id: "Price",
    disablePadding: false,
    label: <FormattedMessage id="OPTION.LABELS.PRICE" />
  },
  {
    id: "CustomerPrice",
    disablePadding: false,
    onlyForAdmin: true,
    label: <FormattedMessage id="OPTION.LABELS.CUSTOMER_PRICE" />
  },
  {
    id: "ClientPrice",
    disablePadding: false,
    onlyForAdmin: true,
    label: <FormattedMessage id="OPTION.LABELS.CLIENT_PRICE" />
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  }
}))

function OptionList({
  intl,
  isUserAdmin,
  options,
  onDelete,
  onEdit,
  onDaysChange,
  isReadOnly
}) {
  const classes = useStyles()
  const rows = options
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleDelete(option) {
    onDelete(option)
  }

  function handleEdit(option) {
    onEdit(option)
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows.filter(
                row =>
                  !(row.notForReadOnly && isReadOnly) &&
                  (!row.onlyForAdmin || isUserAdmin)
              )}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.Name}
                    >
                      {!isReadOnly && (
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          width={90}
                          padding="none"
                        >
                          <Tooltip
                            title={intl.formatMessage({
                              id: "OPTION.DELETE.TOOLTIP"
                            })}
                          >
                            <IconButton
                              aria-label={intl.formatMessage({
                                id: "OPTION.DELETE.TOOLTIP"
                              })}
                              onClick={() => handleDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={intl.formatMessage({
                              id: "OPTION.EDIT.TOOLTIP"
                            })}
                          >
                            <IconButton
                              aria-label={intl.formatMessage({
                                id: "OPTION.EDIT.TOOLTIP"
                              })}
                              onClick={() => handleEdit(row)}
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell align="left" padding="none">
                        {row.Name}
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {row.CatalogNumber}
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {dayCollection.map(day => (
                          <FormControlLabel
                            key={day.value}
                            style={{ margin: 0 }}
                            control={
                              <Checkbox
                                checked={row.Days.includes(day.value)}
                                style={{ padding: 0 }}
                                onChange={event =>
                                  onDaysChange(event, row.Name, day.value)
                                }
                                color="secondary"
                              />
                            }
                            label={day.label}
                            labelPlacement="end"
                          />
                        ))}
                      </TableCell>
                      <TableCell align="left">
                        {row.Stiker ? (
                          <FormattedMessage id="OPTION.YES" />
                        ) : (
                          <FormattedMessage id="OPTION.NO" />
                        )}
                      </TableCell>
                      <TableCell align="left">₪{row.Price}</TableCell>
                      {isUserAdmin && (
                        <>
                          <TableCell align="left">
                            ₪{row.CustomerPrice}
                          </TableCell>
                          <TableCell align="left">₪{row.ClientPrice}</TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(OptionList)
