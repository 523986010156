import usersMock from "../../Auth/__mocks__/userTableMock.js"

let idMock = 9

function createTagData(name, icon, priority, menu, store, parent) {
  return {
    TagID: `76d0f6b7-1ad7-4d45-8b96-b57977c979${++idMock}`,
    Name: name,
    IconURL: "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg",
    Store: store,
    Menu: menu,
    ParentTag: parent,
    Priority: priority
  }
}

let tags = [
  createTagData("Tag1", "", 20, true, false, {}),
  createTagData("Tag2", "", 25, true, false, {}),
  createTagData("Tag3", "", 16, true, false, {
    TagID: "76d0f6b7-1ad7-4d45-8b96-b57977c97910",
    Name: "Tag1"
  }),
  createTagData("Tag4", "", 6, true, true, {}),
  createTagData("Tag5", "", 16, true, false, {}),
  createTagData("Tag6", "", 3, true, false, {}),
  createTagData("Tag7", "", 9, true, false, {}),
  createTagData("Tag8", "", 0, true, true, {}),
  createTagData("Tag9", "", 26, true, false, {
    TagID: "76d0f6b7-1ad7-4d45-8b96-b57977c97911",
    Name: "Tag2"
  }),
  createTagData("Tag10", "", 0, true, false, {}),
  createTagData("Tag11", "", 0, true, false, {}),
  createTagData("Tag12", "", 19, true, false, {}),
  createTagData("Tag13", "", 18, true, false, {})
]

export default function mockTags(mock) {
  mock.onGet("/Tags").reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        let result = tags
        return [200, result]
      }
    }
    return [400]
  })

  mock.onPost("/Tags").reply(({ headers: { Authorization }, data }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length)

    const name = data.get("Name")
    const parentID = data.get("ParentTagID")
    const priority = data.get("Priority")
    const menu = JSON.parse(data.get("Menu"))
    const store = JSON.parse(data.get("Store"))

    if (accessToken) {
      const user = usersMock.find(x => x.Token === accessToken)
      if (user) {
        let newTag = {
          TagID: `76d0f6b7-1ad7-4d45-8b96-b57977c979${++idMock}`,
          Name: name,
          Store: store,
          Menu: menu,
          IconURL:
            "https://upload.wikimedia.org/wikipedia/commons/9/96/Idea.svg",
          ParentTag: tags.find(tag => tag.TagID === parentID) || {},
          Priority: priority
        }

        tags.push(newTag)
        return [200, newTag]
      }
    }
    return [400]
  })

  mock
    .onPost(RegExp(`/Tags/Icon/*`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const icon = data.get("Icon")

      const tagID = url.slice(url.lastIndexOf("/Tags/Icon/") + 11, url.length)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          return new Promise(function(resolve, reject) {
            const reader = new FileReader()
            reader.onloadend = e => {
              tags.find(tag => tag.TagID === tagID).IconURL = e.target.result
              resolve([200])
            }
            reader.onerror = e => {
              console.error(e)
              resolve([500])
            }
            reader.readAsDataURL(icon)
          })
        }
      }
      return [400]
    })

  mock
    .onPatch(RegExp(`/Tags/*`))
    .reply(({ headers: { Authorization }, data, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const tagID = url.slice(url.lastIndexOf("/Tags/") + 6, url.length)

      const name = data.get("Name")
      const parentID = data.get("ParentTagID")
      const priority = data.get("Priority")
      const menu = JSON.parse(data.get("Menu"))
      const store = JSON.parse(data.get("Store"))

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          let tag = tags.find(tag => tag.TagID === tagID)
          if (name) tag.Name = name
          if (data.get("Store")) tag.Store = store
          if (data.get("Menu")) tag.Menu = menu
          if (priority) tag.Priority = priority
          if (parentID)
            tag.ParentTag = tags.find(tag => tag.TagID === parentID) || {}
          return [200]
        }
      }
      return [400]
    })

  mock
    .onDelete(RegExp(`/Tags/*`))
    .reply(({ headers: { Authorization }, url }) => {
      const accessToken =
        Authorization &&
        Authorization.startsWith("Bearer ") &&
        Authorization.slice("Bearer ".length)

      const tagID = url.slice(url.lastIndexOf("/Tags/") + 6, url.length)

      if (accessToken) {
        const user = usersMock.find(x => x.Token === accessToken)
        if (user) {
          tags = tags.filter(tag => tag.TagID !== tagID)
          return [200]
        }
      }
      return [400]
    })
}
