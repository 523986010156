/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort } from "../Common/tableSortingFunctions"
import DishLoader from "../Common/DishLoader"
import clsx from "clsx"
import { useStyles } from "../Common/_styles/elementListStyles"

const headRows = [
    {
        id: "Price Category",
        label: <FormattedMessage id="TABLE.HEAD_LABELS.PRICE_CATEGORY" />
    },
    {
        id: "Actions",
        notSortable: true,
        label: ""
    }
]

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    spacer: {
        flex: "1 1 100%"
    },
    textField: {
        margin: theme.spacing(1),
        width: "auto"
    }
}))

function EnhancedTableToolbar(props) {
    const { searchText, onSearchTextChange } = props
    const classes = useToolbarStyles()

    return (
        <Toolbar className={classes.root}>
            <TextField
                id="standard-search"
                label={<FormattedMessage id="SEARCH_FIELD.LABEL" />}
                className={classes.textField}
                value={searchText}
                onChange={onSearchTextChange}
            />
            <div className={classes.spacer} />
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    onSearchTextChange: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired
}

export default function PriceCategoryList( { priceCategoriesData, onEdit, onDelete }) {
    const classes = useStyles()
    const [rows, setRows] = useState([])
    const [sortBy, setSortBy] = useState("Name")
    const [orderBy, setOrderBy] = useState("asc")
    const [searchText, setSearchText] = useState("")

    function handleSearchTextChange(event) {
        const value = event.target.value
        setSearchText(value.toLowerCase())
    }

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    function handleEdit(priceCategory) {
        onEdit(priceCategory)
    }

    function handleDelete(priceCategory) {
        onDelete(priceCategory)
    }

    useEffect(() => {
        let result
        if (searchText.length !== 0) {
            result = priceCategoriesData.priceCategories.filter(row =>
                row.Name.toLowerCase().includes(searchText)
            )
        } else {
            result = priceCategoriesData.priceCategories
        }
        setRows(result)
    }, [searchText, priceCategoriesData])

    if (priceCategoriesData.priceCategories.length === 0 && priceCategoriesData.isLoading) {
        return <DishLoader />
    }

    return (
        <div className={classes.root} style={{minWidth:"200px"}}>
            <Paper className={clsx(classes.paper, classes.bottomSpacing)}>
                <div className={classes.tableWrapper} style={{overflowX:"hidden", maxHeight:"50vh", width:"auto"}}>
                    <Table
                        className={classes.table}
                        style={{minWidth:"100px"}}
                        aria-labelledby="tableTitle"
                        size="small" stickyHeader
                    >
                        <EnhancedTableHead
                            headRows={headRows}
                            orderBy={orderBy}
                            sortBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody style={{maxHeight:"50vh"}}>
                            {rows.map(
                                (row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell component="th" scope="row" align="left" style={{padding:"2px 16px 2px 16px"}}>
                                                {row.Name}
                                            </TableCell>
                                            <TableCell align="left" style={{padding:"2px 16px 2px 16px"}}>
                                                <Tooltip title="Delete price category">
                                                    <IconButton
                                                        aria-label="Delete price category"
                                                        onClick={() => handleDelete(row)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit price category">
                                                    <IconButton
                                                        aria-label="Edit price category"
                                                        onClick={() => handleEdit(row)}
                                                    >
                                                        <CreateIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    )
}
