/* eslint-disable no-restricted-imports */

import React, {useEffect, useState} from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { he, enUS } from "date-fns/esm/locale"
import RefreshIcon from "@material-ui/icons/Refresh"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../Common/downshiftFunctions"
import Downshift from "downshift"
import { Paper } from "@material-ui/core"
import { useStyles } from "../Common/_styles/orderFilterStyles"
import moment from "moment/moment";

function FilterPanel({
  onParamsChangeSave,
  searchParams,
  customerData,
  locationsData,
  usersData,
  role,
  intl
}) {
  const locale = intl.locale === "en" ? enUS : he;
  const classes = useStyles()

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    onParamsChangeSave(
      ["location", "customer"].includes(name)
        ? { location: "", [name]: value, user: "" }
        : {
            [name]: value
          }
    )
  }

  const [mealDelivery, setMealDelivery] = useState([]);
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    const locationElements =
        locationsData.data.find(i => i.LocationID === searchParams.location);
    if (locationElements) {
      setMeals(locationElements.MealsInfo || []);
    }
  }, [searchParams?.location, locationsData.data])

  useEffect(() => {
    const mealElement =
        meals.find(i => i.MealType === searchParams.mealType);
    if (mealElement) {
      setMealDelivery(mealElement.MealDeliveryTimes || []);
    }
  }, [searchParams?.mealType, meals])

  function handleRefresh(event) {
    event.preventDefault()
    onParamsChangeSave(searchParams)
  }

  return (
    <>
      <form
        className={classes.root}
        onSubmit={handleRefresh}
        autoComplete="off"
      >
              <MuiPickersUtilsProvider utils={DateFnsUtils}
                  locale={locale}>
          <DatePicker
            autoOk
            format="dd/MM/yyyy"
            value={searchParams.date}
            onChange={handleSearchParamChange("date")}
            label={intl.formatMessage({
              id: "DATE_FIELD.LABEL"
            })}
            minDate={role === "Owner" ? new Date("2020-01-01") : new Date()}
          />
        </MuiPickersUtilsProvider>

        <Downshift
          id="downshift-for-customer"
          selectedItem={
            searchParams.customer.Name ? searchParams.customer : null
          }
          onChange={selected => {
            handleSearchParamChange("customer")(selected || {})
          }}
          itemToString={item => (item ? item.Name : "")}
        >
          {({
            clearSelection,
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            openMenu,
            selectedItem
          }) => {
            const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
              onChange: event => {
                if (event.target.value === "") {
                  clearSelection()
                }
              },
              onFocus: openMenu
            })

            return (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: intl.formatMessage({
                    id: "FILTER.CUSTOMER_FIELD.LABEL"
                  }),
                  nested: true,
                  InputLabelProps: getLabelProps({
                    shrink: true
                  }),
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(inputValue, customerData.data, {
                        showEmpty: true
                      }).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion
                          }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )
          }}
        </Downshift>

        <div className="position-relative">
          {locationsData.isLoading && searchParams.customer.Name && (
            <span
              className={`ml-1 spinner spinner-black ${classes.spinner}`}
            ></span>
          )}
          <FormControl
            className={classes.formControl}
            disabled={!searchParams.customer.Name || locationsData.isLoading}
          >
            <InputLabel htmlFor="location">
              <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL" />
            </InputLabel>
            <Select
              value={searchParams.location}
              onChange={handleSearchParamChange("location")}
              inputProps={{
                name: "location",
                id: "location"
              }}
            >
              {locationsData.data &&
                locationsData.data.map(location => (
                  <MenuItem
                    key={location.LocationID}
                    value={location.LocationID}
                  >
                    {location.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="mealType">
            <FormattedMessage id="FILTER.MEAL_TYPE.LABEL" />
          </InputLabel>
          <Select
            value={searchParams.mealType}
            onChange={handleSearchParamChange("mealType")}
            inputProps={{
              name: "mealType",
              id: "mealType"
            }}
          >
            <MenuItem value={1}>
              <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
            </MenuItem>
            <MenuItem value={2}>
              <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
            </MenuItem>
            <MenuItem value={3}>
              <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}
                     disabled={!searchParams.customer.Name || locationsData.isLoading}>
          <InputLabel htmlFor="mealDeliveryTimeId">
            <FormattedMessage id="FILTER.MEAL_DELIVERY_TIME.LABEL" />
          </InputLabel>
          <Select
              value={searchParams.mealDeliveryTimeId}
              onChange={handleSearchParamChange("mealDeliveryTimeId")}
              inputProps={{
                name: "mealDeliveryTimeId",
                id: "mealDeliveryTimeId"
              }}
          >
            {
              mealDelivery.map((delivery) => {
                return (
                    <MenuItem value={delivery.Id}>
                      {moment('2020-10-10 ' + delivery.From).format("HH:mm")}-
                      {moment('2020-10-10 ' + delivery.To).format("HH:mm")}
                    </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <div className="position-relative">
          {usersData.isLoading && (
            <span
              className={`ml-1 spinner spinner-black ${classes.spinner}`}
            ></span>
          )}
          <Downshift
            id="downshift-for-user"
            selectedItem={
              searchParams.user
                ? usersData.data.find(el => el.UserID === searchParams.user)
                : null
            }
            onChange={selected => {
              handleSearchParamChange("user")(selected ? selected.UserID : "")
            }}
            itemToString={item => (item ? item.Name : "")}
          >
            {({
              clearSelection,
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              openMenu,
              selectedItem
            }) => {
              const {
                onChange,
                onBlur,
                onFocus,
                ...inputProps
              } = getInputProps({
                onChange: event => {
                  if (event.target.value === "") {
                    clearSelection()
                  }
                },
                onFocus: openMenu
              })

              return (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    label: intl.formatMessage({
                      id: "FILTER.USERS_FIELD.LABEL"
                    }),
                    nested: true,
                    disabled: usersData.data.length === 0,
                    InputLabelProps: getLabelProps({
                      shrink: true
                    }),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue, usersData.data, {
                          showEmpty: true
                        }).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion
                            }),
                            highlightedIndex,
                            selectedItem
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              )
            }}
          </Downshift>
        </div>

        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={
            !searchParams.date || !searchParams.user || !searchParams.customer
          }
          className={`font-weight-bold my-3 ml-10`}
        >
          <RefreshIcon />
        </Button>
      </form>
    </>
  )
}

export default injectIntl(FilterPanel)
