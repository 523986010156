/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import DeleteIcon from "@material-ui/icons/Delete"
import CreateIcon from "@material-ui/icons/Create"
import { FormattedMessage, injectIntl } from "react-intl"
import { IconButton, Tooltip } from "@material-ui/core"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"

const headRows = [
  {
    id: "Delete",
    disablePadding: true,
    notSortable: true,
    notForReadOnly: true,
    label: ""
  },
  {
    id: "Name",
    disablePadding: true,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.GROUP_NAME" />
  },
  {
    id: "Label",
    disablePadding: false,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.LABEL" />
  },
  {
    id: "IsFree",
    disablePadding: false,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.FREE" />
  },
  {
    id: "IsMandatory",
    disablePadding: false,
    label: <FormattedMessage id="OPTION_GROUP_TABLE.LABELS.MANDATORY" />
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 1, 1, 0)
  },
  paper: {
    width: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  }
}))

function ItemOptionList({
  intl,
  itemOptions,
  optionGroups,
  onDelete,
  onEdit,
  isReadOnly
}) {
  const classes = useStyles()
  const rows = itemOptions
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleDelete(option) {
    onDelete(option)
  }

  function handleEdit(option) {
    onEdit(option)
  }

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              headRows={headRows.filter(
                row => !(row.notForReadOnly && isReadOnly)
              )}
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(orderBy, sortBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.Label}
                    >
                      {!isReadOnly && (
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          width={90}
                          padding="none"
                        >
                          <Tooltip
                            title={intl.formatMessage({
                              id: "OPTION.DELETE.TOOLTIP"
                            })}
                          >
                            <IconButton
                              aria-label={intl.formatMessage({
                                id: "OPTION.DELETE.TOOLTIP"
                              })}
                              onClick={() => handleDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={intl.formatMessage({
                              id: "OPTION.EDIT.TOOLTIP"
                            })}
                          >
                            <IconButton
                              aria-label={intl.formatMessage({
                                id: "OPTION.EDIT.TOOLTIP"
                              })}
                              onClick={() => handleEdit(row)}
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell align="left" padding="none">{row?.OptionGroup?.Name}</TableCell>
                      <TableCell align="left">{row.Label}</TableCell>
                      <TableCell align="left">
                        {row.IsFree ? (
                          <FormattedMessage id="OPTION.YES" />
                        ) : (
                          <FormattedMessage id="OPTION.NO" />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.IsMandatory ? (
                          <FormattedMessage id="OPTION.YES" />
                        ) : (
                          <FormattedMessage id="OPTION.NO" />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

export default injectIntl(ItemOptionList)
