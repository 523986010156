/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import FilterPanel from "../modules/Reports/PushReports/FilterPanel"
import { getCustomersList, getDepartments } from "../modules/Customers/_axios/customerCrud"
import {FormattedMessage, injectIntl} from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import "moment/locale/he"
import EnhancedTableHead from "../modules/Common/EnhancedTableHead"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { getPushReports, pushSync } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import PushList from "../modules/Reports/PushReports/PushList"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DishLoader from "../../app/modules/Common/DishLoader"
import { useHistory } from "react-router-dom";

function PushReportsPage({ intl, ...props }) {

  const history = useHistory();
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })

  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [customerData, setCustomerData] = useState({
    customers: [],
    isLoading: true
  })

  const [syncPushData, setSyncPushData] = useState({
    isLoading: false
  })

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function processReports(data) {
    return data.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  }

  function fetchReports(cancelToken) {
    const { customer, department, location, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      department
    }
    if (customer) {
      params['customer'] = customer.CustomerID
    }
    if (location) {
      params['location'] = location
    }
    if (department) {
      params['department'] = department
    }
    props.history.push({
      pathname: "/reports-push",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getPushReports(
      customer ? customer.CustomerID : null,
      department || null,
      location || null,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: processReports(data),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function syncPushes(cancelToken) {
    setSyncPushData({ isLoading: true })
    pushSync(cancelToken.token, true)
        .then(({ data }) => {
          setSyncPushData({ isLoading: false })
        })
        .catch(error =>
            handleApiError(
                dispatch,
                error,
                intl.formatMessage({
                  id: "API.ERROR.FAILED"
                })
            )
        )
  }

  function fetchCustomers(cancelToken) {
    getCustomersList(cancelToken.token, true, "active")
      .then(({ data }) => {
        setCustomerData({ customers: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
          })
        )
      )
  }

  function handlePushSubmit() {
    history.push('/pushes');
  }

  function handleSyncPushSubmit() {
    const cancelToken = axios.CancelToken.source()
    syncPushes(cancelToken)
    return () => cancelToken.cancel()
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!customerData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)
      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date(
              moment()
                  .subtract(1, "months")
                  .startOf("month")
          )
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const department = urlParams.get("department") || ""
      const customer = urlParams.get("customer")
        ? customerData.customers.find(
            customer => customer.CustomerID === urlParams.get("customer")
          )
        : {}
        const pr = {
          from,
          to,
        };
      if (customer) {
        pr['customer'] = customer;
      }
      if (department && department != 'undefined') {
        pr['department'] = department;
      }
      if (location && location != 'undefined') {
        pr['location'] = location;
      }
      setSearchParams(pr)
    }
  }, [customerData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location && searchParams.customer &&
        searchParams.customer.Locations
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          (searchParams.customer.Locations.find(
            el => el.LocationID === searchParams.location
          )?.Name || '') +
          "_"
        : ""
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  return (
    <>
      {
        <div className={classes.optionsPanel}>
          <button
              type="button"
              onClick={handlePushSubmit}
              className={`btn btn-primary font-weight-bold mr-2 mb-4`}
          >
            {
              intl.formatMessage({
                id: "ADMIN_PUSH.TITLE"
              })
            }
          </button>
          <button
              type="button"
              disabled={syncPushData.isLoading}
              onClick={handleSyncPushSubmit}
              className={`btn btn-primary font-weight-bold mr-2 mb-4`}
          >
            {
              intl.formatMessage({
                id: "ADMIN_PUSH_SYNC"
              })
            }
          </button>
        </div>
      }
      {searchParams.customer && (
        <FilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          customerData={customerData}
          initialSearchParams={searchParams}
        />
      )}
      {
        reportsData.isLoading && <DishLoader />
      }
      {
        !reportsData.isLoading && <PushList pushData={reportsData.reports} />
      }
    </>
  )
}
export default withRouter(
  injectIntl(connect(null, snackbarRedux.actions)(PushReportsPage))
)
