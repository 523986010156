/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { Paper, TextField } from "@material-ui/core"
import Downshift from "downshift"
import {
  renderInput,
  getSuggestions,
  renderSuggestion
} from "../../Common/downshiftFunctions"
import SetDateRangeDialog from "./SetDateRangeDialog"
import { useStyles } from "../../Common/_styles/filterPanelStyles"
import { formatDate } from "../../Common/momentFunctions"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { he, enUS } from "date-fns/esm/locale";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function FilterPanel({
  onSearchParamsSave,
  initialSearchParams,
  supplierData,
 hideSupplierField,
  intl
}) {
  const locale = intl.locale === "en" ? enUS : he;
  const classes = useStyles()
  const [searchParams, setSearchParams] = useState(initialSearchParams)
  const [dateRangeDialogState, setDateRangeDialogState] = useState({
    show: false
  })

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
  }

  function handleSetDateRange(from, to) {
    handleSearchParamChange("from")(from)
    handleSearchParamChange("to")(to)
    setDateRangeDialogState({ show: false })
  }

  function handleOpenSetDateRangeDialog() {
    setDateRangeDialogState({
      show: true,
      from: searchParams.from,
      to: searchParams.to
    })
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }

  return (
    <>
      {/*<SetDateRangeDialog
        show={dateRangeDialogState.show}
        saveRange={(from, to) => handleSetDateRange(from, to)}
        closeDialog={() => setDateRangeDialogState({ show: false })}
        maxDate={dateRangeDialogState.maxDate}
        minDate={dateRangeDialogState.minDate}
        maxRange={35}
        initialStartDate={dateRangeDialogState.from}
        initialEndDate={dateRangeDialogState.to}
      />*/}
      <form
        className={classes.root}
        onSubmit={handleSearchParamsSubmit}
        autoComplete="off"
      >
      {!hideSupplierField && (<Downshift
            id="downshift-for-supplier"
            selectedItem={
              searchParams.supplier.Name ? searchParams.supplier : null
            }
            onChange={selected => {
              handleSearchParamChange("location")("")
              handleSearchParamChange("supplier")(selected || {})
            }}
            itemToString={item => (item ? item.Name : "")}
          >
            {({
              clearSelection,
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              openMenu,
              selectedItem
            }) => {
              const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
                onChange: event => {
                  if (event.target.value === "") {
                    clearSelection()
                  }
                },
                onFocus: openMenu
              })

              return (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    label: intl.formatMessage({
                      id: "FILTER.SUPPLIER_FIELD.LABEL"
                    }),
                    nested: true,
                    InputLabelProps: getLabelProps({
                      shrink: true
                    }),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps
                  })}
                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue, supplierData.data, {
                          showEmpty: true
                        }).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion
                            }),
                            highlightedIndex,
                            selectedItem
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              )
            }}
          </Downshift>
        )}
        <FormControl
            className={classes.formControl}
        >
          <InputLabel htmlFor="status">
            <FormattedMessage id="POS.STATUS" />
          </InputLabel>
          <Select
              value={searchParams.department}
              onChange={handleSearchParamChange("status")}
              inputProps={{
                name: "status",
                id: "status"
              }}
          >
            <MenuItem value="">
              <FormattedMessage id="POS.STATUS" />
            </MenuItem>
            <MenuItem value="true">
              <FormattedMessage id="POS.SUCCESS" />
            </MenuItem>
            <MenuItem value="false">
              <FormattedMessage id="POS.ERROR" />
            </MenuItem>
          </Select>
        </FormControl>

        
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="mealType">
            <FormattedMessage id="REPORT.MEAL_TYPE" />
          </InputLabel>
          <Select
            value={searchParams.mealType}
            onChange={handleSearchParamChange("mealType")}
            inputProps={{
              name: "mealType",
              id: "mealType"
            }}
          >
            <MenuItem value="">
              <FormattedMessage id="FILTER.COMMON.ALL" />
            </MenuItem>
            <MenuItem value={1}>
              <FormattedMessage id="MEAL_TYPE.VALUE.BREAKFAST" />
            </MenuItem>
            <MenuItem value={2}>
              <FormattedMessage id="MEAL_TYPE.VALUE.LUNCH" />
            </MenuItem>
            <MenuItem value={3}>
              <FormattedMessage id="MEAL_TYPE.VALUE.DINNER" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage id="MEAL_TYPE.VALUE.STORE" />
            </MenuItem>
          </Select>
        </FormControl>

{/*
        <div className="d-flex flex-row justify-content-center align-items-baseline">
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.FROM"
            })}
            fullWidth
            value={searchParams.from ? formatDate(searchParams.from) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
          <div className={classes.divider}>-</div>
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.TO"
            })}
            fullWidth
            value={searchParams.to ? formatDate(searchParams.to) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
        </div>
*/}

        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} 
            locale={locale}>
            <DatePicker
                autoOk
                format="dd/MM/yyyy"
                value={searchParams.date}
                onChange={handleSearchParamChange("date")}
                label={intl.formatMessage({
                  id: "DATE_FIELD.LABEL"
                })}
            />
          </MuiPickersUtilsProvider>
        </div>


        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          className={`font-weight-bold my-3 ml-10`}
          disabled={!searchParams.date}
        >
          <span>
            <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
          </span>
        </Button>
      </form>
    </>
  )
}

export default injectIntl(FilterPanel)
