/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import PaymentSelectionForm from "./PaymentSelectionForm";
import { toFixedNumber } from "../../modules/Common/commonFunctions";

function OrderPaymentForm({ intl, summaryData, sumIsInvalid, userInfo,
                            walletBalance, budgetAmount, companyAmount,
                            paymentMethodSubmit, onOpenTranzilla, paymentCreditCard }) {

  const dispatch = useDispatch();
  const [setHeight, setHeightState] = useState("0px");
  const [selectedOption, setSelectedOption] = useState("all");
  const [fullAmountValue, setFullAmountValue] = useState(summaryData.EmployeeSum);
  const amountInputRef = useRef(null);
  const [paymentOptions, setPaymentOptions] = useState([]);

  useEffect(() => {
    if (!summaryData.EmployeeSum) {
      sumIsInvalid(false);
    }
  }, [summaryData.EmployeeSum])

  useEffect(() => {
    setPaymentOptions([
      {
        id: 1,
        name: intl.formatMessage({
          id: "order.payment_options.employer_budget"
        }),
        showInfoIcon: true,
        amount: budgetAmount,
        isHidden: !budgetAmount
      },
      {
        id: 2,
        name: intl.formatMessage({ id: "order.payment_options.dish_wallet" }),
        showInfoIcon: true,
        amount: walletBalance,
        isHidden: !walletBalance
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "order.payment_options.company_account"
        }),
        amount: companyAmount,
        isHidden: !companyAmount
      },
      {
        id: 4,
        name: intl.formatMessage({
          id: "order.payment_options.credit_card"
        }),
      }
    ]);
  }, [walletBalance, budgetAmount, companyAmount,])

  const [selectedAmounts, setSelectedAmounts] = useState({
    1: "",
    2: "",
    3: "",
    4: ""
  });

  const [invalid, setInvalid] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const [selectedItems, setSelectedItems] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const [selectedType, setSelectedType] = useState({
    1: "",
    2: "",
    3: "",
    4: ""
  });

  useEffect(() => {
    sumValidation()
  }, [selectedAmounts])

  useEffect(() => {
    if (Object.keys(invalid).some(it => invalid[it])) {
      sumIsInvalid(true);
    }
  }, [invalid])

  useEffect(() => {
    const items = Object.keys(selectedItems).filter(t => selectedItems[t]).map(it => {
      return {
        type: it,
        value: selectedAmounts[it],
      }
    })
    paymentMethodSubmit(items);
  }, [selectedAmounts, selectedOption])

  function onChangeAmount(typeOption, value) {
    const v = {
      ...selectedAmounts,
    }
    v[typeOption] = value;
    setSelectedAmounts(v);
    switch (+typeOption) {
      case 1: {
        setInvalid(
            {
              ...invalid,
              1: (+value > budgetAmount) || (+value > fullAmountValue)
            }
        );
        break;
      }
      case 2: {
        setInvalid(
            {
              ...invalid,
             2: +value > walletBalance || (+value > fullAmountValue)
            }
        );
        break;
      }
      case 3: {
        setInvalid(
            {
              ...invalid,
              3: (companyAmount > -1 && +value > companyAmount) || (+value > fullAmountValue)
            }
        );
        break;
      }
      case 4: {
        setInvalid(
            {
              ...invalid,
              4: (+value > fullAmountValue)
            }
        );
        break;
      }
    }
  }

  function handleSelectOption(typeOption, value) {
    const type = {
      ...selectedType,
    }
    type[typeOption] = value;
    setSelectedType(type);
    if (type === 'all') {
      selectedAmounts(type, fullAmountValue);
    }
  }

  function selectedAmount(type, value) {
    const amount = {
      ...selectedAmounts,
    }
    amount[type]=value;
    setSelectedAmounts(amount)
  }

  function sumValidation() {
    const val = Object.keys(selectedAmounts).reduce((sum, it) => sum += +selectedAmounts[it], 0);
    sumIsInvalid(val !== fullAmountValue);
  }

  function seletItems(id, ev) {
    selectedItems[id]=ev;
    const selection = {
      ...selectedItems,
    }
    selection[id]=ev;
    setSelectedItems(selection)
  }

  return (
    <div>
      <div className="general-label">
        <span>
          {
            intl.formatMessage({
              id: "CREATE_FORM.SUMMARY_PAYMENT"
            })
          }
        </span>

        <span>
          {
            +fullAmountValue
          }
        </span>
      </div>
      {
        (+fullAmountValue !== 0) ? (
            <div>
              <div>
                {
                  paymentOptions.filter(t => !t.isHidden).map(item => {
                    return (
                        <PaymentSelectionForm
                            intl={intl}
                            onSelect={ev => seletItems(item.id, ev)}
                            selected={selectedItems[item.id]}
                            availableAmount={item.amount}
                            name={item.name}
                            id={item.id}
                            fullAmount={fullAmountValue}
                            selectedAmount={selectedAmounts[item.id]}
                            selectedType={selectedType[item.id]}
                            onChangeAmount={(type, value) => onChangeAmount(type, value)}
                            onSelectOption={(type, value) => handleSelectOption(type, value)}
                            isInvalid={invalid[item.id]}
                            amountInputRef={amountInputRef}
                        />
                    )
                  })
                }

              </div>
              {
                selectedType["4"] && (
                    <>
                      {
                          paymentCreditCard && <div className='credit-card-container'>
                            {`${paymentCreditCard.creditCardToken?.slice(-4)} ***`}
                          </div>
                      }
                      <a
                          className="add-card-button"
                          onClick={onOpenTranzilla}
                      >
                        {intl.formatMessage({
                          id: "order.add-new-card"})}
                      </a>
                    </>
                  )
              }
            </div>

        ) : <></>
      }
    </div>
  );
}
export default injectIntl(OrderPaymentForm);
