/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore"
import DishLoader from "../Common/DishLoader"
import { useStyles } from "../Common/_styles/elementListStyles"
import { IconButton, Tooltip } from "@material-ui/core"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"

function MealsList({ ordersData, searchText, onOrder, intl }) {
    const classes = useStyles()

    const rows = [
        ...ordersData.dishes.filter(
            el =>
                el.ItemName.toLowerCase().includes(searchText) ||
                el.SupplierDisplayName.toLowerCase().includes(searchText)
        ),
        ...ordersData.comboDishes.filter(
            el => el.ComboName.toLowerCase().includes(searchText)
        )
    ]

    const headRows = [
        {
            id: "ItemName",
            label: <FormattedMessage id="LABELS.ITEM_NAME" />
        },
        {
            id: "SupplierName",
            label: <FormattedMessage id="LABELS.SUPPLIER_NAME" />
        },
        {
            id: "Actions",
            notSortable: true,
            label: ""
        }
    ]

    const [sortBy, setSortBy] = useState("OrderItemName")
    const [orderBy, setOrderBy] = useState("desc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    return (
        <Paper className={classes.paper}>
            {ordersData.isLoading && <DishLoader centered />}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell component="th" scope="row" align="left">
                                        <img
                                            src={
                                                row.ItemImages && row.ItemImages.length !== 0
                                                    ? row.ItemImages[0].URL
                                                    : ""
                                            }
                                            onError={e => {
                                                e.target.onerror = null
                                                e.target.src = toAbsoluteUrl(
                                                    `/media/svg/image_filler_icon.svg`
                                                )
                                            }}
                                            alt="Item"
                                            className={classes.iconDisplay}
                                        />
                                        {row.ItemName || row.ComboName}
                                    </TableCell>
                                    <TableCell>{row.SupplierName || ""}</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={intl.formatMessage({
                                                id: "OPTION.ORDER.TOOLTIP"
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: "OPTION.ORDER.TOOLTIP"
                                                })}
                                                onClick={() => onOrder(row)}
                                            >
                                                <LocalGroceryStoreIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(MealsList)