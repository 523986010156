/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage, injectIntl } from "react-intl";
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers";
import ComboItemSelectorDialog from "./ComboItemSelectorDialog";
import ComboItem from "./ComboItem";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStyles } from "../Common/_styles/formDialogStyles";
import TextField from "@material-ui/core/TextField";
import { dayCollection } from "../Common/dayCollection";
import {Checkbox, Chip, FilledInput, FormControlLabel, IconButton, Tooltip} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import {Tab, Tabs} from "react-bootstrap";
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { functions } from "lodash";


function ComboDialog({
  intl,
  show,
  onClose,
  sendRequest,
  initialComboState,
  handleSubmit,
  suppliers,
  allTags,
  updateCombos
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [storedImageURL, setStoredImageURL] = useState("")
  const [tab, setTab] = React.useState(1);
  const [selectedItems, setSelectedItems] = React.useState({
    data: {},
    count: 0
  });
  const [showAddComboItemFormDialog, setShowAddComboItemFormDialog] = React.useState(false);
  const classes = useStyles();

  const takenItemsIDs = [];

    let initialValues;
    if (initialComboState?.Id) {
        initialValues = {
            name: initialComboState.Name,
            description: initialComboState.Description,
            itemImageUrlId: initialComboState.ItemImageUrlId,
            comboImageUrl: initialComboState.ImageUrl,
            comboImage: null,
            clientPrice: initialComboState.ClientPrice,
            customerPrice: initialComboState.CustomerPrice,
            days: initialComboState.Days,
            active: initialComboState.Active,
            items: initialComboState.Items || [],
            tags: (initialComboState.Tags || []).map(item => item.TagID)
        };
    } else {
        initialValues = {
            name: "",
            description: "",
            itemImageUrlId: "",
            comboImageUrl: "",
            comboImage: null,
            clientPrice: "",
            customerPrice: "",
            days: [],
            active: false,
            items: [],
            tags: []
        };
    }

  const nameValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  );

  const priceValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    );

  const imageFileValidationSchema = Yup.mixed()
    .when({
      is: () => {
        return !initialComboState?.Id;
      },
      then: Yup.mixed().required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
    })
    .test(
      "fileType",
      intl.formatMessage({
        id: "VALIDATION.UNSUPPORTED_FILE_FORMAT"
      }),
      value => {
        if (value) {
          return value.type === "image/svg+xml";
        }
        return true;
      }
    );

  const validationSchema = Yup.object().shape(
    {
      name: nameValidationSchema,
      customerPrice: priceValidationSchema,
      clientPrice: priceValidationSchema
    },
    ["name", "customerPrice", "clientPrice"]
  );

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    return false;
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null;
  }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const {
                name,
                customerPrice,
                clientPrice,
                description,
                days,
                active,
                items,
                itemImageUrlId,
                tags,
                comboImage
            } = values;

            const formData = new FormData();
            formData.append('Name', name ?? "");
            formData.append('Description', description ?? "");
            formData.append('CustomerPrice', customerPrice);
            formData.append('ClientPrice', clientPrice);
            days.forEach((day, index) => {
                formData.append(`Days[${index}]`, day);
            });
            formData.append('Active', active);
            appendFormData(formData, 'Items', items);
            appendFormData(formData, 'Tags', tags);

            if (itemImageUrlId) {
                formData.append('ItemImageUrlId', itemImageUrlId);
            }

            if (comboImage) {
                formData.append('ComboImage', comboImage);
            }

            sendRequest(formData, initialComboState?.Id)
                .then(({ data }) => {
                    dispatch(
                        snackbarActions.setSnackbarData(
                            intl.formatMessage({
                                id: 'API.PROCESS_SUCCESS',
                            })
                        )
                    );
                    handleSubmit({ data });
                    onClose({ Id: initialComboState?.Id, ...values });
                })
                .catch(error => {
                    setLoading(false);
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: initialComboState?.Id
                                ? 'API.ERROR.FAILED_TO_UPDATE_COMBO'
                                : 'API.ERROR.FAILED_TO_CREATE_COMBO',
                        })
                    );
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });
    function appendFormData(formData, key, value) {
        if (value === null || value === undefined) {
            return;
        }

        if (Array.isArray(value)) {
            value.forEach((v, index) => {
                appendFormData(formData, `${key}[${index}]`, v);
            });
        } else if (typeof value === 'object' && !(value instanceof File)) {
            Object.keys(value).forEach((k) => {
                appendFormData(formData, `${key}.${k}`, value[k]);
            });
        } else {
            formData.append(key, value);
        }
    }

  function handleClose() {
    onClose(null);
  }

  function comboItemsHandler(items) {
    formik.setFieldValue(
        "items",
        formik.getFieldProps("items").value.concat(items.map(item => {
            return {
              ItemId: item.Item.ItemID,
              Name: item.Item.Name,
              Item: item.Item,
              ClientPrice: item.Item.ClientPrice,
              CustomerPrice: item.Item.CustomerPrice,
              AvilabilityDays: item.Item.AvilabilityDays,
              SupplierName: item.Item.SupplierName,
              ImageUrl: item.Item.Image[0]?.URL
            }
          })
        )
    )
    }

    function Thumb({ imageFile, initialURL, setStoredImageURL }) {
        const [state, setState] = useState({
            loading: false,
            thumb: initialURL || null
        });

        useEffect(() => {
            const handleImageUpload = async () => {
                setState({ loading: true });

                if (imageFile && typeof imageFile !== "string") {
                    let reader = new FileReader();

                    reader.onloadend = async () => {
                        const fileUrl = reader.result;
                        setState({ loading: false, thumb: fileUrl });
                    };

                    reader.readAsDataURL(imageFile);
                } else if (typeof imageFile === "string") {
                    setState({ loading: false, thumb: imageFile });
                    setStoredImageURL(imageFile);
                } else {
                    setState({ loading: false, thumb: initialURL });
                }
            };

            if (imageFile) {
                handleImageUpload();
            }
        }, [imageFile, initialURL]); 

        if (!imageFile && !initialURL) {
            return null;
        }

        if (state.loading) {
            return <span className="spinner spinner-white"></span>;
        }

        return (
            <div>
                <img
                    src={state.thumb}
                    className={classes.imgContainer}
                    alt="Uploaded Image"
                />
            </div>
        );
    }


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={show}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="COMBO_DIALOG.TITLE" />
          </h3>
        </DialogTitle>
        <DialogContent>

          <Tabs id="main-tab"
                activeKey={tab}
                onSelect={(k) => setTab(k)}
                unmountOnExit>
            <Tab eventKey="1"
                 title={<FormattedMessage id="COMBO_DIALOG.TABS.COMMON_INFO"/>}>
            </Tab>
            <Tab
                eventKey="2"
                title={<FormattedMessage id="COMBO_DIALOG.TABS.ITEMS"/>}
            >
            </Tab>
          </Tabs>
          {
            tab == 1 && (
                  <div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className={classes.largeDialog}
                        autoComplete="off"
                    >
                      <div className="position-relative">
                        <TextField
                            id="name"
                            name="name"
                            label={intl.formatMessage({
                              id: "COMBO_FORM.NAME_FIELD.LABEL"
                            })}
                            className={classes.textField}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            {...formik.getFieldProps("name")}
                            error={checkForError("name")}
                        />
                        {renderErrors("name")}
                      </div>
                      <div className="position-relative">
                        <TextField
                            id="description"
                            name="description"
                            label={intl.formatMessage({
                              id: "COMBO_FORM.DESCRIPTION_FIELD.LABEL"
                            })}
                            className={classes.textField}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            {...formik.getFieldProps("description")}
                            error={checkForError("description")}
                        />
                        {renderErrors("description")}
                      </div>

                      <div className="position-relative">

                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={formik.values.active}
                                  name="active"
                                  color="secondary"
                                  {...formik.getFieldProps("active")}
                                  onChange={formik.getFieldProps("active").onChange}
                              />
                            }
                            label={intl.formatMessage({
                              id: "COMBO_FORM.ACTIVE_FIELD.LABEL"
                            })}
                        />
                      </div>

                      <div className="position-relative">
                        <TextField
                            id="clientPrice"
                            name="clientPrice"
                            label={intl.formatMessage({
                              id: "COMBO_FORM.CLIENT_PRICE_FIELD.LABEL"
                            })}
                            className={classes.textField}
                            margin="normal"
                            variant="filled"
                            type="number"
                            inputProps={{
                              min: 0
                            }}
                            fullWidth
                            {...formik.getFieldProps("clientPrice")}
                            error={checkForError("clientPrice")}
                        />
                        {renderErrors("clientPrice")}
                      </div>
                      <div className="position-relative">
                        <TextField
                            id="customerPrice"
                            name="customerPrice"
                            label={intl.formatMessage({
                              id: "COMBO_FORM.CUSTOMER_PRICE_FIELD.LABEL"
                            })}
                            className={classes.textField}
                            margin="normal"
                            variant="filled"
                            type="number"
                            inputProps={{
                              min: 0
                            }}
                            fullWidth
                            {...formik.getFieldProps("customerPrice")}
                            error={checkForError("customerPrice")}
                        />
                        {renderErrors("customerPrice")}
                      </div>
                      <div className="position-relative">
                        {dayCollection.map(day => (<FormControlLabel
                            key={day.value}
                            style={{margin: 0}}
                            control={<Checkbox
                                checked={formik.getFieldProps("days").value.includes(day.value)}
                                style={{padding: 0}}
                                onChange={(event) => {
                                  const newDays = [...formik.getFieldProps("days").value || []];
                                  const id = day.value;
                                  const index = newDays.indexOf(id);
                                  if (index === -1) {
                                    newDays.push(id);
                                  } else {
                                    newDays.splice(index, 1);
                                  }
                                  formik.setFieldValue(
                                      "days",
                                      newDays || []
                                  )
                                }}
                                color="secondary"
                            />}
                            label={day.label}
                            labelPlacement="end"
                        />))}
                      </div>

                      <div className="position-relative">

                         <FormControl className={classes.formControl}>
                             <InputLabel htmlFor="itemImageUrlId">
                                 <FormattedMessage id="COMBO_DIALOG.ITEM_IMAGE_FIELD.LABEL" />
                             </InputLabel>
                             <Select
                                 value={formik.getFieldProps('itemImageUrlId').value}
                                 onChange={(event) => {
                                     formik.setFieldValue("itemImageUrlId", event.target.value);
                                     formik.setFieldValue("comboImage", null);
                                     formik.setFieldValue(
                                         "comboImageUrl", formik.getFieldProps("items").value.find(
                                             i => i.ItemId === event.target.value
                                         )?.ImageUrl
                                     );
                                 }}
                             >
                                <MenuItem disabled value="" key="custom">
                                  <FormattedMessage id="COMBO_DIALOG.CUSTOM_IMAGE.LABEL" />
                                </MenuItem>
                                 {
                                     formik.getFieldProps('items').value.map(item => {
                                         return (
                                             <MenuItem value={item.ItemId} key={item.ItemID}>
                                                 {item.Name}
                                             </MenuItem>
                                         )
                                     })
                                 }
                             </Select>
                         </FormControl>

                         <div className={classes.imgEditContainer}>
                             <Thumb
                                 imageFile={formik.values.comboImage ?? formik.values.comboImageUrl}
                                 initialURL={
                                     formik.getFieldProps("items").value.find(
                                         i => i.ItemId === formik.getFieldProps("itemImageUrlId").value
                                     )?.ImageUrl
                                 }
                                 setStoredImageURL={setStoredImageURL}
                             />
                             <Button variant="contained" component="label">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                     <path d="M5 20h14v-2H5v2zm7-14l-5 5h3v4h4v-4h3l-5-5z" />
                                 </svg>
                                 <input
                                     id="combo-image"
                                     name="comboImage"
                                     type="file"
                                     accept="image/png, image/jpeg, image/jpg"
                                     onChange={event => {
                                         formik.setFieldValue("comboImage", event.currentTarget.files[0]);
                                         formik.setFieldValue("itemImageUrlId", "");
                                     }}
                                     hidden
                                 />
                             </Button>
                         </div>

                      </div>

                      <div className='position-relative'>
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                            fullWidth
                            error={checkForError("tags")}
                        >
                          <InputLabel htmlFor="itemImageUrlId">
                            <FormattedMessage id="COMBO_DIALOG.TAG_FIELD.LABEL" />
                          </InputLabel>
                          <Select
                              {...formik.getFieldProps("tags")}
                              multiple
                              input={
                                <FilledInput
                                    name="tags"
                                    inputProps={{
                                      className: classes.input
                                    }}
                                />
                              }
                              renderValue={selected => (
                                  <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip
                                            key={value}
                                            className={classes.chip}
                                            label={allTags.find(tag => tag.TagID === value)?.Name}
                                        />
                                    ))}
                                  </div>
                              )}
                          >
                            {allTags.map(tag => (
                                <MenuItem key={tag.Name} value={tag.TagID}>
                                  {tag.Name}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {renderErrors("tags")}
                      </div>

                    </form>
                  </div>
            )
          }
          {
            tab == 2 && (
                <div>
                  <div>
                    <div className={classes.comboItemContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.NAME" /></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.CUSTOMER_PRICE" /></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.CLIENT_PRICE" /></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEAD_LABELS.SUPPLIER_NAME" /></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            formik.values.items?.map((item, index) => {
                              return <ComboItem comboId={initialComboState?.Id} item={item}
                                                intl={intl} setSelectedItems={setSelectedItems}
                                                selectedItems={selectedItems}
                                                setSelectedItemOptions={(data) => {
                                                  formik.setFieldValue(
                                                      `items[${index}]`, {
                                                        ...item,
                                                        Options: data,
                                                      }
                                                  )
                                                }}/>
                            })
                          }
                        </TableBody>
                      </Table>
                    </div>
                    <div>
                      <ComboItemSelectorDialog
                          show={showAddComboItemFormDialog}
                          closeDialog={() => setShowAddComboItemFormDialog(false)}
                          submitData={comboItemsHandler}
                          suppliers={suppliers || []}
                          takenItemsIDs={takenItemsIDs}
                      />
                      <Button
                          variant="outlined"
                          type="button"
                          size="large"
                          onClick={() => {
                            setShowAddComboItemFormDialog(true)
                          }}
                          className="mr-3"
                      >
                        <FormattedMessage id="COMBO_DIALOG.ADD_ITEM" />
                      </Button>
                      <Button
                          variant="outlined"
                          type="button"
                          size="large"
                          disabled={selectedItems.count === 0}
                          onClick={() => {
                            let currentItems = formik.getFieldProps("items").value.slice()
                            currentItems = currentItems.filter(item => !selectedItems.data[item.ItemId]);
                            formik.setFieldValue(
                                "items", currentItems
                            )
                            const otemObj = {
                              ...selectedItems
                            }
                            Object.keys(otemObj).forEach((o) => {
                              otemObj[o] = false
                            })
                            setSelectedItems({
                              data: otemObj,
                              count: 0
                            });
                          }}
                          className="mr-3"
                      >
                        <FormattedMessage id="COMBO_DIALOG.DELETE_ITEM" />
                      </Button>
                    </div>
                  </div>
                </div>
              )
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={handleClose}
            className="mr-3"
          >
            <FormattedMessage id="COMBO_DIALOG.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            disabled={loading}
            onClick={formik.submitForm}
            autoFocus
          >
            <FormattedMessage id="COMBO_DIALOG.SUBMIT_BUTTON" />
            {loading && <span className="ml-1 spinner spinner-white"></span>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(ComboDialog);
