/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../Common/tableSortingFunctions"
import { renderStatus } from "../Common/itemStatusTranslation"
import moment from "moment"
import { formatDate } from "../Common/momentFunctions"
import { useStyles } from "../Common/_styles/elementListStyles"
import {TableHead} from "@material-ui/core";

const headRows = [
  {
    id: "MealDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.MEALDATE" />
  },
  {
    id: "OrderDate",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDERDATE" />
  },
  {
    id: "ItemName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ITEM_NAME" />
  },
  {
    id: "SupplierName",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.RESTAURANT" />
  },
  {
    id: "OrderNumber",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.ORDER_NUMBER" />
  },
  {
    id: "Quantity",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.QUANTITY" />
  },
  {
    id: "TotalCost",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.TOTAL_COST" />
  },
  {
    id: "Status",
    label: <FormattedMessage id="TABLE.HEAD_LABELS.STATUS" />
  }
]

function ItemList({ itemsData, intl }) {
  const classes = useStyles()
  const rows = itemsData.data
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }
  const quantity = rows.reduce((sum, it) => sum + it.Quantity, 0)
  const totalCost = rows.reduce((sum, it) => sum + it.TotalCost, 0);

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
        >
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableHead className={classes.summaryLine}>
            <TableCell component="th" scope="row" align="left">

            </TableCell>
            <TableCell component="th" scope="row" align="left">

            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"><span className={classes.priceDefinition}>{quantity}</span></TableCell>
            <TableCell align="left"><span className={classes.priceDefinition}>{totalCost}</span></TableCell>
            <TableCell align="left"></TableCell>
          </TableHead>
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              const isRecent = moment()
                .startOf("day")
                .subtract(6, "hours")
                .isBefore(row.StatusUpdated)
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.OrderID}>
                  <TableCell component="th" scope="row" align="left">
                    {formatDate(row.MealDate)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {formatDate(row.OrderDate)}
                  </TableCell>
                  <TableCell align="left">{row.ItemName}</TableCell>
                  <TableCell align="left">{row.SupplierName}</TableCell>
                  <TableCell align="left">{row.OrderNumber}</TableCell>
                  <TableCell align="left"><span className={classes.priceDefinition}>{row.Quantity}</span></TableCell>
                  <TableCell align="left"><span className={classes.priceDefinition}>{row.TotalCost}</span></TableCell>
                  <TableCell align="left">
                    {renderStatus(intl, row.Status, isRecent)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default injectIntl(ItemList)
