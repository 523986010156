/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button";
import { getItems, getItemVersion, getFilteredItems } from "../../Suppliers/_axios/itemCrud.js";
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers.js";
import MenuItem from "@material-ui/core/MenuItem"
import Downshift from "downshift";
import UploadedItemsList from "./UploadedItemsList";
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField";
import {
  getSuggestions,
  renderInput,
  renderSuggestion
} from "../../Common/downshiftFunctions.js";
import axios from "axios";
import DishLoader from "../../Common/DishLoader.js";

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginTop: '1rem'
  }
}));
function MealSelectorDialog({
  intl,
  show,
  closeDialog,
  submitData,
  suppliers,
  priceCategoryData,
  takenItemsIDs,
  mealTypeIndex
}) {
  const dispatch = useDispatch();

  const itemsIDs = takenItemsIDs.map(it => it.IsCombo ? it.Combo.Id : it.Item.ItemID);

  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedPriceCategory, setSelectedPriceCategory] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  function fetchItems(cancelToken) {
    setItemsData({ ...itemsData, isLoading: true, notRequested: false });
    getFilteredItems(selectedSupplier.SupplierID, selectedPriceCategory, cancelToken.token)
      .then(({ data }) => {
        const items = data.filter(
          item =>
            item.MealTypeUsage &&
            item.MealTypeUsage.indexOf(mealTypeIndex.toString()) !== -1 &&
            !item.Versions.map(version => version.ItemID).some(id =>
                itemsIDs.includes(id)
            )
        );
        setItemsData({
          items: items,
          selected: [],
          isLoading: false,
          notRequested: false
        });
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_MEAL_ITEMS"
          })
        )
      );
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (selectedSupplier.SupplierID) {
      fetchItems(cancelToken);
    }
    return () => cancelToken.cancel();
  }, [selectedSupplier, selectedPriceCategory]);

  const [itemsData, setItemsData] = useState({
    items: [],
    selected: [],
    isLoading: false,
    notRequested: true
  });

  useEffect(() => {
    if (show) {
      setSelectedSupplier({});
      setItemsData({
        items: [],
        selected: [],
        isLoading: false,
        notRequested: true
      });
      setSubmitting(false);
    }
  }, [show]);

  const [isSubmitting, setSubmitting] = useState(false);

  const classes = useStyles();

  function getVersionData(newMealItems, chosenItems, index) {
    let after;
    if (index < chosenItems.length - 1) {
      after = () => {
        getVersionData(newMealItems, chosenItems, index + 1);
      };
    } else {
      after = () => {
        setSubmitting(false);
        submitData(newMealItems);
        closeDialog();
      };
    }

    getItemVersion(
      selectedSupplier.SupplierID,
      chosenItems[index].ItemID,
      chosenItems[index].Versions.reduce(
        (max, version) =>
          version.Active && version.Status === 3 && version.Version > max
            ? version.Version
            : max,
        1
      )
    )
      .then(({ data }) => {
        newMealItems.push({
          Item: {
            ...data,
            SupplierName: selectedSupplier.Name
          },
          ClientPrice: data.ClientPrice,
          CustomerPrice: data.CustomerPrice,
          IsFree: false,
          Priority: 0,
          //Selected all days,not just the available days
          Days: [0, 1, 2, 3, 4, 5, 6],//[...data.AvilabiltyDays],
          From: data.AvilableFrom ? new Date(`${data.AvilableFrom}.000Z`) : "",
          To: data.AvilableTo ? new Date(`${data.AvilableTo}.000Z`) : ""
        });
        after();
      })
      .catch(error => {
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ITEM_VERSION_INFO"
          })
        );
        after();
      });
  }

  function handleSubmit() {
    setSubmitting(true);

    const chosenItems = itemsData.items.filter(item =>
      itemsData.selected.includes(item.ItemID)
    );

    let index = 0;
    const newMealItems = [];
    getVersionData(newMealItems, chosenItems, index);
  }

  function handleToggle(checked, ItemID) {
    if (checked) {
      setItemsData({
        ...itemsData,
        selected: [...itemsData.selected, ItemID]
      })
    } else {
      setItemsData({
        ...itemsData,
        selected: itemsData.selected.filter(value => value !== ItemID)
      })
    }
  }

  function handleToggleAll(checked) {
    if (checked) {
      setItemsData({
        ...itemsData,
          selected: itemsData.items.filter(item =>
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())
          ).map(item => item.ItemID)
      })
    } else {
      setItemsData({
        ...itemsData,
        selected: []
      })
    }
    }

  function updateSearch(search) {
    setSearchQuery(search)
    setItemsData({
        ...itemsData,
        selected: []
    })
  }

  function getFilteredItemsBySearch() {
    return itemsData.items.filter(item =>
          item.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );
  }

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: "100%" } }}
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="ADD_MEAL_ITEM_FORM.TITLE" />
        </h3>
      </DialogTitle>
          <DialogContent style={{ height: 500 }}>
              <div className="position-relative" style={{ display: 'flex' }} >
                  <div className="position-relative" style={{ width: '100%', margin: '5px' }} >
                      <Downshift
                          id="downshift-for-supplier"
                          selectedItem={selectedSupplier.Name ? selectedSupplier : null}
                          onChange={selected => setSelectedSupplier(selected || {})}
                          itemToString={item => (item ? item.Name : "")}
                      >
                          {({
                              clearSelection,
                              getInputProps,
                              getItemProps,
                              getLabelProps,
                              getMenuProps,
                              highlightedIndex,
                              inputValue,
                              isOpen,
                              openMenu,
                              selectedItem
                          }) => {
                              const {
                                  onChange,
                                  onBlur,
                                  onFocus,
                                  ...inputProps
                              } = getInputProps({
                                  onChange: event => {
                                      if (event.target.value === "") {
                                          clearSelection();
                                      }
                                  },
                                  onFocus: openMenu
                              });

                              return (
                                  <div className={classes.container}>
                                      {renderInput({
                                          fullWidth: true,
                                          classes,
                                          label: intl.formatMessage({
                                              id: "FILTER.SEARCH_FIELD.LABEL"
                                          }),
                                          InputLabelProps: getLabelProps({
                                              shrink: true
                                          }),
                                          InputProps: { onBlur, onChange, onFocus },
                                          inputProps
                                      })}

                                      <div {...getMenuProps()}>
                                          {isOpen ? (
                                              <Paper className={classes.paper} square>
                                                  {getSuggestions(inputValue, suppliers, {
                                                      showEmpty: true
                                                  }).map((suggestion, index) =>
                                                      renderSuggestion({
                                                          suggestion,
                                                          index,
                                                          itemProps: getItemProps({
                                                              item: suggestion
                                                          }),
                                                          highlightedIndex,
                                                          selectedItem
                                                      })
                                                  )}
                                              </Paper>
                                          ) : null}
                                      </div>
                                  </div>
                              );
                          }}
                      </Downshift>
                  </div>
                  <div className="position-relative" style={{ width: '100%', margin: '5px', top: '-16px' }}>
                      {priceCategoryData.isLoading && (
                          <span
                              className={`ml-1 spinner spinner-black ${classes.spinner}`}
                          ></span>
                      )}
                      <FormControl
                          className={classes.formControl}
                          disabled={priceCategoryData.isLoading}
                      >
                          <InputLabel htmlFor="priceCategory">
                              <FormattedMessage id="FILTER.PRICE_CATEGORY_FIELD.LABEL" />
                          </InputLabel>
                          <Select
                              value={selectedPriceCategory}
                              onChange={(event) => {
                                  setSelectedPriceCategory(event.target.value || '')
                              }}
                              inputProps={{
                                  name: "priceCategory",
                                  id: "priceCategory"
                              }}
                          >
                              <MenuItem>
                                  <FormattedMessage id="FILTER.PRICE_CATEGORY_FIELD_ALL.LABEL" />
                              </MenuItem>
                              {priceCategoryData &&
                                  priceCategoryData.map(priceCategory => (
                                      <MenuItem
                                          key={priceCategory.Id}
                                          value={priceCategory.Id}
                                      >
                                          {priceCategory.Name}
                                      </MenuItem>
                                  ))}
                          </Select>
                      </FormControl>
                  </div>
              </div>
              {(!itemsData.isLoading && itemsData.items.length > 0) ?
                  <div className="position-relative">
                      <TextField
                          id="standard-search"
                          label={intl.formatMessage({
                              id: "FILTER.SEARCH_FIELD.LABEL"
                          })}
                          onChange={(event) => updateSearch(event.target.value)}
                          className={classes.textField}
                          fullWidth
                      />
                  </div>
                  : null
              }
        {itemsData.isLoading ? (
          <div>
            <DishLoader height={30}/>
          </div>
        ) : itemsData.items.length > 0 ? (
          <UploadedItemsList
            items={getFilteredItemsBySearch()}
            selected={itemsData.selected}
            onToggle={handleToggle}
            onToggleAll={handleToggleAll}
          />

        ) : itemsData.notRequested ? null : (
          <Typography variant="h4">
            <FormattedMessage id="COMMON.NO_RESULTS" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          size="large"
          onClick={closeDialog}
          className={classes.button}
          disabled={isSubmitting}
        >
          <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
        </Button>
        <Button
          variant="contained"
          type="submit"
          size="large"
          color="secondary"
          className={classes.button}
          disabled={isSubmitting || itemsData.selected.length === 0}
          onClick={handleSubmit}
        >
          <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
          {isSubmitting && <span className="ml-1 spinner spinner-white"></span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(MealSelectorDialog);
