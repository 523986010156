import axios from "axios"

export function getRefundItems(params, cancelToken) {
  return axios.post("/Reports/Orders/Refund", params, {
    cancelToken
  })
}

export function sendRefund(orderItemId, data) {
  return axios.post(`/User/Orders/Refund/${orderItemId}`, data)
}

export function sendCreditCardRefund(orderItemId, data) {
  return axios.post(`User/Orders/Refund/${orderItemId}/Employee`, data)
}

export function sendCompanyCreditRefund(orderItemId, data) {
  return axios.post(`User/Orders/Refund/${orderItemId}/Customer`, data)
}
