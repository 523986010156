/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import DishLoader from "../../Common/DishLoader"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "fullName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.FULL_NAME" />
  },
  {
    id: "departmentName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DEPARTMENT_NAME" />
  },
  {
    id: "externalID",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EXTERNAL_ID" />
  },
  {
    id: "balance",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.BALANCE" />
  },
]

function WalletBalanceList({ reportsData, intl }) {
  const classes = useStyles()
  const rows = reportsData.reports
  const [sortBy, setSortBy] = useState("fullName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      {reportsData.isLoading && <DishLoader centered />}
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.userID}>
                  <TableCell component="th" scope="row" align="left">{row.fullName}</TableCell>
                  <TableCell>{row.departmentName}</TableCell>
                  <TableCell>{row.externalID}</TableCell>
                  <TableCell>{row.balance}₪</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(WalletBalanceList)
