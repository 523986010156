/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { FormattedMessage, injectIntl } from "react-intl"
import { Paper } from "@material-ui/core"
import Downshift from "downshift"
import SetDateRangeDialog from "../../Customers/Locations/Meals/SetDateRangeDialog"
import {
    renderInput,
    getSuggestions,
    renderSuggestion
} from "../../Common/downshiftFunctions"
import { useStyles } from "../../Common/_styles/filterPanelStyles"
import { formatDate } from "../../Common/momentFunctions"

function FilterPanel({
    onSearchParamsSave,
    initialSearchParams,
    customerData,
    intl
}) {
    const classes = useStyles()

    const [searchParams, setSearchParams] = useState(initialSearchParams)
    const [dateRangeDialogState, setDateRangeDialogState] = useState({
        show: false
    })

    const handleSearchParamChange = name => data => {
        let value
        if (data.target) {
            value = data.target.type === 'checkbox' ? data.target.checked : data.target.value
        } else {
            value = data
        }
        setSearchParams(oldsearchParams => ({
            ...oldsearchParams,
            [name]: value
        }))
    }

    function handleSetDateRange(from, to) {
        handleSearchParamChange("from")(from)
        handleSearchParamChange("to")(to)
        setDateRangeDialogState({ show: false })
    }

    function handleOpenSetDateRangeDialog() {
        setDateRangeDialogState({
            show: true,
            from: searchParams.from,
            to: searchParams.to
        })
    }

    function handleSearchParamsSubmit(event) {
        event.preventDefault()
        onSearchParamsSave(searchParams)
    }

    return (
        <>
            <SetDateRangeDialog
                intl={intl}
                show={dateRangeDialogState.show}
                saveRange={(from, to) => handleSetDateRange(from, to)}
                closeDialog={() => setDateRangeDialogState({ show: false })}
                maxDate={dateRangeDialogState.maxDate}
                minDate={dateRangeDialogState.minDate}
                maxRange={35}
                initialStartDate={dateRangeDialogState.from}
                initialEndDate={dateRangeDialogState.to}
            />
            <form
                className={classes.root}
                onSubmit={handleSearchParamsSubmit}
                autoComplete="off"
            >
                <Downshift
                    id="downshift-for-customer"
                    selectedItem={
                        searchParams.customer.Name ? searchParams.customer : null
                    }
                    onChange={selected => {
                        handleSearchParamChange("location")("")
                        handleSearchParamChange("customer")(selected || {})
                    }}
                    itemToString={item => (item ? item.Name : "")}
                >
                    {({
                        clearSelection,
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        highlightedIndex,
                        inputValue,
                        isOpen,
                        openMenu,
                        selectedItem
                    }) => {
                        const { onChange, onBlur, onFocus, ...inputProps } = getInputProps({
                            onChange: event => {
                                if (event.target.value === "") {
                                    clearSelection()
                                }
                            },
                            onFocus: openMenu,
                            placeholder: intl.formatMessage({
                                id: "FILTER.CUSTOMER_FIELD.PLACEHOLDER.ALL"
                            })
                        })

                        return (
                            <div className={classes.container}>
                                {renderInput({
                                    fullWidth: true,
                                    classes,
                                    label: intl.formatMessage({
                                        id: "FILTER.CUSTOMER_FIELD.LABEL"
                                    }),
                                    nested: true,
                                    InputLabelProps: getLabelProps({
                                        shrink: true
                                    }),
                                    InputProps: { onBlur, onChange, onFocus },
                                    inputProps
                                })}

                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={classes.paper} square>
                                            {getSuggestions(inputValue, customerData.customers, {
                                                showEmpty: true
                                            }).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({
                                                        item: suggestion
                                                    }),
                                                    highlightedIndex,
                                                    selectedItem
                                                })
                                            )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        )
                    }}
                </Downshift>
                <FormControl
                    className={classes.formControl}
                    disabled={!searchParams.customer.Name}
                >
                    <InputLabel htmlFor="location">
                        <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL" />
                    </InputLabel>
                    <Select
                        value={searchParams.location}
                        onChange={handleSearchParamChange("location")}
                        inputProps={{
                            name: "location",
                            id: "location"
                        }}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="FILTER.COMMON.ALL" />
                        </MenuItem>
                        {searchParams.customer.Locations &&
                            searchParams.customer.Locations.map(location => (
                                <MenuItem key={location.LocationID} value={location.LocationID}>
                                    {location.Name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type">
                        <FormattedMessage id="FILTER.TYPE_FIELD.LABEL" />
                    </InputLabel>
                    <Select
                        value={searchParams.type}
                        onChange={handleSearchParamChange("type")}
                        inputProps={{
                            name: "type",
                            id: "type"
                        }}
                    >
                        <MenuItem value={1}>
                            <FormattedMessage id="FILTER.TYPE_FIELD.VALUE.CREDIT" />
                        </MenuItem>
                        <MenuItem value={2}>
                            <FormattedMessage id="FILTER.TYPE_FIELD.VALUE.CUSTOMER_CREDIT" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label={intl.formatMessage({
                        id: "FILTER.FIRST_NAME_FIELD.LABEL"
                    })}
                    className={classes.textField}
                    value={searchParams.firstName}
                    onChange={handleSearchParamChange("firstName")}
                />
                <TextField
                    label={intl.formatMessage({
                        id: "FILTER.LAST_NAME_FIELD.LABEL"
                    })}
                    className={classes.textField}
                    value={searchParams.lastName}
                    onChange={handleSearchParamChange("lastName")}
                />
                <div className="d-flex flex-row justify-content-center align-items-baseline">
                    <TextField
                        className={classes.textField}
                        inputProps={{
                            readOnly: true
                        }}
                        margin="normal"
                        label={intl.formatMessage({
                            id: "DATE_FIELD.FROM"
                        })}
                        fullWidth
                        value={searchParams.from ? formatDate(searchParams.from) : ""}
                        onClick={handleOpenSetDateRangeDialog}
                    />
                    <div className={classes.divider}>-</div>
                    <TextField
                        className={classes.textField}
                        inputProps={{
                            readOnly: true
                        }}
                        margin="normal"
                        label={intl.formatMessage({
                            id: "DATE_FIELD.TO"
                        })}
                        fullWidth
                        value={searchParams.to ? formatDate(searchParams.to) : ""}
                        onClick={handleOpenSetDateRangeDialog}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={searchParams.filterByOrderToDate || false}
                            onChange={handleSearchParamChange("filterByOrderToDate")}
                            color="primary"
                        />
                    }
                    label={<FormattedMessage id="FILTER.FILTER_BY_ORDER_TO_DATE" />}
                />
                <Button
                    id="search_params_submit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    dir="ltr"
                    className={`font-weight-bold my-3 ml-10`}
                >
                    <span>
                        <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
                    </span>
                </Button>
            </form>
        </>
    )
}

export default injectIntl(FilterPanel)