/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { renderStatus } from "../../Common/itemStatusTranslation"
import { Button, FormControlLabel, Checkbox } from "@material-ui/core"
import moment from "moment"
import DishLoader from "../../Common/DishLoader"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { formatDateTime } from "../../Common/momentFunctions"

export function renderBoolean(intl, value) {
    return value
        ? intl.formatMessage({
            id: "OPTION.YES"
        })
        : intl.formatMessage({
            id: "OPTION.NO"
        })
}

const headRows = [
    {
        id: "DeliveryTimeFrom",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.DELIVERY_TIME" />,
        disablePadding: true,
    },
    {
        id: "LocationName",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.LOCATION_NAME" />
    },
    {
        id: "DepartmentName",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.DEPARTMENT_NAME" />
    },
    {
        id: "UserName",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.USER_NAME" />
    },
    {
        id: "ItemName",
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ITEM_NAME" />
    },
    {
        id: "OrderCreated",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ORDER_DATE" />
    },
    {
        id: "Quantity",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.ORDER_QUANTITY" />
    },
    {
        id: "Options",
        notSortable: true,
        disablePadding: true,
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.OPTIONS" />
    },
    {
        id: "Notes",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.COMMENTS" />
    },
    {
        id: "Status",
        label: <FormattedMessage id="ORDERS_TABLE.LABELS.STATUS" />,
        disablePadding: true,
        onlyForToday: true
    },
    {
        id: "Info",
        label: "",
        disablePadding: true,
        notSortable: true
    },

    {
        id: "Actions",
        notSortable: true,
        label: ""
    }
]

function OrdersList({ ordersData, markReady, isDateToday, handlePrint, intl, sortBy, orderBy, onRequestSort }) {
    const classes = useStyles()
    const rows = ordersData.data.Orders.sort((a, b) => (moment('2020-10-10 ' + a.DeliveryTime?.From).toDate() - moment('2020-10-10 ' + b.DeliveryTime?.From).toDate() || a.CustomerName - b.CustomerName))

    function handleRequestSort(event, property) {
        onRequestSort(property);
    }

    return (
        <Paper className={classes.paper}>
            {ordersData.isLoading && <DishLoader centered />}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows.filter(row => !row.onlyForToday || isDateToday)}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {
                            (orderBy ? stableSort(rows, getSorting(orderBy, sortBy)) : rows).map((row, index) => {
                                const isHighlighted =
                                    isDateToday &&
                                    ((row.Status === 2 &&
                                        moment(row.CloseCancelTime).isBefore(row.StatusUpdated)) ||
                                        (row.Status === 5 &&
                                            moment(row.CloseEditTime).isBefore(row.StatusUpdated)))
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.OrderItemID}
                                        className={isHighlighted ? classes.redRow : ""}
                                    >
                                        <TableCell align="center">
                                            <span>
                                                {
                                                    row.OrderToDate ? moment(row.OrderToDate).format("DD/MM/YYYY ") : ''
                                                }
                                                {
                                                    row.DeliveryTime?.From ?
                                                        moment('2020-10-10 ' + row.DeliveryTime?.From).format("HH:mm") : ''
                                                }
                                            </span>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="left">
                                            {row.Location.Name}
                                        </TableCell>
                                        <TableCell padding="none">{row.DepartmentName}</TableCell>
                                        <TableCell>
                                            {row.UserName} {((row.CreatorName &&
                                                row.CreatorName !== row.FullName) ? '(' + row.CreatorName + ')' : '')}
                                        </TableCell>
                                        <TableCell padding="none">{row.ItemName}</TableCell>
                                        <TableCell>{formatDateTime(row.OrderCreated)}</TableCell>
                                        <TableCell>{row.Quantity}</TableCell>
                                        <TableCell padding="none">
                                            <div className="d-flex flex-row">
                                                {row.OptionGroups &&
                                                    row.OptionGroups.map((optionGroup, index) => (
                                                        <div
                                                            key={optionGroup.GroupName}
                                                            className={`px-1 ${index !== row.OptionGroups.length - 1
                                                                    ? "border-right"
                                                                    : ""
                                                                }`}
                                                        >
                                                            <div>
                                                                <b>{optionGroup.GroupName}</b>:
                                                            </div>
                                                            <div>{optionGroup.SelectedOptios}</div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </TableCell>
                                        <TableCell>{row.Notes}</TableCell>
                                        {isDateToday && (
                                            <TableCell padding="none">
                                                {renderStatus(intl, row.Status)}
                                            </TableCell>
                                        )}
                                        <TableCell padding="none" align="center">
                                            <div>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Checkbox checked={row.IsClose} color="secondary" />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "ORDERS_TABLE.LABELS.IS_CLOSED"
                                                    })}
                                                />
                                            </div>
                                            <div>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Checkbox checked={row.IsReady} color="secondary" />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "ORDERS_TABLE.LABELS.IS_READY"
                                                    })}
                                                />
                                            </div>
                                            <div>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Checkbox checked={row.IsPrinted} color="secondary" />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "ORDERS_TABLE.LABELS.IS_PRINTED"
                                                    })}
                                                />
                                            </div>
                                        </TableCell>

                                        <TableCell align="center">
                                            {row.ExternalSystemReportedOrderId}
                                            {![2, 3].includes(row.Status) && (
                                                <div>
                                                    {!row.IsReady &&
                                                        moment(ordersData.data.Date).isSame(
                                                            new Date(),
                                                            "day"
                                                        ) && (
                                                            <Button
                                                                variant="contained"
                                                                type="button"
                                                                size="large"
                                                                className={classes.button}
                                                                onClick={() => markReady(row.OrderItemID)}
                                                            >
                                                                <FormattedMessage id="ORDERS_TABLE.BUTTONS.MARK_READY" />
                                                            </Button>
                                                        )}
                                                    <Button
                                                        variant="contained"
                                                        type="button"
                                                        size="large"
                                                        className={classes.button}
                                                        onClick={() => handlePrint(row.OrderItemID)}
                                                    >
                                                        {!row.IsPrinted ? (
                                                            <FormattedMessage id="ORDERS_TABLE.BUTTONS.PRINT" />
                                                        ) : (
                                                            <FormattedMessage id="ORDERS_TABLE.BUTTONS.PRINT_AGAIN" />
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(OrdersList)
