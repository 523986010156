/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(1)
  },
  input: {
    padding: theme.spacing(1)
  },
  smallTextField: {
    margin: theme.spacing(1),
    maxWidth: 100
  },
  statusTextField: {
    margin: theme.spacing(1),
    textAlign: "left",
    maxWidth: 134
  },
  iconDisplay: {
    height: 24
  },
  button: {
    margin: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  error: { position: "absolute", bottom: -8, left: 10, color: "#F018A6" },
  errorForOptions: { position: "absolute", top: 0, left: 10, color: "#F018A6" }
}))
