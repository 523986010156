/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  form: {
    minWidth: 300
  },
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1, 0)
  },
  error: { color: "#F018A6" }
}))

function CreateWithNameFormDialog({
  show,
  title,
  closeDialog,
  handleSubmit,
  checkIfNameUnique,
  intl
}) {
  const classes = useStyles()
  const initialValues = {
    name: ""
  }

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      checkIfNameUnique
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const validationSchema = Yup.object().shape(
    {
      name: nameValidationSchema
    },
    ["name"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography={true}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>{title}</h3>
      </DialogTitle>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={classes.form}
      >
        <DialogContent>
          <TextField
            id="name"
            name="name"
            label={intl.formatMessage({
              id: "CREATE_FORM.NAME_FIELD.LABEL"
            })}
            className={classes.textField}
            margin="normal"
            variant="filled"
            fullWidth
            {...formik.getFieldProps("name")}
            error={checkForError("name")}
          />
          <div>{renderErrors("name")}</div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="button"
            size="large"
            onClick={closeDialog}
            className={classes.button}
          >
            <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            color="secondary"
            className={classes.button}
            disabled={formik.isSubmitting}
          >
            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
            {formik.isSubmitting && (
              <span className="ml-1 spinner spinner-white"></span>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default injectIntl(CreateWithNameFormDialog)
