import React, { useEffect, useState } from "react"
import { dayCollection } from "../../../Common/dayCollection"
import {
  makeStyles,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  TextField,
  TableRow,
  TableCell,
  Collapse,
  FormControl,
  Select,
  FilledInput,
  MenuCombo,
  Button
} from "@material-ui/core"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { formatDate } from "../../../Common/momentFunctions"
import { FormattedMessage, injectIntl } from "react-intl"
import { getMealsOptions, saveMealsOptions } from "../../_axios/customerCrud"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import axios from "axios"
import DishLoader from "../../../Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../../redux/snackbar/snackbarHandlers"

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 0,
    minWidth: 110
  },
  optionGroupField: {
    margin: 0,
    minWidth: 90
  },
  priorityField: {
    margin: 0,
    width: 80
  },
  input: {
    padding: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(0, 1)
  },
  iconDisplay: {
    maxHeight: 100,
    maxWidth: 174,
    width: "100%"
  },
  collapsibleRow: {
    "& > *": {
      borderBottom: "unset"
    }
  }
}))

function MealCombo({
  locationID,
  mealID,
  row,
  isSelected,
  onSelect,
  onComboFieldChange,
  onDaysChange,
  setDateRangeDialogState,
  intl
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [mealOptionsData, setMealOptionsData] = useState({
    data: [],
    isLoading: false
  })

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.Combo.Id}
        className={classes.collapsibleRow}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          width={24}
          padding="none"
        >
          <Checkbox
            checked={isSelected}
            style={{ padding: 12 }}
            onChange={() => onSelect(row.Combo.Id)}
            color="secondary"
          />
        </TableCell>
        {/*<TableCell>*/}
        {/*  <img*/}
        {/*    src={*/}
        {/*      row.Combo.ImageUrl ? row.Combo.ImageUrl*/}
        {/*        : ""*/}
        {/*    }*/}
        {/*    onError={e => {*/}
        {/*      e.target.onerror = null*/}
        {/*      e.target.src = toAbsoluteUrl(`/media/svg/image_filler_icon.svg`)*/}
        {/*    }}*/}
        {/*    alt="Combo"*/}
        {/*    className={classes.iconDisplay}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        <TableCell align="left">{row.Combo.Name}</TableCell>
        <TableCell align="left">
          <TextField
            className={classes.priorityField}
            margin="normal"
            variant="filled"
            type="number"
            hiddenLabel
            inputProps={{
              min: 0,
              className: classes.input
            }}
            fullWidth
            value={row.Priority}
            onChange={event => onComboFieldChange(event, "Priority", row.Combo.Id)}
          />
        </TableCell>
        <TableCell align="left">
          {dayCollection.map(day => (
            <FormControlLabel
              key={day.value}
              style={{ margin: 0 }}
              control={
                <Checkbox
                  checked={row.Days?.includes(day.value)}
                  disabled={
                    row.Combo.AvilabiltyDays?.length > 0
                      ? !row.Combo.AvilabiltyDays?.includes(day.value)
                      : false
                  }
                  style={{ padding: 0 }}
                  onChange={event =>
                    onDaysChange(event, row.Combo.Id, day.value)
                  }
                  color="secondary"
                />
              }
              label={day.label}
              labelPlacement="end"
            />
          ))}
        </TableCell>
        <TableCell align="left" width={180}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <TextField
              label={intl.formatMessage({
                id: "OPTION.LABELS.CLIENT_PRICE"
              })}
              margin="normal"
              variant="filled"
              type="number"
              inputProps={{
                min: 0
              }}
              fullWidth
              value={row.ClientPrice}
              onChange={event => onComboFieldChange(event, "ClientPrice", row.Combo.Id)}
            />
            <TextField
              label={intl.formatMessage({
                id: "OPTION.LABELS.CUSTOMER_PRICE"
              })}
              margin="normal"
              variant="filled"
              type="number"
              inputProps={{
                min: 0
              }}
              fullWidth
              value={row.CustomerPrice}
              onChange={event => onComboFieldChange(event, "CustomerPrice", row.Combo.Id)}
            />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default injectIntl(MealCombo)
