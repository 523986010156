/* eslint-disable no-restricted-imports */
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";
import InputMask from "react-input-mask";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormattedMessage, injectIntl} from "react-intl";
import {useStyles} from "../../Common/_styles/formDialogStyles";
import {useDispatch} from "react-redux";
import {handleApiError} from "../../../../redux/snackbar/snackbarHandlers";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";

function FreezeFormDialog({
                              show,
                              closeDialog,
                              intl,
                              variant,
                              submitData,
                              isSubmitting,
                              active
                          }) {
    const dispatch = useDispatch();

    const classes = useStyles();
    const initialValues = {};

    function handleSumbit(userID) {
        closeDialog(userID);
    }

    function handleClose() {
        closeDialog(null);
    }

    return (
        <div>
            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick
            >
                <DialogTitle id="form-dialog-title" disableTypography={true}>
                    <h3 style={{fontWeight: "bold", textAlign: "center"}}>
                        <FormattedMessage id="FREEZE_FORM.TITLE"/>
                    </h3>
                </DialogTitle>
                <div>
                    <DialogContent>
                        {
                            active && (
                                <FormattedMessage id="FREEZE_FORM.CONFIRMATION_FREEZE"/>
                            )
                        }
                        {
                            !active && (
                                <FormattedMessage id="FREEZE_FORM.CONFIRMATION_UN_FREEZE"/>
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            onClick={handleClose}
                            className={classes.button}
                        >
                            <FormattedMessage id="FREEZE_FORM.CANCEL_BUTTON"/>
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="secondary"
                            className={classes.button}
                            disabled={isSubmitting}
                            onClick={ev => submitData(ev)}
                        >
                            <FormattedMessage id="FREEZE_FORM.SUBMIT_BUTTON"/>
                            {isSubmitting && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default injectIntl(FreezeFormDialog);
