import React from "react"
import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel
} from "@material-ui/core"
import PropTypes from "prop-types"

export default function EnhancedTableHead(props) {
  const { summaryRows, headRows, orderBy, sortBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align="left"
            width={row.width ? row.width + "%" : "auto"}
            colSpan={row.join || 1}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={sortBy === row.id ? orderBy : false}
          >
            {row.notSortable ? (
                    row.isSelected ?
                        (
                            <Checkbox
                                checked={row.isSelectedDefaultValue}
                                style={{ padding: 12 }}
                                onChange={(ev) => row.onSelect(ev)}
                                color="secondary"
                            />
                        )
                        : (
                                row.label
                        )
            ) : (
              <TableSortLabel
                active={sortBy === row.id}
                direction={orderBy}
                onClick={createSortHandler(row.id)}
                style={{ flexDirection: "row" }}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
      {
        summaryRows && summaryRows.length > 0 &&
        <TableRow  className="summary-row">
          {
            summaryRows.map(row => (
                <TableCell
                    align="left"
                    key={row.id}
                    padding={row.disablePadding ? "none" : "default"}
                >
                  {row.label}
                </TableCell>
            ))
          }
        </TableRow>
      }

    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  headRows: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}
